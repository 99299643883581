import React from 'react';
import utils from '../Misc/Utils';
import update from 'immutability-helper';
import { Modal, Button, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import { connect } from 'react-redux';

class PreviewLogInModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {session: {}, showErrorModal: false};
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show === true && this.props.show === false) {
      this.setState({session: {email: this.props.current_user.preview_email, password: ''}});
    }
  }

  handleChange(event) {
    this.setState({
      session: update(this.state.session, {$merge: {[event.target.name]: event.target.value}})
    });
  }

  submit() {
    $.ajax('/api/token_sessions', {
      data: {session: this.state.session},
      error: () => this.setState({showErrorModal: true}),
      success: () => utils.location_replace('/sent_referrals'),
      type: 'PATCH'
    });
  }

  render() {
    return(
      <div>
        <Modal show={this.props.show} dialogClassName='modal-lg'>
          <Modal.Body>
            <h1>Your email address has been used before.</h1>
            <h1>
              Log in with your password to access all
              referrals and add this provider to your account:
            </h1>
            <form className="form-horizontal m-xl" onChange={this.handleChange}>
              <FormGroup controlId="session_email" className="form-group">
                <ControlLabel className="col-sm-3 control-label">Email:</ControlLabel>
                <div className="col-sm-9">
                  <FormControl type="email" className="form-control" name="email" defaultValue={this.state.session.email} />
                </div>
              </FormGroup>
              <FormGroup controlId="session_password" className="form-group">
                <ControlLabel className="col-sm-3 control-label">Password:</ControlLabel>
                <div className="col-sm-9">
                  <FormControl type="password" className="form-control" name="password" />
                </div>
              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.submit}>Submit</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showErrorModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">Those credentials are not valid. Please try again.</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={() => this.setState({showErrorModal: false})}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    current_user: state.current_user
  }
}

export default connect(mapStateToProps)(PreviewLogInModal)

PreviewLogInModal.propTypes = {
  current_user: React.PropTypes.object.isRequired
}
