export default {
  location_replace: (uri) => {
    typeof window != 'undefined' && window.location.replace(uri)
  },

  window_open: (url, name, features) => {
    typeof window != 'undefined' && window.open(url,(name || ''))
  },

  window_reload: (force) => {
    if(typeof window != 'undefined'){
      window.location.reload(force)
    }
  },

  set_window_location: (href) => {
    if(typeof window != 'undefined'){
      window.location.href = href
    }
  },

  asset_src: {
    aptible_hipaa_badge: '/img/aptible_hipaa_badge.png',
    no_pictures_available: '/img/no_pictures_available.png',
    avatar: '/img/avatar.png',
    sample: '/img/sample.png'
  },

  ie9Browser: function(){
    var ua = typeof window != 'undefined' ? window.navigator.userAgent : '';
    var msie = ua.indexOf('MSIE ');
    return(msie > 0 && parseInt(ua.substring(msie + 5, ua.indexOf('.', msie))) <= 9)
  },

  getCookie: function(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
  },

  providerTypesDesc:  {
    hha:  'Home Health Agency',
    snf:  'Skilled Nursing Facility',
    ltac: 'Long Term Acute Care Hospital',
    pd:   'Private Duty Agency',
    irf:  'Inpatient Rehab Facility',
    trns: 'Transportation',
    dial: 'Dialysis',
    inf:  'Infusion',
    dme:  'Durable Medical Equipment',
    dar:  'Drug Rehab',
    psyc: 'Inpatient Psych',
    alf:  'Assisted Living/Congregate',
    hosp: 'Hospice',
    outpsyc: 'Outpatient Psych',
    pay: 'Payor',
    pcp: 'Primary Care',
    acute: 'Hospital/Acute Care',
    recupe: 'Recuperative Care',
    inhosp: 'Inpatient Hospice',
    pal: 'Palliative',
  },

  locationalProviderTypes: ['trns', 'pd', 'hha', 'inf', 'hosp', 'dme'],

  nonReviewTypes: ['trns', 'inf', 'dme'],

  declinedReasonsOptions: [
    ['', 'Please Select a Declined Reason'],
    ['no_bed', 'No bed'],
    ['staff_shortage', 'Staff shortage'],
    ['declined_care_needs', 'Care needs exceed capacity'],
    ['declined_insurance', 'Insurance mismatch'],
    ['service_area', 'Service area'],
    ['questionable_disp_plan', 'Questionable dispo plan'],
    ['does_not_meet_criteria', 'Does not meet criteria'],
    ['specialty_provider_unavailable', 'Specialty provider unavailable'],
    ['on_service_with_another_provider', 'On service with another provider'],
    ['other', 'Other']
  ],

  dischargeDispositions: [
    ['discharged_home', 'Discharged home w/o services'],
    ['discharged_home_with_services', 'Discharged home with outpatient/ambulatory services'],
    ['discharged_hospital', 'Hospital'],
    ['discharged_hha', 'Homecare'],
    ['discharged_snf', 'Skilled Nursing'],
    ['discharged_irf', 'Inpatient Rehab'],
    ['discharged_ltac', 'Long Term Acute Care'],
    ['discharged_deceased', 'Patient Deceased'],
    ['discharged_unknown', 'Unknown']
  ],

  trnsReasons: {
    bed_confined: 'Bed Confined',
    paralyzed: 'Paralyzed / CVA',
    need_supported_seating: 'Cannot self support while seated',
    confused_combative: 'Confused / combative / danger to self or others',
    other: 'Other'
  },

  usStatesOptions: [
    ['', "Please Select a State"],
    [12, "Alaska"],
    [40, "Alabama"],
    [17, "Arkansas"],
    [3, "Arizona"],
    [41, "California"],
    [42, "Colorado"],
    [36, "Connecticut"],
    [31, "Washington, D.C."],
    [43, "Delaware"],
    [4, "Florida"],
    [30, "Georgia"],
    [10, "Guam"],
    [20, "Hawaii"],
    [54, "Iowa"],
    [60, "Idaho"],
    [18, "Illinois"],
    [21, "Indiana"],
    [26, "Kansas"],
    [46, "Kentucky"],
    [5, "Lousiana"],
    [59, "Massachusetts"],
    [28, "Maryland"],
    [48, "Maine"],
    [24, "Michigan"],
    [32, "Minnesota"],
    [19, "Missouri"],
    [16, "Mississippi"],
    [6, "Montana"],
    [13, "North Carolina"],
    [44, "North Dakota"],
    [34, "Nebraska"],
    [53, "New Hampshire"],
    [61, "New Jersey"],
    [11, "New Mexico"],
    [37, "Nevada"],
    [58, "New York"],
    [35, "Ohio"],
    [39, "Oklahoma"],
    [14, "Oregon"],
    [2, "Pennsylvania"],
    [38, "Puerto Rico"],
    [49, "Rhode Island"],
    [56, "South Carolina"],
    [50, "South Dakota"],
    [51, "Tennessee"],
    [62, "Texas"],
    [23, "Utah"],
    [52, "Virginia"],
    [29, "Virgin Islands"],
    [15, "Vermont"],
    [47, "Washington"],
    [33, "Wisconsin"],
    [45, "West Virginia"],
    [22, "Wyoming"],
    [63, "American Samoa"],
    [64, "Northern Mariana Islands"]
  ],

  usStatesMap: {
    12: "Alaska",
    40: "Alabama",
    17: "Arkansas",
    3: "Arizona",
    41: "California",
    42: "Colorado",
    36: "Connecticut",
    31: "Washington, D.C.",
    43: "Delaware",
    4: "Florida",
    30: "Georgia",
    10: "Guam",
    20: "Hawaii",
    54: "Iowa",
    60: "Idaho",
    18: "Illinois",
    21: "Indiana",
    26: "Kansas",
    46: "Kentucky",
    5: "Lousiana",
    59: "Massachusetts",
    28: "Maryland",
    48: "Maine",
    24: "Michigan",
    32: "Minnesota",
    19: "Missouri",
    16: "Mississippi",
    6: "Montana",
    13: "North Carolina",
    44: "North Dakota",
    34: "Nebraska",
    53: "New Hampshire",
    61: "New Jersey",
    11: "New Mexico",
    37: "Nevada",
    58: "New York",
    35: "Ohio",
    39: "Oklahoma",
    14: "Oregon",
    2: "Pennsylvania",
    38: "Puerto Rico",
    49: "Rhode Island",
    56: "South Carolina",
    50: "South Dakota",
    51: "Tennessee",
    62: "Texas",
    23: "Utah",
    52: "Virginia",
    29: "Virgin Islands",
    15: "Vermont",
    47: "Washington",
    33: "Wisconsin",
    45: "West Virginia",
    22: "Wyoming",
    63: "American Samoa",
    64: "Northern Mariana Islands"
  }

}
