import { connect } from 'react-redux';
import utils from '../Misc/Utils';
import React from 'react';
import request from 'superagent';
import { SpinnerWave } from '../Misc/Spinners.jsx';
import C3Chart from 'react-c3js';
import ReactTable from 'react-table';
 
class ProviderReport extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      loading: true,
      show_pie_charts: true,
      show_tasks_on_time: true,
      show_srmetrics_responded: true,
      show_breakdown_responded: true,
      show_breakdown_chosen: true,
      show_group: true
    }
  }

  componentDidMount(){
    this.retrieveData()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.filters.last_updated != this.props.filters.last_updated){
      this.retrieveData(nextProps.filters)
    }
    if(nextProps.filters.xlsx_request != this.props.filters.xlsx_request){
      this.retrieveData(nextProps.filters, true)
    }
  }

  retrieveData = (filters, xlsx) => {
    if(xlsx){
      var url = "/api/reports/provider/" + this.props.opts.provider_id + "?export=xlsx&" + Object.entries(this.props.filters).map(([key, val]) => `${key}=${val}`).join('&')
      utils.window_open(url,'')
    } else {
      var query_id = Math.random()
      this.setState({loading:true, last_query: query_id})
      var req = request.get("/api/reports/provider/" + this.props.opts.provider_id) 
      .query(this.props.filters)
      .end( (err,res) => {
        if(this.state.last_query == query_id){
          this.setState({loading:false})
          this.setState(res.body)
        }
      })
    }
  }

  scoreDesc = (score) => {
    return ['Not enough data',
      'Lowest available',
      'Available',
      'Best available'
    ][score] || 'Not enough data'
  }

  fmtPct = (n) => {
    return n ? parseFloat(n).toFixed(2) + '%' : ''
  }

  fmtPctBig = (n) => {
    return n ? <h1 style={{fontSize:'3em'}}>{parseFloat(n).toFixed(2) + '%'}</h1> : <h3>Not enough data</h3>
  }

  fmtHospitalId = data => {
    return this.props.opts.hospital_campuses[data.hospital_id]
  }

  fmtProviderType = data => {
    return this.props.opts.provider_types_inv[data.provider_type][1]
  }


  forceNumericSort = (a,b) => {
    var pa = parseFloat(a)
    var pb = parseFloat(b)
    a = (a === null || a === undefined || isNaN(pa)) ? -Infinity : parseFloat(a)
    b = (b === null || b === undefined || isNaN(pb)) ? -Infinity : parseFloat(b)
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  }


  tableKeys = (chosen_columns) => {
    var arr = [
      { id: "referral_no", accessor: "referral_no", Header: "Referral #" },
      { id: "patient_visit_id", accessor: "patient_visit_id", Header: "Patient Visit ID" },
      { id: "mrn", accessor: "mrn", Header: "Patient MRN" },
      { id: "hospital_id", accessor: this.fmtHospitalId, Header: "Hospital ID" },
      { id: "service_line", accessor: "service_line", Header: "Service Line" },
      { id: "provider_type", accessor: this.fmtProviderType, Header: "Provider Type" },
      { id: "resuming_care", accessor: "resuming_care", Header: "Resuming Care" },
      { id: "name", accessor: "name", Header: "Financial Class Name" },
      { id: "primary_insurances", accessor: "primary_insurances", Header: "Primary Insurance" },
      { id: "secondary_insurances", accessor: "secondary_insurances", Header: "Secondary Insurance" },
      { id: "sent_referrals", accessor: "sent_referrals", Header: "Sent Referrals" },
      { id: "medicare_score", accessor: data => this.scoreDesc(data.medicare_score), Header: "Medicare Score" },
      { id: "outcome_score", accessor: data => this.scoreDesc(data.outcome_score), Header: "Outcome Score" },
      { id: "satisfaction_score", accessor: data => this.scoreDesc(data.satisfaction_score), Header: "Satisfaction Score" },
      { id: "medicare_star", accessor: "medicare_star", Header: "Medicare Star" },
      { id: "respond_task_at", accessor: "respond_task_at", Header: "Respond Task At" },
      { id: "respond_task", accessor: "respond_task", Header: "Respond Task By" },
      { id: "receive_task_at", accessor: "receive_task_at", Header: "Receive Task At" },
      { id: "receive_task", accessor: "receive_task", Header: "Receive Task By" },
      { id: "update_task_at", accessor: "update_task_at", Header: "Update Task At" },
      { id: "update_task", accessor: "update_task", Header: "Update Task By" },
      { id: "patient_satisfaction_score", accessor: "patient_satisfaction_score", Header: "Patient Satisfaction Score" }
    ]
    return arr
  }

  taskLabel = v => {
    return {
      'update_patient_status': 'update patient status',
      'receive_patient': 'receive patient'
    }[v] || v
  }

  taskOrder = ['submit','reopen','share','reserve','discharge']

  metrics_table = (metrics, chosen_columns) => {
      return (
        <div>
          <ReactTable
              defaultSortDesc={true}
              defaultPageSize={10}
              className="report_meta -striped"
              columns={this.tableKeys(chosen_columns)} 
              data={metrics}
              sortable={true}
              getTdProps = {(state,rowInfo,column,instance) => {
                return {
                  className: "tdcol-" + column.id
                }
              } }
              getTrProps = {(state,rowInfo,column,instance) => {
                return {
                  //className: "clickable",
                  // onClick: (e, handleOrig) => {
                  //   this.openProviderPage(rowInfo.row.user_id)
                  //}
                }      
              } }
          >
          </ReactTable>
        </div>
      )
  }

  pieChart = (hashdt, title) => {
    var columns = _.map(hashdt, (k,v) => [v].concat(k))
    if(hashdt){
      return (<div className="reportGauge reportGaugeBig piblock">
      <C3Chart
      data={{ 
        type: 'pie',
        columns: columns
      }} />
      <h2>{ title }</h2>
      </div>)
    } else {
      return <span /> 
    }
  }

  groupTable = (data, title, columns) => {
    columns = columns || []
    return (
      <div className="reportGauge reportGaugeBig piblock">
        <h2>{ title }</h2>
        <table className="w100 table">
          <tbody>
            <tr>
              <th>{ columns[0] || 'Name' }</th>
              <th>{ columns[1] || '# Referrals' }</th>
              <th>{ columns[1] || '# Chosen' }</th>
            </tr>
            {_.map(data, (d,i) => 
              <tr key={i}>
                <td>{ d.name || d.category }</td>
                <td>{ d.referrals_count }</td> 
                <td>{ d.chosen_count }</td> 
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    return (
      <div className="reportsContent">
        { this.state.loading && <SpinnerWave /> }

        { !this.state.loading && this.state.srmetrics_responded && <h2 className="cp" onClick={()=>{ this.setState({show_srmetrics_responded: !this.state.show_srmetrics_responded }) }}>
          <i className={"fa " + (this.state.show_srmetrics_responded ? "fa-chevron-down" : "fa-chevron-right")  } /> Performance
        </h2> }

        { !this.state.loading && this.state.srmetrics_responded && this.state.show_srmetrics_responded && <div style={{display:'flex'}}>
          <div className="rowFlx3">
            <h3>Outcomes</h3>
            { this.fmtPctBig(this.state.srmetrics_responded[0].avg_outcome_pct) }
          </div>
          <div className="rowFlx3">
            <h3>Medicare Star Scores</h3>
            { this.fmtPctBig(this.state.srmetrics_responded[0].avg_medicare_pct) }
          </div>
          <div className="rowFlx3">
            <h3>Patient Satisfaction</h3>
            { this.fmtPctBig(this.state.srmetrics_responded[0].avg_satisfaction_pct) }
          </div>
        </div> }

        { !this.state.loading && this.state.tasks_on_time && <h2 className="cp" onClick={()=>{ this.setState({show_tasks_on_time: !this.state.show_tasks_on_time }) }}>
          <i className={"fa " + (this.state.show_tasks_on_time ? "fa-chevron-down" : "fa-chevron-right")  } /> Tasks Completed on Time, By Type
        </h2> }

        { !this.state.loading && this.state.show_tasks_on_time && <div className="reportGauges">
          { this.state.tasks_on_time && Object.keys(this.state.tasks_on_time).length == 0 && <div className="piblock"><h2>No results</h2></div> }
          {_.map(_.orderBy(this.state.tasks_on_time, p => this.taskOrder.indexOf(p.task_type)) , (p) => {
            var total = p.completed_on_time + p.not_completed_on_time
            var t_pct = parseFloat(p.completed_on_time / total) * 100
            return (
              <div className="reportGauge piblock" key={ p.task_type + this.props.filters.last_updated }>
                <C3Chart data={{
                  type: 'gauge',
                  columns: [['Completed on time', t_pct]]
                }} />
                <p style={{margin:'0.25em 0'}}>{ p.completed_on_time } on time, out of { total } total</p>
                <h1 style={{textTransform:'capitalize', margin: '0.25em 0'}}>{ this.taskLabel(p.task_type) }</h1>
              </div>
            )
            }
          )}
        </div> }


        { !this.state.loading && this.state.bypayor && <h2 className="cp" onClick={()=>{ this.setState({show_pie_charts: !this.state.show_pie_charts }) }}>
          <i className={"fa " + (this.state.show_pie_charts ? "fa-chevron-down" : "fa-chevron-right")  } /> Referral Statistics
        </h2> }

        
        { !this.state.loading && this.state.show_pie_charts && <div className="reportGauges">
          { this.pieChart(this.state.bypayor, "By Payor" )}
          { this.pieChart(this.state.byrefsource, "By Referral Source" )}
          { this.pieChart(this.state.byresponse, "By Response" )}
          { this.pieChart(this.state.bycaretype, "By Care Type" )}
        </div> }


        { !this.state.loading && this.state.in_process_referrals && <h2 className="cp" onClick={()=>{ this.setState({show_in_process_referrals: !this.state.show_in_process_referrals }) }}>
          <i className={"fa " + (this.state.show_in_process_referrals ? "fa-chevron-down" : "fa-chevron-right")  } /> In-Progress Referrals
        </h2> }
        
        { !this.state.loading && this.state.in_process_referrals && this.state.show_in_process_referrals && this.metrics_table(this.state.in_process_referrals, false) }


        { !this.state.loading && this.state.groupbypayor && <h2 className="cp" onClick={()=>{ this.setState({show_group: !this.state.show_group }) }}>
          <i className={"fa " + (this.state.show_group ? "fa-chevron-down" : "fa-chevron-right")  } /> Referral Breakdowns 
        </h2> }
        
        { !this.state.loading && this.state.show_group && this.state.groupbycaretype && this.groupTable(this.state.groupbycaretype, 'Care Types') }
        { !this.state.loading && this.state.show_group && this.state.groupbypayor && this.groupTable(this.state.groupbypayor, 'Payors') }
        { !this.state.loading && this.state.show_group && this.state.groupbyhospital && this.groupTable(this.state.groupbyhospital, 'Hospitals') }


        { !this.state.loading && this.state.breakdown_responded && <h2 className="cp" onClick={()=>{ this.setState({show_breakdown_responded: !this.state.show_breakdown_responded }) }}>
          <i className={"fa " + (this.state.show_breakdown_responded ? "fa-chevron-down" : "fa-chevron-right")  } /> Referrals (Responded)
        </h2> }
        
        { !this.state.loading && this.state.breakdown_responded && this.state.show_breakdown_responded && this.metrics_table(this.state.breakdown_responded, false) }

        { !this.state.loading && this.state.breakdown_chosen && <h2 className="cp" onClick={()=>{ this.setState({show_breakdown_chosen: !this.state.show_breakdown_chosen }) }}>
          <i className={"fa " + (this.state.show_breakdown_chosen ? "fa-chevron-down" : "fa-chevron-right")  } /> Referrals (Chosen)
        </h2> }
        
        { !this.state.loading && this.state.breakdown_chosen && this.state.show_breakdown_chosen && this.metrics_table(this.state.breakdown_chosen, false) }




      </div>
    )
  }

}


const mapStateToProps = state => {
  return {
    current_user: state.current_user,
    current_organization: state.current_organization
  }
}

export default connect(mapStateToProps)(ProviderReport)
