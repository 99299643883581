import React from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import { FormControl, FormGroup, ControlLabel, Button } from 'react-bootstrap';
import BrowserSupportWrapper from '../../BrowserSupportWrapper';
import Video from '../Video';

export default class UpdatePatientStatusSubmit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loading: false, file: null, attachments: []};
  }

  handleFileChange = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => this.setState({file: file});

    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.setState({file: null});
    }
  }

  attachFile = () => {
    let formData = new FormData();
    formData.append('attachment[file]', this.state.file);

    this.setState({loading: true}, () => {
      $.ajax('/api/attachments/create_without_attachable', {
        complete: () => {
          this.setState({loading: false});
        },
        contentType: false,
        data: formData,
        processData: false,
        success: (response) => {
          this.setState({
            file: null,
            attachments: update(this.state.attachments, {$push: [response]})
          });
          this.refs.form.reset();
          this.props.addAttachment(response);
        },
        type: 'POST'
      });
    });
  }

  removeAttachment = (event) => {
    event.preventDefault();
    let id = event.target.value;
    let index = _.findIndex(this.state.attachments, {id: +id});

    this.setState({loading: true}, () => {
      $.ajax(`/api/attachments/${id}`, {
        complete: () => {
          this.setState({loading: false});
        },
        success: (response) => {
          this.setState({
            attachments: update(this.state.attachments, {$splice: [[index, 1]]})
          });
          this.props.removeAttachment(response);
        },
        type: 'DELETE'
      });
    });
  }

  render() {
    return(
      <fieldset className="body current">
        <div className="row">
          <div className="col-lg-5">
            <div className="text-center">
              <div className="m-t-md">
                <Video src="https://www.youtube.com/embed/7Pf35svYBk0?rel=0&showinfo=0" />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <h2>Optional: Share notes with your referral partner {this.props.hospital.name}.</h2>
            <form className="form-horizontal m-t-md">
              <FormGroup controlId="referral_provider_discharged" className="form-group">
                <ControlLabel className="col-sm-12 control-label" style={{textAlign: 'left'}}>Patient's clinical status at {this.props.provider.name}:</ControlLabel>
                <div className="col-sm-12">
                  <FormControl onChange={this.props.handleClinicalStatusChange} componentClass="textarea" style={{height: 100}} />
                </div>
              </FormGroup>
            </form>
            <BrowserSupportWrapper>
              <div className={`ibox-content${this.state.loading ? ' sk-loading' : ''}`} style={{border: 'none', background: 'none'}}>
                <div className="sk-spinner sk-spinner-double-bounce">
                  <div className="sk-double-bounce1"></div>
                  <div className="sk-double-bounce2"></div>
                </div>
                <form className="form-horizontal" ref="form">
                  <ControlLabel className="col-sm-12 control-label" style={{textAlign: 'left'}}>Attach patient clinical notes:</ControlLabel>
                  <FormGroup controlId="patient_status_update_attachment" className="form-group">
                    <div className="col-sm-12 m-t-md">
                      <ul>
                        {this.state.attachments.map((attachment, index) => <li key={attachment.id}>{attachment.full_name}<button value={attachment.id} onClick={this.removeAttachment} className="close text-danger close-delete">×</button></li>)}
                      </ul>
                      <FormControl type="file" className="form-control" name="attachment" onChange={this.handleFileChange} style={{border: 'none'}} />
                      <Button bsStyle="primary" onClick={this.attachFile}>Attach File</Button>
                    </div>
                  </FormGroup>
                </form>
              </div>
            </BrowserSupportWrapper>
          </div>
        </div>
      </fieldset>
    );
  }
}

UpdatePatientStatusSubmit.propTypes = {
  addAttachment: React.PropTypes.func.isRequired,
  removeAttachment: React.PropTypes.func.isRequired,
  handleClinicalStatusChange: React.PropTypes.func.isRequired,
  hospital: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired
}
