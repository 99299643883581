import _ from 'lodash';
import utils from '../../Misc/Utils';
import React from 'react';
import moment from 'moment';
import update from 'immutability-helper';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateActivities, updateTasks } from '../../../actions';
import TaskButton from '../TaskButton';
import UpdatePatientStatus from './UpdatePatientStatus';
import UpdatePatientStatusSubmit from './UpdatePatientStatusSubmit';
import Wizard from '../../Wizard';
import WizardStep from '../../Wizard/WizardStep';

class UpdatePatientStatusTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 'updatePatientStatus',
      errors: {},
      possibleSteps: ['updatePatientStatus', 'updatePatientStatusSubmit'],
      patient_status_update: {
        attachable_attachments_attributes: [],
        clinical_status: undefined,
        referral_changes: {
          discharge_disposition: '',
          indefinite_service: false,
          organization_discharged_to_id: undefined,
          organization_discharged_to_name: undefined,
          organization_discharged_to_type: undefined,
          provider_actual_discharge_date: undefined,
          provider_actual_received_date: props.referral.provider_actual_received_date,
          provider_anticipated_discharge_date: props.referral.provider_anticipated_discharge_date
        },
        sent_referral_id: props.sent_referral_id,
        status: '',
        task_id: props.task.id,
        user_id: props.current_user.id
      },
      valid: false
    };
  }

  close = () => {
    this.setState({show: false});
  }

  getCurrentStep = () => {
    let content;

    switch(this.state.currentStep) {
      case 'updatePatientStatus':
        content =
          <UpdatePatientStatus
            errors={this.state.errors}
            handleDischargeDispositionChange={this.handleDischargeDispositionChange}
            handleIndefiniteServiceChange={this.handleIndefiniteServiceChange}
            handleOrganizationDischargedToChange={this.handleOrganizationDischargedToChange}
            handleOrganizationDischargedToNameChange={this.handleOrganizationDischargedToNameChange}
            handleProviderActualDischargeDateChange={this.handleProviderActualDischargeDateChange}
            handleProviderActualDischargeDateFocus={this.handleProviderActualDischargeDateFocus}
            handleProviderActualReceivedDateChange={this.handleProviderActualReceivedDateChange}
            handleProviderActualReceivedDateFocus={this.handleProviderActualReceivedDateFocus}
            handleProviderAnticipatedDischargeDateChange={this.handleProviderAnticipatedDischargeDateChange}
            handleProviderAnticipatedDischargeDateFocus={this.handleProviderAnticipatedDischargeDateFocus}
            handleStatusChange={this.handleStatusChange}
            patient={this.props.patient}
            patient_status_update={this.state.patient_status_update}
            provider={this.props.provider}
          />;
        break;
      case 'updatePatientStatusSubmit':
        content =
          <UpdatePatientStatusSubmit
            addAttachment={this.addAttachment}
            removeAttachment={this.removeAttachment}
            handleClinicalStatusChange={this.handleClinicalStatusChange}
            hospital={this.props.hospital}
            provider={this.props.provider}
          />;
        break;
    }

    return <WizardStep header="Click Play for Help">{content}</WizardStep>;
  }

  addAttachment = (attachment) => {
    this.setState({
      patient_status_update: update(
        this.state.patient_status_update, {
          attachable_attachments_attributes: {
            $push: [{attachment_id: attachment.id}]
          }
        }
      )
    }, this.validate);
  }

  removeAttachment = (attachment) => {
    let index = _.findIndex(this.state.patient_status_update.attachable_attachments_attributes, {attachment_id: attachment.id});

    this.setState({
      patient_status_update: update(
        this.state.patient_status_update, {
          attachable_attachments_attributes: {
            $splice: [[index, 1]]
          }
        }
      )
    }, this.validate);
  }

  handleClinicalStatusChange = (event) => {
    this.setState({
      patient_status_update: update(
        this.state.patient_status_update, {
          $merge: {
            clinical_status: event.target.value
          }
        }
      )
    }, this.validate);
  }

  handleDischargeDispositionChange = (event) => {
    this.setState({
      patient_status_update: update(
        this.state.patient_status_update, {
          referral_changes: {
            $merge: {
              discharge_disposition: event.target.value
            }
          }
        }
      )
    }, this.validate);
  }

  handleIndefiniteServiceChange = (event) => {
    this.setState({
      patient_status_update: update(
        this.state.patient_status_update, {
          referral_changes: {
            $merge: {
              indefinite_service: event.target.checked,
              provider_anticipated_discharge_date: null
            }
          }
        }
      )
    }, () => {
      if (this.state.patient_status_update.referral_changes.indefinite_service) {
        this.setState({
          errors: update(this.state.errors, {$unset: ['provider_anticipated_discharge_date']}),
        }, this.validate);
      } else {
        this.validate();
      }
    });
  }

  handleOrganizationDischargedToChange = (organizations) => {
    organizations[0] &&
      this.setState({
        patient_status_update: update(
          this.state.patient_status_update, {
            referral_changes: {
              $merge: {
                organization_discharged_to_id: organizations[0].id,
                organization_discharged_to_name: organizations[0].name,
                organization_discharged_to_type: organizations[0].klass
              }
            }
          }
        )
      }, this.validate);
  }

  handleOrganizationDischargedToNameChange = (event) => {
    this.setState({
      patient_status_update: update(
        this.state.patient_status_update, {
          referral_changes: {
            $merge: {
              organization_discharged_to_id: undefined,
              organization_discharged_to_name: event.target.value,
              organization_discharged_to_type: undefined
            }
          }
        }
      )
    }, this.validate);
  }

  handleProviderActualDischargeDateChange = (value, formattedValue) => {
    let errors = {};

    if (new Date(formattedValue) < new Date(this.state.patient_status_update.referral_changes.provider_actual_received_date)) {
      errors = {provider_actual_discharge_date: 'Cannot be before Date Services Began'};
    }

    this.setState({
      errors: errors,
      patient_status_update: update(
        this.state.patient_status_update, {
          referral_changes: {
            $merge: {
              provider_actual_discharge_date: formattedValue
            }
          }
        }
      )
    }, this.validate);
  }

  handleProviderActualDischargeDateFocus = () => {
    if (!this.state.patient_status_update.referral_changes.provider_actual_discharge_date) {
      this.setState({
        patient_status_update: update(
          this.state.patient_status_update, {
            referral_changes: {
              $merge: {
                provider_actual_discharge_date: new Date()
              }
            }
          }
        )
      }, this.validate);
    }
  }

  handleProviderActualReceivedDateChange = (value, formattedValue) => {
    this.setState({
      patient_status_update: update(
        this.state.patient_status_update, {
          referral_changes: {
            $merge: {
              provider_actual_received_date: formattedValue
            }
          }
        }
      )
    }, this.validate);
  }

  handleProviderActualReceivedDateFocus = () => {
    if (!this.state.patient_status_update.referral_changes.provider_actual_received_date) {
      this.setState({
        patient_status_update: update(
          this.state.patient_status_update, {
            referral_changes: {
              $merge: {
                provider_actual_received_date: new Date()
              }
            }
          }
        )
      }, this.validate);
    }
  }

  handleProviderAnticipatedDischargeDateChange = (value, formattedValue) => {
    let errors = {};

    if (formattedValue && moment(formattedValue, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'))) {
      errors = {provider_anticipated_discharge_date: 'Must be in the future'};
    }

    this.setState({
      errors: errors,
      patient_status_update: update(
        this.state.patient_status_update, {
          referral_changes: {
            $merge: {
              provider_anticipated_discharge_date: formattedValue
            }
          }
        }
      )
    }, this.validate);
  }

  handleProviderAnticipatedDischargeDateFocus = () => {
    if (!this.state.patient_status_update.referral_changes.provider_anticipated_discharge_date) {
      this.setState({
        patient_status_update: update(
          this.state.patient_status_update, {
            referral_changes: {
              $merge: {
                provider_anticipated_discharge_date: moment().add(1, 'days').format('YYYY-MM-DD')
              }
            }
          }
        )
      }, this.validate);
    }
  }

  handleStatusChange = (event) => {
    this.setState({
      patient_status_update: update(
        this.state.patient_status_update, {
          $merge: {
            status: event.target.value
          }
        }
      )
    }, this.validate);
  }

  handleSubmit = () => {
    $.ajax({
      data: {
        task: {
          completed: true,
          referral_attributes: {
            patient_status_updates_attributes: [this.state.patient_status_update]
          },
          user_id: this.props.current_user.id
        }
      },
      success: (response) => {
        this.close();
        this.props.updateActivities(response.activity);
        this.props.updateTasks(response.task);

        if (this.props.current_user.previewer) {
          if (this.props.current_user.previewer_signed_up) {
            this.props.showSignUpModal('previewLogIn', true);
          } else {
            this.props.showSignUpModal('previewSignUp', true);
          }
        } else {
          utils.window_reload(true);
        }
      },
      type: 'PATCH',
      url: `/api/tasks/${this.props.task.id}`
    });
  }

  open = () => {
    this.setState({show: true});
  }

  updateStep = (nextStep) => {
    if (this.state.valid) {
      this.setState({currentStep: nextStep});
    }
  }

  validate = () => {
    let valid = !!(
      _.isEmpty(this.state.errors) &&
      (
        this.state.patient_status_update.status === 'discharged' &&
          this.state.patient_status_update.referral_changes.discharge_disposition &&
          this.state.patient_status_update.referral_changes.provider_actual_discharge_date &&
          this.state.patient_status_update.referral_changes.provider_actual_received_date ||
        this.state.patient_status_update.status === 'still_receiving_services' &&
          (
            this.state.patient_status_update.referral_changes.provider_anticipated_discharge_date ||
            this.state.patient_status_update.referral_changes.indefinite_service
          )
      )
    );

    this.setState({valid: valid});
  }

  render() {
    return(
      <span>
        <TaskButton
          id={this.props.task.id}
          task={this.props.task}
          onClick={this.open}
        />
        <Modal show={this.state.show} onHide={this.close} bsSize="lg">
          <Modal.Header closeButton>
            <Modal.Title>Update Status for {this.props.patient.full_name} (Referral #{this.props.referral.id})</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Wizard
              currentStep={this.state.currentStep}
              handleSubmit={this.handleSubmit}
              isStepValidDisplay={this.state.valid}
              possibleSteps={this.state.possibleSteps}
              updateStep={this.updateStep}
            >
              {this.getCurrentStep()}
            </Wizard>
          </Modal.Body>
        </Modal>
      </span>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivities: (activities) => dispatch(updateActivities(activities)),
    updateTasks: (tasks) => dispatch(updateTasks(tasks))
  }
}

export default connect(null, mapDispatchToProps)(UpdatePatientStatusTask)

UpdatePatientStatusTask.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  hospital: React.PropTypes.object.isRequired,
  patient: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired,
  sent_referral_id: React.PropTypes.number.isRequired,
  showSignUpModal: React.PropTypes.func.isRequired,
  task: React.PropTypes.object.isRequired
}
