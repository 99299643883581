import React from 'react';
import inflection from 'inflection';
import moment from 'moment';

export default class Activity extends React.Component {
  render() {
    return(
      <tr>
        <td>
          <span className="label label-primary">
            <i className="fa fa-check"></i> {inflection.titleize(this.props.activity.status)}
          </span>
        </td>
        <td>
          {this.props.activity.title}
        </td>
        <td>
          <p className="small">
            {this.props.activity.description}
          </p>
        </td>
        <td>
          {moment(this.props.activity.created_at).format('MM.DD.Y h:mma')}
        </td>
      </tr>
    )
  }
}

Activity.propTypes = {
  activity: React.PropTypes.object.isRequired
};