import React from 'react';
import utils from '../Misc/Utils';
import _ from 'lodash';
import CheckboxSection from '../Forms/CheckboxSection';
import { Modal, Button } from 'react-bootstrap';

export default class PrintReferralModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrintLinks: false,
      isLoading: false,
      showIndividualAttachments: false
    }
    this.checkAttachmentsExists = this.checkAttachmentsExists.bind(this);
    this.createAllAttachments = this.createAllAttachments.bind(this);
    this.getAttachmentUrl = this.getAttachmentUrl.bind(this);
    this.printPHIChange = this.printPHIChange.bind(this);
  }

  checkAttachmentsExists(){
    let ctx = this;
    $.ajax({
      type: 'GET',
      url: `/api/sent_referrals/${ctx.props.sent_referral.id}/check_attachments_exists`,
      complete: function (response) {
        if (response.status === 200) {
          utils.set_window_location(response.responseJSON['attachment_url']);
        } else if (response.status === 202) {
          setTimeout(ctx.checkAttachmentsExists, 1000);
        } else {
          alert("There was a problem generating your full attachment. Please download each attachment individually")
        }
      }
    });
  }

  createAllAttachments(){
    this.setState({isLoading: true});
    let ctx = this;
    $.ajax({
      type: 'GET',
      url: `/api/sent_referrals/${ctx.props.sent_referral.id}/create_attachments`,
      complete: function (response) {
        if (response.status === 200) {
          utils.set_window_location(response.responseJSON['attachment_url']);
        } else if (response.status === 202) {
          ctx.checkAttachmentsExists()
        } else {
          alert("There was a problem generating your full attachment. Please download each attachment indiviudally")
        }
      }
    });
  }

  getAttachmentUrl(attachment){
    if (attachment.klass === 'PatientReport') {
      utils.set_window_location(`/patient_reports/${attachment.id}`);
    } else {
      $.ajax({
        url: '/api/sent_referrals/'+this.props.sent_referral.id+'/attachments/'+attachment.id,
        success: (response) => {
          utils.set_window_location(response["attachment_url"]);
        },
        type: 'GET'
      });
    }
  }

  mapAttachments(){
    return _.map(this.props.attachments, (attachment, i) => {
      return(
        <div className='m-t' key={i}>
          {attachment.full_name}
          <Button
            bsSize="small"
            bsStyle="primary"
            className="m-l"
            onClick={() => {this.getAttachmentUrl(attachment)}}
          >
            Print
          </Button>
        </div>
      )
    })
  }

  printPHIChange(e) {
    if (e.target.value === 'true' || e.target.value === true) {
      this.setState({showPrintLinks: true});
    } else {
      this.setState({showPrintLinks: false});
    }
  }

  render(){
    let content = null;
    if (this.props.attachments.length > 0) {
      content = this.mapAttachments();
    } else {
      content = <div className='font-italic'>No attachments available</div>;
    }
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>
            What do you want to print?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          { !this.state.showPrintLinks &&
            <div>
              <h2>HIPAA Certification Statement</h2>
              <CheckboxSection
                id={'print-phi'}
                value={this.state.showPrintLinks}
                onChange={this.printPHIChange}
                labelText={"This referral may contain PHI. Treat printed materials accordingly."}
              />
            </div>
          }

          { this.state.showPrintLinks &&
            <div>
              <Button
                bsSize="large"
                bsStyle="primary"
                className="m-t"
                disabled={this.state.isLoading}
                onClick={() => { typeof ga != 'undefined' && ga('send', 'event', 'Print Modal', 'Button', 'Print Referral'); this.createAllAttachments() }}
              >
                Print Referral
              </Button>
              {
                !this.state.showIndividualAttachments
                ? <div>
                    <Button
                      bsSize="lg"
                      className="m-t"
                      disabled={this.state.isLoading}
                      onClick={() => { typeof ga != 'undefined' && ga('send', 'event', 'Print Modal', 'Button', 'Print Individual Attachments'); this.setState({showIndividualAttachments: true}) }}
                    >
                      Print Individual Attachments
                    </Button>
                  </div>
                : null
              }
              {
                this.state.showIndividualAttachments
                ? <div>
                    <h2>Print View</h2>
                    <ul>
                      <li>
                        Facesheet
                        <span>
                        <Button
                          bsSize="small"
                          bsStyle="primary"
                          className="m-l"
                          onClick={() => {
                            utils.set_window_location(`/sent_referrals/${this.props.sent_referral.id}/print`)
                          }}
                        >
                          Print
                        </Button>
                        </span>
                      </li>
                    </ul>

                    <div className='Attachments'>
                      <h2>Attachments</h2>
                      {content}
                      <Button
                        bsSize="large"
                        bsStyle="primary"
                        className="m-t"
                        disabled={this.state.isLoading}
                        onClick={this.createAllAttachments}
                      >
                        Print Referral
                      </Button>
                    </div>
                  </div>
                : null
              }
            </div>
          }
        </Modal.Body>

        <Modal.Footer>
          <div className="col-md-12 p-t-lg">
            <Button
              bsSize="large"
              bsStyle="primary"
              onClick={this.props.closePrintModal}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </div>
    )
  }

}

PrintReferralModal.propTypes = {
  sent_referral: React.PropTypes.object.isRequired,
  attachments: React.PropTypes.array.isRequired,
  closePrintModal: React.PropTypes.func.isRequired
}
