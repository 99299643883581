import React from 'react';
import update from 'immutability-helper';
import { Modal, Button, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput'

export default class UserContactInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        fax: props.user.fax || '',
        phone: props.user.phone || '',
        sms: props.user.sms || ''
      },
      errors: {},
      showSuccessModal: false
    };
  }

  closeSuccessModal = () => {
    this.setState({showSuccessModal: false});
  }

  handleChange = (event) => {
    this.setState({
      user: update(this.state.user, {$merge: {[event.target.name]: event.target.value}})
    });
  }

  submit = () => {
    $.ajax(`/api/users/${this.props.user.id}`, {
      data: {user: this.state.user},
      error: (response) => {
        this.setState({errors: response.responseJSON});
      },
      success: (response) => {
        this.setState({errors: {}, showSuccessModal: true}, () => {
          this.props.updateUser(response.user);
        });
      },
      type: 'PATCH'
    });
  }

  render() {
    return(
      <div>
        <form className="form-horizontal m-t-md" onChange={this.handleChange}>
          <FormGroup controlId="user_first" validationState={this.state.errors.first && 'error'} className="form-group">
            <ControlLabel className="col-sm-3 control-label">First name:</ControlLabel>
            <div className="col-sm-3">
              <FormControl
                type="text"
                className="form-control"
                name="first"
                defaultValue={this.props.user.first}
              />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.first && this.state.errors.first.join(', ')}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup controlId="user_last" validationState={this.state.errors.last && 'error'} className="form-group">
            <ControlLabel className="col-sm-3 control-label">Last name:</ControlLabel>
            <div className="col-sm-3">
              <FormControl
                type="text"
                className="form-control"
                name="last"
                defaultValue={this.props.user.last}
              />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.last && this.state.errors.last.join(', ')}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup controlId="user_fax" validationState={this.state.errors.fax && 'error'} className="form-group">
            <ControlLabel className="col-sm-3 control-label">Fax:</ControlLabel>
            <div className="col-sm-3">
              <MaskedFormControl
                type="text"
                className="form-control"
                name="fax"
                mask="(111) 111-1111"
                value={this.state.user.fax}
                onChange={this.handleChange}
              />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.fax && this.state.errors.fax.join(', ')}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup controlId="user_phone" validationState={this.state.errors.phone && 'error'} className="form-group">
            <ControlLabel className="col-sm-3 control-label">Phone:</ControlLabel>
            <div className="col-sm-3">
              <MaskedFormControl
                type="text"
                className="form-control"
                name="phone"
                mask="(111) 111-1111"
                value={this.state.user.phone}
                onChange={this.handleChange}
              />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.phone && this.state.errors.phone.join(', ')}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup controlId="user_sms" validationState={this.state.errors.sms && 'error'} className="form-group">
            <ControlLabel className="col-sm-3 control-label">Text:</ControlLabel>
            <div className="col-sm-3">
              <MaskedFormControl
                type="text"
                className="form-control"
                name="sms"
                mask="(111) 111-1111"
                value={this.state.user.sms}
                onChange={this.handleChange}
              />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.sms && this.state.errors.sms.join(', ')}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup className="form-group">
            <div className="col-sm-offset-3 col-sm-3">
              <Button bsSize="lg" bsStyle="primary" onClick={this.submit}>Save</Button>
            </div>
          </FormGroup>
        </form>
        <Modal show={this.state.showSuccessModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">Your contact info has been successfully updated.</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.closeSuccessModal}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

UserContactInfo.propTypes = {
  user: React.PropTypes.object.isRequired
}
