import { connect } from 'react-redux';
import utils from '../Misc/Utils';
import React from 'react';
import request from 'superagent';
import { SpinnerWave } from '../Misc/Spinners.jsx';
import ReactTable from 'react-table';
 
class ProvidersReport extends React.Component {
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this.retrieveData()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.filters.last_updated != this.props.filters.last_updated){
      this.retrieveData(nextProps.filters)
    }
    if(nextProps.filters.xlsx_request != this.props.filters.xlsx_request){
      this.retrieveData(nextProps.filters, true)
    }
  }

  retrieveData = (filters, xlsx) => {
    if(xlsx){
      var url = "/api/reports/providers?export=xlsx&" + Object.entries(this.props.filters).map(([key, val]) => `${key}=${val}`).join('&')
      utils.window_open(url,'')
    } else {
      var query_id = Math.random()
      this.setState({last_query: query_id, loading: true})
      var req = request.get("/api/reports/providers")  
      .query(filters || this.props.filters)
      .end( (err,res) => {
        if(!err){
          if(this.state.last_query == query_id){
            this.setState(res.body)
          }
        }
        this.setState({loading:false})
      })
    }
  }

  openUserPage = id => {
    utils.set_window_location(`/reports/provider/${id}`)
  }

  fmtPct = (n) => {
    return n ? parseFloat(n).toFixed(2) + '%' : ''
  }

  fmtPctile = (n) => {
    return n ? parseFloat(n).toFixed(2) + ' %ile' : ''
  }

  forceNumericSort = (a,b) => {
    var pa = parseFloat(a)
    var pb = parseFloat(b)
    a = (a === null || a === undefined || isNaN(pa)) ? -Infinity : parseFloat(a)
    b = (b === null || b === undefined || isNaN(pb)) ? -Infinity : parseFloat(b)
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  }

  columnKey = [

    {id: "id", accessor: "id", Header: "ID"},
    {id: "name", accessor: "name", Header: "Name"},
    {id: "provider_type", accessor: v => (v.provider_type ? v.provider_type.toUpperCase() : ''), Header: "Provider Type"},
    {id: "city", accessor: "city", Header: "City"},
    {id: "readmission_rate", accessor: v => (v.readmission_rate ? this.fmtPct(v.readmission_rate * 100) : ''), Header: "Readmission Rate"},
    {id: "medicare_star", accessor: "medicare_star", Header: "Medicare Star"},
    {id: "referrals_received", accessor: "referrals_received", Header: "Referrals Received"},
    {id: "offered_care", accessor: "offered_care", Header: "Offered Care"},
    {id: "chosen_total", accessor: "chosen_total", Header: "Chosen Total"},
    {id: "declined_care_total", accessor: "declined_care_total", Header: "Declined Total"},
    {id: "declined_care_no_bed", accessor: "declined_care_no_bed", Header: "Declined (No bed)"},
    {id: "declined_care_staff_shortage", accessor: "declined_care_staff_shortage", Header: "Declined (staff shortage)"},
    {id: "declined_care_care_mismatch", accessor: "declined_care_care_mismatch", Header: "Declined (care mismatch)"},
    {id: "declined_care_insurance", accessor: "declined_care_insurance", Header: "Declined (insurance)"},
    {id: "declined_care_service_area", accessor: "declined_care_service_area", Header: "Declined (service area)"},
    {id: "declined_discharge_plan", accessor: "declined_discharge_plan", Header: "Declined (discharge plan)"},
    {id: "declined_criteria_unmet", accessor: "declined_criteria_unmet", Header: "Declined (criteria unmet)"},
    {id: "declined_specialty_provider_unavail", accessor: "declined_specialty_provider_unavail", Header: "Declined (specialty provider unavail.)"},
    {id: "declined_care_other", accessor: "declined_care_other", Header: "Declined (other)"},
    {id: "no_response", accessor: "no_response", Header: "no response"}
  ]


  render() {
    return (
      <div className="reportsContent ibox">
        { (!this.state.providers || this.state.loading) && <SpinnerWave /> } 
        { (this.state.providers && !this.state.loading) && 
          <div>
            <ReactTable
                defaultSortDesc={true}
                defaultPageSize={10}
                className="report_meta -striped"
                columns={this.columnKey} 
                data={this.state.providers}
                sortable={true}
                getTdProps = {(state,rowInfo,column,instance) => {
                  return {
                    className: "tdcol-" + column.id
                  }
                } }
                getTrProps = {(state,rowInfo,column,instance) => {
                  return {
                    className: "clickable",
                    onClick: (e, handleOrig) => {
                      this.openUserPage(rowInfo.row.id)
                    }
                  }      
                } }
            >
            </ReactTable>
          </div>
        }
      </div>
    )
  }

}


const mapStateToProps = state => {
  return {
    current_user: state.current_user,
    current_organization: state.current_organization
  }
}

export default connect(mapStateToProps)(ProvidersReport)
