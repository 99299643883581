import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import CheckboxSection from '../Forms/CheckboxSection';
import { Modal, Button } from 'react-bootstrap';

export default class CareTypesOfferedTab extends React.Component {

	constructor(props){
		super(props);
    this.onMultiCheckChange = this.onMultiCheckChange.bind(this);
    this.state = {
      care_types_offered_attributes: [],
      showSuccessModal: false,
      isLoading: false,
      filtersMessage: ''
    };
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillMount(){
    let newData= _.extend(this.props.provider.care_types_offered_attributes);
    this.setState({care_types_offered_attributes: newData});
  }

  careTypeSelected(care_type) {
    let check = false;
    _.each(this.state.care_types_offered_attributes, (care_types_offered) => {
      if(care_type.id === care_types_offered.care_type_id && care_types_offered._destroy !== true){
        check = true;
      }
    })
    return check;
  }

  closeSuccessModal() {
    this.setState({showSuccessModal: false});
  }

  displayCareTypes(care_types){
    return _.map(care_types, (care_type, i) => {
      return(
        <div key={i} className="col-md-4 m-b">
          <CheckboxSection
            id={'care_type_id-'+care_type.id}
            value={this.careTypeSelected(care_type)}
            onChange={this.onMultiCheckChange}
            labelText={care_type.translations_name}
          />
        </div>
      )
    })
  }

  displayClinicalNeeds(){
    let careTypesSorted = _.chain(this.props.care_types).
      orderBy(care_type => care_type.translations_name).
      orderBy(care_type => care_type.category).
      groupBy(care_type => care_type.category).
      value();
    let isLoading = this.state.isLoading;
    return  _.map(careTypesSorted, (care_types, category) => {
      return(
        <div key={category} className='m-t-md m-b-md'>
          <h3 className="col-md-12">
            {category.split('_').join(' ')}
          </h3>
          <div className="col-md-12">
            {this.displayCareTypes(care_types)}
          </div>
          <div className="col-md-12 m-b">
            <Button bsStyle="primary" disabled={isLoading} onClick={!isLoading ? this.submit : null}>
              Save
            </Button>
          </div>
        </div>
      )
    })
  }

  onMultiCheckChange(e) {
    let clonedCareTypesOffered = _.extend(this.state.care_types_offered_attributes);
    let target_db_id = +e.target.id.split('-')[1];
    let savedCareTypeOffered = _.find(clonedCareTypesOffered, (care_type_offered) => {
      return (care_type_offered.id && care_type_offered.care_type_id == target_db_id)
    });

    if (e.target.checked) {
      if (savedCareTypeOffered) {
        delete savedCareTypeOffered['_destroy']
      } else {
        let newCareTypeOffered = {};
        newCareTypeOffered.care_type_id = target_db_id;
        newCareTypeOffered.provider_id = this.props.provider.id;
        clonedCareTypesOffered.push(newCareTypeOffered);
      }
    } else if (!e.target.checked) {
      if (savedCareTypeOffered) {
        savedCareTypeOffered._destroy = true;
      } else {
        clonedCareTypesOffered = _.filter(clonedCareTypesOffered, (care_type_offered) => {
          if (care_type_offered.id || care_type_offered.care_type_id !== target_db_id) {
            return care_type_offered
          }
        })
      }
    }
    this.setState({care_types_offered_attributes: clonedCareTypesOffered});
  }


  submit() {
    this.setState({isLoading: true});
    $.ajax(`/api/providers/${this.props.provider.id}`, {
      data: {provider: {care_types_offered_attributes: this.state.care_types_offered_attributes} },
      error: (response) => {
        alert('There was an error with your selection.');
        this.setState({isLoading: false});
      },
      success: (response) => {
        this.setState({care_types_offered_attributes: response.care_types_offered_attributes})
        this.setState({filtersMessage: response.filters_message})
        this.setState({showSuccessModal: true});
        this.setState({isLoading: false});
      },
      type: 'PATCH'
    });
  }

  render() {
    return(
      <div className='CareTypesOfferedTab'>
        <div className="p-xl">
      		<h2 className="m-b-xl">
            {this.props.provider.name} accepts the following care types:
          </h2>
      		{this.displayClinicalNeeds()}
          
          <Modal show={this.state.showSuccessModal} animation={false}>
            <Modal.Body>
              <h1 className="p-h-md">
                Your care types updates have been saved. <br/><br/> <span dangerouslySetInnerHTML={{__html: this.state.filtersMessage}} />
              </h1>
            </Modal.Body>
            <Modal.Footer>
              <Button 
                bsSize="large"
                bsStyle="primary"
                onClick={this.closeSuccessModal}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
}

CareTypesOfferedTab.propTypes = {
  provider: React.PropTypes.shape({
  	care_types_offered_attributes: React.PropTypes.array.isRequired
	}),
	care_types: React.PropTypes.array.isRequired
};
