import React from 'react';
export default class CheckboxSection extends React.Component {

  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.target.value = e.target.checked;
    this.props.onChange(e)
  }

  render() {
    let style = {};
    if (this.props.inline) {style={display: 'inline-block'};}
    return (
      <div className={'checkbox checkbox-success m-b-none m-t-none m-r-sm'} style={style}>
        <input
          type="checkbox"
          id={this.props.id}
          name={this.props.name}
          onChange={this.handleChange}
          checked={JSON.parse(this.props.value)}
          disabled={this.props.disabled}
        />
        <label
          htmlFor={this.props.id}
        >
          {this.props.labelText}
        </label>
      </div>
    )
  }

}

CheckboxSection.propTypes = {
  id: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.number]).isRequired,
  value: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.bool]).isRequired,
  onChange: React.PropTypes.func.isRequired,
  name: React.PropTypes.string,
  disabled: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.bool]),
 // labelText: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.bool]),
  inline: React.PropTypes.bool
};
