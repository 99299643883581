import React from 'react';
import _ from 'lodash';
import moment from 'moment';



export default class ReferralMeta extends React.Component {
  constructor(props){
    super(props)
    this.state = {}
  }

  pickUpLocationHelper(){
    return(
      <div>
        <div>{this.props.hospital.name}</div>
        <div>{this.props.hospital.address}</div>
        <div>{this.props.hospital.city}, {this.props.hospital.zip}</div>
      </div>
    )
  }

  pickUpAtHelper(){
    let str;
    if (this.props.referral.will_call) {
      str = 'Will Call';
    } else {
      if (this.props.referral.pick_up_at) {
        str = moment(this.props.referral.pick_up_at).format('LT')
        if (this.props.referral.pick_up_at_flexibility_minutes) {
          str = str.concat(` - ${this.props.referral.pick_up_at_flexibility_minutes} minute flexibility`);
        }
      }
    }
    return str
  }

  levelOfCareHelper(){
    const locs=[
      ['Acute','loc_acute'], ['LTAC','loc_ltac'], ['SNF','loc_snf'], ['Custodial','loc_custodial'], 
      ['Residence','loc_residence'],['Subacute','loc_subacute'], ['Hospice','loc_hospice'], 
      ['Other Care Level','loc_other'], ['Other Care Level Text','loc_other_text']
    ];
    let trueLevelsofCare = _.map(locs, (loc) => {
      if ( this.props.referral[loc[1]] ){
        return (loc[0])
      }
    });
    trueLevelsofCare = _.without(trueLevelsofCare, undefined);
    if (trueLevelsofCare.length > 0 ) {
      return (
        <div>
          {trueLevelsofCare.join(", ")}
        </div>
      )
    } else {
      return(
        <div>
          <span className='font-italic'>None to Display</span> 
        </div>
      )
    }
  }



  followersHelper(){
    let len = (this.props.patient.followers.length-1);
    if (len === -1) {
      return (<span key={999}>No Followers</span>)
    } else {
      return _.map(this.props.patient.followers, (user, index) => {
        if (index < len) {
          return (<span key={index}>{user.name}, </span>)
        } else {
          return (<span key={index}>{user.name}</span>)
        }
      })
    }
  }



  unitRoomLocationHelper(){
    let str;
    if (this.props.patient.location) {
      str = this.props.patient.location;
    } else if(this.props.patient.room_number) {
      str = `${this.props.patient.unit_name} Unit`;
      if (this.props.patient.unit_name) {
       str += ` - ${this.props.patient.room_number} Room`;
      }
    }
    return str
  }

  timerExpired(){
    return _.includes(['provider_responses_closed', 'awaiting_patient'], this.props.referral.status) 
  }

  timerOpen(){
    return this.props.referral.status === 'awaiting_provider_responses'
  }

  resumingCareHelper(){
    if (this.props.referral.resuming_care === null) {
      return (<span className='font-italic'>No data available</span> )
    } else {
      return this.props.referral.resuming_care ? this.props.referral.resuming_care.toString() : 'False'
    }
  }

  dropOffLocationHelper(){
    if (this.props.provider_delivered_to) {
      return (
        <div>
          <div>{ this.props.provider_delivered_to.name }</div>
          <div>{ this.props.provider_delivered_to.address }</div>
          <div>{this.props.provider_delivered_to.state_abbreviation}, {this.props.provider_delivered_to.zip}</div>
        </div>
      )
    } else {
      return <span className='font-italic'>No data available</span> 
    }
  }


  mapDisplayFields(displayFields){
    return _.map(displayFields, (field, index) => {
      return (
        <div key={index} className='m-t-sm'>
          <dt>{ field.label }</dt>
          <dd>{ field.value ? field.value : <span className='font-italic'>No data available</span>  }</dd>
        </div>
      )
    })
  }

  providerTypeFields(){
    let fieldMapping = {
      hha:  ['provider_type', 'zip', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'resuming_care', 'notes', 'followers', 'phone'],
      snf:  ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'resuming_care', 'weeks', 'notes', 'followers', 'phone'],
      ltac: ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'notes', 'followers', 'phone'],
      irf:  ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'notes', 'followers', 'phone'],
      trns: [
        'provider_type', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'pick_up_at', 'will_call', 'pick_up_location',
        'pick_up_location_notes', 'drop_off_location', 'drop_off_location_other',
        'room_number_at_destination', 'level_of_care', 'notes', 'followers', 'phone'
      ],
      dial: ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'weeks', 'notes', 'followers', 'phone'],
      dar:  ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'weeks', 'notes', 'followers', 'phone'],
      outpsyc: ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'weeks', 'notes', 'followers', 'phone'],
      inf:  ['provider_type', 'zip', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'notes', 'followers', 'phone'],
      dme:  ['provider_type', 'zip', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'notes', 'followers', 'phone'],
      psyc: ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'notes', 'followers', 'phone'],
      alf:  ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'resuming_care', 'notes', 'followers', 'phone'],
      hosp: ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'resuming_care', 'notes', 'followers', 'phone'],
      pd:   ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'notes', 'followers', 'phone'],
      pay:  ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'notes', 'followers', 'phone'],
      pcp:  ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'notes', 'followers', 'phone'],
      acute: ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'resuming_care', 'weeks', 'notes', 'followers', 'phone'],
      recupe: ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'resuming_care', 'weeks', 'notes', 'followers', 'phone'],
      inhosp: ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'resuming_care', 'weeks', 'notes', 'followers', 'phone'],
      pal: ['provider_type', 'zip', 'search_radius', 'pick_up_location', 'unit_room_location', 'adt_admission_date', 'discharge_date', 'emr_discharge_date', 'resuming_care', 'notes', 'followers', 'phone'],
    };

    if (this.props.sent_referral.rq) {
      fieldMapping = _.mapValues(fieldMapping, (t) => {
        return _.reject(t, f => f === 'discharge_date');
      })
    }

    let displayFields = {
      provider_type: {
        label: 'Patient Requires:',
        value: this.props.referral.provider_type_desc
      },
      unit_room_location: {
        label: 'Location:',
        value: this.unitRoomLocationHelper()
      },
      zip: {
        label: 'Zip Code of Service Area:', 
        value: this.props.referral.zip
      },
      search_radius: {
        label: 'Search Radius:', 
        value: this.props.referral.search_radius
      },
      adt_admission_date: {
        label: 'Admitted to referral source:',
        value: moment(this.props.sent_referral.patient_visit ? this.props.sent_referral.patient_visit.admission : null).format('MM.DD.Y')
      },
      discharge_date: {
        label: 'Discharge Date:', 
        value: moment(this.props.referral.discharge_date).format('MM.DD.Y') 
      },
      weeks: {
        label: 'Expected LOS:', 
        value: `${this.props.referral.weeks} Weeks`
      },
      resuming_care: {
        label: 'Resuming Care:',
        value: this.resumingCareHelper()
      },
      pick_up_at: {
        label: 'Pick Up Time:',
        value: this.pickUpAtHelper()
      },
      pick_up_location: {
        label: 'Referral Source:',
        value: this.pickUpLocationHelper()
      },
      pick_up_location_notes: {
        label: 'Pick Up Location Notes:',
        value: this.props.referral.pick_up_location
      },
      drop_off_location: {
        label: 'Drop Off Location:',
        value: this.dropOffLocationHelper()
      },
      drop_off_location_other: {
        label: 'Drop Off Location Notes:',
        value: this.props.referral.drop_off_location_other
      },
      room_number_at_destination: {
        label: 'Room Number at Destination:',
        value: this.props.referral.room_number_at_destination
      },
      level_of_care: {
        label: 'Level of Care:',
        value: this.levelOfCareHelper()
      },
      followers: {
        label: 'Care Team:',
        value: this.followersHelper()
      },
      phone: {
        label: 'Contact # for this referral:',
        value: this.props.referral.phone
      },
      notes: {
        label: 'Additional Notes:',
        value: this.props.referral.notes
      },
    };

    if (this.props.referral.emr_discharge_date) {
      displayFields.emr_discharge_date = {
        label: 'EMR Discharge Date:',
        value: moment(this.props.referral.emr_discharge_date).format('MM.DD.Y')
      }
    }

    let providerTypeFileds = _.values(_.pick(displayFields, fieldMapping[this.props.referral.provider_type]))
    return this.mapDisplayFields(providerTypeFileds);
  }

  render(){
    return(
      <div>
        {this.providerTypeFields()}
      </div>
    ) 
  }
}
