import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ModeReport from './ModeReport';

export default class ModeReports extends React.Component {
  render() {
    return(
      <Tabs defaultActiveKey={1} id="mode-reports">
        <Tab eventKey={1} title="Referral Export">
          <ModeReport
            enabled={String(this.props.current_organization.settings.mode_reports_enabled).toLowerCase() === 'true'}
            url={this.props.current_organization.settings.mode_referral_export_url || 'https://modeanalytics.com/myaidin/reports/460766c077d3/embed?secret_key=17498c46-2324-4022-a6e8-acfa8f160fbb'}
            csv_url={this.props.current_organization.settings.mode_referral_export_csv_url}
            hospitals={this.props.hospitals}
            stripe_publishable_key={this.props.stripe_publishable_key}
          />
        </Tab>
        <Tab eventKey={2} title="Referral Funnel">
          <ModeReport
            enabled={String(this.props.current_organization.settings.mode_reports_enabled).toLowerCase() === 'true'}
            url={this.props.current_organization.settings.mode_referral_funnel_url || 'https://modeanalytics.com/myaidin/reports/15af34a451ac/embed?secret_key=a145bf1a-f820-4809-81d3-93a31deb02e5'}
            csv_url={this.props.current_organization.settings.mode_referral_funnel_csv_url}
            hospitals={this.props.hospitals}
            stripe_publishable_key={this.props.stripe_publishable_key}
          />
        </Tab>
        <Tab eventKey={3} title="Team Efficiency">
          <ModeReport
            enabled={String(this.props.current_organization.settings.mode_reports_enabled).toLowerCase() === 'true'}
            url={this.props.current_organization.settings.mode_team_efficiency_url || 'https://modeanalytics.com/myaidin/reports/8472c452a690/embed?secret_key=1f4cfd02-3d98-4f6a-80be-c6f3048814b0'}
            csv_url={this.props.current_organization.settings.mode_team_efficiency_csv_url}
            hospitals={this.props.hospitals}
            stripe_publishable_key={this.props.stripe_publishable_key}
          />
        </Tab>
      </Tabs>
    );
  }
}

ModeReports.propTypes = {
  current_organization: React.PropTypes.object.isRequired,
  hospitals: React.PropTypes.array.isRequired,
  stripe_publishable_key: React.PropTypes.string.isRequired
}
