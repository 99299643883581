import utils from '../Misc/Utils';
import React from 'react';

export default class BrowserSupportWrapper extends React.Component {
  render() {
    if (utils.ie9Browser()) {
      return(
        <div>
          <p>This feature is not supported by your Internet Browser.</p>
          <p>For the best experience, use Google Chrome to view this website.</p>
          <p>For technical support, click the Help button.</p>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
