import React from 'react';
export default class Video extends React.Component {
  render() {
    return(
      <iframe
        width="220"
        height="300"
        src={this.props.src}
        frameBorder="0"
        allowFullScreen
      />
    );
  }
}

Video.propTypes = {
  src: React.PropTypes.string.isRequired
}
