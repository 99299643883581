import React from 'react';
import { SpinnerWave } from '../../Misc/Spinners'
import ReactTable from 'react-table'
import DatePicker from 'react-bootstrap-date-picker'
import request from 'superagent'
import { Modal, Button } from 'react-bootstrap';
require('superagent-rails-csrf')(request);

export default class Fax extends React.Component {
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
  }

  search = (e) => {
    this.setState({searchError: false, loading:true})

    request.post("/admin/faxes/search").setCsrfToken().send({
      from_date: this.state.from_datetime,
      to_date: this.state.to_datetime,
      to_num: this.state.to_num
    }).set('Accept', 'application/json').end((err,res) => {
      if(err){
        this.setState({faxes: null, loading: false, searchError: true})
      } else {
        this.setState({loading:false, faxes: res.body.faxes})
      }
    })

    e.preventDefault()
  }

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  handleDateChange = (dt,d,v) => {
    this.setState({
      [v]: d,
      [v + 'time']: dt
    })
  }

  fax_columns = [
    {id: 'media_sid', accessor: 'media_sid', Header: 'Twilio SID'},
    {id: 'status', accessor: 'status', Header: 'Status'},
    {id: 'to', accessor: 'to', Header: 'To'},
    {id: 'from', accessor: 'from', Header: 'From'},
    {id: 'direction', accessor: 'direction', Header: 'Direction'},
    {id: 'date_created', accessor: 'date_created', Header: 'Created At'},
    {id: 'date_updated', accessor: 'date_updated', Header: 'Updated At'},
    {id: 'duration', accessor: 'duration', Header: 'Duration (seconds)'},
    {id: 'num_pages', accessor: 'num_pages', Header: 'Num. pages'},
    {id: 'price', accessor: 'price', Header: 'Price (USD)'},
    {id: 'quality', accessor: 'quality', Header: 'Status'},
  ]

  openFaxModal = (row) => {
    this.setState({
      showModal: true,
      selected_fax: row
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <div className="admin_faxes">
        <link rel="stylesheet" href="https://unpkg.com/react-table@latest/react-table.css" />
        <h2>Faxes</h2>
        <hr /> 
        <form onSubmit={this.search} className="form-horizontal">
          <div className="form-group">
            <label className="col-sm-2" htmlFor="to_num">Recipent</label>
            <div className="col-sm-10">
              <input type="to_num" className="form-control" id="to_num" name="to_num" placeholder="888-555-2122" onChange={this.handleChange} />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2" htmlFor="from_date">After</label>
            <div className="col-sm-10">
              <DatePicker
                dateFormat="YYYY-MM-DD"
                name="from_date"
                value={this.state.from_date}
                onChange={(dt,d) => this.handleDateChange(dt,d,'from_date')}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2" htmlFor="to_date">Before</label>
            <div className="col-sm-10">
              <DatePicker
                dateFormat="YYYY-MM-DD"
                name="to_date"
                value={this.state.to_date}
                onChange={(dt,d) => this.handleDateChange(dt,d,'to_date')}
                // onFocus={this.handleProviderActualReceivedDateFocus}
              />
            </div>
          </div>
          <div className="form-group"> 
            <div className="col-sm-offset-2 col-sm-10">
              <button type="submit" className="btn btn-primary">Search</button>
            </div>
          </div>
        </form>
        { this.state.loading && <SpinnerWave /> }
        { !this.state.loading && this.state.searchError && <p>Sorry, that search was invalid.</p> }
        { !this.state.loading && this.state.faxes &&
          <div className="results_wrap">
            <p><em>Note: Twilio limits results to 50, please use the search filters to narrow down your search.</em></p>
            <ReactTable 
            data={this.state.faxes}
            columns={this.fax_columns}
            getTrProps={(state,rowInfo,col) => {
              return {
                onClick: (e, handleOriginal) => {
                  this.openFaxModal(rowInfo.original)
                }
              }
            }}
            />
          </div>
        }
        { this.state.showModal && <Modal bsSize="lg" animation={false} show={true} >
          <Modal.Body>
            <h1>Fax Details</h1>
            <table className="table">
              <tbody>
                <tr><td>Fax SID</td><td>{ this.state.selected_fax.sid }</td></tr>
                <tr><td>Media SID</td><td>{ this.state.selected_fax.media_sid }</td></tr>
                <tr><td>Status</td><td>{ this.state.selected_fax.status }</td></tr>
                <tr><td>Direction</td><td>{ this.state.selected_fax.direction }</td></tr>
                <tr><td>From</td><td>{ this.state.selected_fax.from }</td></tr>
                <tr><td>To</td><td>{ this.state.selected_fax.to }</td></tr>
                <tr><td>Date Created</td><td>{ this.state.selected_fax.date_created }</td></tr>
                <tr><td>Date Updated</td><td>{ this.state.selected_fax.date_updated }</td></tr>
                <tr><td>Price</td><td>{ this.state.selected_fax.price }</td></tr>
                <tr><td>Account SID</td><td>{ this.state.selected_fax.account_sid }</td></tr>
                <tr><td>URL</td><td><a href={ this.state.selected_fax.url }>{ this.state.selected_fax.url }</a></td></tr>
                <tr><td>Media URL</td><td><a target="_blank" href={ this.state.selected_fax.links.media }>{ this.state.selected_fax.links.media }</a></td></tr>
                <tr><td>Duration</td><td>{ this.state.selected_fax.duration }</td></tr>
                <tr><td>Num. pages</td><td>{ this.state.selected_fax.num_pages }</td></tr>
                <tr><td>Quality</td><td>{ this.state.selected_fax.quality }</td></tr>
                <tr><td>Price Unit</td><td>{ this.state.selected_fax.price_unit }</td></tr>
                <tr><td>API Version</td><td>{ this.state.selected_fax.api_version }</td></tr>
                <tr><td>Media URL</td><td><a target="_blank" href={ this.state.selected_fax.media_url }>{ this.state.selected_fax.media_url ? this.state.selected_fax.media_url.substring(0,80) + '...' : '...' }</a></td></tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="primary" bsSize="lg" onClick={this.closeModal}>Close</Button>
          </Modal.Footer>
        </Modal> }

      </div>
    )
  }
}
