import React from 'react';
export default class SuccessModal extends React.Component {

  buildClassName() {
    let className = "btn";
    if (this.props.disabled) {
      return className + " " + "btn-disabled";
    }
    return className + " " + "btn-success";
  }

  render() {
    return (
      <button
        href="#"
        type='button'
        className={this.buildClassName()}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.buttonText}
      </button>
    )
  }
}

SuccessModal.propTypes = {
  onClick: React.PropTypes.func.isRequired,
  disabled:React.PropTypes.bool,
  buttonText: React.PropTypes.string.isRequired
};