import React from 'react';
import _ from 'lodash';
import moment from 'moment';

export default class CountDownClock extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hours: '',
      minutes: '',
      seconds: ''
    };
    this.countdownTick = this.countdownTick.bind(this);
  }

  componentDidMount() {
    if (this.props.referral.status === 'awaiting_provider_responses' && moment(this.currentTime()).isAfter(this.respondTaskOverdueAt())) {
      this.countdownTick();
      let intervalId = setInterval(this.countdownTick, 1000);
      this.setState({intervalId});
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  countdownTick() {
    let diff = moment(this.props.referral.time_window_closes_at).diff(moment());
    let duration = moment.duration(diff);
    let hours = _.padStart(duration.hours() + duration.days() * 24, 2, '0');
    let minutes = _.padStart(duration.minutes(), 2, '0');
    let seconds = _.padStart(duration.seconds(), 2, '0');
    this.setState({hours: hours, minutes: minutes, seconds: seconds});
  }

  respondTaskOverdueAt() {
    let respondTask = _.find(this.props.tasks, (task) => {
      return (task.task_type === 'respond' && task.sent_referral_id === this.props.sent_referral.id)
    })
    if (respondTask) {
      return respondTask.overdue_at
    } else {
      return moment(this.currentTime())
    }
  }

  currentTime() {
    return moment().format()
  }

  render() {
    if (this.props.sent_referral.confirmed === false) {
      return (
        <h3 className="text-danger">The patient information on this referral has been anonymized because your access has been revoked.</h3>
      )
    } else if (this.props.referral.status === 'awaiting_provider_responses') {
      if ( moment(this.currentTime()).isSameOrBefore(this.respondTaskOverdueAt()) ) {
        return (
          <h3>Referral closes on {moment(this.props.referral.time_window_closes_at).format('MMMM D [at] h:mma')}</h3>
        )
      } else {
        return (
          <h3 className="text-danger">Referral closes in {this.state.hours} hours, {this.state.minutes} minutes and {this.state.seconds} seconds</h3>
        )
        }
    } else if (this.props.referral.status === 'chosen') {
      return (
        <h3 className="text-success">Congratulations!  You have been chosen as this patient's provider?</h3>
      )
    } else if (this.props.referral.time_window_is_closed && !this.props.referral.provider_id && this.props.sent_referral.status != 'available' && this.props.referral.sent_referrals_count != 1) {
      return (
        <h3 className="text-danger">The patient information on this referral has been anonymized beccause the referral is no longer accepting responses.</h3>
      )
    } else if(this.props.referral.time_window_is_closed && this.props.referral.provider_id && !this.props.referral.are_you_the_provider) {
      return (
        <h3 className="text-danger">The patient information on this referral has been anonymized because the patient has chosen another provider for their care.</h3>
      )
    } else if(this.props.referral.status == 'canceled') {
      return (
        <h3 className="text-danger">
          The patient information on this referral has been anonymized because the referral has been cancelled
          {this.props.referral.cancelled_by_user_name ? ' by ' + this.props.referral.cancelled_by_user_name : ''}
          {this.props.referral.cancel_reason ? ' (' + this.props.referral.cancel_reason + ')' : ''}.
        </h3>
      )
    } else if (this.props.referral.sent_referrals_count == 1) {
      return null;
    } else {
      return (
        <h3>This referral is no longer accepting responses.</h3>
      )
    }
  }
}

CountDownClock.propTypes = {
  referral: React.PropTypes.object.isRequired,
  sent_referral: React.PropTypes.object.isRequired,
  tasks: React.PropTypes.array.isRequired
}
