import React from 'react';
import _ from 'lodash';
import { Button, Modal } from 'react-bootstrap';

export default class ReferralSourcesTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hospitals_providers: _.sortBy(props.hospitals, 'name').map(h => {
        return {hospital_id: h.id, excluded: h.excluded}
      }),
      loading: false,
      showErrorModal: false,
      showSuccessModal: false
    };
  }

  handleChange = event => {
    const hospitalId = parseInt(event.target.value);
    const index = _(this.state.hospitals_providers).findIndex({hospital_id: hospitalId});

    this.setState({hospitals_providers: [
      ...this.state.hospitals_providers.slice(0, index),
      {hospital_id: hospitalId, excluded: !event.target.checked},
      ...this.state.hospitals_providers.slice(index + 1)
    ]});
  }

  submit = () => {
    if (!this.state.loading) {
      this.setState({loading: true}, () => {
        $.ajax(`/api/providers/${this.props.provider.id}/update_hospitals_providers`, {
          complete: () => this.setState({loading: false}),
          data: {hospitals_providers: this.state.hospitals_providers},
          error: () => this.setState({showErrorModal: true}),
          success: () => this.setState({showSuccessModal: true}),
          type: 'PATCH'
        });
      });
    }
  }

  render() {
    return(
      <div className="ReferralSourcesTab">
        <div className="p-xl">
          <h2 className="m-b-xl">
            {this.props.provider.name} accepts referrals from the following sources:
          </h2>
          <div className="col-md-12 m-b-lg">
            {
              this.state.hospitals_providers.map(hp => {
                return(
                  <div key={hp.hospital_id} className="col-md-12 m-b">
                    <div className="checkbox checkbox-success m-b-none m-t-none m-r-sm">
                      <input
                        type="checkbox"
                        id={`hospitals-provider-hospital-${hp.hospital_id}`}
                        value={hp.hospital_id}
                        onChange={this.handleChange}
                        checked={!hp.excluded}
                      />
                      <label htmlFor={`hospitals-provider-hospital-${hp.hospital_id}`}>{_(this.props.hospitals).find(h => h.id === hp.hospital_id).name}</label>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="col-md-12 m-b">
            <Button
              bsStyle="primary"
              disabled={this.state.loading}
              onClick={this.submit}
            >
              Save
            </Button>
          </div>
        </div>
        <Modal bsSize="lg" show={this.state.showErrorModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">An error was encountered in updating your referral sources. Please contact Aidin Support.</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={() => this.setState({showErrorModal: false})}>OK</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showSuccessModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">Your referral sources have been successfully updated.</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={() => this.setState({showSuccessModal: false})}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

ReferralSourcesTab.propTypes = {
  provider: React.PropTypes.object.isRequired,
  hospitals: React.PropTypes.array.isRequired
};
