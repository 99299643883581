import React from 'react';
import utils from '../../Misc/Utils';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { updateOrganizations } from '../../../actions';
import {FormGroup, ControlLabel, FormControl, HelpBlock, Button} from 'react-bootstrap';
import CheckboxSection from '../../Forms/CheckboxSection';

class MultiProviderRespondTask extends React.Component {

  constructor(props) {
    super(props);
    this.state = {sent_referrals: [], message: ''};
    this.sectionInputChange = this.sectionInputChange.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  } 

  componentDidMount() {
    this.checkForDuplicateReferrals()
  }

  checkForDuplicateReferrals(){
    $.getJSON( `/api/users/${this.props.current_user.id}/related_sent_referrals/${this.props.data.sent_referral.id}`, (response) => {
      this.setState({sent_referrals: response.sent_referrals});
    });
  }

  optionsForDeclinedReasons(){
    return _.map(utils.declinedReasonsOptions, (option, i) => {
      return <option key={i} value={option[0]}>{option[1]}</option>
    })
  }

  optionsForPickUpAt(){
    let options = [['', 'Please Select a Pick Up Time']]
    options.push([moment(this.props.data.referral.pick_up_at).format(), moment(this.props.data.referral.pick_up_at).format('LT')])
    var intervalsLen = (this.props.data.referral.pick_up_at_flexibility_minutes / 30)- 1;
    if (this.props.data.referral.pick_up_at_flexibility_minutes) {
      var intervalsArr = [30]; 
      for (var i = 0; i < intervalsLen; i++) {
        intervalsArr.push(intervalsArr[i]+ 30);
      } // => [30, 60, 50]
    }
    _.each(intervalsArr, (time_addition) => {
      let newTime = moment(this.props.data.referral.pick_up_at).add(time_addition, 'minutes');
      options.push([newTime.format(), moment(newTime).format('LT')]);
    })
    return _.map(options, (option, i) => {
      return <option key={i} value={option[0]}>{option[1]}</option>
    })
  }

  saveChanges(){
    $.ajax({
      url: `/api/sent_referrals/multi_provider_update`,
      type: 'PATCH',
      data: {sent_referrals: this.state.sent_referrals},
      success: (data) => {
        this.setState({message: data.message})
      },
      error: (data) => {
        alert("Server Error On RespondTask save")
      }
    });
  }

  sectionInputChange(e) {
    let newData= _.extend(this.state);
    let sent_referral = _.find(newData.sent_referrals, {id: +e.target.id.split('-')[1] })
    sent_referral[e.target.name] = e.target.value
    this.setState({data: newData});
  }

  showRelatedSentReferrals(){
    return _.map(this.state.sent_referrals, (sent_referral, i) => {
      return(
        <div key={i}>
          <h3>{sent_referral.provider_name}</h3>
          <FormGroup controlId={"status-"+sent_referral.id.toString()}>
            <ControlLabel className="col-md-12 control-label">Please submit your response:</ControlLabel>
            <div className="col-md-12">
              <FormControl 
                componentClass="select" 
                onChange={this.sectionInputChange} 
                className="form-control"
                defaultValue={sent_referral.status}
                name="status"
              >
                <option value='pending'>Please Select a response</option>
                <option value='available'>Available</option>
                <option value='unavailable'>Unavailable</option>
              </FormControl>
            </div>
          </FormGroup>

          {/* TRNS Specific Form Sections */}
          { sent_referral.status === 'available' && this.props.data.referral.provider_type === 'trns' && this.props.data.referral.will_call &&
            <FormGroup controlId={"will_call-"+sent_referral.id.toString()}>
              <ControlLabel className="col-md-12 control-label">Confirm your pick up time:</ControlLabel>
              <div className="col-md-12">
                <FormControl 
                  componentClass="select" 
                  onChange={this.sectionInputChange} 
                  className="form-control"
                  defaultValue={sent_referral.will_call}
                  name="will_call"
                >
                  <option value='will_call'>Will Call</option>
                </FormControl>
              </div>
            </FormGroup>
          }
          { sent_referral.status === 'available' && this.props.data.referral.provider_type === 'trns' && !this.props.data.referral.will_call &&
            <FormGroup controlId={"pick_up_at-"+sent_referral.id.toString()}>
              <ControlLabel className="col-md-12 control-label">Confirm your pick up time:</ControlLabel>
              <div className="col-md-12">
                <FormControl 
                  componentClass="select" 
                  onChange={this.sectionInputChange} 
                  className="form-control"
                  defaultValue={moment(sent_referral.pick_up_at).format()}
                  name="pick_up_at"
                >
                  {this.optionsForPickUpAt()}
                </FormControl>
              </div>
            </FormGroup>
          }

          {/* DIAL/DAR/OUTPSYC Specific Form Sections */}
          {sent_referral.status === 'available' && ['dial', 'dar', 'outpsyc'].indexOf(this.props.data.referral.provider_type) > -1 &&
            <div>
              <FormGroup controlId={"available_days"}>
                <ControlLabel className="col-md-12 control-label">Please indicate what dates and times you are offering care for this patient:</ControlLabel>
                <div className="col-md-12 m-b-sm">
                  <CheckboxSection
                    id={'available_sunday-'+sent_referral.id.toString()}
                    name={'available_sunday'}
                    value={sent_referral.available_sunday}
                    labelText={"Sun"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_monday-'+sent_referral.id.toString()}
                    name={'available_monday'}
                    value={sent_referral.available_monday}
                    labelText={"Mon"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_tuesday-'+sent_referral.id.toString()}
                    name={'available_tuesday'}
                    value={sent_referral.available_tuesday}
                    labelText={"Tue"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_wednesday-'+sent_referral.id.toString()}
                    name={'available_wednesday'}
                    value={sent_referral.available_wednesday}
                    labelText={"Wed"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_thursday-'+sent_referral.id.toString()}
                    name={'available_thursday'}
                    value={sent_referral.available_thursday}
                    labelText={"Thu"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_friday-'+sent_referral.id.toString()}
                    name={'available_friday'}
                    value={sent_referral.available_friday}
                    labelText={"Fri"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_saturday-'+sent_referral.id.toString()}
                    name={'available_saturday'}
                    value={sent_referral.available_saturday}
                    labelText={"Sat"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />  
                </div>
              </FormGroup>
              <FormGroup controlId={"available_times-"+sent_referral.id.toString()} className="form-group">
                <ControlLabel className="col-md-12 control-label">Please indicate your available times.</ControlLabel>
                <div className="col-md-12 m-b-sm">
                  <FormControl 
                    componentClass="textarea"
                    name="available_times"
                    className="form-control m-b-sm"
                    style={{height: 80}}
                    defaultValue={sent_referral.available_times}
                    onChange={this.sectionInputChange}
                  />
                </div>
              </FormGroup>
            </div>
          }

          {sent_referral.status === 'unavailable' &&
            <FormGroup controlId={"declined_reason-"+sent_referral.id.toString()}>
              <ControlLabel className="col-md-12 control-label">Declined Reason:</ControlLabel>
              <div className="col-md-12">
                <FormControl 
                  componentClass="select" 
                  onChange={this.sectionInputChange} 
                  className="form-control"
                  defaultValue={sent_referral.declined_reason}
                  name="declined_reason"
                >
                  {this.optionsForDeclinedReasons()}
                </FormControl>
              </div>
            </FormGroup>
          }
        </div>
      )
    })
  }

  render() {
    return(
      <div>
        { this.state.sent_referrals.length > 0 &&
        <div>
          <p className="row">
            We have identified that this referral has been sent to other organizations you belong to. 
            For your convenience, your can update your responses for these organizations below.
          </p>
          <div className="row">
            {this.showRelatedSentReferrals()}
          </div>
          <div className="row m-t">
            <Button bsStyle="primary" onClick={this.saveChanges}>Save Changes</Button>
            { this.state.message.length > 0 && <span>&nbsp;&nbsp;Success!</span>}
          </div>
        </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organizations
  }
}

export default connect(mapStateToProps)(MultiProviderRespondTask)

MultiProviderRespondTask.propTypes = {
  organizations: React.PropTypes.array.isRequired,
  current_user: React.PropTypes.object.isRequired,
  data: React.PropTypes.object.isRequired
};
