import { combineReducers } from 'redux';
import activities from './activities';
import current_user from './current_user';
import messages   from './messages';
import sent_referral_tab from './sent_referral_tab';
import tasks from './tasks';
import organizations from './organizations';

const rootReducer = combineReducers({
  activities,
  current_user,
  messages,
  tasks,
  organizations,
  sent_referral_tab
});

export default rootReducer;
