import React from 'react';
import _ from 'lodash';

export default class Facesheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPhone: false,
      showEmergencyContact: false
    }
  }

  sexHelper() {
    if (this.props.patient.sex === 'M') {
      return 'Male'
    } else if (this.props.patient.sex === 'F') {
      return 'Female'
    }
  }

  showEmergencyContact = (event) => {
    event.preventDefault();
    this.setState({showEmergencyContact: true});
  }

  showPhone = (event) => {
    event.preventDefault();
    this.setState({showPhone: true});
  }

  render() {
    return(
      <dl className="dl-horizontal">
        <h2>Patient Demographics</h2>
        {/* Previously Face Sheet */}
        <div className='m-t-sm'>
          <dt>MRN:</dt>
          <dd>{ this.props.patient.patient_number }</dd>
        </div>
        <div className='m-t-sm'>
          <dt>Name:</dt>
          <dd>{ this.props.patient.name }</dd>
        </div>
        <div className='m-t-sm'>
          <dt>Gender:</dt>
          <dd>{ this.sexHelper() }</dd>
        </div>
        <div className='m-t-sm'>
          <dt>Age:</dt>
          <dd>{ this.props.patient.age } / (Date of Birth: {this.props.patient.date_of_birth})</dd>
        </div>
        <div className='m-t-sm'>
          <dt>SSN:</dt>
          <dd>
            {
              this.props.sent_referral.status == 'chosen'
              ? this.props.patient.formatted_ssn
                ? this.props.patient.formatted_ssn
                : <span className='font-italic'>No data available</span>
              : <span className='font-italic'>SSN hidden. SSN revealed if patient chooses you.</span>
            }
          </dd>
        </div>
        {
          this.props.sent_referral.rq &&
          <div>
            <div className='m-t-sm'>
              <dt>Claim Number:</dt>
              <dd>{this.props.patient.claim_no}</dd>
            </div>
            <div className='m-t-sm'>
              <dt>DOI:</dt>
              <dd>{this.props.patient.doi}</dd>
            </div>
            <div className='m-t-sm'>
              <dt>WCAB:</dt>
              <dd>{this.props.patient.wcab}</dd>
            </div>
            <div className='m-t-sm'>
              <dt>Employer:</dt>
              <dd>{this.props.patient.employer}</dd>
            </div>
            <div className='m-t-sm'>
              <dt>Occupation:</dt>
              <dd>{this.props.patient.occupation}</dd>
            </div>
            <div className='m-t-sm'>
              <dt>Disability Status:</dt>
              <dd>{this.props.patient.disability_status}</dd>
            </div>
            <div className='m-t-sm'>
              <dt>Services Requested:</dt>
              <dd>{this.props.patient.services_requested}</dd>
            </div>
            <div className="m-t-sm">
              <dt>Referral Month:</dt>
              <dd>{this.props.patient.referral_month}</dd>
            </div>
            <div className="m-t-sm">
              <dt>MMI Date:</dt>
              <dd>{this.props.patient.mmi_date}</dd>
            </div>
            <div className="m-t-sm">
              <dt>Attorney Involvement:</dt>
              <dd>{this.props.patient.attorney_involvement}</dd>
            </div>
            <div className="m-t-sm">
              <dt>MED Level:</dt>
              <dd>{this.props.patient.med_level}</dd>
            </div>
            <div className="m-t-sm">
              <dt>Other ID:</dt>
              <dd>{this.props.patient.other_id}</dd>
            </div>
            <div className="m-t-sm">
              <dt>Svc Start Date:</dt>
              <dd>{this.props.patient.svc_start_date}</dd>
            </div>
            <div className="m-t-sm">
              <dt>Svc End Date:</dt>
              <dd>{this.props.patient.svc_end_date}</dd>
            </div>
            <div className="m-t-sm">
              <dt>Eval Date:</dt>
              <dd>{this.props.patient.eval_date}</dd>
            </div>
            <div className="m-t-sm">
              <dt>Candidate?:</dt>
              <dd>{this.props.patient.candidate}</dd>
            </div>
            <div className="m-t-sm">
              <dt>UC Evaluating Physician:</dt>
              <dd>{this.props.patient.uc_evaluating_physician}</dd>
            </div>
          </div>
        }
        <div className='m-t-sm'>
          <dt>Height:</dt>
          <dd>{this.props.patient.height || 'See clinical attachments'}</dd>
        </div>
        <div className='m-t-sm'>
          <dt>Weight:</dt>
          <dd>{this.props.patient.weight || 'See clinical attachments'}</dd>
        </div>
        <div className='m-t-sm'>
          <dt>Allergies:</dt>
          <dd>
            {
              this.props.patient.allergies.length
              ? <ul>
                  {
                    _.orderBy(this.props.patient.allergies, allergy => allergy.description).
                      map(allergy => <li key={allergy.id}>{allergy.description}</li>)
                  }
                </ul>
              : <span className='font-italic'>No data available</span>
            }
          </dd>
        </div>
        <div className='m-t-sm'>
          <dt>Patient demographics edits:</dt>
          <dd>{ this.props.referral.patient_demographics_edits ? this.props.referral.patient_demographics_edits : <span className='font-italic'>No data available</span>  }</dd>
        </div>
        {/* Patient Contact Info */}
        <div className='m-t-sm'>
          <dt>Patient / Caregiver Phone:</dt>
          <dd>
            {
              this.props.patient.phone
              ? this.state.showPhone
                ? this.props.patient.phone
                : <a href="#" className='font-italic' onClick={this.showPhone}>Do not contact patient without Case Manager permission. Click here to show contact details</a>
              : <span className='font-italic'>No data available</span>
            }
          </dd>
        </div>
        {
          this.props.sent_referral.rq &&
          <div className='m-t-sm'>
            <dt>Patient Cell Phone:</dt>
            <dd>{this.props.patient.pt_cell_phone}</dd>
          </div>
        }
        <div className='m-t-sm'>
          <dt>Patient Address:</dt>
          <dd>{ this.props.patient.full_address ? this.props.patient.full_address : <span className='font-italic'>No data available</span> }</dd>
        </div>
        <div className='m-t-sm'>
          <dt>Emergency Contact Info:</dt>
          <dd>
            {
              this.props.patient.emergency_contact
              ? this.state.showEmergencyContact
                ? this.props.patient.emergency_contact
                : <a href="#" className='font-italic' onClick={this.showEmergencyContact}>Do not contact patient without Case Manager permission. Click here to show contact details</a>
              : <span className='font-italic'>No data available</span>
            }
          </dd>
        </div>
        <div className='m-t-sm'>
          <dt>Contact info edits:</dt>
          <dd>{ this.props.referral.contact_info_edits ? this.props.referral.contact_info_edits : <span className='font-italic'>No data available</span> }</dd>
        </div>
      </dl>
    )
  }
}

Facesheet.propTypes = {
  patient: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired,
  sent_referral: React.PropTypes.object.isRequired
};
