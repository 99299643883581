import React from 'react';
import _ from 'lodash';
import moment from 'moment';

export default class Message extends React.Component {
  render() {
    return(
      <li>
        <a href={`/referrals/${this.props.message.referral_id}#tab-messages`} onClick={() => typeof ga != 'undefined' && ga('send', 'event', 'Header', 'Dropdown', 'Messages - Individual Message')}>
          {this.props.messageCount > 0 && <span className="label label-danger">{this.props.messageCount}</span>}
          <img alt="image" className="pull-left img-circle img-md m-t-xs m-r-md" src={this.props.message.avatar_url} />
          <div>
            <strong>{this.props.message.patient_full_name}</strong><br />
            <span className="font-italic">{this.props.message.sender_name}</span><br />
            {this.props.message.sent_by_current_organization && <span><i className="fa fa-share" /> </span>}
            {_.truncate(this.props.message.text, {length: 55})}
            <span className="pull-right text-muted small">{moment(this.props.message.created_at).fromNow()}</span>
          </div>
        </a>
      </li>
    )
  }
}

Message.propTypes = {
  message: React.PropTypes.object.isRequired,
  messageCount: React.PropTypes.number.isRequired
}
