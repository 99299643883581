import React from 'react';
//import { ToastContainer, ToastMessage } from 'react-toastr';
//const ToastMessageFactory = React.createFactory(ToastMessage.animation);

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css' 

export default class ToastVideo extends React.Component {
  toggleHelpVideos = this.toggleHelpVideos.bind(this)
  clearAlert = this.clearAlert.bind(this)
  pushHelpMessage = this.pushHelpMessage.bind(this)
  options = {
    closeButton: false,
    preventDuplicates: false,
    autoClose: false,
    hideProgressBar: true,
    extendedTimeOut: 90000,
    timeOut: 90000,
    tapToDismiss: false,
    position: 'bottom-right'
  }

  constructor(props){
    super(props)
    this.state = {disable_help: props.current_user.prefs.disable_help}
  }
  toggleHelpVideos(val){
    $.post("/api/users/" + this.props.current_user.id + "/disable_help", {disable_help: val})
    typeof ga != 'undefined' && ga('send', 'event', 'Dashboard', 'Help Popup', val ? 'Hide Help Videos' : 'Show Help');
    this.clearAlert()
    this.setState({disable_help: val})
    this.props.current_user.prefs.disable_help = val
  }
  pushHelpMessage(){
    toast(
      <div className="toastrwrap">
        { this.state.disable_help
          ? <div className="show_help_wrap" className="toggle-toastr-help show-toastr-help" onClick={() => this.toggleHelpVideos(false)}> 
              <i className="fa fa-question-circle m-sm" />
              Show help
            </div>
          : <div>
              <iframe width="230" height="315" src={this.props.src} frameBorder="0" />
              <div onClick={() => this.toggleHelpVideos(true)} className="toggle-toastr-help hide-toastr-help">Hide help videos</div>
            </div>    
        }
      </div>,
      this.options
    );
  }

  clearAlert() {
    this.refs.container.clear();
  }
  componentDidUpdate(){
    setTimeout(() => {
      this.pushHelpMessage()
    }, 510);
  }
  componentDidMount() {
    this.pushHelpMessage()
  }

  render() {
    return( <ToastContainer ref="container" className="help-toastr-wrap no-icon" /> );
  }
}
