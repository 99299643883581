import update from 'immutability-helper';
import { UPDATE_ORGANIZATIONS } from '../actions';

export default function(state = {}, action) {
  switch(action.type) {
    case UPDATE_ORGANIZATIONS:
      return update(state, {$merge: action.organizations});
    default:
      return state;
  }
}
