import Insurance  from './Insurance';
import React from 'react';
import PatientDemographics from './PatientDemographics';

export default class InsuranceTab extends React.Component {
  render() {
    return(
      <div>
        <Insurance patient={this.props.patient} provider={this.props.provider} referral={this.props.referral} />
        <PatientDemographics patient={this.props.patient} referral={this.props.referral} sent_referral={this.props.sent_referral} />
      </div>
    )
  }
}

InsuranceTab.propTypes = {
  patient: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired,
  sent_referral: React.PropTypes.object.isRequired
};
