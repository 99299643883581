import _ from 'lodash';
import { Modal, Button } from 'react-bootstrap';

export default class IdleTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTimeLeft: props.modalTimeOut,
      modalTimeLeftMinutes: this.getMinutes(props.modalTimeOut),
      modalTimeLeftSeconds: this.getSeconds(props.modalTimeOut),
      originalTitle: document.title
    };
  }

  componentDidMount() {
    document.cookie = 'loggedOut=false; path=/';

    this.resetTimeOutAt();

    document.addEventListener('click', this.resetTimeOutAt);
    document.addEventListener('keypress', this.resetTimeOutAt);

    let idleIntervalId = setInterval(this.idleTick, 1000);

    this.setState({idleIntervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.idleIntervalId);
    clearInterval(this.state.modalIntervalId);
  }

  logOut() {
    $.ajax({
      complete: () => window.location.replace('/'),
      method: 'DELETE',
      success: () => document.cookie = 'loggedOut=true; path=/',
      url: '/api/sessions'
    });
  }

  getCookieValue(key) {
    return document.cookie.replace(new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1');
  }

  getMinutes(milliseconds) {
    return Math.max(0, Math.floor(milliseconds / 60000));
  }

  getSeconds(milliseconds) {
    return Math.max(0, milliseconds % 60000 / 1000);
  }

  idleTick = () => {
    if (+this.getCookieValue('idleTimeOutAt') <= Date.now() && !this.state.showModal) {
      this.setState({showModal: true, modalTickStartedAt: Date.now()}, () => {
        document.title = 'Session Expiration Warning';

        let modalIntervalId = setInterval(this.modalTick, 1000);

        this.setState({modalIntervalId});
      });
    } else if (this.getCookieValue('loggedOut') === 'true') {
      window.location.replace('/');
    }
  }

  modalTick = () => {
    var modalTimeLeft = this.state.modalTimeLeft - 1000
    if (this.state.modalTickStartedAt + this.props.modalTimeOut + this.props.idleTimeOut < +this.getCookieValue('modalTimeOutAt')) {
      this.stayLoggedIn();
    } else if(modalTimeLeft < 0){
      this.logOut();
      window.location.replace('/');
    } else {
      var modalTimeLeftMinutes = this.getMinutes(modalTimeLeft)
      var modalTimeLeftSeconds = this.getSeconds(modalTimeLeft)
      this.setState({modalTimeLeft, modalTimeLeftMinutes, modalTimeLeftSeconds})
    }
  }

  resetIdleTimeOutAt = () => {
    document.cookie = `idleTimeOutAt=${Date.now() + this.props.idleTimeOut}; path=/`;
  }

  resetModalTimeOutAt = () => {
    document.cookie = `modalTimeOutAt=${Date.now() + this.props.idleTimeOut + this.props.modalTimeOut}; path=/`;
  }

  resetTimeOutAt = () => {
    this.resetIdleTimeOutAt();

    if (!this.state.showModal) {
      this.resetModalTimeOutAt();
    }
  }

  stayLoggedIn = () => {
    clearInterval(this.state.modalIntervalId);

    this.setState({
      modalTimeLeft: this.props.modalTimeOut,
      modalTimeLeftMinutes: this.getMinutes(this.props.modalTimeOut),
      modalTimeLeftSeconds: this.getSeconds(this.props.modalTimeOut),
      showModal: false
    }, this.resetTimeOutAt);

    document.title = this.state.originalTitle;
  }

  render() {
    return(
      <Modal bsSize="lg" show={this.state.showModal} animation={false}>
        <Modal.Body>
          <h1>Session Expiration Warning</h1>
          <h1>You have been inactive for {this.props.idleTimeOut / 60000} minutes.</h1>
          <h1>
            You will be automatically logged out in:
            <span className="text-danger"> {_.padStart(this.state.modalTimeLeftMinutes, 2, '0')}:{_.padStart(this.state.modalTimeLeftSeconds, 2, '0')}</span>
          </h1>
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize="lg" onClick={this.stayLoggedIn}>Stay Logged In</Button>
          <Button bsSize="lg" onClick={this.logOut}>Log Out Now</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

IdleTimer.propTypes = {
  idleTimeOut: React.PropTypes.number.isRequired,
  modalTimeOut: React.PropTypes.number.isRequired
}
