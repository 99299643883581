import utils from '../../Misc/Utils';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Task from './Task';

class TasksButton extends React.Component {
  render() {
    let incompleteTasks = _.filter(this.props.tasks, task => !task.completed_at);

    return(
      <li className="dropdown TasksButton" onClick={() => typeof ga != 'undefined' && ga('send', 'event', 'Header', 'Dropdown', 'Alerts')}>
        {
          incompleteTasks.length ?
            <button className="btn btn-outline btn-danger dim count-info" type="button" data-toggle="dropdown" href="#" aria-expanded="false">
              <i className="fa fa-bell"></i>
              {incompleteTasks.length > 0 && <span className="label label-danger" style={utils.ie9Browser() ? {top: '-9px'} : {}}>{incompleteTasks.length}</span>}
            </button> :
            <a className="dropdown-toggle count-info" data-toggle="dropdown" href="#" aria-expanded="false">
              <i className="fa fa-bell"></i>
            </a>
        }
        <ul className="dropdown-menu">
          {
            !incompleteTasks.length &&
            <li>
              <h1 className="text-center">
                You're on top of your tasks today!
              </h1>
            </li>
          }
          {
            incompleteTasks.slice(0, 8).map((task) => {
              return <Task key={task.id} task={task} />;
            }).reduce((tasks, task, index) => {
              if (tasks === null) {
                return [task];
              } else {
                return [...tasks, <li key={index} className="divider"></li>, task];
              }
            }, null)
          }
        </ul>
      </li>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tasks: state.tasks
  }
}

export default connect(mapStateToProps)(TasksButton)

TasksButton.propTypes = {
  tasks: React.PropTypes.object.isRequired
}
