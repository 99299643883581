import update from 'immutability-helper';
import { UPDATE_ACTIVITIES } from '../actions';

export default function(state = {}, action) {
  switch(action.type) {
    case UPDATE_ACTIVITIES:
      return update(state, {$merge: action.activities});
    default:
      return state;
  }
}
