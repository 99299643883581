import React from 'react';
import _ from 'lodash';
import moment from 'moment';

export default class RestrictedContent extends React.Component {

  constructor(props){
    super(props);
    this.openMessagesTab = this.openMessagesTab.bind(this);
  }

  renderRestricted() {
    return (
      <h1 className="restricted-content text-center">
        Access to patient information expired{this.props.referral.time_window_closes_at && ` ${moment(this.props.referral.time_window_closes_at).calendar()}`}. Contact <a onClick={() => {this.openMessagesTab()} }>{this.props.hospital.name}>{this.props.hospital.name}</a> to request access
      </h1>
    )
  }

  renderCanceled() {
    return (
      <h1 className="restricted-content text-center">
        This referral was cancelled{ this.props.referral.cancelled_at ? ' '+moment(this.props.referral.cancelled_at).calendar() : '' }. Contact <a onClick={() => {this.openMessagesTab()} }>{this.props.hospital.name}</a> to learn more.
      </h1>
    )
  }

  openMessagesTab() {
    this.props.openTab('#tab-messages');
  }

  render() {
    let content = null;
		if ( this.props.referral.status === 'canceled' ) {
      content = this.renderCanceled();
    } else {
      content = this.renderRestricted();
    }

    return(
    	<div className='RestrictedContent chat-discussion'>
    		{content}
    	</div>
    )
  }

}

RestrictedContent.propTypes = {
  referral: React.PropTypes.object.isRequired,
  restrictions: React.PropTypes.bool.isRequired,
  hospital: React.PropTypes.object.isRequired,
  openTab: React.PropTypes.func,
};
