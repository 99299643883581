import React from 'react';
import utils from '../Misc/Utils';
import update from 'immutability-helper';
import { Modal, Button, FormGroup, ControlLabel, FormControl, HelpBlock, Popover, OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateCurrentUser } from '../../actions';

const required_field = 'This field is required';

class PreviewSignUpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {}, 
      errors: {
        first: [],
        last: [],
        email: [],
        password: [required_field],
        password_confirmation: [required_field]
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.closeSuccess = this.closeSuccess.bind(this);
    this.submit = this.submit.bind(this);
    this.submit_disabled = true
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show === true && this.props.show === false) {
      this.setState({
        user: {
          email: this.props.current_user.preview_email,
          first: this.props.current_user.first,
          last: this.props.current_user.last,
          password: '',
          password_confirmation: ''
        },
      });
    }
  }

  handleChange(event) {
    this.setState({
      user: update(this.state.user, {$merge: {[event.target.name]: event.target.value}})
    });
    var f = event.target.name
    var all_errs = this.state.errors || {}
    var disabled = false
    var val = event.target.value
    const errs = all_errs[f] = []

    var all_fields = ['first','last','email','password','password_confirmation']
    all_fields.forEach((field) => {
      if(field == f){
        if(!val || val == ''){
          disabled = true
          errs.push(["This field is required"])
        }
      } else {
        if(!this.state.user[f] || this.state.user[f] == ''){
          all_errs[f] = [required_field]
        }
      }
    })
    if(val && val != ''){
      switch(f){
        case 'password':
          var charClassCount = 0
          var regexes = [/[A-Z]/,/[a-z]/,/[0-9]/,/[^a-zA-Z0-9\s\:]/]
          regexes.forEach(function(regex){ 
            if(val.match(regex)){ 
              charClassCount = charClassCount + 1 
            }
          })
          if(charClassCount < 3){
            disabled = true
            errs.push("Password doesn't have enough kinds of characters (only " + charClassCount + "/4)")
          }
          if(val.length < 8){
            disabled = true
            errs.push("Password is too short (" + val.length + " characters)")
          }
          break;
        case 'password_confirmation':
          if(val != this.state.user.password){
            disabled = true
            errs.push("Confirmation and password must match")
          }
          break;
      } 
    }
    this.setState({
      errors: update(this.state.errors, {$merge: all_errs}),
      submit_disabled: disabled
    })
  }

  submit() {
    $.ajax(`/api/users/${this.props.current_user.id}`, {
      data: {user: this.state.user},
      error: (response) => {
        this.setState({
          errors: update(this.state.errors, {$merge: response.responseJSON})
        });

      },
      success: (response) => {
        this.props.updateCurrentUser(response.user);
        this.setState({showSuccess: true, show: false})
      },
      type: 'PATCH'
    });
  }

  closeSuccess(){
    //this.setState({showSuccess: false})
    utils.location_replace('/sent_referrals');
  }

  render() {
    const popover = (
      <Popover id="popover-password-requirements" title="Password Requirements" style={{maxWidth: 'none', width: 435}}>
        Your password must meet the following requirements:
        <ul>
          <li>has at least 8 characters</li>
          <li>has at least 1 character from 3 of the 4 following categories:</li>
          <ul>
            <li>upper-case letter</li>
            <li>lower-case letter</li>
            <li>number</li>
            <li>symbol (!@#$%^&*)</li>
          </ul>
          <li>not one of your five most recently used passwords</li>
        </ul>
      </Popover>
    );

    return(
      <div>
        <Modal show={this.props.show && !this.state.showSuccess} dialogClassName='modal-lg'>
          <Modal.Body>
            <h1>
              To access future referrals and edit your brochure, please set your password:
            </h1>
            <form className="form-horizontal m-xl" onChange={this.handleChange}>
              <FormGroup controlId="user_first" validationState={this.state.errors.first.length > 0 ? 'error' : null} className="form-group">
                <ControlLabel className="col-sm-3 control-label">First name:</ControlLabel>
                <div className="col-sm-9">
                  <FormControl type="text" className="form-control" name="first" defaultValue={this.props.current_user.first} />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.first}</HelpBlock>
                </div>
              </FormGroup>
              <FormGroup controlId="user_last" validationState={this.state.errors.last.length > 0 ? 'error' : null} className="form-group">
                <ControlLabel className="col-sm-3 control-label">Last name:</ControlLabel>
                <div className="col-sm-9">
                  <FormControl type="text" className="form-control" name="last" defaultValue={this.props.current_user.last} />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.last}</HelpBlock>
                </div>
              </FormGroup>
              <FormGroup controlId="user_email" validationState={this.state.errors.email.length > 0 ? 'error' : null} className="form-group">
                <ControlLabel className="col-sm-3 control-label">Email:</ControlLabel>
                <div className="col-sm-9">
                  <FormControl type="email" className="form-control" name="email" defaultValue={this.props.current_user.preview_email} />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.email && this.state.errors.email.join(', ')}</HelpBlock>
                </div>
              </FormGroup>
              <FormGroup controlId="user_password" validationState={this.state.errors.password.length > 0 ? 'error' : null} className="form-group">
                <ControlLabel className="col-sm-3 control-label">Password:</ControlLabel>
                <div className="col-sm-9">
                  <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
                    <FormControl type="password" className="form-control" name="password" />
                  </OverlayTrigger>
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.password && this.state.errors.password.join(', ')}</HelpBlock>
                </div>
              </FormGroup>
              <FormGroup controlId="user_password_confirmation" validationState={this.state.errors.password_confirmation.length > 0 ? 'error' : null} className="form-group">
                <ControlLabel className="col-sm-3 control-label">Confirm password:</ControlLabel>
                <div className="col-sm-9">
                  <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
                    <FormControl type="password" className="form-control" name="password_confirmation" />
                  </OverlayTrigger>
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.password_confirmation && this.state.errors.password_confirmation.join(', ')}</HelpBlock>
                </div>
              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" bsStyle="primary" onClick={this.submit} disabled={this.state.submit_disabled}>Submit</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showSuccess} dialogClassName='modal-lg'>
          <Modal.Body>
            <h1>
              Success!
            </h1>
            <p>
              Your email and password have been saved as a new account.  You can log in to Aidin anytime at <a href="https://open.myaidin.com">open.myAidin.com</a> by entering your email and password on the upper right.
            </p>
            <p>
              To learn how to use Aidin, <a href="https://goo.gl/forms/999PlPAfBMSYUBjp2">click here</a> to watch the Aidin webinar! Feel free to share it with your team or save it for later.
            </p>
            <p> Here are three things to update now: </p>
            <ul>
              <li>
                Update what patients see about you: <a href="/brochure">Edit Brochure</a>
              </li>
              <li>
                Invite more users: <a href="/users">Add or Remove Users</a>
              </li>
              <li>
                Control your notifications from Aidin (email, fax, and SMS): <a href="/users/edit">User Settings</a>
              </li>
              <li>
                Manage your referral volume: <a href="/filters">Referral Preferences</a>
              </li>
            </ul>

          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" bsStyle="primary" onClick={this.closeSuccess}>Dismiss</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    current_user: state.current_user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentUser: (current_user) => {
      dispatch(updateCurrentUser(current_user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewSignUpModal)

PreviewSignUpModal.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  updateCurrentUser: React.PropTypes.func.isRequired
}
