import React from 'react';
import moment from 'moment';
import update from 'immutability-helper';
import { Tabs, Tab } from 'react-bootstrap';
import BrowserSupportWrapper from '../BrowserSupportWrapper';
import UserAvatar from './UserAvatar';
import UserContactInfo from './UserContactInfo';
import UserNotificationPreferences from './UserNotificationPreferences';
import UserPasswordReset from './UserPasswordReset';
import UserSecurityQuestion from './UserSecurityQuestion';

export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {user: props.user};
  }

  formatPhoneNumber(phoneNumber) {
    let digits = (phoneNumber || '').replace(/\D/g, '');

    if (phoneNumber) {
      return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6, 10)}`;
    }
  }

  updateUser = (user) => {
    this.setState({user: update(this.state.user, {$merge: user})});
  }

  render() {
    return(
      <div>
        <div className="col-lg-9">
          <div className="wrapper wrapper-content animated fadeInUp">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="m-b-md">
                      <h2>Account Summary for {this.state.user.email}</h2>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <dl className="dl-horizontal">
                      <dt>Email:</dt>
                      <dd>{this.state.user.email}</dd>
                      <dt>Fax:</dt>
                      <dd>{this.formatPhoneNumber(this.state.user.fax)}</dd>
                      <dt>Name:</dt>
                      <dd>{this.state.user.first} {this.state.user.last}</dd>
                      <dt>Account Created:</dt>
                      <dd>{moment(this.state.user.created_at).format('MMMM D YYYY [at] h:mma')}</dd>
                    </dl>
                  </div>
                  <div className="col-lg-7">
                    <dl className="dl-horizontal">
                      <dt>Last Login:</dt>
                      <dd>{moment(this.state.user.last_seen).format('MMMM D YYYY [at] h:mma')}</dd>
                      <dt>Total Tasks Completed:</dt>
                      <dd>{this.state.user.tasks_completed_count}</dd>
                      <dt>Tasks Completed On-Time:</dt>
                      <dd>
                        {
                          this.state.user.tasks_completed_on_time_percentage !== null &&
                          `${Math.round(this.state.user.tasks_completed_on_time_percentage * 100)}%`
                        }
                      </dd>
                    </dl>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <dl className="dl-horizontal">
                      <dt>Completed:</dt>
                      <dd>
                        <div className="progress progress-striped active m-b-sm">
                          <div style={{width: '50%'}} className="progress-bar"></div>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>

                <div className="row m-t-sm">
                  <div className="col-lg-12">
                    <div className="p-w-sm">
                      <Tabs defaultActiveKey={1} animation={false} id="user-settings-tabs">
                        <Tab eventKey={1} title="My Password / Security Settings">
                          <div className="p-xl">
                            <h2 className="m-b-xl">Password Reset</h2>
                            <div className="m-b-xl">
                              <h3>
                                Your current password is valid for {90 - moment().diff(moment(this.state.user.password_last_update_at), 'days')} more
                                days. HIPAA regulations require passwords to change every 3 months.
                              </h3>
                            </div>
                            <UserPasswordReset user={this.state.user} updateUser={this.updateUser} />
                            <div className="p-h-xl" />
                            <h2 className="m-b-xl">Security Question</h2>
                            <UserSecurityQuestion user={this.state.user} />
                          </div>
                        </Tab>
                        <Tab eventKey={2} title="My Profile">
                          <div className="p-xl">
                            <h2 className="m-b-xl">Update Contact Info</h2>
                            <UserContactInfo user={this.state.user} updateUser={this.updateUser} />
                            <div className="p-h-xl" />
                            <h2 className="m-b-xl">My Avatar</h2>
                            <BrowserSupportWrapper>
                              <UserAvatar user={this.state.user} />
                            </BrowserSupportWrapper>
                          </div>
                        </Tab>
                        <Tab eventKey={3} title="My Notification Preferences">
                          <div className="p-xl">
                            <h2 className="m-b-xl">My Notification Preferences</h2>
                            <UserNotificationPreferences user={this.state.user} />
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3">
          <h2>Settings Tasks</h2>
        </div>
      </div>
    )
  }
}

User.propTypes = {
  user: React.PropTypes.object.isRequired
}
