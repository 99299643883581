import React from 'react';
export default class TablePaginator extends React.Component {
  render() {
    return(
      <tfoot>
        <tr>
          <td colSpan="6" className="footable-visible">
            <ul className="pagination pull-right">
              <li className="footable-page-arrow">
                <button className="btn-white" onClick={this.props.getPage.bind(this, 1)} disabled={this.props.current_page === 1}>«</button>
              </li>
              <li className="footable-page-arrow">
                <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.current_page - 1)} disabled={this.props.current_page === 1}>‹</button>
              </li>
              {(() => {
                if (this.props.current_page > 3 && this.props.current_page === this.props.total_pages) {
                  return(
                    <li className="footable-page">
                      <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.current_page - 4)}>{this.props.current_page - 4}</button>
                    </li>
                  )
                }
              })()}
              {(() => {
                if (this.props.current_page > 3 && this.props.current_page > this.props.total_pages - 2) {
                  return(
                    <li className="footable-page">
                      <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.current_page - 3)}>{this.props.current_page - 3}</button>
                    </li>
                  )
                }
              })()}
              {(() => {
                if (this.props.current_page > 2) {
                  return(
                    <li className="footable-page">
                      <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.current_page - 2)}>{this.props.current_page - 2}</button>
                    </li>
                  )
                }
              })()}
              {(() => {
                if (this.props.current_page > 1) {
                  return(
                    <li className="footable-page">
                      <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.current_page - 1)}>{this.props.current_page - 1}</button>
                    </li>
                  )
                }
              })()}
              <li className="footable-page active"><button>{this.props.current_page}</button></li>
              {(() => {
                if (this.props.current_page < this.props.total_pages) {
                  return(
                    <li className="footable-page">
                      <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.current_page + 1)}>{this.props.current_page + 1}</button>
                    </li>
                  )
                }
              })()}
              {(() => {
                if (this.props.current_page < this.props.total_pages - 1) {
                  return(
                    <li className="footable-page">
                      <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.current_page + 2)}>{this.props.current_page + 2}</button>
                    </li>
                  )
                }
              })()}
              {(() => {
                if (this.props.current_page < this.props.total_pages - 2 && this.props.current_page < 3) {
                  return(
                    <li className="footable-page">
                      <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.current_page + 3)}>{this.props.current_page + 3}</button>
                    </li>
                  )
                }
              })()}
              {(() => {
                if (this.props.current_page < this.props.total_pages - 3 && this.props.current_page === 1) {
                  return(
                    <li className="footable-page">
                      <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.current_page + 4)}>{this.props.current_page + 4}</button>
                    </li>
                  )
                }
              })()}
              <li className="footable-page-arrow">
                <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.current_page + 1)} disabled={this.props.current_page === this.props.total_pages}>›</button>
              </li>
              <li className="footable-page-arrow">
                <button className="btn-white" onClick={this.props.getPage.bind(this, this.props.total_pages)} disabled={this.props.current_page === this.props.total_pages}>»</button>
              </li>
            </ul>
          </td>
        </tr>
      </tfoot>
    )
  }
}

TablePaginator.propTypes = {
  current_page: React.PropTypes.number.isRequired,
  total_pages: React.PropTypes.number.isRequired,
  getPage: React.PropTypes.func.isRequired
}
