import React from 'react';
import moment from 'moment';

export default class UserRow extends React.Component {
  render() {
    return(
      <tr onClick={() => this.props.selectUser(this.props.user)} className="clickable">
        <td className="client-avatar"><img alt="user_avatar" src={this.props.user.avatar_url} /></td>
        <td><a data-toggle="tab" href="#contact-1" className="client-link">{this.props.user.name}</a></td>
        <td>{this.props.user.last_seen && moment(this.props.user.last_seen).format('MMMM D YYYY [at] h:mma')}</td>
        <td className="contact-type"><i className="fa fa-envelope"></i></td>
        <td>{this.props.user.email}</td>
        <td className="client-status">
          {
            this.props.user.active ?
              <span className="label label-primary">Active</span> :
              <span className="label">Inactive</span>
          }
        </td>
      </tr>
    )
  }
}

UserRow.propTypes = {
  user: React.PropTypes.object.isRequired,
  selectUser: React.PropTypes.func.isRequired
}
