import React from 'react';
 
export default class AdminDefault extends React.Component {
  render() {
    return (
      <div>
        <h2>
          Welcome to the admin panel!
        </h2>
      </div>
    )
  }
}
