import React from 'react';
import utils from '../Misc/Utils';
import { connect } from 'react-redux';
import request from 'superagent';
import { SpinnerWave } from '../Misc/Spinners.jsx';
import C3Chart from 'react-c3js';
import ReactTable from 'react-table';
 
class ReferralReport extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      loading: true,
      show_total_placements: true,
      show_weekly_vol_by_prov: true,
      show_referral_timing_summary: true,
      show_rdv_cancelled: true,
      show_rdv_completed: true,
      show_rdv_in_process: true,
    }
  }

  componentDidMount(){
    this.retrieveData()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.filters.last_updated != this.props.filters.last_updated){
      this.retrieveData(nextProps.filters)
    }
    if(nextProps.filters.xlsx_request != this.props.filters.xlsx_request){
      this.retrieveData(nextProps.filters, true)
    }
  }

  retrieveData = (filters, xlsx) => {
    var query_id = Math.random()
    if(xlsx){
      var url = "/api/reports/referrals?export=xlsx"
      utils.window_open(url,'')
    } else {
      this.setState({loading:true, last_query: query_id})
      var req = request.get("/api/reports/referrals?rnd="+Math.random()) 
      .query(this.props.filters)
      .end( (err,res) => {
        if(this.state.last_query == query_id){
          // chart
          var wv_keys = res.body.weekly_vol_by_prov.length > 0 ? Object.keys(res.body.weekly_vol_by_prov[0]).slice(1) : []
          var chart_data = {
            wv_keys: wv_keys,
            wv_data: _.map(wv_keys, k => [k].concat(_.map(res.body.weekly_vol_by_prov, pt => pt[k])))
          }
          this.setState({loading:false})
          this.setState(res.body)
          this.setState(chart_data)
        }
      })
    }
  }

  scoreDesc = (score) => {
    return ['Not enough data',
      'Lowest available',
      'Available',
      'Best available'
    ][score] || 'Not enough data'
  }

  fmtPct = (n) => {
    return n ? parseFloat(n).toFixed(2) + '%' : ''
  }

  fmtIntBig = (n, add) => {
    return n ? <h1 style={{fontSize:'3em'}}>{parseInt(n) + (add ? ' ' + add : '')}</h1> : <h3>0</h3>
  }

  fmtPctBig = (n) => {
    return n ? <h1 style={{fontSize:'3em'}}>{parseFloat(n).toFixed(2) + '%'}</h1> : <h3>Not enough data</h3>
  }

  fmtHospitalId = data => {
    return this.props.opts.hospital_campuses[data.hospital_id]
  }

  fmtProviderType = data => {
    return this.props.opts.provider_types_inv[data.provider_type][1]
  }

  forceNumericSort = (a,b) => {
    var pa = parseFloat(a)
    var pb = parseFloat(b)
    a = (a === null || a === undefined || isNaN(pa)) ? -Infinity : parseFloat(a)
    b = (b === null || b === undefined || isNaN(pb)) ? -Infinity : parseFloat(b)
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  }

  tableKeys = {
    rdv: [
      {id: "referral_no", accessor: "referral_no", Header: "Referral #"},
      {id: "patient_visit_id", accessor: "patient_visit_id", Header: "Patient Visit ID"},
      {id: "mrn", accessor: "mrn", Header: "MRN"},
      {id: "discharge_date", accessor: "discharge_date", Header: "Discharge Date"},
      {id: "draft_referral", accessor: "draft_referral", Header: "Draft Referral"},
      {id: "submit_task", accessor: "submit_task", Header: "Submit Task User"},
      {id: "reopen_task", accessor: "reopen_task", Header: "Reopen Task User"},
      {id: "share_task", accessor: "share_task", Header: "Share Task User"},
      {id: "reserve_task", accessor: "reserve_task", Header: "Reserve Task User"},
      {id: "confirm_discharge_task", accessor: "confirm_discharge_task", Header: "Confirm Discharge Task User"},
      {id: "last_provider_update_task", accessor: "last_provider_update_task", Header: "Last Provider Update Task User"},
      {id: "last_provider_update_task_at", accessor: "last_provider_update_task_at", Header: "Last Provider Update At"},
      {id: "provider_update_tasks_count", accessor: "provider_update_tasks_count", Header: "Provider Update Task Count"},
      {id: "open_competition", accessor: data => this.fmtPct(data.open_competition), sortMethod: this.forceNumericSort, Header: "Open Competition Rating" },
      {id: "sent_referral_competition_percent", accessor: data => this.fmtPct(data.sent_referral_competition_percent), sortMethod: this.forceNumericSort, Header: "Sent Referral Competition %" },
      {id: "completed_on_time_percent", accessor: data => this.fmtPct(data.completed_on_time_percent), sortMethod: this.forceNumericSort, Header: "Tasks Completed On Time %" },
      {id: "hospital_id", accessor: "hospital_id", Header: "Hospital ID"},
      {id: "service_line", accessor: "service_line", Header: "Service Line"},
      {id: "provider_type", accessor: "provider_type", Header: "Provider Type"},
      {id: "resuming_care", accessor: "resuming_care", Header: "Resuming Care"},
      {id: "name", accessor: "name", Header: "Financial Class"},
      {id: "primary_insurance", accessor: "primary_insurances", Header: "Primary Insurance"},
      {id: "secondary_insurances", accessor: "secondary_insurances", Header: "Secondary Insurances"},
      {id: "sent_referrals", accessor: "sent_referrals", Header: "# Sent Referrals"},
      {id: "offers", accessor: "offers", Header: "# Offers"},
      // {id: "medicare_competition", accessor: "medicare_competition", Header: "Medicare Competition Score"},
      // {id: "outcomes_competition", accessor: "outcomes_competition", Header: "Outcomes Competition Score"},
      // {id: "satisfaction_competition", accessor: "satisfaction_competition", Header: "Satisfaction Competition Score"},
      {id: "reserved_provider", accessor: "reserved_provider", Header: "Reserved Provider"},
      {id: "provider_city", accessor: "provider_city", Header: "Provider City"},
      {id: "medicare_score", accessor: o => this.scoreDesc(o,'medicare_score'), Header: "Medicare Score"},
      {id: "outcome_score", accessor: o => this.scoreDesc(o,'outcome_score'), Header: "Outcome Score"},
      {id: "satisfaction_score", accessor: o => this.scoreDesc(o,'satisfaction_score'), Header: "Satisfaction Score"},
      {id: "medicare_star", accessor: "medicare_star", Header: "Medicare Star"},
      {id: "admission_date", accessor: "admission_date", Header: "Admission Date"},
      {id: "referral_created", accessor: "referral_created", Header: "Referral Created"},
      {id: "last_submitted_at", accessor: "last_submitted_at", Header: "Last Submitted"},
      {id: "time_window_closes_at", accessor: "time_window_closes_at", Header: "Time Window Closed"},
      {id: "presented_to_patient", accessor: "presented_to_patient", Header: "Presented to Patient"},
      {id: "reserved_at", accessor: "reserved_at", Header: "Reserved At"},
      {id: "patient_satisfaction_score", accessor: "patient_satisfaction_score", Header: "Patient Satisfaction Score"},
      {id: "readmitted", accessor: "readmitted", Header: "Patient readmitted?"}
    ],
    referral_timing_summary: [
      {id: "avg_hours_admit_to_refer", accessor: "avg_hours_admit_to_refer", Header: "Average Hours: Submit to Referral"},
      {id: "avg_hours_refer_to_pt_choice", accessor: "avg_hours_refer_to_pt_choice", Header: "Average Hours: Referral to Patient Choice"},
      {id: "avg_hours_pt_choice_to_reserve", accessor: "avg_hours_pt_choice_to_reserve", Header: "Average Hours: Patient Choice to Discharge"},
      {id: "avg_hours_reserve_to_discharge", accessor: "avg_hours_reserve_to_discharge", Header: "Average Hours: Reserve to Discarge"}
    ],
    total_placements: [
      {id: "total_placements", accessor: "total_placements", Header: "Total Placements"}
    ],
    weekly_vol_by_prov: [
      {id: "provider_type", accessor: "provider_type", Header: "Provider Type"},
      {id: "this_week", accessor: "this_week", Header: "This Week"},
      {id: "last_week", accessor: "last_week", Header: "Last Week"},
      {id: "two_weeks_ago", accessor: "two_weeks_ago", Header: "Two Weeks Ago"},
      {id: "three_weeks_ago", accessor: "three_weeks_ago", Header: "Three Weeks Ago"},
    ]
  }

  

  taskLabel = v => {
    return {
      'discharge': 'confirm discharge',
      'share': 'share',
      'reopen': 'reopen',
      'reserve': 'reserve',
      'submit': 'submit'
    }[v] || v
  }

  transpose = (matrix) => {
    const rows = matrix.length
    const cols = matrix[0].length

    let grid = []
    for (let col = 0; col < cols; col++) {
      grid[col] = []
    }
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        grid[col][row] = matrix[row][col]
      }
    }
    return grid
  }

  taskOrder = ['submit','reopen','share','reserve','discharge']

  metrics_table = (metric_key) => {
      return (
        <div>
          <ReactTable
              defaultSortDesc={true}
              defaultPageSize={10}
              className="report_meta -striped"
              columns={this.tableKeys[metric_key] || this.tableKeys.rdv} 
              data={this.state[metric_key]}
              sortable={true}
              getTdProps = {(state,rowInfo,column,instance) => {
                return {
                  className: "tdcol-" + column.id
                }
              } }
              getTrProps = {(state,rowInfo,column,instance) => {
                return {
                  //className: "clickable",
                  // onClick: (e, handleOrig) => {
                  //   this.openUserPage(rowInfo.row.user_id)
                  //}
                }      
              } }
          >
          </ReactTable>
        </div>
      )
  }

  render() {
    return (
      <div className="reportsContent">
        { this.state.loading && <SpinnerWave /> }

        { !this.state.loading && <div>
          { this.state.total_placements && <div>

            <h2 className="cp" onClick={()=>{ this.setState({show_total_placements: !this.state.show_total_placements }) }}>
              <i className={"fa " + (this.state.show_total_placements ? "fa-chevron-down" : "fa-chevron-right")  } /> Total Placements
            </h2>

            { this.state.show_total_placements && <div style={{display:'flex'}}>
                <div className="rowFlx3">
                  <h3>Placements</h3>
                  { this.fmtIntBig(this.state.total_placements[0].total_placements) }
                  <p>patients placed</p>
                </div>
              </div>
            }
          </div> }


          { this.state.referral_timing_summary && <div>
            <h2 className="cp" onClick={()=>{ this.setState({show_referral_timing_summary: !this.state.show_referral_timing_summary }) }}>
              <i className={"fa " + (this.state.show_referral_timing_summary ? "fa-chevron-down" : "fa-chevron-right")  } /> Referral Timing Summary
            </h2> 

            { this.state.show_referral_timing_summary && <div style={{display:'flex'}}>
              <div className="rowFlx3">
                <h3>Avg. Time</h3>
                { this.fmtIntBig(this.state.referral_timing_summary[0].avg_hours_admit_to_refer, 'hours') } 
                <p>Submit to Referral</p>
              </div>
              <div className="rowFlx3">
                <h3>Avg. Time</h3>
                { this.fmtIntBig(this.state.referral_timing_summary[0].avg_hours_refer_to_pt_choice, 'hours') } 
                <p>Referral to Patient Choice</p>
              </div>
              <div className="rowFlx3">
                <h3>Avg. Time</h3>
                { this.fmtIntBig(this.state.referral_timing_summary[0].avg_hours_pt_choice_to_reserve, 'hours') } 
                <p>Patient Choice to Discharge</p> 
              </div>
              <div className="rowFlx3">
                <h3>Avg. Time</h3>
                { this.fmtIntBig(this.state.referral_timing_summary[0].avg_hours_reserve_to_discharge, 'hours') } 
                <p>Reserve to Discharge</p> 
              </div>
            </div> }
          </div> }

          
          { this.state.weekly_vol_by_prov && <div>
            <h2 className="cp" onClick={()=>{ this.setState({show_weekly_vol_by_prov: !this.state.show_weekly_vol_by_prov }) }}>
              <i className={"fa " + (this.state.show_weekly_vol_by_prov ? "fa-chevron-down" : "fa-chevron-right")  } /> Weekly Volume by Provider
            </h2>
            { this.state.show_weekly_vol_by_prov && this.state.weekly_vol_by_prov && <div>
              { this.state.weekly_vol_by_prov.length == 0 && <div className="piblock"><h2>No results</h2></div> }
   
              { this.state.weekly_vol_by_prov.length > 0 && this.state.wv_data && <div className="reportGauges">
                <div className="pblock">
                  <C3Chart data={{
                    type: 'bar',
                    json: this.state.weekly_vol_by_prov,
                    keys: {
                      value: this.state.wv_keys
                    },
                    names: {
                      'this_week': 'This week',
                      'last_week': 'Last week',
                      'two_weeks_ago': 'Two weeks ago',
                      'three_weeks_ago': 'Three weeks ago'
                    }
                  }}
                  axis={{
                    x: {
                      type: 'category',
                      tick: { 
                        format: x => this.state.weekly_vol_by_prov[x].provider_type
                      }
                    }
                  }} />
                </div>
              </div> }
            </div> }
          </div> }

          { this.state.rdv_in_process && <div>
            <h2 className="cp" onClick={()=>{ this.setState({show_rdv_in_process: !this.state.show_rdv_in_process }) }}>
              <i className={"fa " + (this.state.show_rdv_in_process ? "fa-chevron-down" : "fa-chevron-right")  } /> Referral Detailed View (In Progress)
            </h2>

          { this.state.show_rdv_in_process && this.metrics_table('rdv_in_process') }
          </div> }

          { this.state.rdv_completed && <div>
            <h2 className="cp" onClick={()=>{ this.setState({show_rdv_completed: !this.state.show_rdv_completed }) }}>
              <i className={"fa " + (this.state.show_rdv_completed ? "fa-chevron-down" : "fa-chevron-right")  } /> Referral Detailed View (Completed)
            </h2>

          { this.state.show_rdv_completed && this.metrics_table('rdv_completed') }
          </div> }

          { this.state.rdv_cancelled && <div>
            <h2 className="cp" onClick={()=>{ this.setState({show_rdv_cancelled: !this.state.show_rdv_cancelled }) }}>
              <i className={"fa " + (this.state.show_rdv_cancelled ? "fa-chevron-down" : "fa-chevron-right")  } /> Referral Detailed View (Cancelled)
            </h2>

          { this.state.show_rdv_cancelled && this.metrics_table('rdv_cancelled') }
          </div> }

        </div> }

      </div>
    )
  }

}


const mapStateToProps = state => {
  return {
    current_user: state.current_user,
    current_organization: state.current_organization
  }
}

export default connect(mapStateToProps)(ReferralReport)
