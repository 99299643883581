import React from 'react';
import moment from 'moment';
import update from 'immutability-helper';
import DatePicker from 'react-bootstrap-date-picker';
import StripeCheckout from 'react-stripe-checkout';
import { Button, ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import utils from '../Misc/Utils';
import CheckboxSection from '../Forms/CheckboxSection';

export default class ModeReports extends React.Component {
  constructor(props) {
    super(props);

    const referrals_reserved_between_start = moment().subtract(1, 'month').format('YYYY-MM-DD');
    const referrals_reserved_between_end = moment().format('YYYY-MM-DD');

    this.state = {
      hospital_ids: props.hospitals.map(h => h.id),
      referrals_reserved_between_start: referrals_reserved_between_start,
      referrals_reserved_between_end: referrals_reserved_between_end,
      showSubscribeModal: false,
      url: `${props.url}&param_referrals_reserved_between_start=${referrals_reserved_between_start}&param_referrals_reserved_between_end=${referrals_reserved_between_end}`
    }
  }

  export = (event) => {
    if (!this.props.enabled) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({showSubscribeModal: true});
    }
  }

  filter = () => {
    if (this.props.enabled) {
      let params = `&param_hospital_ids=${this.state.hospital_ids.join(',')}`

      if (this.state.referrals_reserved_between_start && this.state.referrals_reserved_between_end) {
        params = `${params}&param_referrals_reserved_between_start=${this.state.referrals_reserved_between_start}&param_referrals_reserved_between_end=${this.state.referrals_reserved_between_end}`;
      }

      this.setState({url: `${this.props.url}${params}`});
    } else {
      this.setState({showSubscribeModal: true});
    }
  }

  handleHospitalIdsChange = event => {
    if (event.target.checked) {
      this.setState({hospital_ids: update(this.state.hospital_ids, {$push: [+event.target.id]})});
    } else {
      const index = _.indexOf(this.state.hospital_ids, +event.target.id);
      this.setState({hospital_ids: update(this.state.hospital_ids, {$splice: [[index, 1]]})});
    }
  }

  handleReferralsReservedBetweenStart = (value, formattedValue) => {
    this.setState({referrals_reserved_between_start: formattedValue})
  }

  handleReferralsReservedBetweenEnd = (value, formattedValue) => {
    this.setState({referrals_reserved_between_end: formattedValue})
  }

  subscribeStandardMonthly = (token) => {
    $.post('/api/stripe/checkout?product=standard&plan=monthly', token);
  }

  subscribeStandardYearly = (token) => {
    $.post('/api/stripe/checkout?product=standard&plan=yearly', token);
  }

  subscribeEnterpriseMonthly = (token) => {
    $.post('/api/stripe/checkout?product=enterprise&plan=monthly', token);
  }

  subscribeEnterpriseYearly = (token) => {
    $.post('/api/stripe/checkout?product=enterprise&plan=yearly', token);
  }

  showSubscribeModal = () => {
    this.setState({showSubscribeModal: true});
    typeof ga != 'undefined' && ga('send', 'event', 'Button', 'Click', 'Subscribe Mode Reporting button');
  }

  render() {
    return(
      <div className="row">
        {
          this.props.enabled ?
            <div className="row m-t-md">
              <div className="col-md-8">
                <Button bsSize="large" bsStyle="primary" className="float-right" href="/zendesk_session" target="_blank">Upgrade</Button>
              </div>
            </div>
          :
            <div className="row m-t-md">
              <img src={utils.asset_src.sample} style={{height: 250, position: 'absolute'}} />
              <div className="col-md-8">
                <Button bsSize="large" bsStyle="danger" className="float-right" onClick={this.showSubscribeModal}>Subscribe</Button>
              </div>
            </div>
        }
        <div className="row m-b-xl">
          <div className="col-md-8">
            <form className="form-horizontal m-t-md">
              <FormGroup controlId="mode_report_referrals_reserved_between_start" className="form-group">
                <ControlLabel className="col-sm-7 control-label">Referrals reserved between:</ControlLabel>
                <div className="col-sm-5">
                  <DatePicker
                    dateFormat="YYYY-MM-DD"
                    name="referrals_reserved_between_start"
                    onChange={this.handleReferralsReservedBetweenStart}
                    value={this.state.referrals_reserved_between_start}
                  />
                </div>
              </FormGroup>
              <FormGroup controlId="mode_report_referrals_reserved_between_end" className="form-group">
                <ControlLabel className="col-sm-7 control-label">and:</ControlLabel>
                <div className="col-sm-5">
                  <DatePicker
                    dateFormat="YYYY-MM-DD"
                    name="referrals_reserved_between_end"
                    onChange={this.handleReferralsReservedBetweenEnd}
                    value={this.state.referrals_reserved_between_end}
                  />
                </div>
              </FormGroup>
              <FormGroup controlId="mode_report_hospital_ids" className="form-group">
                <ControlLabel className="col-sm-7 control-label">Referral sources:</ControlLabel>
                <div className="col-sm-5">
                  {
                    this.props.hospitals.map(h => {
                      return(
                        <CheckboxSection
                          id={h.id}
                          value={String(~this.state.hospital_ids.indexOf(h.id))}
                          onChange={this.handleHospitalIdsChange}
                          labelText={h.name}
                          key={h.id}
                        />
                      );
                    })
                  }
                </div>
              </FormGroup>
              <div className="col-sm-offset-7 col-sm-5">
                <Button bsSize="lg" bsStyle="primary" onClick={this.filter}>Filter</Button>
              </div>
              <div className="m-t-md col-sm-offset-7 col-sm-5">
                <Button bsSize="lg" bsStyle="primary" href={this.props.csv_url} onClick={this.export}>Export</Button>
              </div>
            </form>
          </div>
        </div>
        <iframe src={this.state.url} width="100%" height="760" frameBorder="0"></iframe>
        <Modal show={this.state.showSubscribeModal} animation={false}>
          <Modal.Body>
            <p style={{fontSize: 16}}>
              Aidin reporting gives you insight you can't find anywhere else - from
              real-time insights into your team's responsiveness to referral sources,
              details on the referrals you service, to referral funnel analyses that
              identify where your organization is missing out.
            </p>
            <p style={{fontSize: 16}}>Choose the right subscription for your team.</p>
            <center>
              <div className="m-t-xl">
                <h2>Standard Access</h2>
                <p>access to the past 6 months of your historical Aidin data</p>
                <p>
                  <StripeCheckout
                    amount={34900}
                    stripeKey={this.props.stripe_publishable_key}
                    token={this.subscribeStandardMonthly}
                  >
                    <Button bsSize="lg" bsStyle="primary">Monthly $349</Button>
                  </StripeCheckout>
                </p>
                <p>
                  <StripeCheckout
                    amount={374900}
                    stripeKey={this.props.stripe_publishable_key}
                    token={this.subscribeStandardYearly}
                  >
                    <Button bsSize="lg" bsStyle="primary">Yearly $3749</Button>
                  </StripeCheckout>
                </p>
              </div>
              <div className="m-t-xl">
                <h2>Enterprise Access</h2>
                <p>access to all your historical Aidin data</p>
                <p>
                  <StripeCheckout
                    amount={54900}
                    stripeKey={this.props.stripe_publishable_key}
                    token={this.subscribeEnterpriseMonthly}
                  >
                    <Button bsSize="lg" bsStyle="primary">Monthly $549</Button>
                  </StripeCheckout>
                </p>
                <p>
                  <StripeCheckout
                    amount={599900}
                    stripeKey={this.props.stripe_publishable_key}
                    token={this.subscribeEnterpriseYearly}
                  >
                    <Button bsSize="lg" bsStyle="primary">Yearly $5999</Button>
                  </StripeCheckout>
                </p>
              </div>
            </center>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={() => this.setState({showSubscribeModal: false})}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ModeReports.propTypes = {
  enabled: React.PropTypes.bool.isRequired,
  hospitals: React.PropTypes.array.isRequired,
  stripe_publishable_key: React.PropTypes.string.isRequired,
  url: React.PropTypes.string.isRequired
}
