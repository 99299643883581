import React from 'react';
import Message from './Message';

export default class AllMessages extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.messages && prevProps.messages &&
      (this.props.messages.length > prevProps.messages.length) ||
      (this.props.isActive && !prevProps.isActive)
    ) {
      const node = ReactDOM.findDOMNode(this);
      node.scrollTop = node.scrollHeight;
    }
  }

  render() {
    let content;
    if (this.props.messages && this.props.messages.length) {
      content = this.props.messages.map((message) => {
        return(
          <Message
            key={message.id}
            message={message}
            markAsUnread={this.props.markAsUnread}
          />
        );
      });
    } else if (this.props.messages && this.props.messages.length === 0) {
      content =
        <h1 className="no-messages text-center">No messages to display</h1>
        ;
    } else {
      content =
        <div className="sk-spinner sk-spinner-wave">
          <div className="sk-rect1"></div>
          <div className="sk-rect2"></div>
          <div className="sk-rect3"></div>
          <div className="sk-rect4"></div>
          <div className="sk-rect5"></div>
        </div>
        ;
    }

    return(
      <div className="chat-discussion">
        {content}
      </div>
    )
  }
}

AllMessages.propTypes = {
  messages: React.PropTypes.array,
  isActive: React.PropTypes.bool,
  markAsUnread: React.PropTypes.func.isRequired
};
