import React from 'react';
import utils from '../Misc/Utils';
import _ from 'lodash';
import {Button, Modal, FormGroup, ControlLabel, FormControl, HelpBlock} from 'react-bootstrap';

export default class PicturesTab extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isLoading: false,
      errors: {},
      brochureFile: null
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.submit = this.submit.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
  }

  handleFileChange(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({brochureFile: file});
    }
    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.setState({brochureFile: null});
    }
  }

  leastReventPhotos(photos){
    return _.map(photos.slice(0,-2), (photo, i) => {
      return(
        <span key={i}>
          <img 
            className="img-lg m-md" 
            src={photo.image_url} 
            alt={photo.file_name} 
          />
          {photo.created_at &&
          <i
            className="fa fa-times-circle fa-2x delete-photo" 
            onClick={() => this.handleFileDelete(photo.id)}
          />
          }
        </span>
      )   
    }).reverse()
  }

  mostRecentPhotos(photos){
    if (photos.length === 0 ) {
      photos.push({image_url: utils.asset_src.no_pictures_available, file_name: 'no_picture_available'})
      photos.push({image_url: utils.asset_src.no_pictures_available, file_name: 'no_picture_available'})
    } else if (photos.length === 1) {
      photos.push({image_url: utils.asset_src.no_pictures_available, file_name: 'no_picture_available'})
    }

    return _.map(photos.slice(-2), (photo, i) => {
      return(
        <span key={i}>
          <img 
            className="img-lg m-md" 
            src={photo.image_url} 
            alt={photo.file_name} 
          />
          {photo.created_at &&
          <i
            className="fa fa-times-circle fa-2x delete-photo" 
            onClick={() => this.handleFileDelete(photo.id)}
          />
          }
        </span>
      )      
    }).reverse();
  }

  handleFileDelete(attachment_id){
    let deleteAttachment = _.find(this.props.provider.attachments, (a) => {return a.id === attachment_id});
    deleteAttachment._destroy = true;
    let providerParams = {provider: {id: this.props.provider.id, attachment: deleteAttachment}}
    this.setState({isLoading: true}, () => {
      $.ajax(`/api/providers/${this.props.provider.id}/attachments/${deleteAttachment.id}`, {
        data: providerParams,
        success: (response) => {
          this.props.updateProvider(response.provider);
          this.setState({isLoading: false});
        },
        type: 'DELETE'
      });      
    });
  }

  submit() {
    let formData = new FormData();
    formData.append('provider[attachments][]', this.state.brochureFile);

    this.setState({isLoading: true}, () => {
      $.ajax(`/api/providers/${this.props.provider.id}/upload_attachment`, {
        complete: () => {
          this.setState({isLoading: false});
        },
        data: formData,
        contentType: false,
        processData: false,
        error: (response) => {
          this.setState({errors: response.responseJSON});
        },
        success: (response) => {
          this.props.updateProvider(response.provider);
          this.setState({errors: {}});
        },
        type: 'POST'
      });
    });
  }

  render() {
    let isLoading = this.state.isLoading;
    let sortedPhotos = _.sortBy(this.props.provider.attachments, (attachment) => {return attachment.created_at});
    return(
      <div className='TheBasicsTab'>
        <div className={`ibox-content${isLoading ? ' sk-loading' : ''}`} style={{border: 'none'}}>
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1"></div>
            <div className="sk-double-bounce2"></div>
          </div>
          <div className="p-xl">
            <h2 className="m-b">
              Pictures
            </h2>
            <h3 className="m-b">
              Pictures help patients feel good about their care choice.  
            </h3>
            <h3 className="m-b">
              You may upload 2 photos that appear directly on your printed Brochure and online. 
            </h3>
            <h4>Your two most recently uploaded pictures will appear on your Printable Brochure. All pictures will be visible to patients online.</h4>
            <p>
              <small>Accepted file types: jpg, png, gif, bmp</small>
              <br /><small>Recommended image size: 650x485px. Max 3MB.</small>
            </p>

            { this.mostRecentPhotos(sortedPhotos) }
            <h4>The below photos will appear if patients login to Aidin online. A minimum of four pictures is recommended.</h4>
            { this.leastReventPhotos(sortedPhotos) }
            <form className="form-horizontal m-t-md" ref="form">
              <FormGroup controlId={`provider_attachments`} className="form-group">
                <ControlLabel className="control-label">Image</ControlLabel>
                  <FormControl 
                    type="file" 
                    className="form-control" 
                    name="provider[attachments][i]" 
                    onChange={this.handleFileChange}
                    style={{border: 'none'}} 
                  />
              </FormGroup>
            </form>
            <div className="col-md-12">
              <Button bsStyle="primary" disabled={isLoading || !this.state.brochureFile} onClick={!isLoading ? this.submit : null}>
                Upload Photo
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PicturesTab.propTypes = {
  provider: React.PropTypes.shape({
    attachments: React.PropTypes.array.isRequired,
  }),
  updateProvider: React.PropTypes.func.isRequired
};
