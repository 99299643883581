import React from 'react';
import update from 'immutability-helper';
import { Modal, Button, ButtonGroup, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import CheckboxSection from '../Forms/CheckboxSection';
import { connect } from 'react-redux';
import { SpinnerWave } from '../Misc/Spinners'

class UserInviteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      show: false,
      select_other_orgs: false,
      selected_orgs: [],
      submit_enabled1: false,
      submit_enabled2: false,
      user: {}
    };
  }

  handleCheckboxChange = (event) => {
    this.setState({[event.target.name]: event.target.checked});
  }

  handleFormChange = (event) => {
    this.setState({
      user: update(this.state.user, {$merge: {[event.target.name]: event.target.value}})
    });
  }

  handleModalChange = (event) => {
    if (event.target.value === 'true') {
      this.setState({[event.target.name]: true});
    } else {
      this.setState({[event.target.name]: false, user: {}});
    }
  }

  handleOrgOpen = (event) => {
    this.setState({select_other_orgs: !this.state.select_other_orgs})
  }

  handleOrgChange = (event) => {
    const val = parseInt(event.target.value)
    if(this.state.selected_orgs.includes(val)){
      this.setState({selected_orgs: this.state.selected_orgs.filter(x => x != val)})
    } else {
      this.setState({selected_orgs: this.state.selected_orgs.concat([val])})
    }
  }

  selectAllOrgs = () => {
    this.setState({selected_orgs: this.props.organizations.map(x => x.id)})
  }

  selectNoneOrgs = () => {
    this.setState({selected_orgs: []})
  }

  selectInvertOrgs = () => {
    this.setState({selected_orgs: this.props.organizations.map(x => x.id).filter( x => !this.state.selected_orgs.includes(x) ) })
  }


  submit = () => {
    $.ajax(`/api/users/invite`, {
      data: JSON.stringify({ user: this.state.user, other_orgs: this.state.selected_orgs }),
      dataType:'json',
      contentType: 'application/json; charset=utf-8',
      error: (response) => {
        this.setState({errors: response.responseJSON});
      },
      success: (data, status, jqXHR) => {
        this.setState({
          errors: {},
          show: false,
          show_success_modal: true,
          submit_enabled1: false,
          submit_enabled2: false,
          userName: `${[data.user.first, data.user.last].join(' ')} (${data.user.email})`,
          userExists: jqXHR.status !== 201
        });
      },
      type: 'POST'
    });
  }

  successModalText = () => {
    if (this.state.userExists) {
      return `${this.state.userName} already has access to ${this.props.current_organization.name}.`
    } else {
      return(
        <div>
          <p>An invitation email was sent to {this.state.userName} inviting them to create an account for {this.props.current_organization.name}.</p>
          <p>Make sure {this.state.userName} opens this email invitation to create their account.</p>
          <p>Quick tip: check your SPAM filters for missing emails.</p>
        </div>
      );
    }
  }

  hasError = key => {
    return this.state.errors && this.state.errors[key] && 'error'
  }

  fetchErrors = key => {
    return this.state.errors && this.state.errors[key] && this.state.errors[key].join(', ')
  }

  render() {
    return(
      <div>
        <Button bsSize="sm" bsStyle="primary" onClick={this.handleModalChange} name="show" value="true">
          <i className="fa fa-plus"></i> Add New Team Member
        </Button>
        <Modal bsSize="lg" show={this.state.show} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md text-center">
              You manage who has access to your Aidin referrals.
              Complete the form below to invite a new user to your organization:
            </h1>
            <form className="form-horizontal m-t-md" onChange={this.handleFormChange}>
              <FormGroup controlId="user_first" validationState={this.hasError('first')} className="form-group">
                <ControlLabel className="col-sm-3 control-label">First name:</ControlLabel>
                <div className="col-sm-5">
                  <FormControl type="text" className="form-control" name="first" />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{ this.fetchErrors('first') }</HelpBlock>
                </div>
              </FormGroup>
              <FormGroup controlId="user_last" validationState={this.hasError('last')} className="form-group">
                <ControlLabel className="col-sm-3 control-label">Last name:</ControlLabel>
                <div className="col-sm-5">
                  <FormControl type="text" className="form-control" name="last" />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{ this.fetchErrors('last') }</HelpBlock>
                </div>
              </FormGroup>
              <FormGroup controlId="user_email" validationState={this.hasError('email')} className="form-group">
                <ControlLabel className="col-sm-3 control-label">Email:</ControlLabel>
                <div className="col-sm-5">
                  <FormControl type="email" className="form-control" name="email" />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{ this.fetchErrors('email') }</HelpBlock>
                </div>
              </FormGroup>
              <FormGroup controlId="user_email_confirmation" validationState={this.hasError('email_confirmation')} className="form-group">
                <ControlLabel className="col-sm-3 control-label">Confirm email:</ControlLabel>
                <div className="col-sm-5">
                  <FormControl type="email" className="form-control" name="email_confirmation" />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{ this.fetchErrors('email_confirmation') }</HelpBlock>
                </div>
              </FormGroup>
            </form>

            <div className="m-b-md">
              <CheckboxSection
                id="submit_enabled1"
                name="submit_enabled1"
                onChange={this.handleCheckboxChange}
                value={this.state.submit_enabled1}
                labelText={`I understand that by clicking Submit below, this user will receive an email inviting them to gain access to ${this.props.current_organization.name}'s Aidin account.`}
              />
            </div>

            <div className="m-b-md">
              <CheckboxSection
                id="submit_enabled2"
                name="submit_enabled2"
                onChange={this.handleCheckboxChange}
                value={this.state.submit_enabled2}
                labelText={`I understand that this user must check their email to activate access to ${this.props.current_organization.name}.`}
              />
            </div>
            { this.props.organizations.length > 1 &&
                <div>
                  <div onClick={ this.handleOrgOpen }>
                    <i style={{width:'2em'}}className={'fa ' + (this.state.select_other_orgs ? 'fa-chevron-down' : 'fa-chevron-right')} />
                    <label style={{fontWeight:'normal'}}>(Optional) Grant { this.state.user.first || "this user" } access to other organizations...</label>
                  </div>
                </div>
            }
            { this.state.select_other_orgs && 
              <div className="well well-sm">
                <ButtonGroup>
                  <Button bsSize="sm" onClick={this.selectAllOrgs}>Select all</Button>
                  <Button bsSize="sm" onClick={this.selectNoneOrgs}>Select none</Button>
                  <Button bsSize="sm" onClick={this.selectInvertOrgs}>Invert selection</Button>
                </ButtonGroup>
                { this.props.organizations.map( (org,i) => <div className="checkbox checkbox-success" key={i}>
                    <input type="checkbox" value={ org.id } id={"revoke_org_checkbox_" + i} onChange={this.handleOrgChange} checked={this.state.selected_orgs.includes(org.id)} />
                    <label htmlFor={"revoke_org_checkbox_" + i}>{org.name}</label>
                  </div>)
                }
              </div>
            }


          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.handleModalChange} name="show" value="false">Cancel</Button>
            <Button bsSize="lg" bsStyle="danger" onClick={this.submit} disabled={!this.state.submit_enabled1 || !this.state.submit_enabled2}>Submit</Button>
          </Modal.Footer>
        </Modal>
        <Modal bsSize="lg" show={this.state.show_success_modal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">{this.successModalText()}</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.handleModalChange} name="show_success_modal" value="false">OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

UserInviteModal.propTypes = {
  current_organization: React.PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    organizations: state.organizations
  }
}


export default connect(mapStateToProps)(UserInviteModal)
