import React from 'react';
import utils from '../Misc/Utils';
import { Provider } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import MessagesButton from './MessagesButton';
import OrganizationsButton from './OrganizationsButton';
import TasksButton from './TasksButton';

export default class TopNavbarMenu extends React.Component {
  logOut(event) {
    event.preventDefault();
    $.ajax({
      method: 'DELETE',
      success: () => {
        document.cookie = 'loggedOut=true; path=/';
        utils.location_replace('/');
      },
      url: '/api/sessions'
    });
  }

  launchAidinNext = () => {
    utils.location_replace('/sessions/aidin_next_redirect');
    typeof ga != 'undefined' && ga('send', 'event', 'Aidin Beta Teaser', 'Button', 'Try out the new Beta')
  }

  render() {
    return(
      <div className="text-center">
        <Button bsStyle="primary" bsSize="lg" style={{textTransform: 'none'}} className="dim m-t-xs launch-aidin-next" onClick={this.launchAidinNext}>
          Try out the new Beta version of Aidin
        </Button>
        <ul className="nav navbar-top-links navbar-right TopNavbarMenu">
          { this.props.current_user_roles.includes('provider') && <Provider store={store}>
            <TasksButton />
          </Provider> }
          {utils.ie9Browser() ? (<li style={{width: '8px'}}></li>): ""}
          { this.props.current_user_roles.includes('provider') && <Provider store={store}>
            <MessagesButton />
          </Provider> }
          {utils.ie9Browser() ? (<li style={{width: '8px'}}></li>): ""}
          { this.props.current_user_roles.includes('provider') && <Provider store={store}>
            <OrganizationsButton />
          </Provider> }
          <li>
            <a href="#" onClick={this.logOut}>
              <i className="fa fa-sign-out"></i> Log out
            </a>
          </li>
          <br />
          <a className="launch-aidin-next" onClick={this.launchAidinNext}>Try out the new Beta version of Aidin</a>
        </ul>
      </div>
    )
  }
}
