// actions/index.js

export const UPDATE_ACTIVITIES = 'UPDATE_ACTIVITIES';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const UPDATE_TASKS = 'UPDATE_TASKS';
export const UPDATE_ORGANIZATIONS = 'UPDATE_ORGANIZATIONS';
export const CHANGE_SENT_REFERRAL_TAB = 'CHANGE_SENT_REFERRAL_TAB';

export function updateActivities(activities) {
  return {
    type: UPDATE_ACTIVITIES,
    activities
  }
}

export function updateCurrentUser(current_user) {
  return {
    type: UPDATE_CURRENT_USER,
    current_user
  }
}

export function updateMessages(messages) {
  return {
    type: UPDATE_MESSAGES,
    messages
  }
}

export function updateTasks(tasks) {
  return {
    type: UPDATE_TASKS,
    tasks
  }
}

export function changeSentReferralTab(tab_key) {
  return {
    type: CHANGE_SENT_REFERRAL_TAB,
    tab_key
  }
}

export function updateOrganizations(organizations) {
  return {
    type: UPDATE_ORGANIZATIONS,
    organizations
  }
}
