import React from 'react';
import _ from 'lodash';
import utils from '../Misc/Utils';
import moment from 'moment';
import TaskButton from '../Tasks/TaskButton';

export default class SentReferralRow extends React.Component {
  handleRowClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    utils.set_window_location(`/sent_referrals/${this.props.sent_referral.id}${this.props.sent_referral.rq ? '#full-patient-record' : ''}`);
  }

  handleMessagesButtonClick(event) {
    event.stopPropagation();
    typeof ga != 'undefined' && ga('send', 'event', 'Dashboard', 'Button', 'Referral Messages Button');
    utils.set_window_location(`/sent_referrals/${this.props.sent_referral.id}#tab-messages`);
  }

  handleTaskButtonClick = (event) => {
    typeof ga != 'undefined' && ga('send', 'event', 'Dashboard', 'Referral Response', event.target.textContent.trim());
  }

  completionDate() {
    if (this.props.sent_referral.cancelled_at) {
      return(
        <td className="project-completion">
          { this.referralNo() }
          <br />
          <small>Cancelled on {moment(this.props.sent_referral.cancelled_at).format('MMMM D [at] h:mma')}</small>
        </td>
      );
    } else if (this.props.sent_referral.restricted) {
      return(
        <td className="project-completion">
          { this.referralNo() }
          <br />
          <small>Access to patient information expired {this.props.sent_referral.time_window_closes_at && moment(this.props.sent_referral.time_window_closes_at).format('MMMM D')}</small>
        </td>
      );
    } else {
      return(
        <td className="project-completion">
          { this.referralNo() }
          <br />
          {!this.props.sent_referral.rq && <small>Discharge date {moment(this.props.sent_referral.discharge_date).format('MMMM D YYYY')}</small>}
        </td>
      );
    }
  }

  referralNo = () => {
    return (
      <span style={{fontWeight:600}}>
        Referral # { this.props.sent_referral.referral_id }
      </span>
    )
  }

  unreadMessagesLabel = () => {
    let messages = this.props.messageGroups[this.props.sent_referral.referral_id];

    if (messages) {
      let unreadMessageCount = messages.filter((message) => {
        return !message.read_flag && !message.sent_by_current_organization;
      }).length;

      if (unreadMessageCount > 0) {
        return <span className="label label-danger" style={utils.ie9Browser() ? {display: 'none'} : {right: '4px', top: '20px'}}>{unreadMessageCount}</span>;
      }
    }
  }

  render() {
    let tasks = this.props.taskGroups[this.props.sent_referral.id];
    let task, taskButton, overdue;

    if (tasks) {
      task = _.minBy(tasks, 'overdue_at') || _.minBy(tasks, 'created_at');
      taskButton = <TaskButton id={this.props.sent_referral.id} task={task} onClick={this.handleTaskButtonClick} />;

      if (moment().isSameOrAfter(moment(task.overdue_at))) {
        overdue = <i className="fa fa-exclamation-triangle warning-triangle"></i>;
      }
    }

    return(
      <tr onClick={this.handleRowClick} className={ this.props.mini ? "clickable mini" : "clickable" }>
        { !this.props.mini && <td className="project-status">{taskButton}</td>}
        <td className="project-title">
          <a href={`/sent_referrals/${this.props.sent_referral.id}`}>
            {this.props.sent_referral.patient_name} {this.props.sent_referral.patient_number}
          </a>
          <br />
          <small>Sent {moment(this.props.sent_referral.sent_at).format('MMMM D YYYY, h:mma')}</small>
        </td>
        { !this.props.mini && <td>{overdue}</td> }
        {this.completionDate()}
        <td>
          <img alt="image" className="img-circle" style={{width: '32px', height: '32px'}} src={this.props.sent_referral.avatar_url} />
        </td>
        { !this.props.mini && <td className="project-actions">
          <button onClick={this.handleMessagesButtonClick.bind(this)} className="btn btn-default btn-sm count-info">
            <i className="fa fa-envelope"/>{this.unreadMessagesLabel()} Messages
          </button> 
        </td> }
      </tr>
    )
  }
}

SentReferralRow.propTypes = {
  messageGroups: React.PropTypes.object.isRequired,
  taskGroups: React.PropTypes.object.isRequired,
  sent_referral: React.PropTypes.object.isRequired
}
