import { Provider } from 'react-redux';
import ReferralDetail from './ReferralDetail';
import React from 'react';

import { createStore } from 'redux';
import rootReducer from '../../reducers';

export default class SentReferral extends React.Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    if (typeof window.Appcues != 'undefined') {
      window.Appcues.identify(this.props.current_user.id, {
        email: this.props.current_user.email
      });

      window.Appcues.on("step_completed", (event) => {
        if (event.stepId === "-Ld5MmnUN0Z1ANZAEOYe") {
          const redirect_url = encodeURIComponent(`/referrals/${this.props.referral.id}`)
          window.open(`/sessions/aidin_next_redirect?redirect_url=${redirect_url}`, '_blank');
        }
      });
    }
  }

  render() {
    if(this.props.store){
      var store = createStore(rootReducer, typeof this.props.store !== 'undefined' && this.props.store || {});
      store.dispatch({type: 'CHANGE_SENT_REFERRAL_TAB', tab_key: '#default'})
    } else {
      var store = window.store
    }
    return(
      <Provider store={store}>
        <ReferralDetail {...this.props}/>
      </Provider>
    )
  }
}
