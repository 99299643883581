import _ from 'lodash';
import Activity from './Activity';
import React from 'react';

export default class Activities extends React.Component {
  
  render() {
    return(
      <table className="table table-striped Activities">
        <thead>
        <tr>
          <th>Status</th>
          <th>Title</th>
          <th>Comments</th>
          <th>Start Time</th>
        </tr>
        </thead>
        <tbody>
          {
            _.orderBy(this.props.activities, activity => activity.created_at).
              map((activity) => {
                return(
                  <Activity
                    key={activity.id}
                    activity={activity}
                  />
                )
              })
          }
        </tbody>
      </table>
    )
  }
}

Activities.propTypes = {
  activities: React.PropTypes.object.isRequired
};
