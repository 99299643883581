import React from 'react';
import _ from 'lodash';
import utils from '../../Misc/Utils';
import { connect } from 'react-redux';
import AddOrganization from './AddOrganization';

class OrganizationsButton extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      all_orgs: props.organizations,
      shown_orgs: props.organizations
    }
    this.onChange = this.onChange.bind(this)
  }
  onChange(e){
    const term = e.target.value.toLowerCase()
    this.setState({
      shown_orgs: _.uniqBy(_.filter(this.state.all_orgs, o => _.find(['name','state','city'], prp => o[prp] && typeof o[prp] == 'string' && o[prp].toLowerCase().includes(term))), 'id')
      // Should this preloaded org/provider thing include state/city?
    })
  }
  render() {
    return(
      <li className="dropdown OrganizationsButton" onClick={() => typeof ga != 'undefined' && ga('send', 'event', 'Header', 'Dropdown', 'Locations')}>
        {
          _.some(this.props.organizations, 'alertable') ?
            <button className="btn btn-outline btn-danger dim count-info" type="button" data-toggle="dropdown" href="#" aria-expanded="false">
              <i className="fa fa-globe"></i>
            </button> :
            <a className="dropdown-toggle count-info" data-toggle="dropdown" href="#" aria-expanded="false">
              <i className="fa fa-globe"></i>
            </a>
        }
        <ul className="dropdown-menu">
          { this.state.all_orgs.length > 0 ? [
              <li className="searchbox" key={0}>
                <input className="form-control" type="text" onChange={this.onChange} placeholder='Search by provider name, city or state'/>
              </li>
          ] : '' }
          {_.orderBy(this.state.shown_orgs, ['alertable', 'name'],['desc','asc']).map(organization => {
            return(
              <li key={organization.id} className={organization.alertable ? 'text-danger' : ''}>
                <a href={`/organizations/${organization.id}`}>
                  <div className="h4">
                    <i className="fa fa-globe m-r-lg"></i>
                    {` ${organization.name}`}
                  </div>
                  <div className="location">
                    <span className="loc_city">{organization.city}, </span>
                    <span className="loc_state">{organization.state}</span>
                  </div>
                </a>
              </li>
            );
          })}
          <AddOrganization user={this.props.current_user} />
        </ul>
      </li>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    current_user: state.current_user,
    organizations: state.organizations
  }
}

export default connect(mapStateToProps)(OrganizationsButton)

OrganizationsButton.propTypes = {
  organizations: React.PropTypes.array.isRequired
}
