import update from 'immutability-helper';
import { UPDATE_MESSAGES } from '../actions';

export default function(state = {}, action) {
  switch(action.type) {
    case UPDATE_MESSAGES:
      return update(state, {$merge: action.messages});
    default:
      return state;
  }
}
