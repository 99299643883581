import React from 'react';
import utils from '../Misc/Utils';
import _ from 'lodash';
import Insurance  from './Insurance';
import Facesheet from './Facesheet';
import FullPatientRecord  from './FullPatientRecord';
import PhysicianCertificationStatement from './PhysicianCertificationStatement';
import RestrictedContent from './RestrictedContent';

export default  class PrintView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {key: typeof window != 'undefined' ? window.location.hash : ''};
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount(){
    let signatureField = $('#signature-field');
    let signatureJSON = $('#signature-json').val();
    signatureField.signature({
      disabled: true
    });

    if (this.props.provider.provider_type === 'trns' && !this.props.sent_referral.restricted) {
      if (signatureJSON && signatureJSON.trim()) {
        signatureField.signature('draw', signatureJSON);
      }   
      var canvas = $( "canvas" ).get( 0 );
      var img  = canvas.toDataURL("image/png");
      $("#signature-field").html('<img style="max-width:100%; height:auto;" src="'+img+'" alt="print_sig"/>');
    }
    typeof window != 'undefined' && window.print();
  }

  handleSelect(key) {
    // Fail Silently
  }

  render() {
    return(
      <div className="p-w-sm PrintView SentReferralTabs">
        <div>
          <div className="hr-line-solid"/>
          <div className="m-t-md">
            <h1>Patient Demographics</h1>
            {!this.props.restrictions ? (
              <Facesheet
                patient={this.props.patient}
                provider={this.props.provider}
                provider_delivered_to={this.props.provider_delivered_to}
                referral={this.props.referral}
                sent_referral={this.props.sent_referral}
                hospital={this.props.hospital}
                print_view={true}
              />
            ) : (
              <RestrictedContent
                referral={this.props.referral}
                hospital={this.props.hospital}
                restrictions={this.props.restrictions}
                openTab={this.handleSelect}
              />
            )}
          </div>

          <div className="hr-line-solid"/>
          <div className="m-t-md">
            <h1>Full Patient Record</h1>
            {!this.props.restrictions ? (
              <FullPatientRecord
                patient={this.props.patient}
                referral={this.props.referral}
                sent_referral={this.props.sent_referral}
                showPrintModal={this.props.showPrintModal}
              />
            ) : (
              <RestrictedContent
                referral={this.props.referral}
                hospital={this.props.hospital}
                restrictions={this.props.restrictions}
                openTab={this.handleSelect}
              />
            )}
          </div>

          <div className="hr-line-solid"/>
          <div className="m-t-md">
            <h1>Insurance / Payer / Financial Details</h1>
            {!this.props.restrictions ? (
              <Insurance
                patient={this.props.patient}
                provider={this.props.provider}
                referral={this.props.referral}
              />
            ) : (
              <RestrictedContent
                referral={this.props.referral}
                hospital={this.props.hospital}
                restrictions={this.props.restrictions}
                openTab={this.handleSelect}
              />
            )}
          </div>

          {this.props.provider.provider_type === 'trns' &&
            <div>
            <div className="hr-line-solid"/>
              <div className="m-t-md">
                <h1>Physician Certification Statement</h1>
                {!this.props.restrictions ? (
                  <PhysicianCertificationStatement
                    referral={this.props.referral}
                  />
                ) : (
                  <RestrictedContent
                    referral={this.props.referral}
                    hospital={this.props.hospital}
                    restrictions={this.props.restrictions}
                    openTab={this.handleSelect}
                  />
                )}
              </div>
            </div>
          }
        </div>

      </div>
    )
  }
}


PrintView.propTypes = {
  sent_referral: React.PropTypes.object,
  hospital: React.PropTypes.object,
  referral: React.PropTypes.object,
  provider: React.PropTypes.object,
  tasks: React.PropTypes.array,
  updateMessages: React.PropTypes.func,
  restrictions: React.PropTypes.bool,
  showPrintModal: React.PropTypes.func.isRequired
}
