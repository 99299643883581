import React from 'react';
import _ from 'lodash';
import CheckboxSection from './CheckboxSection';

export default class CheckboxJoinSection extends React.Component {

  constructor(props) {
    super(props);
    this.onMultiCheckChange = this.onMultiCheckChange.bind(this);
  }

  componentWillMount(){
    if (this.props.autoSelect===true){
      this.autoSelect()
    } else if (this.props.autoSelect===false) {
      this.undoAutoSelect()
    }
  }

  autoSelect(){   
    let newOptionsForSelect = [];
    let ctx=this;
    _.filter(ctx.props.optionsForSelect, (primaryTableRow) => {
      let primary_table_ids = _.map(ctx.props.values, this.props.foreign_key1);
      if (!_.includes(primary_table_ids, primaryTableRow.id) ) {
        newOptionsForSelect.push(primaryTableRow)
      }
    });   
    _.each(newOptionsForSelect, (primaryTableRow) => {
      let html_id = "something-"+primaryTableRow.id;
      let e = {target: {checked: true, id: html_id } };    
      ctx.onMultiCheckChange(e)
    })
  }

  undoAutoSelect(){
    let dbOptionsForSelect = _.filter(this.props.values, (joinsTableRow) => {return joinsTableRow.id});
    this.props.onInputChange(this.props.inputFieldPath, dbOptionsForSelect)
  }

  valuesIncludeOption(option) {
    let check = false;
    _.each(this.props.values, (primaryTableRow) => {
      if (option.id === primaryTableRow[this.props.foreign_key1] && primaryTableRow._destroy !== true) {
        check = true;
      }
    });
    return check
  }

  onMultiCheckChange(e) {
    let clonedJoinTableValues = _.extend(this.props.values);
    let target_db_id = +e.target.id.split('-')[1];
    let savedJoinTableRow = _.find(clonedJoinTableValues, (joinTableRow) => {
      return (joinTableRow.id && joinTableRow[this.props.foreign_key1] == target_db_id)
    });
    if (e.target.checked) {
      if (savedJoinTableRow) {
        delete savedJoinTableRow['_destroy']
      } else {
        let newJoinTableRow = {};
        newJoinTableRow[this.props.foreign_key1] = target_db_id;
        newJoinTableRow[this.props.foreign_key2] = this.props.secondaryTable.id;
        clonedJoinTableValues.push(newJoinTableRow);
      }
    } else if (!e.target.checked) {
      if (savedJoinTableRow) {
        savedJoinTableRow._destroy = true;
      } else {
        clonedJoinTableValues = _.filter(clonedJoinTableValues, (joinTableRow) => {
          if (joinTableRow.id || joinTableRow[this.props.foreign_key1] !== target_db_id) {
            return joinTableRow
          }
        })
      }
    }
    this.props.onInputChange(this.props.inputFieldPath, clonedJoinTableValues)
  }

  displayCheckboxes() {
    if (!this.props.optionsForSelect.length >= 1){
      return(<p>No options to display</p>)
    } else {
      return _.map(this.props.optionsForSelect, (primaryTableRow) => {
        return (
          <div key={primaryTableRow.id} className="m-b-sm">
            <CheckboxSection
              id={this.props.id+"-"+primaryTableRow.id}
              value={this.valuesIncludeOption(primaryTableRow)}
              onChange={this.onMultiCheckChange}
              labelText={this.props.specificCheckLabel(primaryTableRow)}
            />
          </div>
        )
      })
    }
  }

  render() {
    return (
      <div className="form-group">
        <p><strong>{this.props.labelText}</strong></p>
        {this.displayCheckboxes()}
      </div>
    )
  }
}

CheckboxJoinSection.propTypes={
  id: React.PropTypes.string,
  labelText: React.PropTypes.string.isRequired,
  specificCheckLabel: React.PropTypes.func.isRequired,
  inputFieldPath: React.PropTypes.string.isRequired,
  optionsForSelect: React.PropTypes.array.isRequired,
  values: React.PropTypes.array,
  secondaryTable: React.PropTypes.object.isRequired,
  foreign_key1: React.PropTypes.string.isRequired,
  foreign_key2: React.PropTypes.string.isRequired,
  onInputChange: React.PropTypes.func.isRequired,
  autoSelect: React.PropTypes.bool
};
