import React from 'react';
import utils from '../Misc/Utils';
import _ from 'lodash';
import moment from 'moment';
import CheckboxSection from '../Forms/CheckboxSection';
import { Modal, Button, FormGroup, FormControl, Tooltip, Overlay } from 'react-bootstrap';

export default class CountiesOfferedTab extends React.Component {

  constructor(props){
    super(props);
    this.onMultiCheckChange = this.onMultiCheckChange.bind(this);
    this.state = {
      provider_served_counties_attributes: [],
      showSuccessModal: false,
      searched_counties: [],
      selected_state: {},
      selected_county: {id: 0},
      isLoading: false,
      showTooltip: false
    };
    this.handleUsStateChange = this.handleUsStateChange.bind(this);
    this.handleCountySelectionChange = this.handleCountySelectionChange.bind(this);
    this.addCounty = this.addCounty.bind(this);
    this.submit = this.submit.bind(this);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
  }

  componentWillMount(){
    let newData= _.extend(this.props.provider.provider_served_counties_attributes);
    this.setState({provider_served_counties_attributes: newData});
  }

  addCounty(){
    let new_served_county = {
      county_id: this.state.selected_county.county_id, 
      county_name: this.state.selected_county.county_name, 
      provider_id: this.props.provider.id, 
      state_id: this.state.selected_state.state_id,
      state_name: this.state.selected_state.state_name
    }
    let existingCounty = _.find(this.state.provider_served_counties_attributes, (served_county) => {
      return served_county.county_id === new_served_county.county_id 
    });

    if(!existingCounty && new_served_county.county_id && new_served_county.county_id !== 0) {
      let newData= _.extend(this.state.provider_served_counties_attributes);
      newData.push(new_served_county);
      this.setState({provider_served_counties_attributes: newData});
      this.setState({showTooltip: true})
    }
    this.setState({selected_county: {id: 0}})
  }

  careTypeSelected(served_county){
    let check = false;
      if(served_county._destroy !== true){
        check = true;
      }
    return check;
  }

  closeSuccessModal(){
    this.setState({showSuccessModal: false});
  }

  displayCounties(served_counties){
    return  _.map(served_counties, (served_county) => {
      return(
        <div key={served_county.county_id} className="col-md-4 m-b">
          <CheckboxSection
            id={'served_county_id-'+served_county.county_id}
            value={this.careTypeSelected(served_county)}
            onChange={this.onMultiCheckChange}
            labelText={served_county.county_name}
          />
        </div>
      )
    })
  }

  displayCountyOptions(){
    return _.map(this.state.searched_counties, (county) => {
      return (
        <option key={county.id} value={county.id}>
          {county.name}
        </option>
      )
    })
  }

  displayUsStateOptions(){
    return _.map(utils.usStatesOptions, (stateArr) => {
      return(
        <option key={stateArr[0]} value={stateArr[0]}>
          {stateArr[1]}
        </option>
      )
    })
  }

  displayStates(){
    let servedCountiesStateGrouped = _.chain(this.state.provider_served_counties_attributes).
      orderBy(served_county => served_county.county_name).
      orderBy(served_county => served_county.state_name).
      groupBy(served_county => served_county.state_name).
      value();

    return  _.map(servedCountiesStateGrouped, (served_counties, state) => {
      return(
        <div 
          key={state} 
          className='m-t-md m-b-md'
        >
          <h3 className="col-md-12">
            <a name={state} className="remove_a_styles">
              {state}
            </a> 
          </h3>
          <div className="col-md-12">
            {this.displayCounties(served_counties)}
          </div>
        </div>
      )
    })
  }

  handleUsStateChange(e){
    this.setState({selected_state: {state_id: +e.target.value, state_name: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text}})
    $.get( `/api/counties/${e.target.value}`, (data) => {
      let countySearch = [{id: 0, name: 'Please Select a County'}].concat(data.counties);
      this.setState({searched_counties: countySearch})
    });
  }

  handleCountySelectionChange(e){
    this.setState({selected_county: {county_id: +e.target.value, county_name: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text}})
  }

  onMultiCheckChange(e) {
    let clonedServicedCounties = _.extend(this.state.provider_served_counties_attributes);
    let target_db_id = +e.target.id.split('-')[1];
    let savedServicedCounty = _.find(clonedServicedCounties, (served_county) => {
      return (served_county.id && served_county.county_id == target_db_id)
    });

    if (e.target.checked) {
      if (savedServicedCounty) {
        delete savedServicedCounty['_destroy']
      } else {
        let newCareTypeOffered = {};
        newCareTypeOffered.county_id = target_db_id;
        newCareTypeOffered.provider_id = this.props.provider.id;
        clonedServicedCounties.push(newCareTypeOffered);
      }
    } else if (!e.target.checked) {
      if (savedServicedCounty) {
        savedServicedCounty._destroy = true;
      } else {
        clonedServicedCounties = _.filter(clonedServicedCounties, (served_county) => {
          if (served_county.id || served_county.county_id !== target_db_id) {
            return served_county
          }
        })
      }
    }
    this.setState({provider_served_counties_attributes: clonedServicedCounties});
  }

  submit() {
    this.setState({isLoading: true});
    $.ajax(`/api/providers/${this.props.provider.id}`, {
      data: {provider: {provider_served_counties_attributes: this.state.provider_served_counties_attributes} },
      error: (response) => {
        alert('There was an error with your selection.');
        this.setState({isLoading: false});
      },
      success: (response) => {
        this.setState({provider_served_counties_attributes: response.provider_served_counties_attributes})
        this.setState({showSuccessModal: true});
        this.setState({selected_county: {id: 0}});
        this.setState({isLoading: false});
      },
      type: 'PATCH'
    });
  }

  render() {
    let isLoading = this.state.isLoading;
    return(
      <div className='CountiesOfferedTab'>
        <div className="p-xl">
          <h2 className="m-b-md">
            {this.props.provider.name} services the following counties:
          </h2>
          <div className="col-md-12">
            {this.displayStates()}
          </div>

          <div className="col-md-12 m-b">
            <Button bsStyle="primary" disabled={isLoading} onClick={!isLoading ? this.submit : null}>
              Save Changes
            </Button>
          </div>

          <div className="col-md-12">
            <h3>
              Want to add a county to your coverage area? Please start by selecting a state.
            </h3>
            <div className="col-md-6">
              <FormControl componentClass="select" onChange={this.handleUsStateChange} className="m-t-xs">
                {this.displayUsStateOptions()}
              </FormControl>
              <FormControl 
                componentClass="select" 
                onChange={this.handleCountySelectionChange} 
                className="m-t-md"
                value={this.state.selected_county.id}
              >
                {this.displayCountyOptions()}
              </FormControl>
            </div>
            {/* The location of the following data needs to change */}
            <div className="col-md-6">
              <div ref='tooltipContainer' style={{marginTop: '60px'}}>
                <Button bsStyle="primary" onClick={this.addCounty} ref='target'>
                  Add County
                </Button>
              </div>
              <Overlay 
                show={this.state.showTooltip} 
                onHide={() => this.setState({ showTooltip: false })}
                rootClose={true}
                placement="right" 
                container={props => ReactDOM.findDOMNode(this.refs.tooltipContainer)}
                target={ props => ReactDOM.findDOMNode(this.refs.target)}
              >
                <Tooltip id="AddCountyTooltip">
                  <strong>County added. Scroll up to view updates. Click Save Changes to finalize all updates.</strong>
                </Tooltip>
              </Overlay>
            </div>
          </div>

          <div className="col-md-12 m-t">
            <Button bsStyle="primary" disabled={isLoading} onClick={!isLoading ? this.submit : null}>
              Save Changes
            </Button>
          </div>

          <Modal show={this.state.showSuccessModal} animation={false}>
            <Modal.Body>
              <h1 className="p-h-md">
                Your counties serviced has been updated.
              </h1>
            </Modal.Body>
            <Modal.Footer>
              <Button 
                bsSize="large"
                bsStyle="primary"
                onClick={this.closeSuccessModal}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
}

CountiesOfferedTab.propTypes = {
  provider: React.PropTypes.shape({
    provider_served_counties_attributes: React.PropTypes.array.isRequired
  }),
  counties: React.PropTypes.array
};
