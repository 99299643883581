import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import update from 'immutability-helper';
import UserInviteModal from './UserInviteModal';
import UserRevokeAccessModal from './UserRevokeAccessModal';
import UserRow from './UserRow';
import { Provider } from 'react-redux';

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: props.users,
      selectedUser: props.users[0],
      selectedUserAnimate: 'fadeInUp'
    };
  }

  removeUser = (user) => {
    let index = _.findIndex(this.state.users, {id: user.id});

    this.setState({users: update(this.state.users, {$splice: [[index, 1]]})}, () => {
      this.selectUser(this.state.users[0]);
    });
  }

  selectUser = (user) => {
    this.setState({selectedUserAnimate: 'fadeOutDown'}, () => {
      setTimeout(() => {
        this.setState({selectedUser: user, selectedUserAnimate: 'fadeInUp'});
      }, 50);
    });
  }

  render() {
    return(
      <div>
        <div className="col-sm-8">
          <div className="wrapper wrapper-content animated fadeInUp">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="m-b-md">
                      <h2>My Team</h2>
                      <h3>Your team has access to your Aidin account. Authorized personnel only.</h3>
                      <Provider store={store}>   
                        <UserInviteModal current_organization={this.props.current_organization} />
                      </Provider>   
                    </div>
                  </div>
                </div>

                <div className="row m-t-sm">
                  <div className="col-sm-12">
                    <div className="p-w-sm">
                      <div className="table-responsive" style={{height: 745, overflow: 'auto'}}>
                        <table className="table table-striped table-hover">
                          <tbody>
                            {this.state.users.map(user => <UserRow key={user.id} user={user} selectUser={this.selectUser} />)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className={`wrapper wrapper-content animated ${this.state.selectedUserAnimate}`}>
            <div className="ibox ">
              {this.state.selectedUser &&
                <div className="ibox-content">
                  <div className="row m-b-lg">
                    <div className="row text-center">
                      <h2>{this.state.selectedUser.name}</h2>

                      <div className="m-b-sm">
                        <img alt="user_avatar" className="img-circle" src={this.state.selectedUser.avatar_url} style={{width: 62}} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-offset-2 col-md-8">
                        <Provider store={store}>
                          <UserRevokeAccessModal
                            user={this.state.selectedUser}
                            current_organization={this.props.current_organization}
                            removeUser={this.removeUser}
                          />
                        </Provider>
                      </div>
                    </div>
                  </div>
                  <div className="client-detail">
                    <strong>User Details</strong>

                    <ul className="list-group clear-list">
                      <li className="list-group-item">
                        <span className="pull-right">{this.state.selectedUser.email}</span>
                        Email address
                      </li>
                      <li className="list-group-item">
                        <span className="pull-right">
                          {moment(this.state.selectedUser.created_at).format('MMMM D YYYY [at] h:mma')}
                        </span>
                        Account created
                      </li>
                      <li className="list-group-item">
                        <span className="pull-right">
                          {this.state.selectedUser.last_seen && moment(this.state.selectedUser.last_seen).format('MMMM D YYYY [at] h:mma')}
                        </span>
                        Last login
                      </li>
                    </ul>
                    <strong>Campus Permissions</strong>
                    <p>{this.state.selectedUser.organizations.map(organization => organization.name).join(', ')}</p>
                    <hr />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Users.propTypes = {
  users: React.PropTypes.array.isRequired
}
