import Insurance from './Insurance';
import React from 'react';
import PatientDemographics from './PatientDemographics';
import SentReferralMeta from '../SentReferral/ReferralMeta';

export default class Facesheet extends React.Component {
  render() {
    return(
      <div className="Facesheet">
        <dl className="dl-horizontal">
          <h2>Referral Information</h2>
          <SentReferralMeta
            patient={this.props.patient}
            referral={this.props.referral}
            sent_referral={this.props.sent_referral}
            provider={this.props.provider}
            provider_delivered_to={this.props.provider_delivered_to}
            hospital={this.props.hospital}
          />
        </dl>
        <Insurance patient={this.props.patient} provider={this.props.provider} referral={this.props.referral} />
        <PatientDemographics patient={this.props.patient} referral={this.props.referral} sent_referral={this.props.sent_referral} />
      </div>
    )
  }
}

Facesheet.propTypes = {
  hospital: React.PropTypes.object.isRequired,
  patient: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired,
  sent_referral: React.PropTypes.object.isRequired
};
