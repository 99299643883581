import React from 'react';
import utils from '../Misc/Utils';
import _ from 'lodash';

export default class PhysicianCertificationStatement extends React.Component {

	componentDidMount(){
		this.signatureGenerator()
	}

	signatureGenerator(){
		let signatureField = $('#signature-field');
		let signatureJSON = $('#signature-json').val();
		signatureField.signature({
		  disabled: true
		});
		if (signatureJSON && signatureJSON.trim()) {
		  signatureField.signature('draw', signatureJSON);
		}		
	}

  render() {
    return(
    	<div className="PhysicianCertificationStatement">
	      <dl className="dl-horizontal">
	        <div className='m-t-sm'>
	          <dt>Physician Certification Statement:</dt> 
	          <dd>{ this.props.referral.physician_certification_statement }</dd>
	        </div>
	        <div className='m-t-sm'>
	          <dt>Transportation Reason:</dt> 
	          <dd>{ utils.trnsReasons[this.props.referral.trns_reason] }</dd>
	        </div>
	        <div className='m-t-sm'>
	          <dt>Authorized Name:</dt> 
	          <dd>{ this.props.referral.authorized_name }</dd>
	        </div>
	        <div className='m-t-sm'>
	          <dt>Title:</dt> 
	          <dd>{ this.props.referral.authorized_name_title }</dd>
	        </div>
	        <div className='m-t-sm'>
	          <dt>Signature:</dt> 
	          <dd>
		          <textarea 
		          	id="signature-json" 
		          	hidden={true} 
		          	readOnly={true}
		          	value={ this.props.referral.transportation_signature ? this.props.referral.transportation_signature : ''} 
	          	/>
	          	<div id="signature-field" className='kbw-signature'></div>
          	</dd>
	        </div>
	      </dl>
      </div>
    )
  }

}

PhysicianCertificationStatement.propTypes = {
  referral: React.PropTypes.object.isRequired
};

