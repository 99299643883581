import React from 'react';
import utils from '../Misc/Utils';
import update from 'immutability-helper';
import { FormGroup, FormControl, HelpBlock, Button, Popover, OverlayTrigger, Modal } from 'react-bootstrap';

export default class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      user: {
        first: props.user.first,
        last: props.user.last
      }
    };
  }

  closeErrorModal = () => {
    this.setState({showErrorModal: false});
  }

  handleChange = (event) => {
    this.setState({
      user: update(this.state.user, {$merge: {[event.target.name]: event.target.value}})
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    $.ajax(`/api/users/${this.props.user.id}/sign_up`, {
      data: {user: this.state.user, invitation_token: this.props.user.invitation_token},
      error: (response) => {
        if (response.status === 429) {
          this.setState({showErrorModal: true});
        } else {
          this.setState({errors: response.responseJSON || {}});
        }
      },
      success: () => utils.location_replace('/'),
      type: 'PATCH'
    });
  }

  render() {
    const popoverRight = (
      <Popover id="popover-password-requirements" title="Password Requirements" style={{maxWidth: 'none', width: 435}}>
        Your password must meet the following requirements:
        <ul>
          <li>has at least 8 characters</li>
          <li>has at least 1 character from 3 of the 4 following categories:</li>
          <ul>
            <li>upper-case letter</li>
            <li>lower-case letter</li>
            <li>number</li>
            <li>symbol (!@#$%^&*)</li>
          </ul>
          <li>not one of your five most recently used passwords</li>
        </ul>
      </Popover>
    );

    return(
      <div className="SignUpForm middle-box text-center loginscreen animated fadeInDown">
        <div>
          <h3>Sign Up</h3>
          <p>To access future referrals and edit your brochure, please set your password:</p>
          <form className="m-t text-left" onChange={this.handleChange} onSubmit={this.handleSubmit}>
            <FormGroup controlId="user_first" validationState={this.state.errors.first && 'error'} className="form-group">
              <FormControl type="text" className="form-control" name="first" placeholder="First name" defaultValue={this.props.user.first} />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.first}</HelpBlock>
            </FormGroup>
            <FormGroup controlId="user_last" validationState={this.state.errors.last && 'error'} className="form-group">
              <FormControl type="text" className="form-control" name="last" placeholder="Last name" defaultValue={this.props.user.last} />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.last}</HelpBlock>
            </FormGroup>
            <FormGroup controlId="user_email" className="form-group">
              <FormControl type="email" className="form-control" name="email" placeholder="Email" defaultValue={this.props.user.previewer ? this.props.user.preview_email : this.props.user.email} disabled />
            </FormGroup>
            <FormGroup controlId="user_password" validationState={this.state.errors.password && 'error'} className="form-group">
              <OverlayTrigger trigger="focus" placement="right" overlay={popoverRight}>
                <FormControl type="password" className="form-control" name="password" placeholder="Password" />
              </OverlayTrigger>
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.password && this.state.errors.password.join(', ')}</HelpBlock>
            </FormGroup>
            <FormGroup controlId="user_password_confirmation" validationState={this.state.errors.password_confirmation && 'error'} className="form-group">
              <OverlayTrigger trigger="focus" placement="right" overlay={popoverRight}>
                <FormControl type="password" className="form-control" name="password_confirmation" placeholder="Confirm password" />
              </OverlayTrigger>
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.password_confirmation && this.state.errors.password_confirmation.join(', ')}</HelpBlock>
            </FormGroup>
            <Button bsStyle="primary" type="submit" className="block full-width m-b">Submit</Button>
          </form>
          <Modal bsSize="lg" show={this.state.showErrorModal} animation={false}>
            <Modal.Body>
              <h1 className="p-h-md">You have tried to sign up unsuccessfully too many times. Please wait 10 minutes and try again.</h1>
            </Modal.Body>
            <Modal.Footer>
              <Button bsSize="lg" onClick={this.closeErrorModal}>OK</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
}

SignUpForm.propTypes = {
  user: React.PropTypes.object.isRequired
}
