import React from 'react';
import update from 'immutability-helper';
import { Modal, Button, FormGroup, ControlLabel } from 'react-bootstrap';
import CheckboxSection from '../Forms/CheckboxSection';
import _ from 'lodash';
import deepcopy from 'deepcopy';

export default class UserNotificationPreferences extends React.Component {
  constructor(props) {
    super(props);
    // true false values on this one are whether or not the user has them on by default
    var default_notif_prefs = {
      new_referral: {fax: true, email: true, sms: true},
      you_chosen: {fax: false, email: true, sms: true},
      other_chosen: {fax: false, email: true, sms: true},
      referral_cancelled: {fax: false, email: true, sms: true},
      new_message: {fax: false, email: true, sms: true},
      referral_updated: {fax: false, email: true, sms: true},
      time_window_closing: {fax: true, email: true, sms: true},
      provider_reviewed: {fax: false, email: true, sms: true},
      patient_question: {fax: false, email: true, sms: true},
      patient_status_update_due: {email: true},
      receives_respond_via_fax_fax: {fax: false}
    }
    this.state = {
      user: {
        prefs: {
          notify: props.user.prefs.notify || default_notif_prefs
        }
      },
      showSuccessModal: false,

      // true false values on this one are whether or not they can be edited
      notification_types: {
        new_referral: {fax: true, email: true, sms: true, name: "You have a new referral"},
        you_chosen: {fax: false, email: true, sms: true, name: "You have been chosen by the patient"},
        other_chosen: {fax: false, email: true, sms: true, name: "You were NOT chosen by the patient"},
        referral_cancelled: {fax: false, email: true, sms: true, name: "Referral has been cancelled by the sender"},
        new_message: {fax: false, email: true, sms: true, name: "You have a new message about a referral"},
        referral_updated: {fax: false, email: true, sms: true, name: "Referral details have been updated by the sender"},
        time_window_closing: {fax: true, email: true, sms: true, name: "Your time to respond is ending soon"},
        provider_reviewed: {fax: false, email: true, sms: true, name: "You have a new patient review"},
        patient_question: {fax: false, email: true, sms: true, name: "You have a question from a patient"},
        patient_status_update_due: {email: true, name: 'You have a patient update due'},
        receives_respond_via_fax_fax: {fax: true, name: "Allow me to respond and receive full clinical documents via fax"}
      }
    }
  }

  closeSuccessModal = () => {
    this.setState({showSuccessModal: false});
  }

  handleChange = (event) => {
    this.setState({
      user: update(this.state.user, {$merge: {[event.target.name]: event.target.checked}})
    });
  }

  handlePrefsChange = (k, med, event) => {
    var new_user = deepcopy(this.state.user)
    if (!new_user.prefs.notify[k]) {
      new_user.prefs.notify[k] = {};
    }
    new_user.prefs.notify[k][med] = !new_user.prefs.notify[k][med]
    this.setState({user: new_user})
  }

  submit = () => {
    $.ajax(`/api/users/${this.props.user.id}/notif_prefs`, {
      data: {notify: JSON.stringify(this.state.user.prefs.notify)},
      success: (response) => {
        this.setState({showSuccessModal: true});
      },
      type: 'PATCH'
    });
  }

   

  render() {
    let notifyByFaxLabel = !this.props.user.fax ? 'Add your fax number to enable fax notifications'  : '';
    let notifyByTextLabel = !this.props.user.sms ? 'Add your text number to enable text notifications' : '';

    return(
      <div>
        <form className="form-horizontal m-t-md">
          <table className="notif_select_table">
            <thead>
              <tr>
                <th>Notification Type</th>
                <th>Fax</th>
                <th>Email</th>
                <th>Text/SMS</th>
              </tr>
            </thead>
            <tbody>
              { _.map(this.state.notification_types, (nt, k, i) => {
                  return <tr key={k}>
                    <td>{ nt.name }</td>
                    { _.map(['fax','email','sms'], med =>  {
                      if (nt[med]) {
                        return <td className="nt_med_sel" key={k + med}>
                          <label className={ !nt[med] && 'disabled' }>
                            <input type='checkbox'
                              disabled={!nt[med]}
                              checked={this.state.user.prefs.notify[k] && this.state.user.prefs.notify[k][med]}
                              onChange={e => this.handlePrefsChange(k, med, e)}
                            />
                          </label>
                        </td>
                      } else {
                        return <td className="nt_med_sel" key={k + med}>N/A</td>
                      }
                    })}
                  </tr>
                })
              }
            </tbody>
          </table>
          <FormGroup className="form-group m-t-xl">
            <div className="col-sm-offset-3 col-sm-3">
              <Button bsSize="lg" bsStyle="primary" onClick={this.submit}>Save</Button>
            </div>
          </FormGroup>
        </form>
        <Modal show={this.state.showSuccessModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">Your notification preferences have been successfully updated.</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.closeSuccessModal}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

UserNotificationPreferences.propTypes = {
  user: React.PropTypes.object.isRequired
}
