import React from 'react';
import AdminDefault from './Default'
import Fax from './Fax'

export default class AdminContent extends React.Component {
  render() {
    switch(this.props.current_page){
      case "faxes_index":
        return <Fax />;
        break;
      case "admin_index":
        return <AdminDefault />;
        break
    }
  }
}
