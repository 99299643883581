import React from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import { FormControl, FormGroup, ControlLabel, Button } from 'react-bootstrap';
import BrowserSupportWrapper from '../../BrowserSupportWrapper';
import Video from '../Video';

export default class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      complete: false,
      loading: false,
      file: null,
      message_content: {
        message_attributes: {
          organization_id: props.hospital.id,
          organization_type: props.hospital.class_name
        },
        organization_id: props.data.provider.id,
        organization_type: props.data.provider.class_name,
        patient_id: props.data.patient.id,
        referral_id: props.data.referral.id,
        text: ''
      }
    };
  }

  attachFile = () => {
    let formData = new FormData();
    formData.append('attachment_file', this.state.file);
    formData.append('attachment_name', this.state.file.name);
    formData.append('attachable_type', 'Referral');
    formData.append('attachable_id', this.props.data.referral.id);

    this.setState({loading: true}, () => {
      $.ajax('/api/attachments', {
        complete: () => {
          this.setState({loading: false});
        },
        contentType: false,
        data: formData,
        processData: false,
        success: (response) => {
          this.setState({
            file: null,
            attachments: update(this.state.attachments, {$push: [response.attachment]})
          });
          this.refs.form.reset();
        },
        type: 'POST'
      });
    });
  }

  handleFileChange = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => this.setState({file: file});

    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.setState({file: null});
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    $.ajax({
      data: {message_content: this.state.message_content},
      success: () => this.setState({complete: true}),
      type: 'POST',
      url: `/api/messages`
    });
  }

  handleTextChange = (event) => {
    this.setState({
      message_content: update(this.state.message_content, {$merge: {text: event.target.value}})
    });
  }

  removeAttachment = (event) => {
    event.preventDefault();
    let id = event.target.value;
    let index = _.findIndex(this.state.attachments, {id: +id});

    this.setState({loading: true}, () => {
      $.ajax(`/api/attachments/${id}`, {
        complete: () => {
          this.setState({loading: false});
        },
        success: (response) => {
          this.setState({
            attachments: update(this.state.attachments, {$splice: [[index, 1]]})
          });
        },
        type: 'DELETE'
      });
    });
  }

  render() {
    return(
      <div className="row">
        <div className="col-lg-5">
          <div className="text-center">
            <div className="m-t-md">
              <Video src={`https://www.youtube.com/embed/7Pf35svYBk0?rel=0&showinfo=0${this.props.current_user.previewer ? '&autoplay=1' : ''}`} />
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <form className="form-horizontal m-t-md" onSubmit={this.handleSubmit}>
            <FormGroup controlId="message_content_text" className="form-group">
              <div className={`col-sm-12${this.state.complete ? ' text-muted' : ''}`}>
                <ControlLabel className="control-label">Message for Referral #{this.props.data.referral.id}</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  onChange={this.handleTextChange}
                  value={this.state.message_content.text}
                  disabled={this.state.complete}
                  style={{height: 110}}
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group">
              <div className="col-sm-offset-9 col-sm-3">
                <Button bsSize="lg" type="submit" disabled={this.state.complete}>Send</Button>
              </div>
            </FormGroup>
          </form>
          <BrowserSupportWrapper>
            <div className={`ibox-content${this.state.loading ? ' sk-loading' : ''}`} style={{border: 'none', background: 'none'}}>
              <div className="sk-spinner sk-spinner-double-bounce">
                <div className="sk-double-bounce1"></div>
                <div className="sk-double-bounce2"></div>
              </div>
              <form className="form-horizontal" ref="form">
                <ControlLabel className="col-sm-12 control-label" style={{textAlign: 'left'}}>Attach patient clinical notes:</ControlLabel>
                <FormGroup controlId="patient_status_update_attachment" className="form-group">
                  <div className="col-sm-12 m-t-md">
                    <ul>
                      {this.state.attachments.map((attachment, index) => <li key={attachment.id}>{attachment.file_file_name}<button value={attachment.id} onClick={this.removeAttachment} className="close text-danger close-delete">×</button></li>)}
                    </ul>
                    <FormControl type="file" className="form-control" name="attachment" onChange={this.handleFileChange} style={{border: 'none'}} />
                    <Button bsStyle="primary" onClick={this.attachFile}>Attach File</Button>
                  </div>
                </FormGroup>
              </form>
            </div>
          </BrowserSupportWrapper>
        </div>
      </div>
    );
  }
}

Upload.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  data: React.PropTypes.object.isRequired,
  hospital: React.PropTypes.object.isRequired,
  onInputChange: React.PropTypes.func.isRequired
};
