import React from 'react';
import moment from 'moment';
import { FormControl, FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import DatePicker from 'react-bootstrap-date-picker';
import Video from '../Video';

export default class ReceivePatient extends React.Component {
  providerActualReceivedDateValue() {
    if (this.props.referral.provider_actual_received_date) {
      return new Date(this.props.referral.provider_actual_received_date).toISOString();
    }
  }

  providerAnticipatedDischargeDateValue() {
    if (this.props.referral.provider_anticipated_discharge_date) {
      return new Date(this.props.referral.provider_anticipated_discharge_date).toISOString();
    }
  }

  render() {
    let updatePatientStatusDates = this.props.tasks.filter((task) => {
      return task.task_type === 'update_patient_status';
    }).map((task) => {
      return moment(task.visible_at).format('M/D/Y');
    }).join(', ');

    return(
      <fieldset className="body current">
        <div className="row">
          <div className="col-lg-5">
            <div className="text-center">
              <div className="m-t-md">
                <Video src={`https://www.youtube.com/embed/poWxE3YCF4M?rel=0&showinfo=0${this.props.current_user.previewer ? '&autoplay=1' : ''}`} />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <h2>Has {this.props.patient.full_name} begun services with {this.props.provider.name}?</h2>
            <form className="form-horizontal m-t-md">
              <FormGroup controlId="referral_patient_received" className="form-group">
                <div className="col-sm-12">
                  <FormControl
                    componentClass="select"
                    placeholder=""
                    name="patient_received"
                    value={this.props.referral.patient_received}
                    onChange={this.props.handlePatientReceivedChange}
                  >
                    <option value="">Pending</option>
                    <option value="true">Patient received services</option>
                    <option value="false">Patient not receiving services from {this.props.provider.name}</option>
                  </FormControl>
                </div>
              </FormGroup>
              {
                this.props.referral.patient_received === 'true' &&
                <div>
                  <FormGroup controlId="referral_provider_actual_received_date" className="form-group">
                    <ControlLabel className="col-sm-7 control-label">Date received / Services Began:</ControlLabel>
                    <div className="col-sm-5">
                      <DatePicker
                        dateFormat="YYYY-MM-DD"
                        name="provider_actual_received_date"
                        value={this.providerActualReceivedDateValue()}
                        onChange={this.props.handleProviderActualReceivedDateChange}
                        onFocus={this.props.handleProviderActualReceivedDateFocus}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup controlId="referral_provider_anticipated_discharge_date" validationState={this.props.errors.provider_anticipated_discharge_date && 'error'} className="form-group">
                    <ControlLabel className="col-sm-7 control-label">Anticipated Discharge / Services End:</ControlLabel>
                    <div className="col-sm-5">
                      <DatePicker
                        dateFormat="YYYY-MM-DD"
                        name="provider_anticipated_discharge_date"
                        value={this.providerAnticipatedDischargeDateValue()}
                        onChange={this.props.handleProviderAnticipatedDischargeDateChange}
                        onFocus={this.props.handleProviderAnticipatedDischargeDateFocus}
                      />
                      <HelpBlock>{this.props.errors.provider_anticipated_discharge_date}</HelpBlock>
                    </div>
                  </FormGroup>
                  {
                    updatePatientStatusDates &&
                    <h3 className='text-danger'>
                      The referring organization requests a status update on this patient on {updatePatientStatusDates}
                    </h3>
                  }
                </div>
              }
            </form>
          </div>
        </div>
      </fieldset>
    );
  }
}

ReceivePatient.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  errors: React.PropTypes.object.isRequired,
  handleProviderActualReceivedDateChange: React.PropTypes.func.isRequired,
  handleProviderActualReceivedDateFocus: React.PropTypes.func.isRequired,
  handleProviderAnticipatedDischargeDateChange: React.PropTypes.func.isRequired,
  handleProviderAnticipatedDischargeDateFocus: React.PropTypes.func.isRequired,
  handlePatientReceivedChange: React.PropTypes.func.isRequired,
  patient: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired,
  tasks: React.PropTypes.array.isRequired
}
