import React from 'react';
import { Provider } from 'react-redux';

export default class SentReferralFake extends React.Component {
  render() {
    return(
      <div>
        foobar
      </div>
    )
  }
}
