import React from 'react';
import inflection from 'inflection';

export default class WizardStep extends React.Component {

  render() {
    return (
      <div className="jumbotron clearfix" style={{marginBottom:'1em'}}>
        <fieldset>
          <h2 className="title current">
            {this.props.header}
          </h2>
          {this.props.children}
        </fieldset>
      </div>
    )
  }
}

WizardStep.propTypes = {
  header: React.PropTypes.string.isRequired
};
