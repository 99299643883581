import React from 'react';
import utils from '../Misc/Utils';
import { FormGroup, Alert, FormControl, Button, Modal, Navbar } from 'react-bootstrap'
import DropZone from 'react-dropzone'
import update from 'immutability-helper'
import request from 'superagent'

export default class MobileAttach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showErrorModal: false, session: {}, name: ''};
    if(props.user){
      this.state.stage = 'select_referral'
      this.loadReferrals()
    } else {
      this.state.stage = 'login'
    }
    this.closeErrorModal = this.closeErrorModal.bind(this)
  }

  closeErrorModal = () => {
    this.setState({showErrorModal: false});
  }

  patientNameChanged = (event) => {
    this.loadReferrals(event.target.value)
  }

  loadReferrals = (name) => {
    this.setState({last_name_entry: name})
    $.get("/api/referrals", { name: name, verbose: true })
    .then(d => {
      if(name == this.state.last_name_entry){
        this.setState(d)
      }
    })
  }

  loginSubmit = (event) => {
    event.preventDefault()

   $.post('/api/sessions', {session: this.state.session, for_attachments: true}, (response) => {
      this.setState({stage: 'select_referral'})
      this.loadReferrals('')
    }).fail((response) => {
      if(response.status == 429){
        this.setState({showErrorModal: true, error_message:"Sorry, you've tried to log in too many times.  Please try again in a few minutes.", statusCode: response.status});
      } else {
        this.setState({showErrorModal: true, error_message:"Sorry, those credentials were not valid.", statusCode: response.status});
      }
    });

  }

  handleAttachChange = (event) => {
    this.setState({ attachment_name: event.target.value })
  }

  handleChange = (event) => {
    this.setState({ session: update(this.state.session, {$merge: {[event.target.name]: event.target.value}}) }); 
  }

  onDrop = (files) => {
    this.setState({file: files[0]})
  }

  attachSubmit = (event) => {
    this.setState({submitting:true})
    event.preventDefault()

    const req = request.post('/api/attachments')
    req.set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    req.attach('attachment_file', this.state.file)
    req.field('attachment_name', this.state.file.name)
    req.field('attachable_id', this.state.referral.id)
    req.field('attachable_type', 'Referral')

    req.end( (err, res) => {
      this.setState({submitting:false})
      if(res.success){
        alert("Error uploading file.")
      } else {
        this.setState({stage: 'success'})
      }
    })
  }

  referralButton = (ref, index) => {
    return(
      <div key={ index } className="btn btn-default referral_btn tal" onClick={ () => {this.selectReferral(ref.referral) } }>
        <strong>{ ref.patient_name }</strong>
        <br />{ ref.referral.provider_type.toString().toUpperCase() } referral
        <br />Referral #{ ref.referral.id }
        <br /><em>{ ref.hospital.name}</em>
      </div>
    )
  }

  logOut = () => {
    this.setState({logging_out: true})
    $.ajax({url: "/api/sessions", type: 'DELETE'}).then( () => {
      // this.setState({user: null, stage: 'login', logging_out: false})
      utils.window_reload()
    })
  }

  doReload = () => {
    utils.window_reload()
  }

  selectReferral = referral => {
    this.setState({referral: referral, stage: 'attach_file'})
  }

  render() {
    return(
      <div className="wrapper wrapper-content">
        <div className="ibox">

          <div className="mobile_attach_wrap">
            {{
                'login':
                   <div className="ma_login_wrap row">
                     <div className="col-md-12">
                       <form onChange={this.handleChange} onSubmit={this.loginSubmit}>
                         <h2>Please log in:</h2>
                         <FormGroup>
                           <FormControl type="text" name="email" placeholder="Email" />
                         </FormGroup>
                         <FormGroup>
                           <FormControl type="password" name="password" placeholder="Password" />
                         </FormGroup>
                         <Button type="submit" bsSize="lg" bsStyle="primary">Log in</Button>
                       </form>
                     </div>
                   </div>,
                'select_referral':
                  <div className="ma_select_referral row">
                    { (this.state.user_referrals && this.state.hospital_referrals) ? 
                      <div className="referrals_wrap">
                        <div className="col-md-12">
                          <h2>Select a referral</h2>
                        </div>
                        <div className="col-md-12">
                          <FormGroup>
                            <FormControl type="text" onChange={this.patientNameChanged} name="patient_name" placeholder="Filter by referral number or patient name..." />
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <h2>Your referrals</h2>
                          { (this.state.user_referrals.length > 0) ? this.state.user_referrals.map(this.referralButton) : <h3>No referrals linked to you found.</h3> }
                        </div>
                        <div className="col-md-6">
                          <h2>Hospital referrals</h2>
                          { (this.state.hospital_referrals.length > 0) ? this.state.hospital_referrals.map(this.referralButton) : <h3>No referrals linked to your hospital found.</h3> }
                        </div>
                      </div>
                    : 
                      <div className="col-md-12">
                        <span>Loading...</span>
                      </div>
                    }
                  </div>,
                'attach_file':
                  <div className="ma_attach_file">
                     <form onSubmit={this.attachSubmit}>
                       <h2>Select file</h2>
                       <div className="row">
                         { false && <div className="col-md-6">
                           <FormGroup>
                             <FormControl type="text" onChange={this.handleAttachChange} name="attachment_name" placeholder={this.state.file ? "Attachment name (optional, defaults to " + this.state.file.name + ")" : "Attachment name (optional)"} />
                           </FormGroup>
                         </div> }
                         <div className="col-md-6">
                           <FormGroup>
                             <DropZone onDrop={this.onDrop} accept="image/*" multiple={false} style={{}} inputProps={{capture: 'camera'} }>
                               <button className="btn btn-warning" type="button" style={{textAlign:'center', width:'100%'}}>
                                 <i className="fa fa-camera m-xs" style={{fontSize:'5em'}} />
                                 <br />
                                 Select file...
                               </button>
                               {this.state.file 
                                 ? <Alert bsStyle="success">
                                   <i className="fa fa-picture-o" style={{fontSize:'2em', verticalAlign:'middle', marginRight:'0.25em'}} /> <strong>Image selected</strong>
                                   </Alert> 
                                 : '' }
                             </DropZone>
                           </FormGroup>
                         </div>
                       </div>
                       { this.state.submitting ? 
                         <div className="sk-spinner sk-spinner-double-bounce">
                           <div className="sk-double-bounce1"></div>
                           <div className="sk-double-bounce2"></div>
                         </div>
                       : 
                         <Button type="submit" bsSize="lg" bsStyle="primary">Attach</Button>
                       }
                     </form>
                  </div>,
                'success':
                   <div className="ma_success">
                     <h2>Success!  Attachment added.</h2>
                     <Button type="submit" bsSize="lg" onClick={this.doReload} bsStyle="primary">Attach another file</Button>
                   </div>
            }[this.state.stage]}
            { this.state.stage != 'login' ? 
                 <div className="row m-t-lg">
                   <div className="col-md-12" style={{textAlign: 'right'}}>
                     <h3>
                       <a onClick={this.logOut}>Log out</a>
                     </h3>
                   </div>
                 </div>
                 : ''
            }
            <Modal show={this.state.showErrorModal} animation={false}>
              <Modal.Body>
                <h1 className="p-h-md">{this.state.error_message}</h1>
              </Modal.Body>
              <Modal.Footer>
                <Button bsSize="lg" autoFocus={true} onClick={this.closeErrorModal}>OK</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>

    )
  }
}

MobileAttach.propTypes = {
}
