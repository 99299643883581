import { connect } from 'react-redux';
import request from 'superagent';
import utils from '../Misc/Utils';
import { SpinnerWave } from '../Misc/Spinners.jsx';
import ReactTable from 'react-table';
 
class UsersReport extends React.Component {
  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){
    this.retrieveData()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.filters.last_updated != this.props.filters.last_updated){
      this.retrieveData(nextProps.filters)
    }
    if(nextProps.filters.xlsx_request != this.props.filters.xlsx_request){
      this.retrieveData(nextProps.filters, true)
    }
  }

  retrieveData = (filters, xlsx) => {
    if(xlsx){
      var url = "/api/reports?export=xlsx&" + Object.entries(this.props.filters).map(([key, val]) => `${key}=${val}`).join('&')
      utils.window_open(url,'')
    } else {
      var query_id = Math.random()
      this.setState({last_query: query_id, loading: true})
      var req = request.get("/api/reports")  
      .query(filters || this.props.filters)
      .end( (err,res) => {
        if(!err){
          if(this.state.last_query == query_id){
            this.setState(res.body)
          }
        }
        this.setState({loading:false})
      })
    }
  }

  openUserPage = id => {
    utils.set_window_location(`/reports/user/${id}`)
  }

  fmtPct = (n) => {
    return n ? parseFloat(n).toFixed(2) + '%' : ''
  }

  fmtPctile = (n) => {
    return n ? parseFloat(n).toFixed(2) + ' %ile' : ''
  }

  forceNumericSort = (a,b) => {
    var pa = parseFloat(a)
    var pb = parseFloat(b)
    a = (a === null || a === undefined || isNaN(pa)) ? -Infinity : parseFloat(a)
    b = (b === null || b === undefined || isNaN(pb)) ? -Infinity : parseFloat(b)
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  }

  columnKey = [
    {id: "id", accessor: "id", Header: "User ID"},
    {id: "user_full_name_or_email", accessor: "user_full_name_or_email", Header: "Name/Email"},
    {id: "all_submitted_referrals_count", accessor: "all_submitted_referrals_count", Header: "# Submitted Referrals"},
    {id: 'sent_referral_competition_percent', sortMethod: this.forceNumericSort, accessor: v => this.fmtPct(v.sent_referral_competition_percent), Header: "Open Competition (% Recommended Providers Chosen)"},
    {id: "all_shared_referrals_count", accessor: "all_shared_referrals_count", Header: "# Shared Referrals"},
    {id: "avg_quality_percentile", sortMethod: this.forceNumericSort, accessor: v => this.fmtPctile(v.avg_quality_percentile), Header: "Quality First (Avg. Percentile)"},
    {id: "all_tasks_count", accessor: "all_tasks_count", Header: "# Completed Tasks"},
    {id: "completed_on_time_percent", sortMethod: this.forceNumericSort, accessor: v => this.fmtPct(v.completed_on_time_percent), Header: "Accountability (% Tasks Completed on Time)"}
  ]


  render() {
    return (
      <div className="reportsContent ibox">
        { (!this.state.user_data || this.state.loading) && <SpinnerWave /> } 
        { (this.state.user_data && !this.state.loading) && 
          <div>
            <ReactTable
                defaultSortDesc={true}
                defaultPageSize={10}
                className="report_meta -striped"
                columns={this.columnKey} 
                data={this.state.user_data}
                sortable={true}
                getTdProps = {(state,rowInfo,column,instance) => {
                  return {
                    className: "tdcol-" + column.id
                  }
                } }
                getTrProps = {(state,rowInfo,column,instance) => {
                  return {
                    className: "clickable",
                    onClick: (e, handleOrig) => {
                      this.openUserPage(rowInfo.row.id)
                    }
                  }      
                } }
            >
            </ReactTable>
          </div>
        }
      </div>
    )
  }

}


const mapStateToProps = state => {
  return {
    current_user: state.current_user,
    current_organization: state.current_organization
  }
}

export default connect(mapStateToProps)(UsersReport)
