import { Modal, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import React from 'react';

export default class PasswordResetEmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {show: false, showResponseModal: false};
  }

  close = () => {
    this.setState({show: false, showResponseModal: false});
  }

  handleChange = (event) => {
    this.setState({user: {email: event.target.value}});
  }

  open = (event) => {
    event.preventDefault();
    this.setState({show: true});
  }

  submit = (event) => {
    event.preventDefault();
    $.ajax(`/api/password_resets`, {
      complete: (response) => { this.setState({show: false, showResponseModal: true, statusCode: response.status}) },
      data: {user: this.state.user},
      error: () => { this.setState({success: false}) },
      success: () => { this.setState({success: true}) },
      type: 'POST'
    });
  }

  renderResponseModalContent = () => {
    if (this.state.success) {
      return(
        <div>
          <p>An email has been sent to {this.state.user.email} with instructions to reset your password.</p>
          <p className="m-t-md">Your password reset link will be valid for 20 minutes.</p>
        </div>
      );
    } else if (this.state.statusCode == 421) {
      return (<span>Hospital users please continue to <a href="https://app.myaidin.com">https://app.myaidin.com</a></span>);
    } else {
      return(
        <div>
          <p>There was something wrong with your request.</p>
          <p className="m-t-md">Check your spelling and try again.</p>
        </div>
      );
    }
  }

  render() {
    return(
      <div>
        <a href="#" onClick={this.open} className="forgotpw"><small>Forgot password?</small></a>
        <Modal bsSize="lg" show={this.state.show} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">Submit the email you use to log in to Aidin:</h1>
            <form className="form-horizontal m-t-md" onSubmit={this.submit}>
              <FormGroup controlId="user_email" className="form-group">
                <ControlLabel className="col-sm-3 control-label">Email:</ControlLabel>
                <div className="col-sm-6">
                  <FormControl type="email" className="form-control" name="email" autoFocus="true" onChange={this.handleChange} />
                </div>
              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.close}>Cancel</Button>
            <Button bsSize="lg" bsStyle="primary" onClick={this.submit}>Submit</Button>
          </Modal.Footer>
        </Modal>
        <Modal bsSize="lg" show={this.state.showResponseModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">{this.renderResponseModalContent()}</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.close}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
