import React from 'react';
import AdminInner from './AdminInner';
import { Provider } from 'react-redux';
 
export default class Admin extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <AdminInner {...this.props} />
      </Provider>
    )
  }
}
