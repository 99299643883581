import React from 'react';
import utils from '../Misc/Utils';
import update from 'immutability-helper';
import { FormGroup, FormControl, HelpBlock, Button, Modal } from 'react-bootstrap';

export default class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {errors: {}, user: {}, showResponseModal: false};
  }

  closeResponseModal = () => {
    this.setState({showResponseModal: false}, () => {
      if (this.state.status.toString().match(/2\d\d/)) {
        utils.location_replace('/');
      }
    });
  }

  handleChange = (event) => {
    this.setState({
      user: update(this.state.user, {$merge: {[event.target.name]: event.target.value}})
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    $.ajax(`/api/users/${this.props.user.id}/reset_password`, {
      data: {user: this.state.user, password_reset_token: this.props.user.password_reset_token},
      error: (response) => {
        if (response.status === 422) {
          this.setState({errors: response.responseJSON});
        } else {
          this.setState({status: response.status, showResponseModal: true});
        }
      },
      success: (data, textStatus, jqXHR) => {
        this.setState({errors: {}, status: jqXHR.status, showResponseModal: true}, () => {
          this.refs.form.reset();
        });
      },
      type: 'PATCH'
    });
  }

  renderResponseModalText = () => {
    switch (this.state.status) {
      case 403:
        return 'Your password reset link is no longer valid. Please try again.';
      case 429:
        return 'You have tried to reset your password unsuccessfully too many times. Please wait 10 minutes and try again.';
      default:
        return 'Your password has been successfully reset.';

    }
  }

  render() {
    return(
      <div className="PasswordResetForm animated fadeInDown">
        <div className="middle-box text-center loginscreen">
          <div>
            <h3>Password Reset</h3>
            <p>Please set a new password:</p>
            <form className="m-t text-left" onChange={this.handleChange} onSubmit={this.handleSubmit} ref="form">
              <FormGroup controlId="user_password" validationState={this.state.errors.password && 'error'} className="form-group">
                <FormControl type="password" className="form-control" name="password" placeholder="Password" />
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.password && this.state.errors.password.join(', ')}</HelpBlock>
              </FormGroup>
              <FormGroup controlId="user_password_confirmation" validationState={this.state.errors.password_confirmation && 'error'} className="form-group">
                <FormControl type="password" className="form-control" name="password_confirmation" placeholder="Confirm password" />
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.password_confirmation && this.state.errors.password_confirmation.join(', ')}</HelpBlock>
              </FormGroup>
              <Button bsStyle="primary" type="submit" className="block full-width m-b">Submit</Button>
            </form>
            <Modal bsSize="lg" show={this.state.showResponseModal} animation={false}>
              <Modal.Body>
                <h1 className="p-h-md">{this.renderResponseModalText()}</h1>
              </Modal.Body>
              <Modal.Footer>
                <Button bsSize="lg" onClick={this.closeResponseModal}>OK</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div className="row m-t-xl">
          <div className="middle-box">
            <div className="panel panel-warning">
              <div className="panel-heading">
                <i className="fa fa-warning"></i> Password Requirements
              </div>
              <div className="panel-body">
                <p>Your password must meet the following requirements:</p>
                <ul>
                  <li>has at least 8 characters</li>
                  <li>has at least 1 character from 3 of the 4 following categories:</li>
                  <ul>
                    <li>upper-case letter</li>
                    <li>lower-case letter</li>
                    <li>number</li>
                    <li>symbol (!@#$%^&*)</li>
                  </ul>
                  <li>not one of your five most recently used passwords</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PasswordResetForm.propTypes = {
  user: React.PropTypes.object.isRequired
}
