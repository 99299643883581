import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import { connect, Provider } from 'react-redux';
import PreviewLogInModal from '../PreviewLogInModal';
import PreviewSignUpModal from '../PreviewSignUpModal';
import ReceivePatientTask from './ReceivePatientTask';
import RespondTask from './RespondTask';
import UpdatePatientStatusTask from './UpdatePatientStatusTask';

class Tasks extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showPreviewSignUpModal: false,
      showPreviewLogInModal: false,
      showFiltersModal: false,
      filtersMessage: ''
    };
    this.showSignUpModal = this.showSignUpModal.bind(this);
    this.showFiltersModal = this.showFiltersModal.bind(this);
    this.closeFiltersModal = this.closeFiltersModal.bind(this);
  }

  closeFiltersModal(){
    this.setState({showFiltersModal: false})
  }

  showFiltersModal(message){
    this.setState({showFiltersModal: true, filtersMessage: message})
  }

  showSignUpModal(modal, show) {
    this.setState({[`show${_.upperFirst(modal)}Modal`]: show});
  }

  filteredTasks() {
    return _.filter(this.props.tasks, task => task.sent_referral_id === this.props.sent_referral.id);
  }

  getTaskComponent(task){
    switch(task.task_type) {
      case 'respond':
        return(
          <RespondTask
            task={task}
            hospital={this.props.hospital}
            referral={this.props.referral}
            patient={this.props.patient}
            showSignUpModal={this.showSignUpModal}
            showFiltersModal={this.showFiltersModal}
            current_user={this.props.current_user}
          />
        );
      case 'receive_patient':
        return(
          <ReceivePatientTask
            current_user={this.props.current_user}
            hospital={this.props.hospital}
            patient={this.props.patient}
            provider={this.props.provider}
            referral={this.props.referral}
            showSignUpModal={this.showSignUpModal}
            task={task}
            tasks={this.filteredTasks()}
          />
        );
      case 'update_patient_status':
        return(
          <UpdatePatientStatusTask
            current_user={this.props.current_user}
            hospital={this.props.hospital}
            patient={this.props.patient}
            provider={this.props.provider}
            referral={this.props.referral}
            sent_referral_id={this.props.sent_referral.id}
            showSignUpModal={this.showSignUpModal}
            task={task}
          />
        );
    }
  }

  render() {
    return (
      <div className="taskBox wrapper wrapper-content project-manager" style={{marginLeft:0,marginRight:0}}>
        <h2 className="m-t-xs">Referral Tasks</h2>
        <ul className="tag-list" style={{padding: 0}}>
          <div className="taskList">
            {
              this.props.sent_referral.confirmed === true && this.filteredTasks().map(task => {
                return(
                  <div key={task.id}>
                    {this.getTaskComponent(task)}
                    {
                      !task.completed_at && moment().isSameOrAfter(moment(task.overdue_at)) &&
                      <i className="fa fa-exclamation-triangle warning-triangle"></i>
                    }
                  </div>
                );
              })
            }
          </div>
        </ul>
        <Provider store={store}>
          <PreviewSignUpModal show={this.state.showPreviewSignUpModal} />
        </Provider>
        <Provider store={store}>
          <PreviewLogInModal show={this.state.showPreviewLogInModal} />
        </Provider>
        <Modal show={this.state.showFiltersModal} animation={false}>
            <Modal.Body>
              <h1 className="p-h-md">
                <span dangerouslySetInnerHTML={{__html: this.state.filtersMessage}} />
              </h1>
            </Modal.Body>
            <Modal.Footer>
              <Button 
                bsSize="large"
                bsStyle="primary"
                onClick={this.closeFiltersModal}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    current_user: state.current_user,
    tasks: state.tasks
  }
}

export default connect(mapStateToProps)(Tasks)

Tasks.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  hospital: React.PropTypes.object.isRequired,
  patient: React.PropTypes.object.isRequired,
  sent_referral: React.PropTypes.object.isRequired,
  tasks: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired
};
