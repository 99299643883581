import React from 'react';
import { Provider } from 'react-redux';
import ReferralFilters from './ReferralFilters';

export default class ProviderFilters extends React.Component {
  render() {
    return(
      <Provider store={store}>
        <ReferralFilters {...this.props}/>
      </Provider>
    )
  }
}
