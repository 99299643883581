import React from 'react';
import utils from '../../Misc/Utils';
import _ from 'lodash';
import { connect } from 'react-redux';
import { updateMessages } from '../../../actions';
import Message from './Message';

class MessagesButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loaded: false};
    this.handleClick = this.handleClick.bind(this);
    this.messages = this.messages.bind(this);
  }

  handleClick() {
    if (!this.state.loaded) {
      $.getJSON('/api/messages', (response) => {
        this.props.updateMessages(response.messages);
        this.setState({loaded: true});
      });
    }
  }

  messageGroups() {
    return _.chain(this.props.messages).
      orderBy(message => message.created_at, 'desc').
      groupBy(message => message.referral_id).
      orderBy(messages => messages[0].created_at, 'desc').
      value();
  }

  messages() {
    if (this.state.loaded) {
      return this.messageGroups().slice(0, 8).map((messages, index) => {
        return(
          <Message
            key={messages[0].id}
            message={messages[0]}
            messageCount={
              messages.filter((message) => {
                return !message.sent_by_current_organization && !message.read_flag;
              }).length
            }
          />
        );
      }).reduce((messages, message, index) => {
        if (messages === null) {
          return [message];
        } else {
          return [...messages, <li key={index} className="divider"></li>, message];
        }
      }, null);
    } else {
      return(
        <li className="m-t-sm">
          <div className="sk-spinner sk-spinner-wave">
            <div className="sk-rect1"></div>
            <div className="sk-rect2"></div>
            <div className="sk-rect3"></div>
            <div className="sk-rect4"></div>
            <div className="sk-rect5"></div>
          </div>
        </li>
      );
    }
  }

  render() {
    let unreadCount = _.filter(this.props.messages, (message) => {
      return !message.sent_by_current_organization && !message.read_flag;
    }).length;

    return(
      <li className="dropdown MessagesButton" onClick={() => typeof ga != 'undefined' && ga('send', 'event', 'Header', 'Dropdown', 'Messages')}>
        {
          unreadCount ?
            <button onClick={this.handleClick} className="btn btn-outline btn-danger dim count-info" type="button" data-toggle="dropdown" href="#" aria-expanded="false">
              <i className="fa fa-envelope"></i>
              {unreadCount > 0 && <span className="label label-danger" style={utils.ie9Browser() ? {top: '-9px'} : {}}>{unreadCount}</span>}
            </button> :
            <a onClick={this.handleClick} className="dropdown-toggle count-info" data-toggle="dropdown" href="#" aria-expanded="false">
              <i className="fa fa-envelope"></i>
            </a>
        }
        <ul className="dropdown-menu">
          {this.messages()}
          <li className="menubutton">
            <a href="/sent_referrals?recommended_search=my_unread_messages" style={{textAlign: 'center'}}>
              <h3>
                <i className="fa fa-comments" /> My unread messages
              </h3>
            </a>
          </li>
          <li className="menubutton">
            <a href="/sent_referrals?recommended_search=my_sent_messages_still_awaiting_reply" style={{textAlign: 'center'}}>
              <h3>
                <i className="fa fa-comments" /> Messages still awaiting reply
              </h3>
            </a>
          </li>
        </ul>
      </li>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.messages
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateMessages: (messages) => {
      dispatch(updateMessages(messages))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesButton)

MessagesButton.propTypes = {
  messages: React.PropTypes.object.isRequired,
  updateMessages: React.PropTypes.func.isRequired
}
