import React from 'react';
import utils from '../Misc/Utils';
import _ from 'lodash';
import moment from 'moment';
import {Button, Tabs, Tab, Row, Nav, NavItem } from 'react-bootstrap';
import BrowserSupportWrapper from '../BrowserSupportWrapper';
import TheBasicsTab      from './TheBasicsTab';
import DescriptionTab    from './DescriptionTab';
import PicturesTab       from './PicturesTab';
import PatientReviewsTab from './PatientReviewsTab';
import PreviewBrochureModal from './PreviewBrochureModal';

export default class EditBrochure extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      provider: {
        attachments: [],
        reviews: []
      },
      showPreviewBrochure: false
    };
    this.onClickPreviewBrochure = this.onClickPreviewBrochure.bind(this);
    this.closePreviewBrochure = this.closePreviewBrochure.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateProvider = this.updateProvider.bind(this);
  }

  componentWillMount(){
    this.setState({provider: _.extend(this.props.provider)});
  }

  closePreviewBrochure() {
    this.setState({showPreviewBrochure: false});
  }

  handleChange(event) {
    let newProvider = _.extend(this.props.provider);
    newProvider[event.target.name] = event.target.value;
    this.setState({provider: newProvider})
  }

  onClickPreviewBrochure() {
    this.setState({showPreviewBrochure: true})
  }

  // TODO: Add a comment here. 
  updateProvider(providerResponse) {
    let clonedProvider = _.extend(this.state.provider);
    _.forEach(providerResponse, (value, key) => {
      clonedProvider[key] = providerResponse[key]
    })
    this.setState({provider: clonedProvider});
  }

  render() {
    let reviewsProvider = !_.includes((utils.nonReviewTypes), this.props.provider.provider_type);
    return(
      <div>
        <div className="col-lg-9">
          <div className="wrapper wrapper-content animated fadeInUp">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="m-b-md">
                      <h2>
                        <span>
                          My Patient Brochure
                        </span>
                        <Button 
                          bsSize="large"
                          bsStyle="primary"
                          className="pull-right"
                          onClick={this.onClickPreviewBrochure}
                        >
                          Preview Brochure
                        </Button>
                      </h2>
                    </div>
                    <dl className="dl-horizontal">
                      <dt>Last Updated:</dt> 
                      <dd>{moment(this.props.provider.created_at).format('dddd h:mma - MM.DD.Y')}</dd>
                    </dl>
                  </div>
                </div>

                <div className="row m-t-sm">
                  <div className="col-lg-12">
                    <div className="p-w-sm">
                      <Tabs defaultActiveKey={1} id="edit-brochure-tabs">
                        <Tab eventKey={1} title="The Basics">
                          <TheBasicsTab
                            provider={this.state.provider}
                            handleChange={this.handleChange}
                            updateProvider={this.updateProvider}
                          />
                        </Tab>
                        <Tab eventKey={2} title="Description">
                          <DescriptionTab
                            provider={this.state.provider}
                            handleChange={this.handleChange}
                            updateProvider={this.updateProvider}
                          />
                        </Tab>
                        <Tab eventKey={3} title="Pictures">
                          <BrowserSupportWrapper>
                            <PicturesTab
                              provider={this.state.provider}
                              updateProvider={this.updateProvider}
                            />
                          </BrowserSupportWrapper>
                        </Tab>
                        {reviewsProvider &&
                        <Tab eventKey={4} title="Patient Reviews">
                          <PatientReviewsTab
                            provider={this.state.provider}
                          />
                        </Tab>
                        }
                      </Tabs>
                    </div>
                  </div>
                </div>

                <PreviewBrochureModal
                  provider={this.props.provider}
                  showPreviewBrochure={this.state.showPreviewBrochure}
                  closePreviewBrochure={this.closePreviewBrochure}
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className="col-lg-3">
          <h4>{/* Task Content Holder will fit here. */}</h4>
        </div>
      </div>
    )
  }
}

EditBrochure.propTypes = {
  provider: React.PropTypes.shape({
    attachments: React.PropTypes.array.isRequired,
    reviews: React.PropTypes.array.isRequired
  })
}
