import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {Button, FormGroup, ControlLabel, FormControl, HelpBlock, Modal} from 'react-bootstrap';

export default class DescriptionTab extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showSuccessModal: false,
      isLoading: false,
      errors: {},
      charsLeft: (400 - props.provider.description.length)
    };
    this.handleTextCountChange = this.handleTextCountChange.bind(this);
    this.submit = this.submit.bind(this);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
  }

  charCountWarning(){
    if (this.state.charsLeft < 11) {
      return (
        <div className="text-box-char text-danger">
          {this.state.charsLeft} char remaining.
        </div>
      )
    }
  }

  closeSuccessModal() {
    this.setState({showSuccessModal: false});
  }

  handleTextCountChange(e){
    this.setState({charsLeft: (400 - e.target.value.length) });
    this.props.handleChange(e);
  }

  disabledSubmit() {
    return (this.state.isLoading || this.state.charsLeft <= 0)
  }

  submit() {
    this.setState({isLoading: true});
    $.ajax(`/api/providers/${this.props.provider.id}`, {
      data: {provider: _.omit(this.props.provider, ['reviews', 'attachments'])},
      error: (response) => {
        this.setState({isLoading: false, errors: response.responseJSON});
      },
      success: (response) => {
        this.setState({showSuccessModal: true, isLoading: false, errors: {}}, () => {
          this.props.updateProvider(response.provider)
        });
      },
      type: 'PATCH'
    });
  }

  render() {
    let isLoading = this.disabledSubmit();
    return(
      <div className='TheBasicsTab'>
        <div className="p-xl">
          <h2 className="m-b">
            Description
          </h2>
          <h3 className="m-b center-text">
            Your description lets patients hear directly from you before they choose their care.
          </h3>
          <h3 className="m-b center-text">
            Please limit yourself to one paragraph. 400 character maximum.
          </h3>
          <form>
            <FormGroup controlId="provider_description" validationState={this.state.errors.description && 'error'} className="form-group">
              <div className="col-md-12">
                <FormControl 
                  componentClass="textarea" 
                  name="description"
                  className="form-control m-b-sm"
                  style={{height: 200}}
                  maxLength="400"
                  defaultValue={this.props.provider.description}
                  onChange={this.handleTextCountChange}
                />
                {this.charCountWarning()}
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.description && this.state.errors.description.join(', ')}</HelpBlock>
              </div>
            </FormGroup>
          </form>
          <div className="col-md-12">
            <Button bsStyle="primary" disabled={isLoading} onClick={!isLoading ? this.submit : null}>
              Save
            </Button>
          </div>

          <Modal show={this.state.showSuccessModal} animation={false}>
            <Modal.Body>
              <h1 className="p-h-md">
                Your Description has been saved.
              </h1>
            </Modal.Body>
            <Modal.Footer>
              <Button 
                bsSize="large"
                bsStyle="primary"
                onClick={this.closeSuccessModal}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
}

DescriptionTab.propTypes = {
  provider: React.PropTypes.object.isRequired,
  handleChange: React.PropTypes.func.isRequired,
  updateProvider:  React.PropTypes.func.isRequired,
};
