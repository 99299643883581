import React from 'react';
import update from 'immutability-helper';
import { Modal, Button, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

export default class UserPasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {user: {}, errors: {}, showSuccessModal: false};
  }

  closeSuccessModal = () => {
    this.setState({showSuccessModal: false});
  }

  handleChange = (event) => {
    this.setState({
      user: update(this.state.user, {$merge: {[event.target.name]: event.target.value}})
    });
  }

  submit = () => {
    $.ajax(`/api/users/${this.props.user.id}`, {
      data: {user: this.state.user},
      error: (response) => {
        this.setState({errors: response.responseJSON});
      },
      success: (response) => {
        this.setState({user: {}, errors: {}, showSuccessModal: true}, () => {
          this.refs.form.reset();
          this.props.updateUser(response.user);
        });
      },
      type: 'PATCH'
    });
  }

  render() {
    return(
      <div>
        <div className="row">
          <div className="col-lg-6">
            <div className="panel panel-warning">
              <div className="panel-heading">
                <i className="fa fa-warning"></i> Password Requirements
              </div>
              <div className="panel-body">
                <p>Your password must meet the following requirements:</p>
                <ul>
                  <li>has at least 8 characters</li>
                  <li>has at least 1 character from 3 of the 4 following categories:</li>
                  <ul>
                    <li>upper-case letter</li>
                    <li>lower-case letter</li>
                    <li>number</li>
                    <li>symbol (!@#$%^&*)</li>
                  </ul>
                  <li>not one of your five most recently used passwords</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <form className="form-horizontal m-t-md" onChange={this.handleChange} ref="form">
          <FormGroup controlId="user_current_password" validationState={this.state.errors.current_password && 'error'} className="form-group">
            <ControlLabel className="col-sm-3 control-label">Current Password:</ControlLabel>
            <div className="col-sm-3">
              <FormControl type="password" className="form-control" name="current_password" />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.current_password && this.state.errors.current_password.join(', ')}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup controlId="user_password" validationState={this.state.errors.password && 'error'} className="form-group">
            <ControlLabel className="col-sm-3 control-label">New Password:</ControlLabel>
            <div className="col-sm-3">
              <FormControl type="password" className="form-control" name="password" />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.password && this.state.errors.password.join(', ')}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup controlId="user_password_confirmation" validationState={this.state.errors.password_confirmation && 'error'} className="form-group">
            <ControlLabel className="col-sm-3 control-label">Confirm new password:</ControlLabel>
            <div className="col-sm-3">
              <FormControl type="password" className="form-control" name="password_confirmation" />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.password_confirmation && this.state.errors.password_confirmation.join(', ')}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup className="form-group">
            <div className="col-sm-offset-3 col-sm-3">
              <Button bsSize="lg" bsStyle="primary" onClick={this.submit}>Save</Button>
            </div>
          </FormGroup>
        </form>
        <Modal show={this.state.showSuccessModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">Your password has been successfully updated.</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.closeSuccessModal}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

UserPasswordReset.propTypes = {
  user: React.PropTypes.object.isRequired
}
