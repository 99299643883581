import React from 'react';
import utils from '../../Misc/Utils';

import { FormGroup, FormControl, Button, Modal } from 'react-bootstrap';

export default class AddOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: {},
      session_token: '',
      show: false,
      showResponseModal: false,
      status: null,
    };
  }

  closeModal = (event) => {
    this.setState({[event.target.name]: false});

    if (this.state.status === 200) {
      utils.window_reload(true);
    }
  }

  handleChange = (event) => {
    this.setState({session_token: event.target.value});
  }

  handleSubmit = (event) => {
    event.preventDefault();

    $.ajax(`/api/users/${this.props.user.id}/user_organizations`, {
      complete: () => this.setState({session_token: '', show: false, showResponseModal: true}),
      data: {session_token: this.state.session_token},
      error: (response) => this.setState({status: response.status}),
      success: (data, textStatus, jqXHR) => {
        this.setState({organization: data.organization, status: jqXHR.status});
      },
      type: 'POST'
    });
  }

  openModal = (event) => {
    event.preventDefault();

    this.setState({show: true});
  }

  renderResponseModalContent = () => {
    switch (this.state.status) {
      case 200:
        return(
          <div>
            <h1 className="p-h-md">Your account has been updated to include:</h1>
            <h1>
              {this.state.organization.name}
              <br/ >
              {this.state.organization.city}, {this.state.organization.state}
            </h1>
          </div>
        );
      case 422:
        return <h1 className="p-h-md">This organization has already been added to your Aidin account.</h1>;
      default:
        return <h1 className="p-h-md">This passphrase is not valid.</h1>;
    }
  }

  render() {
    return(
      <li className="menubutton">
        <a href="#" style={{textAlign: 'center'}} onClick={this.openModal}>
          <h3><i className="fa fa-globe" /> Add Another Organization</h3>
        </a>
        <Modal bsSize="lg" show={this.state.show} animation={false}>
          <Modal.Body>
            <h1>If you have received a passphrase from Aidin for another organization that you manage, enter it below to add this organization to your Aidin account.</h1>
            <h1>Enter passphrase:</h1>
            <form className="form-horizontal p-h-md" onSubmit={this.handleSubmit}>
              <FormGroup controlId="session_token" className="form-group">
                <div className="col-sm-10 col-sm-offset-1">
                  <FormControl onChange={this.handleChange} className="form-control input-lg text-center" type="text" style={{fontSize: 36}} autoFocus />
                </div>
              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.closeModal} name="show">Close</Button>
            <Button bsSize="lg" bsStyle="primary" onClick={this.handleSubmit}>Submit</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showResponseModal} animation={false}>
          <Modal.Body>{this.renderResponseModalContent()}</Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.closeModal} name="showResponseModal">OK</Button>
          </Modal.Footer>
        </Modal>
      </li>
    )
  }
}

AddOrganization.propTypes = {
  user: React.PropTypes.object.isRequired
}
