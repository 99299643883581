import React from 'react';
import update from 'immutability-helper';
import { Modal, Button, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

export default class UserSecurityQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        security_question_answer: '',
        security_question_id: ''
      },
      errors: {},
      showSuccessModal: false
    };
  }

  closeSuccessModal = () => {
    this.setState({showSuccessModal: false});
  }

  handleChange = (event) => {
    this.setState({
      user: update(this.state.user, {$merge: {[event.target.name]: event.target.value}})
    });
  }

  submit = () => {
    $.ajax(`/api/users/${this.props.user.id}`, {
      data: {user: this.state.user},
      error: (response) => {
        if(response.responseJSON){
          this.setState({errors: response.responseJSON});
        } else {
          alert("Sorry, there was an error saving your information - please click the Help button.")
        }
      },
      success: () => {
        this.setState({
          user: {
            security_question_answer: '',
            security_question_id: ''
          },
          errors: {},
          showSuccessModal: true
        }, () => {
          this.refs.form.reset();
        });
      },
      type: 'PATCH'
    });
  }

  render() {
    return(
      <div>
        <form className="form-horizontal m-t-md" onChange={this.handleChange} ref="form">
          <FormGroup controlId="user_security_question_id" validationState={this.state.errors.security_question_id && 'error'} className="form-group">
            <ControlLabel className="col-sm-3 control-label">Security Question:</ControlLabel>
            <div className="col-sm-6">
              <FormControl componentClass="select" name="security_question_id">
                <option value="">Select a security question</option>
                {
                  this.props.user.security_questions.map((question) => {
                    return <option key={question[1]} value={question[1]}>{question[0]}</option>;
                  })
                }
              </FormControl>
              <HelpBlock>{this.state.errors.security_question_id && this.state.errors.security_question_id.join(', ')}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup controlId="user_security_question_answer" validationState={this.state.errors.security_question_answer && 'error'} className="form-group">
            <ControlLabel className="col-sm-3 control-label">Answer:</ControlLabel>
            <div className="col-sm-6">
              <FormControl type="text" className="form-control" name="security_question_answer" />
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.security_question_answer && this.state.errors.security_question_answer.join(', ')}</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup className="form-group">
            <div className="col-sm-offset-3 col-sm-3">
              <Button bsSize="lg" bsStyle="primary" onClick={this.submit}>Save</Button>
            </div>
          </FormGroup>
        </form>
        <Modal show={this.state.showSuccessModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">Your security question has been successfully updated.</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.closeSuccessModal}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

UserSecurityQuestion.propTypes = {
  user: React.PropTypes.object.isRequired
}
