import React from 'react';
import utils from '../Misc/Utils';
export default class NewMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {text: ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChange(event) {
    this.setState({text: event.target.value});
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      $.ajax({
        url: `/api/messages`,
        type: 'POST',
        data: {
          message_content: {
            referral_id: this.props.referral_id,
            patient_id: this.props.patient_id,
            organization_id: this.props.sender.id,
            organization_type: this.props.sender.class_name,
            text: this.state.text,
            message_attributes: {
              organization_id: this.props.receiver.id,
              organization_type: this.props.receiver.class_name
            }
          }
        },
        success: (response) => {
          this.setState({text: ''});
          this.props.updateMessages(response.message);
        }
      });
    }
  }

  render() {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='chat-message-form'>
            <div className='form-group'>
              <textarea
                value={this.state.text}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                className='form-control message-input'
                placeholder='Enter message text'
              />
              {utils.ie9Browser() ? (<label>Enter Message Text and Press Enter</label>) :''}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

NewMessage.propTypes = {
  receiver: React.PropTypes.object.isRequired,
  referral_id: React.PropTypes.number.isRequired,
  patient_id: React.PropTypes.number.isRequired,
  sender: React.PropTypes.object.isRequired,
  updateMessages: React.PropTypes.func.isRequired
}
