import React from 'react';
import utils from '../Misc/Utils';
import _ from 'lodash';
import moment from 'moment';
// import GoogleMapReact from 'google-map-react';

const Marker = ({ text }) => <i className="fa fa-map-marker fa-3x"></i>;

export default class PreviewBrochure extends React.Component {

  displayReviews(){
    return _.map(this.props.provider.reviews.slice(0,2), (review, i) => {
      return (
        <div className="feed-element" key={i}>
          <div className="pull-left">
            <i className="fa fa-user fa-3x"/>
          </div>
          <div className="media-body">
            <div className="well" style={{marginTop: '0px', fontSize: '13px', lineHeight: 1.428}}>
              {review.review_text}
            </div>
          </div>
        </div>
      )
    })
  }

  displayPhotos(){
    let photos = this.props.provider.attachments;
    if (photos.length === 0 ) {
      photos.push({image_url: utils.asset_src.no_pictures_available, file_name: 'no_picture_available'})
      photos.push({image_url: utils.asset_src.no_pictures_available, file_name: 'no_picture_available'})
    } else if (photos.length === 1) {
      photos.push({image_url: utils.asset_src.no_pictures_available, file_name: 'no_picture_available'})
    }

    return _.map(photos, (attachment, i) => {
      return (
        <div key={i} className='col-md-6'>
          <img alt={attachment.file_name} src={attachment.image_url} style={{width: '100%'}}/>
        </div>
      )
    })
  }

  render() {
    let reviewsProvider = !_.includes((utils.nonReviewTypes), this.props.provider.provider_type);
    return(
      <div className="PreviewBrochure">
        <h3>A description from this organization</h3>
        <p>
          {this.props.provider.description}
        </p>
        
        {reviewsProvider &&
          <div className="previewBrochureReviews">
            <h3 className="m-t-md">Reviews from previous patients</h3>
            <div className="feed-activity-list">
              {this.displayReviews()}
            </div>
          </div>
        }

        <h3 className="m-t-md">Photos</h3>
        <div className="row">
          {this.displayPhotos()}
        </div>


        {/* 
        <div className="hr-line-thin"/>
        
        <h3 className="m-t-md">Directions</h3>
        <div className="row">
          <div className='col-md-12'>
            <div style={{width: '100%', height: '300px'}}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyB4TjPuII0FGJZ-1wQPfz56Q9u9MqWg8ic'
                }}
                center={[this.props.provider.latitude, this.props.provider.longitude]}
                defaultZoom={15}
                options={{scrollwheel: false, navigationControl: false, mapTypeControl: false, scaleControl: false, draggable: false}}
              >
                <Marker
                  lat={this.props.provider.latitude}
                  lng={this.props.provider.longitude}
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
        */}
      </div>
    )
  }
}

PreviewBrochure.propTypes = {
  provider: React.PropTypes.shape({
    attachments: React.PropTypes.array.isRequired,
    reviews: React.PropTypes.array.isRequired
  })
};
