import React from 'react';
import utils from '../../Misc/Utils';
import _ from 'lodash';
import CheckboxJoinSection from '../../Forms/CheckboxJoinSection';
import Video from '../Video';

export default class ProviderOfferings extends React.Component {

  constructor(props) {
    super(props);
    this.careTypeLabel = this.careTypeLabel.bind(this);
    this.insuranceCompanyLabel = this.insuranceCompanyLabel.bind(this);
    this.servicedCountyLabel = this.servicedCountyLabel.bind(this);
  }

  declinedReasonToStr() {
    const declinedReasons = {
      1: 'No Bed',
      2: 'Staff Dhortage',
      3: 'Wrong Care Needs',
      4: 'Insurance Mismatch',
      5: 'Service Area',
      6: 'Other'
    };
    return declinedReasons[this.props.data.sent_referral.declined_reason]
  }

  careTypeLabel(careType){
    return careType.translations_name
  }

  insuranceCompanyLabel(insuranceCompany){
    if (insuranceCompany.primary) {
      return `${insuranceCompany.name} - Primary`
    } else {
      return insuranceCompany.name
    }
  }

  servicedCountyLabel(county){
    return `${county.name} County, ${county.state_name} `
  }

  render() {
    let locationProviderBool = _.includes((utils.locationalProviderTypes), this.props.data.provider.provider_type);
    let careTypeUpdateText, autoSelect;
    if (this.props.data.sent_referral.status === 'available') {
      careTypeUpdateText = 'Was this patient a good match? Aidin updated your filters to send you more patients like this.';
      autoSelect = true;
    } else if (this.props.data.sent_referral.status === 'unavailable') {
      careTypeUpdateText = 'Not a good match for you? Aidin updated your filters so we don’t send more patients like this.';
      autoSelect = false;
    }

    return (
      <div className="row">
        <div className="col-lg-5">
          <div className="text-center">
            <div className="m-t-md">
              <Video src={`https://www.youtube.com/embed/ctXRYC1ehDw?rel=0&amp;showinfo=0${this.props.current_user.previewer ? '&autoplay=1' : ''}`} />
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <p>
            {careTypeUpdateText}
          </p>
          <CheckboxJoinSection
            key ="1"
            id ='insurance_accepteds'
            labelText = {"Does "+this.props.data.provider.name+" accept this insurance?"}
            specificCheckLabel = {this.insuranceCompanyLabel}
            inputFieldPath = '[provider][insurance_accepteds_attributes]'
            optionsForSelect = {this.props.data.patient.insurance_companies}
            values = {this.props.data.provider.insurance_accepteds_attributes}
            secondaryTable = {_.pick(this.props.provider, ['id', 'name'])}
            foreign_key1 = 'adt_insurance_company_id'
            foreign_key2 = 'provider_id'
            onInputChange = {this.props.onInputChange}
            autoSelect = {autoSelect}
          />
          <CheckboxJoinSection
            key ="2"
            id ='care_type_offered'
            labelText = {"Does "+this.props.data.provider.name+" provide this service?"}
            specificCheckLabel = {this.careTypeLabel}
            inputFieldPath = '[provider][care_types_offered_attributes]'
            optionsForSelect = {this.props.data.referral.care_types}
            values = {this.props.data.provider.care_types_offered_attributes}
            secondaryTable = {_.pick(this.props.provider, ['id', 'name'])}
            foreign_key1 = 'care_type_id'
            foreign_key2 = 'provider_id'
            onInputChange = {this.props.onInputChange}
            autoSelect = {autoSelect}
          />
          {locationProviderBool &&
          <CheckboxJoinSection
            key ="3"
            id ='provider_serviced_counties'
            labelText = {"Does "+this.props.data.provider.name+" service this Area?"}
            specificCheckLabel = {this.servicedCountyLabel}
            inputFieldPath = '[provider][provider_served_counties_attributes]'
            optionsForSelect = {this.props.data.referral.counties}
            values = {this.props.data.provider.provider_served_counties_attributes}
            secondaryTable = {_.pick(this.props.provider, ['id', 'name'])}
            foreign_key1 = 'county_id'
            foreign_key2 = 'provider_id'
            onInputChange = {this.props.onInputChange}
            autoSelect = {autoSelect}
          />
          }
          {
            !this.props.current_user.previewer ? (
              <p>Don't like what you see? <a href="/filters">Click here</a> to update.</p>
            ) : (
              <p>Don't like what you see? Click "next" to finish and setup your account.</p>
            )
          }
        </div>
      </div>
    )
  }
}

ProviderOfferings.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  data: React.PropTypes.object.isRequired,
  onInputChange: React.PropTypes.func.isRequired
};
