import React from 'react';
import { connect } from 'react-redux';
import Video from '../Video';
import MultiProviderRespondTask from './MultiProviderRespondTask';

class Confirmation extends React.Component {

  render() {
    return (
      <div className="row">
        <div className="col-lg-5">
          <div className="text-center">
            <div className="m-t-md">
              <Video src={`https://www.youtube.com/embed/EZWUjUk0ngQ?rel=0&amp;showinfo=0${this.props.current_user.previewer ? '&autoplay=1' : ''}`} />
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <h3>
            You're almost done.
          </h3>
          { 
            this.props.organizations.length>0 && 
            <MultiProviderRespondTask 
              current_user={this.props.current_user}
              data={this.props.data} 
            />
          }
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    organizations: state.organizations
  }
}

export default connect(mapStateToProps)(Confirmation)

Confirmation.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  data: React.PropTypes.object.isRequired,
  organizations: React.PropTypes.array.isRequired
};