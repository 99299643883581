import React from 'react';
import utils from '../Misc/Utils';
import _ from 'lodash';
import moment from 'moment';
import { Tabs, Tab, Row, Nav, NavItem } from 'react-bootstrap';
import CareTypesOfferedTab from './CareTypesOfferedTab';
import InsuranceCompaniesOfferedTab from './InsuranceCompaniesOfferedTab';
import ReferralSourcesTab from './ReferralSourcesTab';
import CountiesOfferedTab from './CountiesOfferedTab';

export default class ReferralFilters extends React.Component {
  
  constructor(props){
      super(props);
  }

  mapCareTypeNameToOffered(){
    let firstCareTypes = _.map(this.props.provider.care_types_offered_attributes.slice(0, 4), (care_type_offerred) => {
      return _.find(this.props.care_types, {id: care_type_offerred.care_type_id}).translations_name
    });
    let firstCareTypesStr = firstCareTypes.join(", ");
    if (this.props.provider.care_types_offered_attributes.length > 3) {
      firstCareTypesStr = firstCareTypesStr.concat(', ...');
    }
    return firstCareTypesStr
  }

  mapInsuranceAcceptedsToCompanyName(){
    let firstInsuranceAccepteds = _.map(this.props.provider.insurance_accepteds_attributes.slice(0, 4), (insurance_accepted) => {
      let foundInsuranceCompany = _.find(this.props.adt_insurance_companies, {id: insurance_accepted.adt_insurance_company_id});
      if (foundInsuranceCompany) { return foundInsuranceCompany.name }
    });
    firstInsuranceAccepteds = _.compact(firstInsuranceAccepteds);
    let firstInsuranceAcceptedsStr = firstInsuranceAccepteds.join(", ");
    if (firstInsuranceAccepteds.length > 1) {
      firstInsuranceAcceptedsStr = firstInsuranceAcceptedsStr.concat(', ...');
    } else {
      firstInsuranceAcceptedsStr = 'No data present';
    }
    return firstInsuranceAcceptedsStr
  }

  mapCountiesToCompanyName(){
    let firstCounties = _.map(this.props.provider.provider_served_counties_attributes.slice(0, 3),  (served_county) => {
      return served_county.county_name
    });
    let firstCountiesStr = firstCounties.join(", ");
    if (this.props.provider.provider_served_counties_attributes.length > 2) {
      firstCountiesStr = firstCountiesStr.concat(', ...');
    }
    return firstCountiesStr
  }

  render() {
    let locationProviderBool = _.includes((utils.locationalProviderTypes), this.props.provider.provider_type);

    return(
      <div>
        <div className="col-lg-9">
          <div className="wrapper wrapper-content animated fadeInUp">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="m-b-md">
                      <h2>
                        Referral Preferences for {this.props.provider.name}
                      </h2>
                    </div>
                    <dl className="dl-horizontal">
                      <dt>Care Types Offered:</dt> 
                      <dd>{this.mapCareTypeNameToOffered()}</dd>
                    </dl>
                    <dl className="dl-horizontal">
                      <dt>Insurances Accepted:</dt> 
                      <dd>{this.mapInsuranceAcceptedsToCompanyName()}</dd>
                    </dl>
                    {locationProviderBool &&
                    <dl className="dl-horizontal">
                      <dt>Service Areas:</dt> 
                      <dd>{this.mapCountiesToCompanyName()}</dd>
                    </dl>
                    }
                  </div>
                </div>

                <div className="row m-t-sm">
                  <div className="col-lg-12">
                    <div className="p-w-sm">
                      <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                        <Tab eventKey={1} title="Care Types Offerred">
                          <CareTypesOfferedTab
                            provider={this.props.provider}
                            care_types={this.props.care_types}
                          />
                        </Tab>
                        <Tab eventKey={2} title="Insurance Info">
                          <InsuranceCompaniesOfferedTab
                            provider={this.props.provider}
                            adt_insurance_companies={this.props.adt_insurance_companies}
                          />
                        </Tab>
                        <Tab eventKey={3} title="Referral Sources">
                          <ReferralSourcesTab provider={this.props.provider} hospitals={this.props.hospitals} />
                        </Tab>
                        {locationProviderBool &&
                        <Tab eventKey={4} title="Counties Served">
                          <CountiesOfferedTab
                            provider={this.props.provider}
                            counties={this.props.counties}
                          />
                        </Tab>
                        }
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="col-lg-3">
        	<h4>{/* Task Content Holder will fit here. */}</h4>
        </div>
      </div>
    )
  }
}

ReferralFilters.propTypes = {
  provider: React.PropTypes.shape({
  	care_types_offered_attributes: React.PropTypes.array.isRequired,
    insurance_accepteds_attributes: React.PropTypes.array.isRequired,
    provider_served_counties_attributes: React.PropTypes.array.isRequired
	}),
  care_types: React.PropTypes.array.isRequired,
  adt_insurance_companies: React.PropTypes.array.isRequired
}
