import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {Button, Modal} from 'react-bootstrap';

export default class PatientReviewsTab extends React.Component {

  displayReviews(){
    return _.map(this.props.provider.reviews, (review, i) => {
      return(
        <div key={i} className='chat-message'>
          <div className='message'>
            <span className='message-date'>
              {`${moment(review.created_at).format('dddd h:mma - MM.DD.Y')} - ${moment(review.created_at).fromNow()}`}
            </span>
            <span className='message-content'>{review.review_text}</span>
          </div>
        </div>
      )
    })
  }

  render() {
    return(
      <div className="TheBasicsTab row">
        <div className="p-xl">
          <h2 className="m-b">
            Patient Review
          </h2>
          <h3 className="m-b">
            Aidin collects reviews from previous patients you’ve served so you know how you’re doing. 
          </h3>
        </div>
        <div className="col-lg-12 Messages">
          <div className="ibox chat-view">
            <div className="ibox-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="chat-discussion">
                  { this.props.provider.reviews.length >0 ? (
                      this.displayReviews()
                    ) : (
                      <h1 className="no-messages text-center">No reviews to display</h1>
                    )
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PatientReviewsTab.propTypes = {
  provider: React.PropTypes.shape({
    reviews: React.PropTypes.array.isRequired
  }),
};