import React from 'react';
import moment from 'moment';

export default class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {read: true};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.props.markAsUnread(this.props.message.id);
  }

  render() {
    let markAsUnread;

    if (!this.props.message.sent_by_current_organization && this.props.message.read_flag) {
      markAsUnread = <span> - <a onClick={this.handleClick} href="#">mark as unread</a></span>;
    }

    return(
      <div className={`chat-message ${this.props.message.sent_by_current_organization ? 'right' : 'left'}${!this.props.message.sent_by_current_organization && !this.props.message.read_flag ? ' font-bold' : ''}`}>
        <img alt="image" className="message-avatar" src={this.props.message.avatar_url} />
        <div className='message'>
          <span className='message-author' href='#'>{this.props.message.sender_name}</span>
          <span className='message-date'>
            {`${moment(this.props.message.created_at).format('dddd h:mma - MM.DD.Y')} - ${moment(this.props.message.created_at).fromNow()}`}
            {markAsUnread}
          </span>
          <span className='message-content'>{this.props.message.text}</span>
        </div>
      </div>
    )
  }
}

Message.propTypes = {
  message: React.PropTypes.object.isRequired,
  markAsUnread: React.PropTypes.func.isRequired
};
