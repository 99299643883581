import utils from '../../Misc/Utils';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import CheckboxSection from '../../Forms/CheckboxSection';
import Video from '../Video';
import {FormGroup, ControlLabel, FormControl, HelpBlock} from 'react-bootstrap';

export default class Respond extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      validationPeformed: false
    };
    this.sectionInputChange = this.sectionInputChange.bind(this);
  }

  sectionInputChange(e) {
    this.validate(e.target.name, e.target.value)
  }

  validate(path, value) {
    let clonedErrors = _.extend(this.state.errors);
    if (path === 'sent_referral.status' && value === ''){
      clonedErrors.status = ["Can't be empty"];
    } else {
      delete clonedErrors['status']
    }
    // Check for changes. Don't trigger initially!
    if (this.props.data[path] !== value) {
      if (this.props.data.sent_referral.status === 'available' && this.props.data.referral.provider_type !== 'pay' && !this.props.data.sent_referral.rq) {
        if (path==='task.read_terms' && (value === false || value === "false")) {
          clonedErrors.read_terms = "Can't be empty"
        } else {
          delete clonedErrors['read_terms']
        }
        if (path==='task.capacity_terms' && (value === false || value === "false")) {
          clonedErrors.capacity_terms = "Can't be empty"
        } else {
          delete clonedErrors['capacity_terms']
        }
        if (path==='task.update_terms' && (value === false || value === "false")) {
          clonedErrors.update_terms = "Can't be empty"
        } else {
          delete clonedErrors['update_terms']
        }
      }

      if (this.props.data.sent_referral.status === 'unavailable' && this.props.data.referral.provider_type !== 'pay') {
        if (path==='sent_referral.declined_reason' && value === ''){
          clonedErrors.declined_reason = ["Can't be empty"];
        } else {
          delete clonedErrors['declined_reason']
        }

        if (path === 'sent_referral.declined_reason' && !this.props.data.sent_referral.declined_reason_other) {
          clonedErrors.declined_reason_other = ["Can't be empty"];
        }

        if (path === 'sent_referral.declined_reason_other' &&  !value) {
          clonedErrors.declined_reason_other = ["Can't be empty"];
        } else {
          delete clonedErrors['declined_reason_other']
        }
      }
    }
    
    this.setState({errors: clonedErrors}, this.props.onInputChange(path, value))
    
    if (Object.keys(clonedErrors).length === 0 && clonedErrors.constructor === Object) {
      this.props.onSectionValidChange(true, {wizard_next_message: !this.props.data.referral.clover && this.props.data.referral.provider_type !== 'pay' && !this.props.data.sent_referral.rq && "You are responsible for communicating any patient or other financial obligations to the case manager."})
    } else {
      this.props.onSectionValidChange(false, {wizard_next_message: false})
    }
  }

  // Based on props
  validateAll(){
    let clonedErrors = _.extend(this.state.errors);

    if (!_.includes(['available', 'unavailable'], this.props.data.sent_referral.status)){
      clonedErrors.status = ["Can't be empty"];
    } else {
      delete clonedErrors['status']
    }

    if (this.props.data.sent_referral.status === 'available' && this.props.data.referral.provider_type !== 'pay' && !this.props.data.sent_referral.rq) {
      if (this.props.data.task.read_terms === false || this.props.data.task.read_terms === 'false') {
        clonedErrors.read_terms = "Can't be empty"
      } else {
        delete clonedErrors['read_terms']
      }
      if (this.props.data.task.capacity_terms === false || this.props.data.task.capacity_terms === 'false') {
        clonedErrors.capacity_terms = "Can't be empty"
      } else {
        delete clonedErrors['capacity_terms']
      }
      if (this.props.data.task.update_terms === false || this.props.data.task.update_terms === 'false') {
        clonedErrors.update_terms = "Can't be empty"
      } else {
        delete clonedErrors['update_terms']
      }
    }

    if (this.props.data.sent_referral.status === 'unavailable' && this.props.data.referral.provider_type !== 'pay') {
      if (this.props.data.sent_referral.declined_reason === ''){
        clonedErrors.declined_reason = ["Can't be empty"];
      } else {
        delete clonedErrors['declined_reason']
      }

      if (this.props.data.sent_referral.declined_reason === 'other' && !this.props.data.sent_referral.declined_reason_other) {
        clonedErrors.declined_reason_other = ["Can't be empty"];
      } else {
        delete clonedErrors['declined_reason_other']
      }
    }
    
    this.setState({errors: clonedErrors})

    if (Object.keys(clonedErrors).length === 0 && clonedErrors.constructor === Object) {
      this.props.onSectionValidChange(true)
      return true
    } else {
      this.props.onSectionValidChange(false)
      return false
    }
  }

  optionsForDeclinedReasons(){
    return _.map(utils.declinedReasonsOptions, (option, i) => {
      return <option key={i} value={option[0]}>{option[1]}</option>
    })
  }

  optionsForPickUpAt(){
    let options = [['', 'Please Select a Pick Up Time']]
    options.push([moment(this.props.data.referral.pick_up_at).format(), moment(this.props.data.referral.pick_up_at).format('LT')])
    var intervalsLen = (this.props.data.referral.pick_up_at_flexibility_minutes / 30)- 1;
    if (this.props.data.referral.pick_up_at_flexibility_minutes) {
      var intervalsArr = [30]; 
      for (var i = 0; i < intervalsLen; i++) {
        intervalsArr.push(intervalsArr[i]+ 30);
      } // => [30, 60, 50]
    }
    _.each(intervalsArr, (time_addition) => {
      let newTime = moment(this.props.data.referral.pick_up_at).add(time_addition, 'minutes');
      options.push([newTime.format(), moment(newTime).format('LT')]);
    })
    return _.map(options, (option, i) => {
      return <option key={i} value={option[0]}>{option[1]}</option>
    })
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-5">
          <div className="text-center">
            <div className="m-t-md">
              <Video src={`https://www.youtube.com/embed/j5ibCWwB9xE?rel=0&amp;showinfo=0${this.props.current_user.previewer ? '&autoplay=1' : ''}`} />
            </div>
          </div>
        </div>

        <div className="col-lg-7">
          <FormGroup controlId="status" validationState={this.state.errors.status && 'error'}>
            <ControlLabel className="col-md-12 control-label">Please submit your response:</ControlLabel>
            <div className="col-md-12">
              <FormControl 
                componentClass="select" 
                onChange={this.sectionInputChange} 
                className="form-control"
                defaultValue={this.props.data.sent_referral.status}
                name="sent_referral.status"
              >
                <option value=''>Please Select a response</option>
                <option value='available'>{this.props.data.referral.provider_type === 'pay' || this.props.data.sent_referral.rq ? 'Approved' : 'Available'}</option>
                <option value='unavailable'>{this.props.data.referral.provider_type === 'pay' || this.props.data.sent_referral.rq ? 'Not Approved' : 'Unvailable'}</option>
              </FormControl>
              <FormControl.Feedback className="fa fa-times" />
              <HelpBlock>{this.state.errors.status && this.state.errors.status.join(', ')}</HelpBlock>
            </div>
          </FormGroup>

          {(!this.props.data.sent_referral.status || this.props.data.sent_referral.status === 'under_review') && this.props.data.referral.provider_type !== 'pay' && !this.props.data.sent_referral.rq &&
            <ul>
              <li><h2>There may be multiple providers reviewing this referral</h2></li>
              <li><h2>You will receive a notification when a final choice has been made</h2></li>
            </ul>
          }

          {/* TRNS Specific Form Sections */}
          { this.props.data.sent_referral.status === 'available' && this.props.data.referral.provider_type === 'trns' && this.props.data.referral.will_call &&
            <FormGroup controlId="will_call" validationState={this.state.errors.will_call && 'error'}>
              <ControlLabel className="col-md-12 control-label">Confirm your pick up time:</ControlLabel>
              <div className="col-md-12">
                <FormControl 
                  componentClass="select" 
                  onChange={this.sectionInputChange} 
                  className="form-control"
                  defaultValue={this.props.data.sent_referral.will_call}
                  name="sent_referral.will_call"
                >
                  <option value='will_call'>Will Call</option>
                </FormControl>
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.will_call && this.state.errors.will_call.join(', ')}</HelpBlock>
              </div>
            </FormGroup>
          }
          { this.props.data.sent_referral.status === 'available' && this.props.data.referral.provider_type === 'trns' && !this.props.data.referral.will_call &&
            <FormGroup controlId="pick_up_at" validationState={this.state.errors.pick_up_at && 'error'}>
              <ControlLabel className="col-md-12 control-label">Confirm your pick up time:</ControlLabel>
              <div className="col-md-12">
                <FormControl 
                  componentClass="select" 
                  onChange={this.sectionInputChange} 
                  className="form-control"
                  defaultValue={moment(this.props.data.sent_referral.pick_up_at).format()}
                  name="sent_referral.pick_up_at"
                >
                  {this.optionsForPickUpAt()}
                </FormControl>
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.pick_up_at && this.state.errors.pick_up_at.join(', ')}</HelpBlock>
              </div>
            </FormGroup>
          }

          {/* DIAL/DAR/OUTPSYC Specific Form Sections */}
          {this.props.data.sent_referral.status === 'available' && ['dial', 'dar', 'outpsyc'].indexOf(this.props.data.referral.provider_type) > -1 &&
            <div>
              <FormGroup controlId="available_days" validationState={this.state.errors.available_days && 'error'}>
                <ControlLabel className="col-md-12 control-label">Please indicate what dates and times you are offering care for this patient:</ControlLabel>
                <div className="col-md-12 m-b-sm">
                  <CheckboxSection
                    id={'available_sunday'}
                    name={'sent_referral.available_sunday'}
                    value={this.props.data.sent_referral.available_sunday}
                    labelText={"Sun"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_monday'}
                    name={'sent_referral.available_monday'}
                    value={this.props.data.sent_referral.available_monday}
                    labelText={"Mon"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_tuesday'}
                    name={'sent_referral.available_tuesday'}
                    value={this.props.data.sent_referral.available_tuesday}
                    labelText={"Tue"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_wednesday'}
                    name={'sent_referral.available_wednesday'}
                    value={this.props.data.sent_referral.available_wednesday}
                    labelText={"Wed"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_thursday'}
                    name={'sent_referral.available_thursday'}
                    value={this.props.data.sent_referral.available_thursday}
                    labelText={"Thu"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_friday'}
                    name={'sent_referral.available_friday'}
                    value={this.props.data.sent_referral.available_friday}
                    labelText={"Fri"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />
                  <CheckboxSection
                    id={'available_saturday'}
                    name={'sent_referral.available_saturday'}
                    value={this.props.data.sent_referral.available_saturday}
                    labelText={"Sat"}
                    onChange={this.sectionInputChange}
                    inline={true}
                  />  
                </div>
              </FormGroup>
              <FormGroup controlId="available_times" validationState={this.state.errors.available_times && 'error'} className="form-group">
                <ControlLabel className="col-md-12 control-label">Please indicate your available times. (12 characters max)</ControlLabel>
                <div className="col-md-12 m-b-sm">
                  <FormControl
                    name="sent_referral.available_times"
                    className="form-control m-b-sm"
                    style={{width: 220}}
                    defaultValue={this.props.data.sent_referral.available_times}
                    onChange={this.sectionInputChange}
                    maxLength="12"
                  />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.available_times && this.state.errors.available_times.join(', ')}</HelpBlock>
                </div>
              </FormGroup>
            </div>
          }

          {this.props.data.sent_referral.status === 'available' && this.props.data.referral.provider_type !== 'pay' && !this.props.data.sent_referral.rq &&
            <div className="col-md-12">
              <FormGroup controlId="read_terms" validationState={this.state.errors.read_terms && 'error'}>
                <CheckboxSection
                  id={'read_terms'}
                  name={'task.read_terms'}
                  value={this.props.data.task.read_terms}
                  labelText={this.props.data.referral.clover ? "I understand that this referral is preauthorized by the payer on the insurance tab." : "I have reviewed and accept the patient's insurance information. I will request authorization once patient makes final choice."}
                  onChange={this.sectionInputChange}
                />
              </FormGroup>
              <FormGroup controlId="capacity_terms" validationState={this.state.errors.capacity_terms && 'error'}>
                <CheckboxSection
                  id={'capacity_terms'}
                  name={'task.capacity_terms'}
                  value={this.props.data.task.capacity_terms}
                  labelText={`My organization has capacity to provide necessary clinical care on or around ${moment(this.props.data.referral.discharge_date).format('MMMM D')}`}
                  onChange={this.sectionInputChange}
                />
              </FormGroup>
              <FormGroup controlId="update_terms" validationState={this.state.errors.update_terms && 'error'}>
                <CheckboxSection
                  id={'update_terms'}
                  name={'task.update_terms'}
                  value={this.props.data.task.update_terms}
                  labelText={`I can update my response until ${moment(this.props.data.referral.time_window_closes_at).format('MMMM D [at] h:mma')}`}
                  onChange={this.sectionInputChange}
                />
              </FormGroup>
              <ul style={{marginLeft: '-16px'}}>
                <li>{`I can expect a final decision from the patient by ${moment(this.props.data.referral.time_window_closes_at).format('MMMM D [at] h:mma')}`}</li>
              </ul>
            </div>
          }

          {this.props.data.sent_referral.status === 'unavailable' && this.props.data.referral.provider_type !== 'pay' &&
            <div>
              <FormGroup controlId="declined_reason" validationState={this.state.errors.declined_reason && 'error'}>
                <ControlLabel className="col-md-12 control-label">Declined Reason:</ControlLabel>
                <div className="col-md-12">
                  <FormControl
                    componentClass="select"
                    onChange={this.sectionInputChange}
                    className="form-control"
                    defaultValue={this.props.data.sent_referral.declined_reason}
                    name="sent_referral.declined_reason"
                  >
                    {this.optionsForDeclinedReasons()}
                  </FormControl>
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.declined_reason && this.state.errors.declined_reason.join(', ')}</HelpBlock>
                </div>
              </FormGroup>
              {this.props.data.sent_referral.declined_reason === 'other' &&
                <FormGroup controlId="declined_reason_other" validationState={this.state.errors.declined_reason_other && 'error'}>
                  <ControlLabel className="col-md-12 control-label">Other Reason:</ControlLabel>
                  <div className="col-md-12">
                    <FormControl
                      type="text"
                      onChange={this.sectionInputChange}
                      className="form-control"
                      defaultValue={this.props.data.sent_referral.declined_reason_other}
                      name="sent_referral.declined_reason_other"
                    />
                    <FormControl.Feedback className="fa fa-times" />
                    <HelpBlock>{this.state.errors.declined_reason_other && this.state.errors.declined_reason_other.join(', ')}</HelpBlock>
                  </div>
                </FormGroup>
              }
            </div>
          }
        </div>
      </div>
    )
  }

}

Respond.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  data: React.PropTypes.shape({
    task: React.PropTypes.object.isRequired,
    referral: React.PropTypes.object.isRequired,
    sent_referral: React.PropTypes.object.isRequired
  }),
  onInputChange: React.PropTypes.func.isRequired,
  onSectionValidChange: React.PropTypes.func.isRequired
};
