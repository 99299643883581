import React from 'react';
import _ from 'lodash';
import { ProgressBar } from 'react-bootstrap';
import Button from '../Forms/Button';

export default class Wizard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isStepValidDisplay: true
    };
    this.onNextStep = this.onNextStep.bind(this);
    this.onPrevStep = this.onPrevStep.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // Tabs

  wizardTabs() {
    let currentStepIndex = _.indexOf(this.props.possibleSteps, this.props.currentStep);
    let percentComplete = ((currentStepIndex+1)/this.props.possibleSteps.length)*100;
    return (
      <ProgressBar active now={percentComplete} />
    )
  }

  // Next Buttons

  prevButton() {
    return (
      <Button
        buttonText="Previous"
        onClick={this.onPrevStep}
        disabled={false}
      />
    )
  }

  nextButton(customText) {
    return (
      <Button
        buttonText="Next"
        onClick={this.onNextStep}
        disabled={!this.props.isStepValidDisplay}
      />
    )
  }

  submitButton() {
    return (
      <Button
        buttonText="Submit"
        onClick={this.onSubmit}
        disabled={!this.props.isStepValidDisplay}
      />
    )
  }

  stepButtons() {
    if (this.props.possibleSteps.length === 1) {
      return (
        <div className="float-right">
          <div className="btn-group">{this.submitButton()}</div>
        </div>
      )
    } else if (_.indexOf(this.props.possibleSteps, this.props.currentStep) === 0) {
      return (
        <div>
          { this.props.wizard_opts && this.props.wizard_opts.wizard_next_message && <div className='alert alert-danger'>{this.props.wizard_opts.wizard_next_message}</div>}
          <div className="btn-group float-right">
            {this.nextButton()}
          </div>
        </div>
      )
    } else if (_.last(this.props.possibleSteps) === this.props.currentStep) {
      return (
        <div className="float-right">
          <div className="btn-group m-r-sm">{this.prevButton()}</div>
          <div className="btn-group m-r-sm">{this.submitButton()}</div>
        </div>
      )
    } else {
      return (
        <div className="float-right">
          <div className="btn-group m-r-sm">{this.prevButton()}</div>
          <div className="btn-group m-r-sm">{this.nextButton()}</div>
        </div>
      )
    }
  }

  onPrevStep() {
    let currentStepIndex = _.indexOf(this.props.possibleSteps, this.props.currentStep);
    this.props.updateStep(this.props.possibleSteps[currentStepIndex-1])
  }

  onNextStep() {
    let currentStepIndex = _.indexOf(this.props.possibleSteps, this.props.currentStep);
    this.props.updateStep(this.props.possibleSteps[currentStepIndex+1])
  }

  onSubmit(){
    this.props.handleSubmit()
  }

  render() {
    return (
      <div>
        <div className="clearfix">
          {this.wizardTabs()}
        </div>
        <div>{this.props.children}</div>
        <div className="clearfix">
          {this.stepButtons()}
        </div>
      </div>
    )
  }

}

Wizard.propTypes = {
  possibleSteps: React.PropTypes.array.isRequired,
  currentStep:   React.PropTypes.string.isRequired,
  updateStep:    React.PropTypes.func,
  handleSubmit:  React.PropTypes.func,
  isStepValidDisplay: React.PropTypes.bool.isRequired
};

