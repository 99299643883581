import { connect } from 'react-redux';
import utils from '../Misc/Utils';
import request from 'superagent';
import { SpinnerWave } from '../Misc/Spinners.jsx';
import C3Chart from 'react-c3js';
import ReactTable from 'react-table';
 
class UserReport extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      loading: true,
      show_aggregate_metrics: true,
      show_completed_referrals: true,
      show_in_process_referrals: true,
      show_cancelled_referrals: true,
      show_report_gauges: true
    }
  }

  componentDidMount(){
    this.retrieveData()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.filters.last_updated != this.props.filters.last_updated){
      this.retrieveData(nextProps.filters)
    }
    if(nextProps.filters.xlsx_request != this.props.filters.xlsx_request){
      this.retrieveData(nextProps.filters, true)
    }
  }

  retrieveData = (filters, xlsx) => {
    if(xlsx){
      var url = "/api/reports/user/" + this.props.opts.user_id + "?export=xlsx&" + Object.entries(this.props.filters).map(([key, val]) => `${key}=${val}`).join('&')
      utils.window_open(url,'')
    } else {
      var query_id = Math.random()
      this.setState({loading:true, last_query: query_id})
      var req = request.get("/api/reports/user/" + this.props.opts.user_id) 
      .query(this.props.filters)
      .end( (err,res) => {
        if(this.state.last_query == query_id){
          this.setState({loading:false})
          if(res && res.body){
            this.setState(res.body)
          }
        }
      })
    }
  }

  scoreDesc = (score) => {
    return ['Not enough data',
      'Lowest available',
      'Available',
      'Best available'
    ][score] || 'Not enough data'
  }

  fmtPct = (n) => {
    return n ? parseFloat(n).toFixed(2) + '%' : ''
  }

  fmtPctBig = (n) => {
    return n ? <h1 style={{fontSize:'3em'}}>{parseFloat(n).toFixed(2) + '%'}</h1> : <h3>Not enough data</h3>
  }

  fmtHospitalId = data => {
    return this.props.opts.hospital_campuses[data.hospital_id]
  }

  fmtProviderType = data => {
    return this.props.opts.provider_types_inv[data.provider_type][1]
  }


  forceNumericSort = (a,b) => {
    var pa = parseFloat(a)
    var pb = parseFloat(b)
    a = (a === null || a === undefined || isNaN(pa)) ? -Infinity : parseFloat(a)
    b = (b === null || b === undefined || isNaN(pb)) ? -Infinity : parseFloat(b)
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  }



  tableKeys = (show_aggregates) => {
    if(show_aggregates){
      var base = [
        { id: "open_competition", sortMethod: this.forceNumericSort, accessor: data => this.fmtPct(data.open_competition), Header: "Open Competition (% Recommended Providers Chosen)" },
        { id: "sent_referral_competition_percent", sortMethod: this.forceNumericSort, accessor: data => this.fmtPct(data.sent_referral_competition_percent), Header: "Quality First (Avg. Percentile)" },
        { id: "completed_on_time_percent", sortMethod: this.forceNumericSort, accessor: data => this.fmtPct(data.completed_on_time_percent), Header: "Accountability (% Tasks Completed On Time)" }
      ]
    } else {
      var base = []
    }
    return base.concat([ 
      { id: "cancel_reason", accessor: "cancel_reason", Header: "Cancelled Reason" },
      { id: "referral_no", accessor: "referral_no", Header: "Referral #" },
      { id: "patient_visit_id", accessor: "patient_visit_id", Header: "Patient Visit ID" },
      { id: "mrn", accessor: "mrn", Header: "Patient MRN" },
      { id: "draft_referral", accessor: "draft_referral", Header: "Draft Referral" },
      { id: "submit_task", accessor: "submit_task", Header: "Submit Task" },
      { id: "reopen_task", accessor: "reopen_task", Header: "Reopen Task" },
      { id: "share_task", accessor: "share_task", Header: "Share Task" },
      { id: "reserve_task", accessor: "reserve_task", Header: "Reserve Task" },
      { id: "confirm_discharge_task", accessor: "confirm_discharge_task", Header: "Confirm Discharge Task" },
      { id: "hospital_id", accessor: this.fmtHospitalId, Header: "Hospital ID" },
      { id: "service_line", accessor: "service_line", Header: "Service Line" },
      { id: "provider_type", accessor: this.fmtProviderType, Header: "Provider Type" },
      { id: "resuming_care", accessor: "resuming_care", Header: "Resuming Care" },
      { id: "name", accessor: "name", Header: "Financial Class Name" },
      { id: "primary_insurances", accessor: "primary_insurances", Header: "Primary Insurance" },
      { id: "secondary_insurances", accessor: "secondary_insurances", Header: "Secondary Insurance" },
      { id: "sent_referrals", accessor: "sent_referrals", Header: "Sent Referrals" },
      { id: "offers", accessor: "offers", Header: "Offers" },
      { id: "medicare_competition", accessor: "medicare_competition", Header: "Medicare Competition" },
      { id: "outcomes_competition", accessor: "outcomes_competition", Header: "Outcomes Competition" },
      { id: "satisfaction_competition", accessor: "satisfaction_competition", Header: "Satisfaction Competition" },
      { id: "reserved_provider", accessor: "reserved_provider", Header: "Reserved Provider" },
      { id: "provider_city", accessor: "provider_city", Header: "Provider City" },
      { id: "medicare_score", accessor: data => this.scoreDesc(data.medicare_score), Header: "Medicare Score" },
      { id: "outcome_score", accessor: data => this.scoreDesc(data.outcome_score), Header: "Outcome Score" },
      { id: "satisfaction_score", accessor: data => this.scoreDesc(data.satisfaction_score), Header: "Satisfaction Score" },
      { id: "medicare_star", accessor: "medicare_star", Header: "Medicare Star" },
      { id: "admission_date", accessor: "admission_date", Header: "Admission Date" },
      { id: "referral_created", accessor: "referral_created", Header: "Referral Created" },
      { id: "presented_to_patient", accessor: "presented_to_patient", Header: "Presented To Patient" },
      { id: "reserve", accessor: "reserve", Header: "Reserve" },
      { id: "discharge_date", accessor: "discharge_date", Header: "Discharge Date" },
      { id: "patient_satisfaction_score", accessor: "patient_satisfaction_score", Header: "Patient Satisfaction Score" },
      { id: "readmitted", accessor: "readmitted", Header: "Readmitted" }
    ])
  }
  
  taskLabel = v => {
    return {
      'discharge': 'confirm discharge',
      'share': 'share',
      'reopen': 'reopen',
      'reserve': 'reserve',
      'submit': 'submit'
    }[v] || v
  }

  taskOrder = ['submit','reopen','share','reserve','discharge']

  metrics_table = (metrics, show_aggregates) => {
      return (
        <div>
          <ReactTable
              defaultSortDesc={true}
              defaultPageSize={10}
              className="report_meta -striped"
              columns={this.tableKeys(show_aggregates)} 
              data={metrics}
              sortable={true}
              getTdProps = {(state,rowInfo,column,instance) => {
                return {
                  className: "tdcol-" + column.id
                }
              } }
              getTrProps = {(state,rowInfo,column,instance) => {
                return {
                  //className: "clickable",
                  // onClick: (e, handleOrig) => {
                  //   this.openUserPage(rowInfo.row.user_id)
                  //}
                }      
              } }
          >
          </ReactTable>
        </div>
      )
  }

  render() {
    return (
      <div className="reportsContent">
        { this.state.loading && <SpinnerWave /> }

        { !this.state.loading && this.state.aggregate_metrics && <h2 className="cp" onClick={()=>{ this.setState({show_aggregate_metrics: !this.state.show_aggregate_metrics }) }}>
          <i className={"fa " + (this.state.show_aggregate_metrics ? "fa-chevron-down" : "fa-chevron-right")  } /> Performance
        </h2> }

        { !this.state.loading && this.state.aggregate_metrics && this.state.show_aggregate_metrics && <div style={{display:'flex'}}>
          <div className="rowFlx3">
            <h3>Open Competition</h3>
            { this.fmtPctBig(this.state.aggregate_metrics.sent_referral_competition_percent) }
            { this.state.aggregate_metrics.all_submitted_referrals_count } total
          </div>
          <div className="rowFlx3">
            <h3>Quality First</h3>
            { this.fmtPctBig(this.state.aggregate_metrics.avg_quality_percentile) }
            { this.state.aggregate_metrics.all_shared_referrals_count } total
          </div>
          <div className="rowFlx3">
            <h3>Accountability</h3>
            { this.fmtPctBig(this.state.aggregate_metrics.completed_on_time_percent) }
            <p>{ this.state.aggregate_metrics.all_tasks_count } total</p>
          </div>
        </div> }

        { !this.state.loading && this.state.tasks_on_time && <h2 className="cp" onClick={()=>{ this.setState({show_report_gauges: !this.state.show_report_gauges }) }}>
          <i className={"fa " + (this.state.show_report_gauges ? "fa-chevron-down" : "fa-chevron-right")  } /> Tasks Completed on Time, By Type
        </h2> }

        
        { !this.state.loading && this.state.show_report_gauges && <div className="reportGauges">
          { this.state.tasks_on_time && Object.keys(this.state.tasks_on_time).length == 0 && <div className="piblock"><h2>No results</h2></div> }
          {_.map(_.orderBy(this.state.tasks_on_time, p => this.taskOrder.indexOf(p[0])) , (p,i) => {
            var total = p.completed_on_time + p.not_completed_on_time
            var t_pct = (p.completed_on_time / total) * 100
            return (
              <div className="reportGauge piblock" key={p.task_type + i}>
                <C3Chart data={{
                  type: 'gauge',
                  columns: [['Completed on time', t_pct]]
                }} />
                <p style={{margin:'0.25em 0'}}>{p.completed_on_time} on time, out of {total} total</p>
                <h1 style={{textTransform:'capitalize', margin: '0.25em 0'}}>{ this.taskLabel(p.task_type) }</h1>
              </div>
            )
            }
          )}
        </div> }

        { !this.state.loading && this.state.in_process_referrals && <h2 className="cp" onClick={()=>{ this.setState({show_in_process_referrals: !this.state.show_in_process_referrals }) }}>
          <i className={"fa " + (this.state.show_in_process_referrals ? "fa-chevron-down" : "fa-chevron-right")  } /> In-Progress Referrals
        </h2> }
        
        { !this.state.loading && this.state.in_process_referrals && this.state.show_in_process_referrals && this.metrics_table(this.state.in_process_referrals, false) }


        { !this.state.loading && this.state.completed_referrals && <h2 className="cp" onClick={()=>{ this.setState({show_completed_referrals: !this.state.show_completed_referrals }) }}>
          <i className={"fa " + (this.state.show_completed_referrals ? "fa-chevron-down" : "fa-chevron-right")  } /> Completed Referrals
        </h2> }
        
        { !this.state.loading && this.state.completed_referrals && this.state.show_completed_referrals && this.metrics_table(this.state.completed_referrals, true) }


        { !this.state.loading && this.state.cancelled_referrals && <h2 className="cp" onClick={()=>{ this.setState({show_cancelled_referrals: !this.state.show_cancelled_referrals }) }}>
          <i className={"fa " + (this.state.show_cancelled_referrals ? "fa-chevron-down" : "fa-chevron-right")  } /> Cancelled Referrals
        </h2> }

        { !this.state.loading && this.state.cancelled_referrals && this.state.show_cancelled_referrals && this.metrics_table(this.state.cancelled_referrals, false) }

      </div>
    )
  }

}


const mapStateToProps = state => {
  return {
    current_user: state.current_user,
    current_organization: state.current_organization
  }
}

export default connect(mapStateToProps)(UserReport)
