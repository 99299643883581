import React from 'react';
import AllMessages from './AllMessages';
import NewMessage from './NewMessage';

export default class Messages extends React.Component {
  componentDidMount() {
    if (this.props.isActive) {
      setTimeout(this.props.setReadMessages, 500);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isActive && !prevProps.isActive) {
      this.props.setReadMessages();
    }
  }

  render() {
    return(
      <div className="Messages row">
        <div className="col-lg-12">
          <div className="ibox chat-view">
            <div className="ibox-content">
              <div className="row">
                <div className="col-md-12">
                  <AllMessages
                    messages={this.props.messages}
                    markAsUnread={this.props.markAsUnread}
                    isActive={this.props.isActive}
                  />
                </div>
              </div>
              <NewMessage
                receiver={this.props.receiver}
                referral_id={this.props.referral_id}
                patient_id={this.props.patient_id}
                sender={this.props.sender}
                updateMessages={this.props.updateMessages}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Messages.propTypes = {
  messages: React.PropTypes.array,
  patient_id: React.PropTypes.number.isRequired,
  receiver: React.PropTypes.object.isRequired,
  referral_id: React.PropTypes.number.isRequired,
  sender: React.PropTypes.object.isRequired,
  isActive: React.PropTypes.bool,
  markAsUnread: React.PropTypes.func.isRequired,
  setReadMessages: React.PropTypes.func.isRequired,
  updateMessages: React.PropTypes.func.isRequired
};
