import { CHANGE_SENT_REFERRAL_TAB } from '../actions';

export default function(state = {}, action) {
  switch(action.type) {
    case CHANGE_SENT_REFERRAL_TAB:
      return action.tab_key;
    default:
      return state;
  }
}
