import React from 'react';
import { Button, FormGroup, ControlLabel, FormControl, HelpBlock, Modal } from 'react-bootstrap';

export default class UserAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {avatarUrl: props.user.avatar_url, avatarFile: null},
      errors: {},
      loading: false,
      showSuccessModal: false
    };
  }

  closeSuccessModal = () => {
    this.setState({showSuccessModal: false});
  }

  handleFileChange = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({user: {avatarUrl: reader.result, avatarFile: file}});
    }

    if (file) {
      reader.readAsDataURL(file);
    } else {
      this.setState({user: {avatarUrl: '', avatarFile: null}});
    }
  }

  submit = () => {
    let formData = new FormData();
    formData.append('user[avatar]', this.state.user.avatarFile);

    this.setState({loading: true}, () => {
      $.ajax(`/api/users/${this.props.user.id}`, {
        complete: () => {
          this.setState({loading: false});
        },
        contentType: false,
        data: formData,
        error: (response) => {
          this.setState({errors: response.responseJSON});
        },
        processData: false,
        success: (response) => {
          this.setState({errors: {}, showSuccessModal: true});
        },
        type: 'PATCH'
      });
    });
  }

  render() {
    return(
      <div>
        <div className={`ibox-content${this.state.loading ? ' sk-loading' : ''}`} style={{border: 'none'}}>
          <div className="sk-spinner sk-spinner-double-bounce">
            <div className="sk-double-bounce1"></div>
            <div className="sk-double-bounce2"></div>
          </div>
          <form className="form-horizontal m-t-md" ref="form">
            <FormGroup controlId="user_avatar" validationState={this.state.errors.avatar && 'error'} className="form-group">
              <ControlLabel className="col-sm-3 control-label">Image</ControlLabel>
              <div className="col-sm-3">
                <img className="img-lg m-md" src={this.state.user.avatarUrl} />
                <FormControl type="file" className="form-control" name="avatar" onChange={this.handleFileChange} style={{border: 'none'}} />
                <HelpBlock>{this.state.errors.avatar && this.state.errors.avatar[0]}</HelpBlock>
              </div>
            </FormGroup>
            <FormGroup className="form-group">
              <div className="col-sm-offset-3 col-sm-3">
                <Button bsSize="lg" bsStyle="primary" onClick={this.submit} disabled={!this.state.user.avatarFile}>Save</Button>
              </div>
            </FormGroup>
          </form>
        </div>
        <Modal show={this.state.showSuccessModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">Your avatar image has been successfully updated.</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.closeSuccessModal}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

UserAvatar.propTypes = {
  user: React.PropTypes.object.isRequired
}
