import utils from '../Misc/Utils';
import React from 'react';
import update from 'immutability-helper';
import PasswordResetEmailForm from '../PasswordResetEmailForm';
import { Modal, Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {session: {}, showErrorModal: false};
  }

  componentDidMount() {
    let intervalStart = Date.now();

    setInterval(() => {
      if (intervalStart + 1800000 < Date.now()) {
        utils.window_reload(true);
      }
    }, 1000);
  }

  closeErrorModal = () => {
    this.setState({showErrorModal: false});
  }

  handleChange = (event) => {
    this.setState({
      session: update(this.state.session, {$merge: {[event.target.name]: event.target.value}})
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    $.post('/api/sessions', {session: this.state.session}, (response) => {
      if(response.roles.includes('hospital_admin')){
        utils.location_replace('/reports');
      } else {
        utils.location_replace('/sent_referrals');
      }
    }).fail((response) => {
      this.setState({showErrorModal: true, statusCode: response.status});
    });
  }

  renderErrorModalText = () => {
    if (this.state.statusCode === 429) {
      return 'You have tried to log in unsuccessfully too many times. Please wait 10 minutes and try again.';
    } else if (this.state.statusCode === 403) {
      return 'Your access to Aidin has been revoked. Please contact your team to request access.';
    } else if (this.state.statusCode === 421) {
      return (<span>Hospital users please please continue to <a href="https://app.myaidin.com">https://app.myaidin.com</a></span>);
    } else {
      return 'Those credentials are not valid. Please try again.';
    }
  }

  render() {
    return(
      <div>
        <form className="form-inline pull-right m-t-md" onChange={this.handleChange} onSubmit={this.handleSubmit}>
          <FormGroup controlId="session_email" className="form-group">
            <ControlLabel className={utils.ie9Browser() ? "": "sr-only" }>Email</ControlLabel>
            <FormControl className="form-control m-r-xs" placeholder="Email" type="email" name="email" />
          </FormGroup>
          <FormGroup controlId="session_password" className="form-group">
            <ControlLabel className={utils.ie9Browser() ? "": "sr-only" }>Password</ControlLabel>
            <FormControl className="form-control m-r-xs" placeholder="Password" type="password" name="password" />
          </FormGroup>
          <Button className="btn btn-white" onClick={this.handleSubmit} type="submit">Log in</Button>
          <div className="forgotpwwrap">
            <PasswordResetEmailForm />
          </div>
        </form>
        <Modal bsSize="lg" show={this.state.showErrorModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">{this.renderErrorModalText()}</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.closeErrorModal}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

LoginForm.propTypes = {
}
