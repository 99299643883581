import React from 'react';

export default class AdminSidebar extends React.Component {
  links = [
    { url: "/admin", title: "Home", exact_url: true},
    { url: "/admin/faxes", title: "Faxes" },
  ]

  render() {
    return (
      <div>
        <h2>Navigation</h2>
        <hr />
        <ul>
          { _.map( this.links, (x,i) => (<li key={i}><a href={x.url} className={ x.exact_url && x.url ==  window.location.pathname || !x.exact_url && window.location.pathname.includes(x.url) ? 'active' : ''}>{ x.title }</a></li>) ) }
        </ul>
      </div>
    )
  }
}
