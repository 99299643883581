import React from 'react';
import AdminSidebar from './AdminSidebar';
import AdminContent from './AdminContent';

export default class AdminInner extends React.Component {
  render() {
    return (
      <div className="admin_wrapper">
        <div className="admin_sidebar">
          <AdminSidebar />
        </div>
        <div className="admin_content">
          <AdminContent {...this.props} />
        </div>
      </div>
    )
  }
}
