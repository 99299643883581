import utils from '../../Misc/Utils';
import React from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateActivities, updateTasks } from '../../../actions';
import PatientNotReceived from './PatientNotReceived';
import ReceivePatient from './ReceivePatient';
import TaskButton from '../TaskButton';
import Wizard from '../../Wizard';
import WizardStep from '../../Wizard/WizardStep';

class ReceivePatientTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 'receivePatient',
      errors: {},
      possibleSteps: ['receivePatient', 'patientNotReceived'],
      referral: {
        id: props.referral.id,
        patient_received: (() => {
          if (props.referral.status === 'handed_to_provider') {
            return 'true';
          } else if (props.task.completed_at) {
            return 'false';
          } else {
            return '';
          }
        })(),
        provider_actual_received_date: props.referral.provider_actual_received_date,
        provider_anticipated_discharge_date: props.referral.provider_anticipated_discharge_date
      },
      valid: false
    };
  }

  close = () => {
    this.setState({show: false});
  }

  getCurrentStep = () => {
    let content;

    switch(this.state.currentStep) {
      case 'receivePatient':
        content =
          <ReceivePatient
            current_user={this.props.current_user}
            errors={this.state.errors}
            handleProviderActualReceivedDateChange={this.handleProviderActualReceivedDateChange}
            handleProviderActualReceivedDateFocus={this.handleProviderActualReceivedDateFocus}
            handleProviderAnticipatedDischargeDateChange={this.handleProviderAnticipatedDischargeDateChange}
            handleProviderAnticipatedDischargeDateFocus={this.handleProviderAnticipatedDischargeDateFocus}
            handlePatientReceivedChange={this.handlePatientReceivedChange}
            patient={this.props.patient}
            provider={this.props.provider}
            referral={this.state.referral}
            tasks={this.props.tasks}
          />;
        break;
      case 'patientNotReceived':
        content =
          <PatientNotReceived
            current_user={this.props.current_user}
            hospital={this.props.hospital}
            patient={this.props.patient}
            provider={this.props.provider}
            referral={this.state.referral}
          />;
        break;
    }

    return <WizardStep header="Click Play for Help">{content}</WizardStep>;
  }

  getPossibleSteps = () => {
    if (this.state.valid && this.state.referral.patient_received === 'true') {
      return ['receivePatient'];
    } else {
      return ['receivePatient', 'patientNotReceived'];
    }
  }

  handleProviderActualReceivedDateFocus = () => {
    if (!this.state.referral.provider_actual_received_date) {
      this.setState({
        referral: update(this.state.referral, {$merge: {provider_actual_received_date: new Date()}})
      }, this.validate);
    }
  }

  handleProviderActualReceivedDateChange = (value, formattedValue) => {
    this.setState({
      referral: update(this.state.referral, {$merge: {provider_actual_received_date: formattedValue}})
    }, this.validate);
  }

  handleProviderAnticipatedDischargeDateFocus = () => {
    if (!this.state.referral.provider_anticipated_discharge_date) {
      this.setState({
        referral: update(this.state.referral, {$merge: {provider_anticipated_discharge_date: new Date()}})
      }, this.validate);
    }
  }

  handleProviderAnticipatedDischargeDateChange = (value, formattedValue) => {
    let errors = {};

    if (new Date(formattedValue) < new Date(this.state.referral.provider_actual_received_date)) {
      errors = {provider_anticipated_discharge_date: 'Must be on or after Date Services Began'};
    }

    this.setState({
      errors: errors,
      referral: update(this.state.referral, {$merge: {provider_anticipated_discharge_date: formattedValue}})
    }, this.validate);
  }

  handlePatientReceivedChange = (event) => {
    this.setState({
      referral: update(this.state.referral, {$merge: {patient_received: event.target.value}})
    }, this.validate);
  }

  handleSubmit = () => {
    $.ajax({
      data: {
        task: {
          completed: true, referral_attributes: this.state.referral,
          user_id: this.props.current_user.id
        }
      },
      success: (response) => {
        this.close();
        this.props.updateActivities(response.activity);
        this.props.updateTasks(response.task);

        if (this.props.current_user.previewer) {
          if (this.props.current_user.previewer_signed_up) {
            this.props.showSignUpModal('previewLogIn', true);
          } else {
            this.props.showSignUpModal('previewSignUp', true);
          }
        } else {
          utils.window_reload(true);
        }
      },
      type: 'PATCH',
      url: `/api/tasks/${this.props.task.id}`
    });
  }

  open = () => {
    this.setState({show: true});
  }

  updateStep = (nextStep) => {
    if (this.state.valid) {
      this.setState({currentStep: nextStep});
    }
  }

  validate = () => {
    let valid = !!(
      _.isEmpty(this.state.errors) &&
      this.state.referral.patient_received === 'true' &&
        this.state.referral.provider_actual_received_date &&
        this.state.referral.provider_anticipated_discharge_date ||
      this.state.referral.patient_received !== 'true'
    );

    this.setState({valid: valid}, () => {
      this.setState({possibleSteps: this.getPossibleSteps()});
    });
  }

  render() {
    return(
      <span>
        <TaskButton
          id={this.props.task.id}
          task={this.props.task}
          onClick={this.open}
        />
        <Modal show={this.state.show} onHide={this.close} bsSize="lg">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Patient Receipt for {this.props.patient.full_name} (Referral #{this.props.referral.id})</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Wizard
              currentStep={this.state.currentStep}
              handleSubmit={this.handleSubmit}
              isStepValidDisplay={this.state.valid}
              possibleSteps={this.state.possibleSteps}
              updateStep={this.updateStep}
            >
              {this.getCurrentStep()}
            </Wizard>
          </Modal.Body>
        </Modal>
      </span>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivities: (activities) => dispatch(updateActivities(activities)),
    updateTasks: (tasks) => dispatch(updateTasks(tasks))
  }
}

export default connect(null, mapDispatchToProps)(ReceivePatientTask)

ReceivePatientTask.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  hospital: React.PropTypes.object.isRequired,
  patient: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired,
  showSignUpModal: React.PropTypes.func.isRequired,
  task: React.PropTypes.object.isRequired,
  tasks: React.PropTypes.array.isRequired
}
