import React from 'react';
import update from 'immutability-helper';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Video from '../Video';

export default class PatientNotReceived extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      message_content: {
        message_attributes: {
          organization_id: props.hospital.id,
          organization_type: props.hospital.class_name
        },
        organization_id: props.provider.id,
        organization_type: props.provider.class_name,
        patient_id: props.patient.id,
        referral_id: props.referral.id,
        text: 'You reserved our services for this patient but we did not receive them. Please cancel this referral or update it accordingly.'
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    $.ajax({
      data: {message_content: this.state.message_content},
      success: () => this.setState({complete: true}),
      type: 'POST',
      url: `/api/messages`
    });
  }

  handleTextChange = (event) => {
    this.setState({
      message_content: update(this.state.message_content, {$merge: {text: event.target.value}})
    });
  }

  render() {
    return(
      <fieldset className="body current">
        <div className="row">
          <div className="col-lg-5">
            <div className="text-center">
              <div className="m-t-md">
                <Video src={`https://www.youtube.com/embed/dzQjpt7Jvfo?rel=0&showinfo=0${this.props.current_user.previewer ? '&autoplay=1' : ''}`} />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <h3>
              If {this.props.patient.full_name} will not receive services from {this.props.provider.name}, please request
              that {this.props.hospital.name} update their records and clear this patient from your matched list.
            </h3>
            <form className="form-horizontal m-t-md" onSubmit={this.handleSubmit}>
              <FormGroup controlId="message_content_text" className="form-group">
                <div className={`col-sm-12${this.state.complete ? ' text-muted' : ''}`}>
                  <ControlLabel className="control-label">Message for Referral #{this.props.referral.id}</ControlLabel>
                  <FormControl
                    componentClass="textarea"
                    onChange={this.handleTextChange}
                    value={this.state.message_content.text}
                    disabled={this.state.complete}
                    style={{height: 110}}
                  />
                </div>
              </FormGroup>
              <FormGroup className="form-group">
                <div className="col-sm-offset-9 col-sm-3">
                  <Button bsSize="lg" type="submit" disabled={this.state.complete}>Send</Button>
                </div>
              </FormGroup>
            </form>
          </div>
        </div>
      </fieldset>
    );
  }
}

PatientNotReceived.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  hospital: React.PropTypes.object.isRequired,
  patient: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired
}
