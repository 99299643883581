import React from 'react';
import _ from 'lodash';
import moment from 'moment'; 
import inflection from 'inflection';
import { Button } from 'react-bootstrap';

export default class FullPatientRecord extends React.Component {
  
  attendingPhysicianHelper(){
    return <span className='font-italic'>No data available</span>
  }

  diagnosisAndProcedureCodes(){
    return (<span className='font-italic'>No data available</span>)
  }

  displayClinicalNeeds(){
    let categoryCareTypes = _.groupBy(this.props.referral.care_types, 'category')
    return _.map(categoryCareTypes, (care_types, category) => {
      return (
        <dl className="dl-horizontal" key={category} >
          <dt>
            {inflection.titleize(category)}:
          </dt>
          <dd>
            {this.filteredCareTypes(care_types)}
          </dd>
        </dl>
      )
    })
  }

  filteredCareTypes(care_types){
    return _.map(care_types, (care_type, i) => {
      return(
        <div key={i}>
          { care_type.translations_name }
        </div>
      )
    })
  }

  mapAttachments(){
    return _.map(this.props.referral.attachments, (attachment, i) => {
      return(
        <div className='m-t' key={i}>
          {attachment.full_name}
          <Button 
            bsSize="small"
            bsStyle="primary"
            className="m-l"
            onClick={this.props.showPrintModal}
          >
            Print
          </Button>
        </div>
      )
    })
  }

  showNewAttachmentsMsg = () => {
    if(!this.props.referral.last_submitted_at){ return null }
    var last_submit = moment(this.props.referral.last_submitted_at)
    var now = moment()
    var minutes_diff = now.diff(last_submit) / 60000
    if(minutes_diff > 10){
      return null
    } else {
      return <p className="alert alert-warning" style={{marginTop:'24px'}}>This referral was just submitted {parseInt(minutes_diff)} minutes ago - you may want to refresh in a few minutes to make sure its creator has finished uploading attachments.</p>
    }
  }

  render() {
    return(
      <div className='FullPatientRecord'>
        
        <div className="ClinicalNeeds">
          <dl className="dl-horizontal m-t-md">
            <h2>Clinical Needs</h2>
            <dl className='dl-horizontal'>
              <dt>Diagnosis and Procedure Codes:</dt>
              <dd>{ this.diagnosisAndProcedureCodes() }</dd>
            </dl>
            <dl className='dl-horizontal'>
              <dt>Attending Physician:</dt>
              <dd>{this.attendingPhysicianHelper()}</dd>
            </dl>
            {this.props.referral.provider_type === 'hha' &&
            <dl className='dl-horizontal'>
              <dt>Home Health Order:</dt>
              <dd>{ this.props.referral.home_health_order ? this.props.referral.home_health_order : <span className='font-italic'>No data available</span>  }</dd>
            </dl>
            }
            {this.props.referral.provider_type === 'inf' &&
              <dl className='dl-horizontal'>
                <dt>Infusion Details:</dt>
                <dd>{ this.props.referral.infusion_details ? this.props.referral.infusion_details : <span className='font-italic'>No data available</span>  }</dd>
              </dl>
            }
            {this.props.referral.provider_type === 'dme' &&
              <dl className='dl-horizontal'>
                <dt>DME Details:</dt>
                <dd>{this.props.referral.dme_details ? this.props.referral.dme_details : <span className='font-italic'>No data available</span>}</dd>
              </dl>
            }
          </dl>
        </div>

        <hr />

        <div className="CareTypes">
          <dl className="dl-horizontal">
            <h2>Care Types</h2>
            <div className="row">
              <div className="col-md-12">
                <div className='m-t-sm'>
                  { this.displayClinicalNeeds() }
                </div>
                {this.props.referral.other_care_type &&
                  <div className='m-t-sm m-b-sm'>
                    <dl className="dl-horizontal">
                      <dt>Other Care Types:</dt>
                      <dd>
                        { this.props.referral.other_care_type }
                      </dd>      
                    </dl>
                  </div>
                }
              </div>
            </div>
          </dl>
        </div>

        <hr />

        <div className="Attachments">
          <dl className="dl-horizontal">
            <h2>
              Clinical Docs
              <Button bsStyle="primary" bsSize="lg" className="pull-right" onClick={this.props.showPrintModal}>
                <i className="fa fa-print" /> Print All Clinical Docs
              </Button>
            </h2>
            <div className='m-t-sm'>
              {this.props.referral.attachments.length === 0 && <span style={{color: 'red', fontWeight: 'bold'}}>No Attachments have been uploaded to this referral. Please contact the Care Team under the Messages tab.</span>}
              {this.showNewAttachmentsMsg()}
              {this.mapAttachments()}
            </div>
          </dl>
          <div className='m-t-sm'>
            <dl className="dl-horizontal">
              <dl className="dl-horizontal">
                <dt>Inputs & Outputs:</dt>
                <dd style={{whiteSpace: 'pre'}}>
                  {this.props.referral.ins_outs}
                </dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>Medication Administrations:</dt>
                <dd style={{whiteSpace: 'pre'}}>
                  {this.props.referral.medication_administrations}
                </dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>Vital Signs:</dt>
                <dd style={{whiteSpace: 'pre'}}>
                  {this.props.referral.vital_signs}
                </dd>
              </dl>
              <dl className="dl-horizontal">
                <dt>Results:</dt>
                <dd style={{whiteSpace: 'pre'}}>
                  {this.props.referral.labs}
                </dd>
              </dl>
            </dl>
          </div>
        </div>
      </div>
    )
  }

}

FullPatientRecord.propTypes = {
  patient: React.PropTypes.shape({}).isRequired,
  referral: React.PropTypes.shape({
  	care_types: React.PropTypes.array.isRequired,
    attachments: React.PropTypes.array.isRequired
	}),
  sent_referral: React.PropTypes.object.isRequired,
  showPrintModal: React.PropTypes.func.isRequired
};
