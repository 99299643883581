import React from 'react';
import moment from 'moment';

export default class Task extends React.Component {
  content() {
    switch(this.props.task.task_type) {
      case 'respond':
        return 'Respond! (Offer or decline care before access to patient record closes)';
      case 'receive_patient':
        return 'Receive patient? Update patient status';
      case 'update_patient_status':
        return 'Update Required (Update patient status or close this case)';
    }
  }

  render() {
    return(
      <li>
        <a href={`/sent_referrals/${this.props.task.sent_referral_id}`}>
            {
              moment().isSameOrAfter(moment(this.props.task.overdue_at)) &&
              <i className="fa fa-exclamation-triangle warning-triangle"></i>
            }
          <img
            alt="image"
            className="pull-left img-circle img-md m-t-xs m-r-md"
            src={this.props.task.avatar_url}
          />
          <div>
            <strong>{this.props.task.patient_full_name}</strong><br />
            <span className="font-italic">{this.props.task.hospital_name}</span><br />
            {this.content()}
          </div>
        </a>
      </li>
    );
  }
}

Task.propTypes = {
  task: React.PropTypes.object.isRequired
}
