import React from 'react';
import SupportRequest from './SupportRequest';
import utils from '../Misc/Utils';
import { connect } from 'react-redux';

class SidebarContent extends React.Component {

  constructor(props){
    super(props);
  }

  isActive = opts => {
    if(typeof window == 'undefined'){
      return false
    } else if(opts.submenu){
      return window.location.pathname.indexOf(opts.base_url) == 0
    } else {
      return opts.url == window.location.pathname;
    }
  }

  isSubmenuOpen = opts => {
    return opts.submenu && (this.isActive(opts) || this.state[opts.submenu.show_key])
  }

  sidebarBtn = (opts, key) => {
    if(_.some(opts.roles, r => this.props.current_user.roles.includes(r))){
      var is_open = this.isSubmenuOpen(opts)
      return(
        <li className={ this.isActive(opts) ? 'active' : '' } key={key} >
          <a href={ opts.url ? opts.url : '#' } onClick={opts.url && opts.label === 'Dashboard' ? () => typeof ga != 'undefined' && ga('send', 'event', 'Dashboard Access', 'Click', 'Sidebar Dashboard Link') : (opts.url ? null : (e) => this.sidebarToggle(opts.submenu, e))}>
            { opts.icon && <i className={ "fa fa-" + opts.icon } /> }
            <span className="nav-label">{ opts.label }</span>
            { opts.submenu && <span className={is_open ? "fa fa-chevron-down" : "fa fa-chevron-right"} style={{fontSize:"0.8em", float:'right', display: 'inline-block', padding:'0.3em'}} onClick={(e) => opts.submenu ? this.sidebarToggle(opts.submenu, e) : null} /> }
          </a>
          { opts.submenu && is_open && <ul className="nav nav-second-level"> {_.map(opts.submenu.items, this.sidebarBtn) } </ul> }
        </li>
      )
    } else {
      return ''
    }
  }

  sidebarToggle = (submenu, event) => {
    event.preventDefault()
    this.setState({[submenu.show_key]: !this.state[submenu.show_key]})
  }

  reportsSubMenu = {
    show_key: 'show_submenu_reports',
    items: [
      {roles: ['hospital_admin'], url: '/reports/users', label: 'Users Report'},
      {roles: ['hospital_admin'], url: '/reports/providers', label: 'Providers Report'},
      {roles: ['hospital_admin'], url: '/reports/referrals', label: 'Referrals Report'},
    ]
  }

  render() {
    let currentLocation = typeof window != 'undefined' ? window.location.pathname : ''
    return (
      <ul className="nav" id="side-menu">
        <li className="nav-header">
          <span>
            <img 
              className="img-circle img-md" 
              alt="Sample Logo" 
              src={this.props.current_organization.avatar_url}
            />
          </span>
          <div className="profile-element">
            <span className="clear">
              <span className="block m-t-xs">
                <strong className="font-bold" style={{color:'#DFE4ED'}}>{this.props.current_organization.name}</strong>
              </span>
            </span>
            <ul className="dropdown-menu animated fadeInRight m-t-xs">
              <li><a href="#">Log out</a></li>
            </ul>
          </div>
        </li>
        { this.sidebarBtn({roles: ['provider'], url: '/sent_referrals', icon: 'laptop', label: 'Dashboard'}) }
        { this.sidebarBtn({roles: ['provider'], url: '/filters', icon: 'filter', label: 'Referral Preferences'}) }
        { this.sidebarBtn({roles: ['provider'], url: '/brochure', icon: 'file-text', label: 'Edit Brochure'}) }
        { this.sidebarBtn({roles: ['provider'], url: '/users/edit', icon: 'gear', label: 'User Settings'}) }
        { this.sidebarBtn({roles: ['provider'], url: '/users', icon: 'group', label: 'Add or Remove Users'}) }
        { this.sidebarBtn({roles: ['hospital_admin'], base_url: '/reports', icon: 'group', label: 'Reports', submenu: this.reportsSubMenu}) }
        <li>
          <SupportRequest/>
        </li>

        <li className={window.location.pathname === '/mode_reports' ? 'active' : '' }>
          <a href="/mode_reports" onClick={() => typeof ga != 'undefined' && ga('send', 'event', 'Link', 'Click', 'Reporting link')}>
            <i className="fa fa-bar-chart-o" />
            <span className="nav-label">Reporting</span>
          </a>
        </li>
      </ul>
    )
  }

}

SidebarContent.propTypes = {
  current_organization: React.PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    current_user: state.current_user
  }
}

export default connect(mapStateToProps)(SidebarContent)
