import {Button} from 'react-bootstrap';
import React from 'react';
import PreviewBrochureModal from '../../ProviderBrochure/PreviewBrochureModal';
import Video from '../Video';

export default class UpdateBrochure extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      provider: {
        attachments: [],
        reviews: []
      },
      showPreviewBrochure: false
    };
    this.onClickPreviewBrochure = this.onClickPreviewBrochure.bind(this);
    this.closePreviewBrochure = this.closePreviewBrochure.bind(this);
  }

  closePreviewBrochure() {
    this.setState({showPreviewBrochure: false});
  }

  onClickPreviewBrochure() {
    typeof ga != 'undefined' && ga('send', 'event', 'Referral Response Modal', 'Button', 'Preview Brochure');

    this.setState({showPreviewBrochure: true})
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-5">
          <div className="text-center">
            <div className="m-t-md">
              <Video src={`https://www.youtube.com/embed/jKDgmY-FSUY?rel=0&amp;showinfo=0${this.props.current_user.previewer ? '&autoplay=1' : ''}`} />
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <p>
            Here’s what the patient will see about your organization.
          </p>
          <div className="row">
            <Button 
              bsSize="large"
              bsStyle="primary"
              className="m-l m-b"
              onClick={this.onClickPreviewBrochure}
            >
              Preview Brochure
            </Button>
          </div>
          {
            !this.props.current_user.previewer ? (
              <p>Don't like what you see? <a href="/brochure">Click here</a> to update.</p>
            ) : (
              <p>Don't like what you see? Click "next" to finish and setup your account.</p>
            )
          }
        </div>

        <PreviewBrochureModal
          provider={this.props.provider}
          showPreviewBrochure={this.state.showPreviewBrochure}
          closePreviewBrochure={this.closePreviewBrochure}
        />
      </div>
    )
  }

}

UpdateBrochure.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  provider: React.PropTypes.shape({
    attachments: React.PropTypes.array.isRequired,
    reviews: React.PropTypes.array.isRequired
  })
};
