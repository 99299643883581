import React from 'react';
import { Provider } from 'react-redux';
import UserReport from './UserReport.jsx';
import UsersReport from './UsersReport.jsx';
import ProviderReport from './ProviderReport.jsx';
import ProvidersReport from './ProvidersReport.jsx';
import ReferralReport from './ReferralReport.jsx';
import update from 'immutability-helper';
import { Modal, Button, ButtonGroup, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import DatePicker from 'react-bootstrap-date-picker';
import moment from 'moment'; 

export default class Reports extends React.Component{
  constructor(props){
    super(props)
    var filters = localStorage.getItem('report_filters')
    filters = JSON.parse(filters) || this.baseFilters()
    this.state = {
      filters: filters
    }
  }

  resetFilters = () => {
    this.setState({filters: this.baseFilters()})
  }

  baseFilters = () => {
    var now = moment().subtract(1,'months')
    return {
      date_start: now.format(),
      date_end: '',
      provider_type: '',
      hospital_campus: '',
      provider_network: '',
      service_line: '',
      financial_class: '',
      payor: '',
      provider: ''
    }
  }

  updateFilterDateFn = n => {
    return (value, fmtValue) => {
      this.setState({
        filters: update(this.state.filters, {$merge: {[n]: value}})
      })
    }
  }

  updateFilter = e => {
    this.setState({
      filters: update(this.state.filters, {$merge: {[e.target.name]: e.target.value}})
    })
  }

  saveFiltersToLocalStorage = () => {
    localStorage.setItem('report_filters', JSON.stringify(this.state.filters))
  }

  exportToXlsx = e => {
    this.saveFiltersToLocalStorage()
    this.setState({
      filters: update(this.state.filters, {$merge: {xlsx_request: (new Date()).toISOString() }})
    })
  }

  triggerUpdate = e => {
    this.saveFiltersToLocalStorage()
    this.setState({
      filters: update(this.state.filters, {$merge: {last_updated: (new Date()).toISOString() }})
    })
  }

  FieldGroup({ id, label, help, className, ...props }) {
    return (
      <FormGroup controlId={id} className={ className }>
        <FormControl name={id} {...props} />
        {help && <HelpBlock>{help}</HelpBlock>}
      </FormGroup>
    );
  }

  selectBoxVk = (key, key_plural, placeholder) => {
    return (
      <FormGroup className="col-sm-4" controlId="formControlsSelect">
        <FormControl name={key} componentClass="select" onChange={this.updateFilter} value={this.state.filters[key]}>
          <option key="-1" value={false} defaultValue={true}>{placeholder}</option>
          { _.map(this.props.opts[key_plural], (k,i) => { return <option key={i} value={k[0]}>{k[1]}</option> }) }
        </FormControl>
      </FormGroup>
    );
  }
  selectBoxKv = (key, key_plural, placeholder) => {
    return (
      <FormGroup className="col-sm-4" controlId="formControlsSelect">
        <FormControl name={key} componentClass="select" onChange={this.updateFilter} value={this.state.filters[key]}>
          <option key="-1" value={false} defaultValue={true}>{placeholder}</option>
          { _.map(this.props.opts[key_plural], (k,i) => <option key={i} value={k[1]}>{k[0]}</option>) }
        </FormControl>
      </FormGroup>
    );
  }

  render(){
    return(
      <div className="reports_wrapper ibox">

        <h1 className="m-t-lg">{ this.props.title }</h1>

        <div className="ibox-content">
          <form className="filters row">

            <div className="col-sm-4">
              <DatePicker name="date_start" value={this.state.filters.date_start} onChange={this.updateFilterDateFn('date_start')} placeholder="Start date" />
            </div>

            <div className="col-sm-4">
              <DatePicker name="date_end" value={this.state.filters.date_end} onChange={this.updateFilterDateFn('date_end')} placeholder="End date" />
            </div>

            { false && <this.FieldGroup className="col-sm-4" id="date_start" type="text" placeholder="Start date (ex. 04/01/2013)" onChange={this.updateFilter} value={this.state.filters.date_start} /> }

            { false && <this.FieldGroup className="col-sm-4" id="date_end" type="text" placeholder="End date (ex. 12/31/2017)" onChange={this.updateFilter} value={this.state.filters.date_end} /> }

            { this.selectBoxKv('provider_type','provider_types','All provider types') }

            { this.selectBoxVk('hospital_campus','hospital_campuses','All hospital campuses') }

            { this.selectBoxVk('provider_network','provider_networks','All provider networks') }

            { this.selectBoxVk('service_line','service_lines','All service lines') }

            { this.selectBoxVk('financial_class','financial_classes','All financial classes') }

            { this.props.display != 'users_overview' && this.selectBoxVk('payor','payors','All primary payors') }

            { this.props.display != 'user' && this.selectBoxVk('provider','providers','All providers') }

            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-4">
                  <button className="btn btn-default" type="button" onClick={ this.resetFilters }>
                    Reset filters
                  </button>
                </div>
                <div className="col-sm-8 tar">
                  <button className="btn btn-primary m-r-sm" type="button" onClick={ this.triggerUpdate }>
                    Generate report
                  </button>
                  <button className="btn btn-success" type="button" onClick={ this.exportToXlsx }>
                    Export to .xlsx
                  </button>
                </div>
              </div>
            </div>

          </form>
        </div>

        { this.props.display == 'users_overview' && 
          <Provider store={store}>
            <UsersReport {...this.props} filters={this.state.filters} opts={this.props.opts} /> 
          </Provider> }

        { this.props.display == 'user' && 
          <Provider store={store}>
            <UserReport {...this.props} filters={this.state.filters} opts={this.props.opts} /> 
        </Provider> }

        { this.props.display == 'providers_overview' && 
          <Provider store={store}>
            <ProvidersReport {...this.props} filters={this.state.filters} opts={this.props.opts} /> 
          </Provider> }

        { this.props.display == 'provider_detail' && 
          <Provider store={store}>
            <ProviderReport {...this.props} filters={this.state.filters} opts={this.props.opts} /> 
        </Provider> }

        { this.props.display == 'referral_report' && 
          <Provider store={store}>
            <ReferralReport {...this.props} filters={this.state.filters} opts={this.props.opts} /> 
        </Provider> }

      </div>
    )
  }
}

