import React from 'react';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { SpinnerWave } from '../Misc/Spinners'

class UserRevokeAccessModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {show: false, submit_enabled: false, select_other_orgs: false, selected_orgs: [], initialized: false};
    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
  }

  loadSharedOrgs = (props) => {
    this.setState({initialized: false})
    $.get(`/api/users/${props.user.id}/get_shared_orgs`).then(d => {
      this.setState({initialized: true, shared_orgs: d})
    })
  }

  componentWillReceiveProps(nextprops){
    this.loadSharedOrgs(nextprops)
  }

  componentDidMount(){
    this.loadSharedOrgs(this.props)
  }

  handleChange = (event) => {
     let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value === 'true';

     this.setState({[event.target.name]: value });
  }

  handleOrgOpen = (event) => {
    this.setState({select_other_orgs: !this.state.select_other_orgs})
  }

  handleOrgChange = (event) => {
    const val = parseInt(event.target.value)
    if(this.state.selected_orgs.includes(val)){
      this.setState({selected_orgs: this.state.selected_orgs.filter(x => x != val)})
    } else {
      this.setState({selected_orgs: this.state.selected_orgs.concat([val])})
    }
  }

  selectAllOrgs = () => {
    this.setState({selected_orgs: this.state.shared_orgs.map(x => x.id)})
  }

  selectNoneOrgs = () => {
    this.setState({selected_orgs: []})
  }

  selectInvertOrgs = () => {
    this.setState({selected_orgs: this.state.shared_orgs.map(x => x.id).filter( x => !this.state.selected_orgs.includes(x) ) })
  }

  submit = () => {
    this.setState({is_submitting: true})
    var urlstr = `/api/users/${this.props.user.id}/user_organizations/${this.props.current_organization.id}`
    if(this.state.select_other_orgs && this.state.selected_orgs.length > 0){
      urlstr = urlstr + "?other_orgs=" + this.state.selected_orgs.join(',')
    }
    $.ajax(urlstr, {
      type: 'DELETE',
      success: (response) => {
        this.setState({is_submitting: false, show: false, submit_enabled: true}, () => {
          this.props.removeUser(response.user);
        });
      }
    });
  }

  render() {
    return(
      <div>
        <Button bsSize="sm" bsStyle="primary" className="btn-block" onClick={this.handleChange} name="show" value="true">Revoke Access</Button>
        <Modal bsSize="lg" show={this.state.show} animation={false}>
          { this.state.initialized ? <Modal.Body>
            <h1 className="p-h-md text-center">Are you sure you want to revoke {this.props.user.name}'s access to {this.props.current_organization.name}?</h1>
            <div className="row">
              <div className="col-sm-12">
                <div className="checkbox checkbox-success">
                  <input type="checkbox" id="revoke_form_se" name="submit_enabled" checked={this.state.submit_enabled} onChange={this.handleChange} />
                  <label htmlFor="revoke_form_se">I understand that by clicking Submit below, { this.props.user.name } will lose access to {this.props.current_organization.name}'s Aidin account.</label>
                </div>
              </div>
              { this.state.shared_orgs.length > 0 && 
                <div className="col-sm-12">
                  <div onClick={ this.handleOrgOpen }>
                    <i style={{width:'2em'}}className={'fa ' + (this.state.select_other_orgs ? 'fa-chevron-down' : 'fa-chevron-right')} />
                    <label style={{fontWeight:'normal'}}>(Optional) Revoke { this.props.user.name }&#39;s access to other organizations...</label>
                  </div>
                </div>
              }
            </div>
            { this.state.select_other_orgs && 
              <div className="well well-sm">
                <ButtonGroup>
                  <Button bsSize="sm" onClick={this.selectAllOrgs}>Select all</Button>
                  <Button bsSize="sm" onClick={this.selectNoneOrgs}>Select none</Button>
                  <Button bsSize="sm" onClick={this.selectInvertOrgs}>Invert selection</Button>
                </ButtonGroup>
                { this.state.shared_orgs.map( (org,i) => <div className="checkbox checkbox-success" key={i}>
                    <input type="checkbox" value={ org.id } id={"revoke_org_checkbox_" + i} onChange={this.handleOrgChange} checked={this.state.selected_orgs.includes(org.id)} />
                    <label htmlFor={"revoke_org_checkbox_" + i}>{org.name}</label>
                  </div>)
                }
              </div>
            }
          </Modal.Body> : '' }
          <Modal.Footer>
            { (this.state.is_submitting || !this.state.initialized) ? <SpinnerWave /> : <div>
                <Button bsSize="lg" onClick={this.handleChange} name="show" value="false">Cancel</Button>
                <Button bsSize="lg" bsStyle="danger" onClick={this.submit} disabled={!this.state.submit_enabled}>Submit</Button>
              </div>
            }
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

UserRevokeAccessModal.propTypes = {
  current_organization: React.PropTypes.object.isRequired,
  user: React.PropTypes.object.isRequired,
  removeUser: React.PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps)(UserRevokeAccessModal)
