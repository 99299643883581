import React from 'react';
class SpinnerChasingDots extends React.Component {
  render(){
    return (
      <div className="sk-chasing-dots">
        <div className="sk-child sk-dot1"></div>
        <div className="sk-child sk-dot2"></div>
      </div>
    )
  }
}
class SpinnerCircle extends React.Component {
  render(){
    return (
      <div className="sk-circle">
        <div className="sk-circle1 sk-child"></div>
        <div className="sk-circle2 sk-child"></div>
        <div className="sk-circle3 sk-child"></div>
        <div className="sk-circle4 sk-child"></div>
        <div className="sk-circle5 sk-child"></div>
        <div className="sk-circle6 sk-child"></div>
        <div className="sk-circle7 sk-child"></div>
        <div className="sk-circle8 sk-child"></div>
        <div className="sk-circle9 sk-child"></div>
        <div className="sk-circle10 sk-child"></div>
        <div className="sk-circle11 sk-child"></div>
        <div className="sk-circle12 sk-child"></div>
      </div>
    )
  }
}
class SpinnerCubeGrid extends React.Component {
  render(){
    return (
      <div className="sk-cube-grid">
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
        <div className="sk-cube sk-cube3"></div>
        <div className="sk-cube sk-cube4"></div>
        <div className="sk-cube sk-cube5"></div>
        <div className="sk-cube sk-cube6"></div>
        <div className="sk-cube sk-cube7"></div>
        <div className="sk-cube sk-cube8"></div>
        <div className="sk-cube sk-cube9"></div>
      </div>
    )
  }
}
class SpinnerDoubleBounce extends React.Component {
  render(){
    return (
      <div className="sk-spinner sk-spinner-double-bounce">
        <div className="sk-double-double1"></div>
        <div className="sk-double-double2"></div>
      </div>
    )
  }
}
class SpinnerFadingCircle extends React.Component {
  render(){
    return (
      <div className="sk-fading-circle">
        <div className="sk-circle1 sk-circle"></div>
        <div className="sk-circle2 sk-circle"></div>
        <div className="sk-circle3 sk-circle"></div>
        <div className="sk-circle4 sk-circle"></div>
        <div className="sk-circle5 sk-circle"></div>
        <div className="sk-circle6 sk-circle"></div>
        <div className="sk-circle7 sk-circle"></div>
        <div className="sk-circle8 sk-circle"></div>
        <div className="sk-circle9 sk-circle"></div>
        <div className="sk-circle10 sk-circle"></div>
        <div className="sk-circle11 sk-circle"></div>
        <div className="sk-circle12 sk-circle"></div>
      </div>
    )
  }
}
class SpinnerFoldingCube extends React.Component {
  render(){
    return (
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube"></div>
        <div className="sk-cube2 sk-cube"></div>
        <div className="sk-cube4 sk-cube"></div>
        <div className="sk-cube3 sk-cube"></div>
      </div>
    )
  }
}
class SpinnerPulse extends React.Component {
  render(){
    return (
      <div className="sk-spinner sk-spinner-pulse"></div>
    )
  }
}
class SpinnerRotatingPlane extends React.Component {
  render(){
    return (
      <div className="sk-rotating-plane"></div>
    )
  }
}
class SpinnerThreeBounce extends React.Component {
  render(){
    return (
      <div className="sk-three-bounce">
        <div className="sk-child sk-bounce1"></div>
        <div className="sk-child sk-bounce2"></div>
        <div className="sk-child sk-bounce3"></div>
      </div>
    )
  }
}
class SpinnerWanderingCubes extends React.Component {
  render(){
    return (
      <div className="sk-wandering-cubes">
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
      </div>
    )
  }
}
class SpinnerWave extends React.Component {
  render(){
    return (
      <div className="sk-spinner sk-spinner-wave">
        <div className="sk-rect1"></div>
        <div className="sk-rect2"></div>
        <div className="sk-rect3"></div>
        <div className="sk-rect4"></div>
        <div className="sk-rect5"></div>
      </div>
    )
  }
}

export { 
  SpinnerChasingDots,
  SpinnerCircle,
  SpinnerCubeGrid,
  SpinnerDoubleBounce,
  SpinnerFadingCircle,
  SpinnerFoldingCube,
  SpinnerPulse,
  SpinnerRotatingPlane,
  SpinnerThreeBounce,
  SpinnerWanderingCubes,
  SpinnerWave
}
