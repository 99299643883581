import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import update from 'immutability-helper';
import CheckboxSection from '../Forms/CheckboxSection';
import { Modal, Button } from 'react-bootstrap';

export default class InsuranceCompaniesOfferedTab extends React.Component {

  constructor(props){
    super(props);

    const hospitals = _(props.adt_insurance_companies).map('hospitals').flatten().uniqBy('name').orderBy('name').value();

    this.state = {
      insurance_accepteds_attributes: [],
      showSuccessModal: false,
      isLoading: false,
      filtersMessage: '',
      hospitals: hospitals,
      selectedHospitals: _.map(hospitals, 'id'),
      adt_insurance_companies: props.adt_insurance_companies
    };

    this.closeSuccessModal = this.closeSuccessModal.bind(this);
    this.onMultiCheckChange = this.onMultiCheckChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillMount(){
    let newData= _.extend(this.props.provider.insurance_accepteds_attributes);
    this.setState({insurance_accepteds_attributes: newData});
  }

  careTypeSelected(adt_insurance_company){
    let check = false;
    _.each(this.state.insurance_accepteds_attributes, (insurance_offered) => {
      if(adt_insurance_company.id === insurance_offered.adt_insurance_company_id && insurance_offered._destroy !== true){
        check = true;
      }
    })
    return check;
  }

  closeSuccessModal(){
    this.setState({showSuccessModal: false});
  }

  displayAlphabet(){
    const alphabet = "1#ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    return _.map(alphabet, (letter) => {
      return(
        <a key={letter} href={'#'+letter} className="anchor_index">
          {letter}
        </a>
      )
    });
  }

  displayInsuranceCompanies(insurance_companies){
    return  _.map(insurance_companies, (adt_insurance_company, i) => {
      return(
        <div key={i} className="col-md-4 m-b">
          <CheckboxSection
            id={'adt_insurance_company_id-'+adt_insurance_company.id}
            value={this.careTypeSelected(adt_insurance_company)}
            onChange={this.onMultiCheckChange}
            labelText={adt_insurance_company.name}
          />
        </div>
      )
    })
  }

  displayInsuranceCompanyLetters(){
    let insuranceCompaniesGrouped = _.chain(this.state.adt_insurance_companies).
      groupBy(adt_insurance_company => (adt_insurance_company.name || '').substr(0,1).toUpperCase()).
      value();
    let isLoading = this.state.isLoading;
    return  _.map(insuranceCompaniesGrouped, (insurance_companies, firstLetter) => {
      return(
        <div key={firstLetter} className='m-t-md m-b-md'>
          <h3 className="col-md-12">
            <a name={firstLetter} className="remove_a_styles">
              {firstLetter}
            </a>
          </h3>
          <div className="col-md-12">
            {this.displayInsuranceCompanies(insurance_companies)}
          </div>
          <div className="col-md-12 m-b">
            <Button bsStyle="primary" disabled={isLoading} onClick={!isLoading ? this.submit : null}>
              Save
            </Button>
          </div>
        </div>
      )
    })
  }

  onMultiCheckChange(e) {
    let clonedInsurancesOfferred = _.extend(this.state.insurance_accepteds_attributes);
    let target_db_id = +e.target.id.split('-')[1];
    let savedCareTypeOffered = _.find(clonedInsurancesOfferred, (insurance_offered) => {
      return (insurance_offered.id && insurance_offered.adt_insurance_company_id == target_db_id)
    });

    if (e.target.checked) {
      if (savedCareTypeOffered) {
        delete savedCareTypeOffered['_destroy']
      } else {
        let newCareTypeOffered = {};
        newCareTypeOffered.adt_insurance_company_id = target_db_id;
        newCareTypeOffered.provider_id = this.props.provider.id;
        clonedInsurancesOfferred.push(newCareTypeOffered);
      }
    } else if (!e.target.checked) {
      if (savedCareTypeOffered) {
        savedCareTypeOffered._destroy = true;
      } else {
        clonedInsurancesOfferred = _.filter(clonedInsurancesOfferred, (insurance_offered) => {
          if (insurance_offered.id || insurance_offered.adt_insurance_company_id !== target_db_id) {
            return insurance_offered
          }
        })
      }
    }
    this.setState({insurance_accepteds_attributes: clonedInsurancesOfferred});
  }

  handleHospitalChange = (event) => {
    let selectedHospitals;

    if (event.target.checked) {
      selectedHospitals = update(this.state.selectedHospitals, {$push: [+event.target.value]});
    } else {
      const index = _.indexOf(this.state.selectedHospitals, +event.target.value);
      selectedHospitals = update(this.state.selectedHospitals, {$splice: [[index, 1]]});
    }

    this.setState({selectedHospitals: selectedHospitals}, () => {
      this.setState({
        adt_insurance_companies: this.props.adt_insurance_companies.filter(c => {
          return _.intersection(_.map(c.hospitals, h => +h.id), this.state.selectedHospitals).length > 0;
        })
      });
    });
  }

  submit() {
    this.setState({isLoading: true});
    $.ajax(`/api/providers/${this.props.provider.id}`, {
      data: {provider: {insurance_accepteds_attributes: this.state.insurance_accepteds_attributes} },
      error: (response) => {
        alert('There was an error with your selection.');
        this.setState({isLoading: false});
      },
      success: (response) => {
        this.setState({insurance_accepteds_attributes: response.insurance_accepteds_attributes});
        this.setState({filtersMessage: response.filters_message});
        this.setState({showSuccessModal: true});
        this.setState({isLoading: false});
      },
      type: 'PATCH'
    });
  }

  render() {
    return(
      <div className='InsuranceCompaniesOfferedTab'>
        <div className="p-xl">
          <h2 className="m-b-xl">
            {this.props.provider.name} accepts the following insurances:
          </h2>
          <div className="col-md-12 m-b-lg">
            {
              _.map(this.state.hospitals, hospital => {
                return(
                  <div key={hospital.id} className="col-md-12 m-b">
                    <div className="checkbox checkbox-success m-b-none m-t-none m-r-sm">
                      <input
                        type="checkbox"
                        id={`hospital-${hospital.id}`}
                        value={hospital.id}
                        onChange={this.handleHospitalChange}
                        checked={this.state.selectedHospitals.includes(hospital.id)}
                      />
                      <label htmlFor={`hospital-${hospital.id}`}>{hospital.name}</label>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="col-md-12">
            {this.displayAlphabet()}
          </div>
          <div className="col-md-12 hr-line-dashed"/>
          <div className="col-md-12">
            {this.displayInsuranceCompanyLetters()}
          </div>

          <Modal show={this.state.showSuccessModal} animation={false}>
            <Modal.Body>
              <h1 className="p-h-md">
                Your insurance companies have been saved. <br/><br/> <span dangerouslySetInnerHTML={{__html: this.state.filtersMessage}} />
              </h1>
            </Modal.Body>
            <Modal.Footer>
              <Button bsSize="large" bsStyle="primary" onClick={this.closeSuccessModal}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
}

InsuranceCompaniesOfferedTab.propTypes = {
  provider: React.PropTypes.shape({
    insurance_accepteds_attributes: React.PropTypes.array.isRequired
  }),
  adt_insurance_companies: React.PropTypes.array.isRequired
};
