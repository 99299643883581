import React from 'react';
import _ from 'lodash';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { Modal, Button, FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';

export default class SupportRequest extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showModal: false,
      support_request: {support_request_type: 'Aidin3'},
      isLoading: false,
      errors: {}
    };
    this.showModal = this.showModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.setState({showModal: true});
  }

  closeModal() {
    this.setState({showModal: false});
  }

  handleChange(event) {
    let newSupportRequest = _.extend(this.state.support_request);
    newSupportRequest[event.target.name] = event.target.value;
    this.setState({support_request: newSupportRequest})
  }

  submit() {
    this.setState({isLoading: true}, () => {
      $.ajax('/api/support_requests/', {
        complete: () => {
          this.setState({isLoading: false});
        },
        data: {support_request: this.state.support_request},
        error: (response) => {
          this.setState({errors: response.responseJSON});
        },
        success: (response) => {
          this.setState({errors: {}, showModal: false, support_request: {support_request_type: 'Aidin3'}});
        },
        type: 'POST'
      });
    });
  }

  render() {
    return (
      <a href="/zendesk_session" target="_blank">
        <i className="fa fa-wrench"/>
        <span className="nav-label">Help</span>

        <Modal 
          show={this.state.showModal} 
          onHide={this.closeModal}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Send Aidin Technical Support a Message Now.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-horizontal m-t-md">
              <FormGroup controlId="support_request_name" validationState={this.state.errors.name && 'error'} className="form-group">
               <ControlLabel className="col-sm-3 control-label">Name:</ControlLabel>
                <div className="col-md-7">
                  <FormControl
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={this.handleChange}
                  />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.name && this.state.errors.name.join(', ')}</HelpBlock>
                </div>
              </FormGroup>

              <FormGroup controlId="support_request_phone" validationState={this.state.errors.phone && 'error'} className="form-group">
               <ControlLabel className="col-sm-3 control-label">Phone:</ControlLabel>
                <div className="col-md-7">
                  <MaskedFormControl 
                    type='text' 
                    name='phone'
                    mask='(111) 111-1111' 
                    className="form-control"
                    onChange={this.handleChange}
                  />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.phone && this.state.errors.phone.join(', ')}</HelpBlock>
                </div>
              </FormGroup>

              <FormGroup controlId="support_request_email" validationState={this.state.errors.email && 'error'} className="form-group">
               <ControlLabel className="col-sm-3 control-label">Email:</ControlLabel>
                <div className="col-md-7">
                  <FormControl
                    type="text"
                    className="form-control"
                    name="email"
                    onChange={this.handleChange}
                  />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.email && this.state.errors.email.join(', ')}</HelpBlock>
                </div>
              </FormGroup>

              <FormGroup controlId="support_request_referral_id" validationState={this.state.errors.referral_id && 'error'} className="form-group">
               <ControlLabel className="col-sm-3 control-label">Referral ID # (if applicable):</ControlLabel>
                <div className="col-md-7">
                  <FormControl
                    type="text"
                    className="form-control"
                    name="referral_id"
                    onChange={this.handleChange}
                  />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.referral_id && this.state.errors.referral_id.join(', ')}</HelpBlock>
                </div>
              </FormGroup>

              <FormGroup controlId="support_request_message" validationState={this.state.errors.message && 'error'} className="form-group">
                <ControlLabel className="col-sm-3 control-label">Message:</ControlLabel>
                <div className="col-md-7">
                  <FormControl 
                    componentClass="textarea" 
                    name="message"
                    className="form-control m-b-sm"
                    style={{height: 100}}
                    onChange={this.handleChange}
                  />
                  <FormControl.Feedback className="fa fa-times" />
                  <HelpBlock>{this.state.errors.message && this.state.errors.message.join(', ')}</HelpBlock>
                </div>
              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              bsSize="large"
              bsStyle="primary"
              onClick={this.submit}
            >
              {this.state.isLoading ? 'Loading...' : 'OK'}
            </Button>
          </Modal.Footer>
        </Modal>
      </a>
    )

  }

}
