import { UPDATE_CURRENT_USER } from '../actions';

export default function(state = {}, action) {
  switch(action.type) {
    case UPDATE_CURRENT_USER:
      return action.current_user;
    default:
      return state;
  }
}
