import React from 'react';
import _ from 'lodash';
import utils from '../Misc/Utils';
import moment from 'moment';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { updateCurrentUser, changeSentReferralTab } from '../../actions'
import { Alert, Modal, Button, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import CountDownClock from '../SentReferral/CountDownClock';
import Video from '../Tasks/Video';

class PreviewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showWarningModal: false,
      user: {
        first: '',
        last: '',
        preview_email: ''
      },
      errors: {}
    };
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openMessagesTab = this.openMessagesTab.bind(this);
  }

  componentDidMount() {
    this.setState({show: this.props.showPreviewModal});
  }

  closeWarningModal = () => {
    this.setState({showWarningModal: false});
  }

  submit(event, callback) {
    event.preventDefault()
    $.ajax(`/api/users/${this.props.current_user.id}`, {
      data: {
        user: this.state.user,
        new_preview_user: true,
        referral_id: this.props.referral.id,
        sent_referral_id: this.props.sent_referral.id
      },
      error: (response) => {
        this.setState({errors: response.responseJSON || {}});
      },
      success: (response) => {
        this.props.updateCurrentUser(response.user);

        if (this.props.sent_referral.rq) {
          if (typeof callback === 'function') {
            this.setState({errors: {}, show: false}, callback);
          } else {
            this.setState({errors: {}, show: false}, () => {
              this.props.changeSentReferralTab('#full-patient-record');
            });
          }
        } else {
          if (typeof callback === 'function') {
            this.setState({errors: {}, show: false, showWarningModal: true}, callback);
          } else {
            this.setState({errors: {}, show: false, showWarningModal: true});
          }
        }
      },
      type: 'PATCH'
    });
  }

  handleChange(event) {
    this.setState({
      user: update(this.state.user, {$merge: {[event.target.name]: event.target.value}})
    });
  }

  openMessagesTab(event) {
    this.submit(event, () => {
      this.props.changeSentReferralTab('#tab-messages');
    });
  }

  render() {
    return(
      <div>
        <Modal show={this.state.show} dialogClassName='modal-lg'>
          <form onChange={this.handleChange} onSubmit={(e) => this.submit(e) }>
            <Modal.Body>
              <h1>Referral #{this.props.referral.id} from {this.props.hospital.name}</h1>
              <div>
                <h3> Welcome, {this.props.provider.name} in {this.props.provider.city}, {this.props.provider.state_abbreviation}! </h3>
              </div>
              <Alert bsStyle="warning" className="m-b-xs">
                {
                  this.props.referral.test_referral ?
                    <div>
                      <p>Welcome to Aidin! Congratulations on receiving your first test referral in Aidin. In preparation for an upcoming go-live near you, we are sending you this test referral for you to practice responding to referrals in Aidin.</p>
                      <p>INSTRUCTIONS: Please enter your information below and click the green "View Referral" button. To open the referral, click the red "Respond" button. After submitting your response, create a password to finish setting up your Aidin account!</p>
                    </div>
                  : <span>The passphrase you entered can only be used for referral #{this.props.referral.id}. If you've used Aidin before and would like to view all your organization's referrals, go back to <a href="/">open.myAidin.com</a> and enter your email and password on the top-right.</span>
                }
              </Alert>
              <div className="row m-t-sm">
                <div className="col-md-3 text-center">
                  <Video src="https://www.youtube.com/embed/faKItpP9XAw?rel=0&showinfo=0&autoplay=1" />
                </div>
                <div className="col-md-9">
                  <FormGroup controlId="user_first" validationState={this.state.errors.first && 'error'} className="form-group">
                    <ControlLabel className="col-sm-3 control-label">First name:</ControlLabel>
                    <div className="col-sm-9">
                      <FormControl type="text" className="form-control" name="first" />
                      <FormControl.Feedback className="fa fa-times" />
                      <HelpBlock>{this.state.errors.first}</HelpBlock>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="user_last" validationState={this.state.errors.last && 'error'} className="form-group">
                    <ControlLabel className="col-sm-3 control-label">Last name:</ControlLabel>
                    <div className="col-sm-9">
                      <FormControl type="text" className="form-control" name="last" />
                      <FormControl.Feedback className="fa fa-times" />
                      <HelpBlock>{this.state.errors.last}</HelpBlock>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="user_email" validationState={this.state.errors.preview_email && 'error'} className="form-group">
                    <ControlLabel className="col-sm-3 control-label">Email:</ControlLabel>
                    <div className="col-sm-9">
                      <FormControl type="email" className="form-control" name="preview_email" />
                      <FormControl.Feedback className="fa fa-times" />
                      <HelpBlock>{this.state.errors.preview_email && this.state.errors.preview_email.join(', ')}</HelpBlock>
                    </div>
                  </FormGroup>
                  <div className="text-center m-l-xl">
                    <img src={utils.asset_src.aptible_hipaa_badge} style={{height: 56}} />
                    <h3>Aidin tracks who accesses patient data.</h3>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button bsSize="lg" bsStyle="primary" type="submit">View Referral</Button>
              <Button bsSize="lg" bsStyle="primary" type="button" onClick={this.openMessagesTab}>Contact {this.props.hospital.name}</Button>
            </Modal.Footer>
          </form>
        </Modal>
        <Modal show={this.state.showWarningModal} animation={false}>
          <Modal.Body>
            <h2 className="p-h-sm">3 things to know about this Aidin referral:</h2>
            <ul>
              <li><h2>There may be multiple providers reviewing this referral! You have not been selected by the patient yet. DO NOT contact the patient or family without case manager permission.</h2></li>
              <li><h2>You and the other providers on this referral have the same amount of time to respond. Pay attention to when the referral closes!</h2></li>
              <li><h2>Make sure to check dates, attachments, insurance, and additional notes before responding.</h2></li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.closeWarningModal}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    current_user: state.current_user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSentReferralTab: (key) => {
      dispatch(changeSentReferralTab(key))
    },
    updateCurrentUser: (current_user) => {
      dispatch(updateCurrentUser(current_user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModal)

PreviewModal.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  hospital: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired,
  sent_referral: React.PropTypes.object.isRequired,
  tasks: React.PropTypes.array.isRequired,
  updateCurrentUser: React.PropTypes.func.isRequired,
}
