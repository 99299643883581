import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import React from 'react';

export default class TaskButton extends React.Component {
  tooltip() {
    return(
      <Tooltip id={`tooltip-${this.props.id}`}>
        <strong>{this.tooltipContent()}</strong>
      </Tooltip>
    );
  }

  tooltipContent() {
    switch(this.props.task.task_type) {
      case 'respond':
        return 'Offer or decline care before access to patient record closes';
      case 'receive_patient':
        return 'Update patient status';
      case 'update_patient_status':
        return 'Update patient status or close this case';
    }
  }

  buttonContent() {
    switch(this.props.task.task_type) {
      case 'respond':
        return <span><i className="fa fa-clock-o"/> Respond!</span>;
      case 'receive_patient':
        return <span><i className="fa fa-thumbs-o-up"/> Receive Patient?</span>;
      case 'update_patient_status':
        return <span><i className="fa fa-folder-open-o"/> Update Required</span>;
    }
  }

  render() {
    return(
      <OverlayTrigger placement="right" overlay={this.tooltip()}>
        <Button bsStyle={!this.props.task.completed_at ? 'danger' : undefined} bsSize="large" className="dim" onClick={this.props.onClick}>
          {this.buttonContent()}
        </Button>
      </OverlayTrigger>
    );
  }
}

TaskButton.propTypes = {
  id: React.PropTypes.number.isRequired,
  task: React.PropTypes.object.isRequired,
  onClick: React.PropTypes.func
};
