import update from 'immutability-helper';
import { UPDATE_TASKS } from '../actions';

export default function(state = {}, action) {
  switch(action.type) {
    case UPDATE_TASKS:
      return update(state, {$merge: action.tasks});
    default:
      return state;
  }
}
