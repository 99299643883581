import _ from 'lodash';
import utils from '../../Misc/Utils';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { updateActivities, updateTasks, changeSentReferralTab } from '../../../actions';
import TaskButton from '../TaskButton';
import Wizard from '../../Wizard';
import WizardStep from '../../Wizard/WizardStep';
import Respond           from './Respond';
import UpdateBrochure    from './UpdateBrochure';
import ProviderOfferings from './ProviderOfferings';
import Upload            from './Upload';
import Confirmation      from './Confirmation';

class RespondTask extends React.Component {

  constructor(props) {
    super(props);
    if (props.referral.provider_type === 'pay' || props.referral.rq) {
      this.possibleSteps = ['respond', 'upload', 'confirmation'];
    } else {
      this.possibleSteps = ['respond', 'updateBrochure', 'providerOfferings', 'confirmation'];
    }
    this.state = {
      data: {
        task: {read_terms: false, capacity_terms: false, update_terms: false},
        sent_referral: {
          status: '', declined_reason: '', pick_up_at: '', will_call: 'will_call', 
          available_sunday: false, available_monday: false, available_tuesday: false, available_wednesday: false, 
          available_thursday: false, available_friday: false, available_saturday: false, available_times: ''
        },
        provider: {
          name: '',
          care_types_offered_attributes: [],
          insurance_accepteds_attributes: [],
          provider_served_counties_attributes: [],
          users_attributes: [],
          multi_access_users_attributes: [],
          reviews: [],
          attachments: []
        },
        referral: {
          care_types: [],
          counties: []
        },
        patient: {
          full_name: '',
          insurance_companies: [],
        }
      },
      currentStep: "respond",
      sending: false,
      showModal: false,
      isStepValidDisplay: true,
      showPreviewSignUpModal: false
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.closeTooLate = this.closeTooLate.bind(this);
    this.updateStep = this.updateStep.bind(this);
    this.updateData = this.updateData.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSectionValidChange = this.onSectionValidChange.bind(this)
  }

  open() {
    this.getRespondTaskData()
  }

  close() {
    this.setState({showModal: false, currentStep: 'respond'});
  }

  getRespondTaskData() {
    $.getJSON( `/api/tasks/${this.props.task.id}/respond`, (response) => {
      this.setState({data: response, showModal: true});
    });
  }

  onSectionValidChange(isStepValid, wizard_opts) {
    this.setState({isStepValidDisplay: isStepValid})
    if(wizard_opts){
      this.setState({wizard_opts: wizard_opts})
    }
  }

  updateData(path, value){
    let newData= _.extend(this.state.data);
    _.updateWith(newData, path, _.constant(value));
    this.setState({data: newData});
  }

  sendMessage(){
    this.props.changeSentReferralTab('#tab-messages')
    this.setState({too_late:false})
    this.close()
  }

  updateStep(nextStep) {
    let valid = true;
    if (this.refs.child && this.refs.child.validateAll) {
      valid = this.refs.child.validateAll();
    }

    if (valid) {
      // "Unavailable"
      if (this.state.data.sent_referral.status =='unavailable') {
        // ['Wrong care needs', 'Insurance mismatch','Service area'] - Skip Step 2
        if(  _.includes(['declined_care_needs','declined_insurance','service_area'], this.state.data.sent_referral.declined_reason) ) {
          // currentStep == 1, nextStep == 2
          if (this.state.currentStep === 'respond' && nextStep === 'updateBrochure') {
            // Go to 3.
            this.setState({ currentStep: 'providerOfferings' });
          // currentStep == 3, nexyStep == 2
          } else if (this.state.currentStep === 'providerOfferings' && nextStep === 'updateBrochure') {
            // Go to 1
            this.setState({ currentStep: 'respond' })
          } else {
            this.setState({ currentStep: nextStep})
          }
        // ['No bed', 'Staff shortage', 'Other'] - Skip Step 2 and 3
        } else {
          // currentStep == 1, nextStep == 2
          if (this.state.currentStep === 'respond' && nextStep === 'updateBrochure') {
            // Go to 4.
            this.setState({ currentStep: 'confirmation' })
          // currentStep == 4, nextStep == 3
          } else if (this.state.currentStep === 'confirmation' && nextStep === 'providerOfferings') {
            // Go to 1
            this.setState({ currentStep: 'respond' })
          } else {
            this.setState({ currentStep: nextStep })
          }
        }
      } else {
        // Perform normally.
        this.setState({ currentStep: nextStep })
      }
    }
  }

  handleSubmit() {
    $.ajax({
      beforeSend: () => {
        if (this.state.sending) {
          return false;
        } else {
          this.setState({sending: true});
        }
      },
      complete: () => {
        this.setState({sending: false});
      },
      url: `/api/tasks/${this.props.task.id}/update_response`,
      type: 'PATCH',
      data: this.state.data,
      success: (data) => {
        this.props.updateActivities(data.activity);
        this.props.updateTasks(data.task);
        this.close();
        if (data.filters_message) {
          this.props.showFiltersModal(data.filters_message);
        }
        if (this.props.current_user.previewer) {
          if (this.props.current_user.previewer_signed_up) {
            this.props.showSignUpModal('previewLogIn', true);
          } else {
            this.props.showSignUpModal('previewSignUp', true);
          }
        }
      },
      error: (data) => {
        if(data.responseJSON && data.responseJSON.error == 'too_late'){
          this.setState({too_late: true})
        } else {
          alert("Sorry, there was an error, and we couldn't save your response.")
        }
      }
    });
  }
  closeTooLate(){
    this.setState({too_late:false})
    this.close()
    utils.window_reload()
  }

  getCurrentStep() {
    let innerForm;
    switch(this.state.currentStep) {
      case "respond":
        innerForm = <Respond
          ref="child"
          data={this.state.data}
          onInputChange={this.updateData}
          onSectionValidChange={this.onSectionValidChange}
          current_user={this.props.current_user}
        />;
        break;
      case "updateBrochure":
        innerForm = <UpdateBrochure
          current_user={this.props.current_user}
          provider={this.state.data.provider}
        />;
        break;
      case "providerOfferings":
        innerForm = <ProviderOfferings
          current_user={this.props.current_user}
          data={this.state.data}
          onInputChange={this.updateData}
        />;
        break;
      case "upload":
        innerForm = <Upload
          current_user={this.props.current_user}
          data={this.state.data}
          hospital={this.props.hospital}
          onInputChange={this.updateData}
        />;
        break;
      case "confirmation":
        innerForm = <Confirmation
          current_user={this.props.current_user}
          data={this.state.data}
          onInputChange={this.updateData}
        />
        break;
    }

    return (
      <WizardStep header="Click Play for Help">
        {innerForm}
      </WizardStep>
    )
  }

  render() {
    return (
      <span className="respondTask">
        <TaskButton
          id={this.props.task.id}
          task={this.props.task}
          onClick={this.open}
        />
        <Modal show={this.state.showModal} onHide={this.close} bsSize="large">
          <Modal.Header closeButton>
            <Modal.Title>Respond to {this.state.data.patient.full_name} (Referral #{this.state.data.referral.id})</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Wizard
              possibleSteps = {this.possibleSteps}
              currentStep   = {this.state.currentStep}
              updateStep    = {this.updateStep}
              handleSubmit  = {this.handleSubmit}
              isStepValidDisplay = {this.state.isStepValidDisplay}
              wizard_opts = {this.state.wizard_opts}
            >
              {this.getCurrentStep()}
            </Wizard>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.too_late} bsSize="lg" onHide={this.closeTooLate}>
          <Modal.Header>
            <Modal.Title>Sorry - that referral has closed.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Sorry, that referral has closed or been reserved already.</p>
            <div>
              <button className="btn btn-primary m-r-sm" onClick={this.closeTooLate}>Dismiss</button>
              <button className="btn btn-primary" onClick={this.sendMessage}>Contact {this.props.hospital.name}</button>
            </div>
          </Modal.Body>
        </Modal>
      </span>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    current_user: state.current_user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivities: (activities) => {
      dispatch(updateActivities(activities))
    },
    changeSentReferralTab: (tab_key) => {
      dispatch(changeSentReferralTab(tab_key))
    },
    updateTasks: (tasks) => {
      dispatch(updateTasks(tasks))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RespondTask)

RespondTask.propTypes = {
  current_user: React.PropTypes.object.isRequired,
  task: React.PropTypes.object.isRequired,
  updateActivities: React.PropTypes.func.isRequired,
  updateTasks: React.PropTypes.func.isRequired,
  changeSentReferralTab: React.PropTypes.func.isRequired,
  showSignUpModal: React.PropTypes.func.isRequired,
  showFiltersModal: React.PropTypes.func.isRequired
};
