import React from 'react';
import SupportRequest from './SupportRequest';
import SidebarContent from './SidebarContent';
import { Provider } from 'react-redux';
 
export default class Sidebar extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <SidebarContent {...this.props} />
      </Provider>
    )
  }
}
