import React from 'react';
import utils from '../../Misc/Utils';
import _ from 'lodash';
import moment from 'moment';
import { FormControl, FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import DatePicker from 'react-bootstrap-date-picker';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import CheckboxSection from '../../Forms/CheckboxSection';
import Video from '../Video';

export default class UpdatePatientStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {organizations: []};
  }

  providerActualDischargeDateValue() {
    if (this.props.patient_status_update.referral_changes.provider_actual_discharge_date) {
      return new Date(this.props.patient_status_update.referral_changes.provider_actual_discharge_date).toISOString();
    }
  }

  providerActualReceivedDateValue() {
    if (this.props.patient_status_update.referral_changes.provider_actual_received_date) {
      return new Date(this.props.patient_status_update.referral_changes.provider_actual_received_date).toISOString();
    }
  }

  providerAnticipatedDischargeDateValue() {
    if (this.props.patient_status_update.referral_changes.provider_anticipated_discharge_date) {
      return new Date(this.props.patient_status_update.referral_changes.provider_anticipated_discharge_date).toISOString();
    }
  }

  handleSearch = (text) => {
    $.ajax({
      data: {search: text},
      success: response => this.setState({organizations: response.organizations}),
      type: 'GET',
      url: '/api/organizations/search'
    });
  }

  render() {
    return(
      <fieldset className="body current">
        <div className="row">
          <div className="col-lg-5">
            <div className="text-center">
              <div className="m-t-md">
                <Video src="https://www.youtube.com/embed/-A7S1oMu9_o?rel=0&showinfo=0" />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <h2>Please update {this.props.patient.full_name}'s status with {this.props.provider.name}:</h2>
            <form className="form-horizontal m-t-md">
              <FormGroup controlId="patient_status_update_status" className="form-group">
                <div className="col-sm-12">
                  <FormControl
                    componentClass="select"
                    placeholder=""
                    name="status"
                    onChange={this.props.handleStatusChange}
                    value={this.props.patient_status_update.status}
                  >
                    <option value="">Select one</option>
                    <option value="discharged">Discharged</option>
                    <option value="still_receiving_services">Still receiving services from {this.props.provider.name}</option>
                  </FormControl>
                </div>
              </FormGroup>
              {
                this.props.patient_status_update.status === 'discharged' &&
                <div>
                  <FormGroup controlId="patient_status_update_referral_changes_provider_actual_received_date" className="form-group">
                    <ControlLabel className="col-sm-7 control-label">Date Services Began:</ControlLabel>
                    <div className="col-sm-5">
                      <DatePicker
                        dateFormat="YYYY-MM-DD"
                        name="provider_actual_received_date"
                        value={this.providerActualReceivedDateValue()}
                        onChange={this.props.handleProviderActualReceivedDateChange}
                        onFocus={this.props.handleProviderActualReceivedDateFocus}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup controlId="patient_status_update_referral_changes_provider_actual_discharge_date" validationState={this.props.errors.provider_actual_discharge_date && 'error'} className="form-group">
                    <ControlLabel className="col-sm-7 control-label">Discharge Date:</ControlLabel>
                    <div className="col-sm-5">
                      <DatePicker
                        dateFormat="YYYY-MM-DD"
                        name="provider_actual_discharge_date"
                        value={this.providerActualDischargeDateValue()}
                        onChange={this.props.handleProviderActualDischargeDateChange}
                        onFocus={this.props.handleProviderActualDischargeDateFocus}
                      />
                      <HelpBlock>{this.props.errors.provider_actual_discharge_date}</HelpBlock>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="patient_status_update_referral_changes_discharge_disposition" className="form-group">
                    <ControlLabel className="col-sm-5 control-label">Discharge Disposition:</ControlLabel>
                    <div className="col-sm-7">
                      <FormControl
                        componentClass="select"
                        placeholder=""
                        name="status"
                        onChange={this.props.handleDischargeDispositionChange}
                        value={this.props.patient_status_update.referral_changes.discharge_disposition}
                      >
                        <option value=""></option>
                        {
                          utils.dischargeDispositions.map((dischargeDisposition) => {
                            return <option key={dischargeDisposition[0]} value={dischargeDisposition[0]}>{dischargeDisposition[1]}</option>;
                          })
                        }
                      </FormControl>
                    </div>
                  </FormGroup>
                  {
                    ['discharged_home_with_services', 'discharged_hospital', 'discharged_hha', 'discharged_snf', 'discharged_irf', 'discharged_ltac', 'discharged_unknown'].indexOf(this.props.patient_status_update.referral_changes.discharge_disposition) > -1 &&
                    <div onKeyUp={this.props.handleOrganizationDischargedToNameChange}>
                      <ControlLabel className="control-label">What organization did you discharge {this.props.patient.full_name} to?</ControlLabel>
                      <FormGroup controlId="patient_status_update_referral_changes_discharged_to_name" className="form-group">
                        <AsyncTypeahead
                          emptyLabel=""
                          labelKey={organization => `${organization.name} - ${organization.city}, ${organization.state_abbreviation}`}
                          minLength={4}
                          onChange={this.props.handleOrganizationDischargedToChange}
                          onSearch={this.handleSearch}
                          options={this.state.organizations}
                        />
                      </FormGroup>
                    </div>
                  }
                </div>
              }
              {
                this.props.patient_status_update.status === 'still_receiving_services' &&
                <div>
                  <FormGroup controlId="patient_status_update_referral_changes_provider_actual_received_date" className="form-group">
                    <ControlLabel className="col-sm-7 control-label">Date Services Began:</ControlLabel>
                    <div className="col-sm-5">
                      <DatePicker
                        dateFormat="YYYY-MM-DD"
                        name="provider_actual_received_date"
                        value={this.providerActualReceivedDateValue()}
                        onChange={this.props.handleProviderActualReceivedDateChange}
                        onFocus={this.props.handleProviderActualReceivedDateFocus}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup controlId="patient_status_update_referral_changes_provider_anticipated_discharge_date" validationState={this.props.errors.provider_anticipated_discharge_date && 'error'} className="form-group">
                    <ControlLabel className="col-sm-7 control-label">Anticipated Discharge Date:</ControlLabel>
                    <div className="col-sm-5">
                      <DatePicker
                        dateFormat="YYYY-MM-DD"
                        name="provider_anticipated_discharge_date"
                        disabled={this.props.patient_status_update.referral_changes.indefinite_service}
                        value={this.providerAnticipatedDischargeDateValue()}
                        onChange={this.props.handleProviderAnticipatedDischargeDateChange}
                        onFocus={this.props.handleProviderAnticipatedDischargeDateFocus}
                      />
                      <HelpBlock>{this.props.errors.provider_anticipated_discharge_date}</HelpBlock>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="patient_status_update_referral_changes_indefinite_service" className="form-group">
                    <ControlLabel className="col-sm-7 control-label">Indefinite service:</ControlLabel>
                    <div className="col-sm-5">
                      <CheckboxSection
                        id='patient_status_update_referral_changes_indefinite_service'
                        onChange={this.props.handleIndefiniteServiceChange}
                        value={this.props.patient_status_update.referral_changes.indefinite_service}
                      />
                    </div>
                  </FormGroup>
                  {
                    _.isEmpty(this.props.errors) &&
                    this.props.patient_status_update.referral_changes.provider_anticipated_discharge_date &&
                    <span>You will be prompted to update this patient's status again on:<br />{moment(this.props.patient_status_update.referral_changes.provider_anticipated_discharge_date).format('MMMM D, YYYY')}</span>
                  }
                </div>
              }
            </form>
          </div>
        </div>
      </fieldset>
    );
  }
}

UpdatePatientStatus.propTypes = {
  errors: React.PropTypes.object.isRequired,
  handleDischargeDispositionChange: React.PropTypes.func.isRequired,
  handleIndefiniteServiceChange: React.PropTypes.func.isRequired,
  handleOrganizationDischargedToChange: React.PropTypes.func.isRequired,
  handleOrganizationDischargedToNameChange: React.PropTypes.func.isRequired,
  handleProviderActualDischargeDateChange: React.PropTypes.func.isRequired,
  handleProviderActualDischargeDateFocus: React.PropTypes.func.isRequired,
  handleProviderActualReceivedDateChange: React.PropTypes.func.isRequired,
  handleProviderActualReceivedDateFocus: React.PropTypes.func.isRequired,
  handleProviderAnticipatedDischargeDateChange: React.PropTypes.func.isRequired,
  handleProviderAnticipatedDischargeDateFocus: React.PropTypes.func.isRequired,
  handleStatusChange: React.PropTypes.func.isRequired,
  patient: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired
}
