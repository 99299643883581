import React from 'react';
import utils from '../Misc/Utils';
import inflection from 'inflection';
import { Provider } from 'react-redux';
import AllSentReferrals from './AllSentReferrals';
import SentReferralRow from './SentReferralRow';
import { Button } from 'react-bootstrap';

export default class SentReferrals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent_referrals: [],
      meta: {current_page: 0, total_pages: 0},
      search: '',
      searchTerms: '',
      recommendedSearch: '',
      showRecommendedSearch: false
    };
    this.getSentReferrals = this.getSentReferrals.bind(this);
    this.handleSearchFocus = this.handleSearchFocus.bind(this);
    this.handleSearchBlur = this.handleSearchBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    let recommendedSearchParam = typeof window != undefined ? window.location.search.match(/recommended_search=(.*)$/) : '';
    if (recommendedSearchParam) {
      this.setState({recommendedSearch: recommendedSearchParam[1]}, this.getSentReferrals);
    } else {
      this.getSentReferrals();
    }
  }

  getSentReferrals(page) {
    this.setState({loading_search_results: true, sent_referrals: [], meta: {current_page: 0, total_pages: 0}});
    $.ajax({
      data: {
        search: this.state.search || undefined,
        recommended_search: this.state.recommendedSearch || undefined,
        page: page
      },
      statusCode: {
        302: (response) => {
          if(response && typeof response.responseJSON != 'undefined'){
            utils.set_window_location(`/sent_referrals/${response.responseJSON.id}`)
          } else if (typeof response.data != 'undefined') {
            utils.set_window_location(`/sent_referrals/${response.data.id}`)
          }
        }
      },
      success: (response) => {
        this.setState({
          loading_search_results: false,
          sent_referrals: response.sent_referrals,
          meta: response.meta,
          searchTerms: (() => {
            if (this.state.search) {
              return `Results for "${this.state.search}"`;
            } else if (this.state.recommendedSearch.length) {
              return inflection.humanize(this.state.recommendedSearch);
            } else {
              return '';
            }
          })()
        });
      },
      type: 'GET',
      url: '/api/sent_referrals'
    });
  }

  clickHandler = event => {
      var click = $(event.target).closest(".sr_search_wrapper")
      if(click.length == 0){
        this.setState({showRecommendedSearch: false});
        $(document).off('click.search_suggestions')
      }
  }

  handleSearchFocus() {
    if (this.state.search.length) {
      this.setState({showRecommendedSearch: true});
    }
    $(document).off('click.search_suggestions')
    $(document).on('click.search_suggestions', this.clickHandler)
  }

  handleSearchBlur(event) {
  }

  handleChange(event) {
    this.setState({search: event.target.value, showRecommendedSearch: !!event.target.value.length});
    this.loadRecommendedSearch(event.target.value)
  }

  loadRecommendedSearch = str => {
    this.setState({lastSearch: str, suggestionsLoading: true})
    $.get("/api/sent_referrals/search", {search: str}).then(resp => {
      if(this.state.lastSearch == str){
        this.setState({suggestedResults: resp.sent_referrals, suggestionsLoading: false })
      }
    })
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.setState({recommendedSearch: '', showRecommendedSearch: false}, this.getSentReferrals);
    }
  }

  handleClick() {
    this.setState({recommendedSearch: ''}, this.getSentReferrals);
  }

  handleRecommendedSearchClick = (recommendedSearch, btnText, event) => {
    event.preventDefault();
    event.stopPropagation();

    typeof ga != 'undefined' && ga('send', 'event', 'Dashboard', 'Navigation Button', btnText);

    this.setState({recommendedSearch: recommendedSearch, search: ''}, () => {
      this.getSentReferrals();
      this.setState({showRecommendedSearch: false});
    });
  }

  refresh() {
    this.setState({search: '', recommendedSearch: ''}, this.getSentReferrals);
  }

  recommendedSearchBtn = (bsStyle, faclass, text, search_name ) => {
    return(<Button style={{textAlign:'left'}} onClick={ (event) => {this.handleRecommendedSearchClick(search_name, text, event)} } bsSize="lg" bsStyle={bsStyle}>
      <i style={{fontSize:'1.3em', verticalAlign:'middle'}} className={ "fa " + faclass } /> {text}
    </Button>)
  }

  render() {
    return(
      <div className="ibox SentReferrals">
        <div className="ibox-title">
          <h2><strong>{this.state.searchTerms || 'Referral Search'}</strong></h2>
        </div>
        <div className="ibox-content">
          <div className="sr_search_btns m-b-sm" style={{clear:'both'}}>
            { this.recommendedSearchBtn("primary", "fa-exclamation-triangle","Missed and cancelled referrals", 'missed_and_cancelled_referrals') }
            { this.recommendedSearchBtn("primary", "fa-check-square", "Responded available", 'responded_available') }
            { this.recommendedSearchBtn("primary", "fa-window-close-o", "Responded unavailable", 'responded_unavailable') }
            { this.recommendedSearchBtn("primary", "fa-users", "Patients in my care", 'patients_in_my_care') }
            { this.recommendedSearchBtn("primary", "fa-comments", "My unread messages",'my_unread_messages') }
            { this.recommendedSearchBtn("primary", "fa-comment", "Messages awaiting reply", 'my_sent_messages_still_awaiting_reply') }
          </div>
          <div className="input-group sr_search_wrapper">
            <span className="input-group-btn">
              <button onClick={this.refresh} type="button" className="btn btn-white btn-lg">
                <i className={`fa ${this.state.searchTerms.length || this.state.recommendedSearch ? 'fa-arrow-circle-left' : 'fa-refresh'}`} />
                {this.state.searchTerms.length || this.state.recommendedSearch ? ' Back' : ' Refresh'}
              </button>
            </span>

            <input
              value={this.state.search}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              onClick={this.handleSearchFocus}
              onFocus={this.handleSearchFocus}
              onBlur={this.handleSearchBlur}
              type="text"
              placeholder="Search by patient name or Referral ID"
              className="form-control input-lg placeholder-highlight"
            />
            <div className="dropdown-menu suggestionmenu" role="listbox" style={{ display: this.state.showRecommendedSearch ? 'block' : 'none', left: this.state.searchTerms.length || this.state.recommendedSearch ? 94 : 119}}>
              <table style={{width:'100%'}}>
                <tbody>
                  { !this.state.suggestionsLoading && _.map(this.state.suggestedResults, (sr,i) => { return(<SentReferralRow key={i} mini={true} sent_referral={sr} messageGroups={{}} taskGroups={{}}></SentReferralRow>)})}
                  { !this.state.suggestionsLoading && this.state.suggestedResults && this.state.suggestedResults.length == 0 && <tr><td><h3 className="m-sm">No results</h3></td></tr> }
                  { this.state.suggestionsLoading && 
                      <tr><td><div className="sk-spinner sk-spinner-wave" style={{margin:'0 auto'}}>                                                                       
                        <div className="sk-rect1"></div>
                        <div className="sk-rect2"></div>
                        <div className="sk-rect3"></div>
                        <div className="sk-rect4"></div>
                        <div className="sk-rect5"></div>
                      </div></td></tr>}
                </tbody>
              </table>
            </div>
            <span className="input-group-btn">
              <button onClick={this.handleClick} type="button" className="btn btn-lg btn-primary"> Go!</button>
            </span>
          </div>
          <hr />
          <Provider store={store}>
            <AllSentReferrals
              sent_referrals={this.state.sent_referrals}
              meta={this.state.meta}
              getSentReferrals={this.getSentReferrals}
              search={this.state.search}
              recommendedSearch={this.state.recommendedSearch}
              handleRecommendedSearchClick={this.handleRecommendedSearchClick}
              loading_search_results={this.state.loading_search_results}
            />
          </Provider>
        </div>
      </div>
    )
  }
}

SentReferrals.propTypes = {
}
