import React from 'react';
import utils from '../Misc/Utils';
import _ from 'lodash';
import { Tabs, Tab, Row, Nav, NavItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateMessages, changeSentReferralTab } from '../../actions'
import Activities from '../Activities';
import Messages   from '../Messages';
import InsuranceTab from './InsuranceTab';
import Facesheet from './Facesheet';
import FullPatientRecord  from './FullPatientRecord';
import PhysicianCertificationStatement from './PhysicianCertificationStatement';
import RestrictedContent from './RestrictedContent';
import PreviewModal from '../PreviewModal';

class SentReferralTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {tab_key: this.getTabKey(props)}
    this.handleSelect = this.handleSelect.bind(this);
    this.markAsUnread = this.markAsUnread.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.messages = this.messages.bind(this);
    this.setReadMessages = this.setReadMessages.bind(this);
  }

  validateTabKey(tab_key){
    return ['#default',"#tab-messages","#patient-demographcs","#full-patient-record","#insurance","#physician-cert"].includes(tab_key)
  }

  getTabKey(props){
    if (typeof window != 'undefined' && this.validateTabKey(window.location.hash)) {
      return window.location.hash;
    } else if (props.tab_key) {
      return props.tab_key;
    } else {
      return '#default';
    }
  }

  handleSelect(tab_key) {
    this.setState({tab_key});
  }

  markAsUnread(id) {
    $.ajax(`/api/messages/${id}`, {
      data: {message: {read_flag: false}},
      success: (response) => {
        this.props.updateMessages(response.message);
      },
      type: 'PATCH'
    });
  }

  messages() {
    return _.chain(this.props.messages).
      filter(message => message.referral_id === this.props.sent_referral.referral_id).
      orderBy(message => message.created_at).
      value();
  }

  setReadMessages() {
    let unreadMessages = this.messages().filter((message) => {
      return !message.sent_by_current_organization && !message.read_flag;
    });

    if (unreadMessages.length > 0) {
      $.ajax('/api/messages', {
        data: {
          ids: unreadMessages.map((message) => { return message.id; }),
          message: {read_flag: true}
        },
        success: (response) => {
          this.props.updateMessages(response.messages);
        },
        type: 'PATCH'
      });
    }
  }

  tabTitleMessages(){
    let unreadMessagesCount = this.messages().filter((message) => {
      return !message.sent_by_current_organization && !message.read_flag;
    }).length;
    let unreadMessagesLabel;

    if (unreadMessagesCount > 0) {
      unreadMessagesLabel =
        <span className="label label-danger">
          {unreadMessagesCount}
        </span>
      ;
    }
    return(
      <span>
        <img alt="image"
          className="img-circle"
          src={this.props.hospital.avatar_url}
          style={{opacity: this.state.tab_key === '#tab-messages' ? '1' : '0.5', maxHeight:'1em'}}
        /> Messages
        {unreadMessagesLabel}
      </span>
    )
  }

  componentWillReceiveProps(nextProps){
    if(this.props.tab_key != nextProps.tab_key){
      this.handleSelect(nextProps.tab_key)
    }
  }

  changeTab = (tabKey) => {
    if (typeof ga != 'undefined') {
      const tabNames = {
        '#default': 'Last Activity',
        '#tab-messages': 'Messages',
        '#patient-demographcs': 'Facesheet',
        '#full-patient-record': 'Full Patient Record',
        '#insurance': 'Insurance'
      }
      ga('send', 'event', 'Referrals', 'Tab', tabNames[tabKey] || tabKey);
    }

    this.props.changeSentReferralTab(tabKey);
  }

  render() {
    return(
      <div className="p-w-sm SentReferralTabs">
        <Tabs activeKey={this.state.tab_key} onSelect={this.changeTab} id='sent_referrals_tabs'>
          <Tab eventKey={'#default'} title={<span><i className="fa fa-tachometer"></i>Last activity</span>}>
            <Activities
              activities={this.props.activities}
            />
          </Tab>
          <Tab eventKey={'#tab-messages'} title={this.tabTitleMessages()}>
            <Messages
              messages={this.messages()}
              sender={this.props.provider}
              receiver={this.props.hospital}
              referral_id={this.props.referral.id}
              patient_id={this.props.referral.patient_id}
              updateMessages={this.props.updateMessages}
              isActive={this.state.tab_key === '#tab-messages'}
              markAsUnread={this.markAsUnread}
              setReadMessages={this.setReadMessages}
            />
          </Tab>
          <Tab eventKey={'#patient-demographcs'} title={<span><i className="fa fa-info-circle"></i>Facesheet</span>}>
            {!this.props.restrictions ? (
              <Facesheet
                patient={this.props.patient}
                referral={this.props.referral}
                sent_referral={this.props.sent_referral}
                provider={this.props.provider}
                provider_delivered_to={this.props.provider_delivered_to}
                hospital={this.props.hospital}
              />
            ) : (
              <RestrictedContent
                referral={this.props.referral}
                hospital={this.props.hospital}
                restrictions={this.props.restrictions}
                openTab={this.handleSelect}
              />
            )}
          </Tab>
          <Tab eventKey={'#full-patient-record'} title={<span><i className="fa fa-plus-square"></i>Full Patient Record</span>}>
            {!this.props.restrictions ? (
              <FullPatientRecord
                patient={this.props.patient}
                referral={this.props.referral}
                sent_referral={this.props.sent_referral}
                showPrintModal={this.props.showPrintModal}
              />
            ) : (
              <RestrictedContent
                referral={this.props.referral}
                hospital={this.props.hospital}
                restrictions={this.props.restrictions}
                openTab={this.handleSelect}
              />
            )}
          </Tab>
          <Tab eventKey={'#insurance'} title={<span><i className="fa fa-umbrella"></i>Insurance</span>}>
            {!this.props.restrictions ? (
              <InsuranceTab
                patient={this.props.patient}
                provider={this.props.provider}
                referral={this.props.referral}
                sent_referral={this.props.sent_referral}
              />
            ) : (
              <RestrictedContent
                referral={this.props.referral}
                hospital={this.props.hospital}
                restrictions={this.props.restrictions}
                openTab={this.handleSelect}
              />
            )}
          </Tab>
          {this.props.referral.provider_type === 'trns' &&
          <Tab eventKey={'#physician-cert'} title={<span><i className="fa fa-paperclip"></i>Physician Certification Statement</span>}>
            {!this.props.restrictions ? (
              <PhysicianCertificationStatement
                referral={this.props.referral}
              />
            ) : (
              <RestrictedContent
                referral={this.props.referral}
                hospital={this.props.hospital}
                restrictions={this.props.restrictions}
                openTab={this.handleSelect}
              />
            )}
          </Tab>
          }
        </Tabs>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activities: state.activities,
    messages: state.messages,
    tab_key: state.sent_referral_tab
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSentReferralTab: (tab_key) => {
      dispatch(changeSentReferralTab(tab_key))
    },
    updateMessages: (messages) => {
      dispatch(updateMessages(messages))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SentReferralTabs)

SentReferralTabs.propTypes = {
  activities: React.PropTypes.object.isRequired,
  sent_referral: React.PropTypes.object.isRequired,
  hospital: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired,
  messages: React.PropTypes.object.isRequired,
  changeSentReferralTab: React.PropTypes.func.isRequired,
  // key: React.PropTypes.array.isRequired,
  tasks: React.PropTypes.array.isRequired,
  updateMessages: React.PropTypes.func.isRequired,
  restrictions: React.PropTypes.bool.isRequired,
  showPrintModal: React.PropTypes.func.isRequired
}
