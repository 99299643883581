import _ from 'lodash';
import utils from '../Misc/Utils';
import React from 'react';
import moment from 'moment';
import {Button, FormGroup, ControlLabel, FormControl, HelpBlock, Modal} from 'react-bootstrap';
import BrowserSupportWrapper from '../BrowserSupportWrapper';
import MaskedFormControl from 'react-bootstrap-maskedinput'
import OrganizationAvatar from '../OrganizationAvatar';

export default class TheBasicsTab extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showSuccessModal: false,
      isLoading: false,
      errors: {}
    };
    this.submit = this.submit.bind(this);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
  }

  closeSuccessModal() {
    this.setState({showSuccessModal: false});
  }

  displayUsStateOptions(){
    return _.map(utils.usStatesOptions, (stateArr) => {
      return(
        <option key={stateArr[0]} value={stateArr[0]}>
          {stateArr[1]}
        </option>
      )
    })
  }

  submit() {
    this.setState({isLoading: true});
    $.ajax(`/api/providers/${this.props.provider.id}`, {
      data: {provider: _.omit(this.props.provider, ['reviews', 'attachments'])},
      error: (response) => {
        this.setState({isLoading: false, errors: response.responseJSON});
      },
      success: (response) => {
        this.setState({showSuccessModal: true, isLoading: false, errors: {}}, () => {
          this.props.updateProvider(response.provider)
        });
      },
      type: 'PATCH'
    });
  }

  render() {
    let isLoading = this.state.isLoading;
    return(
      <div className='TheBasicsTab'>
        <div className="p-xl">
          <h2 className="m-b">
            The Basics
          </h2>
          <h3 className="m-b">
            Make sure your organization name, address and contact information appear just right.
          </h3>

          <form className="form-horizontal m-t-md">
            <FormGroup controlId="provider_name" validationState={this.state.errors.name && 'error'} className="form-group">
             <ControlLabel className="col-sm-3 control-label">Name:</ControlLabel>
              <div className="col-md-7">
                <FormControl
                  type="text"
                  className="form-control"
                  name="name"
                  defaultValue={this.props.provider.name}
                  onChange={this.props.handleChange}
                />
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.name && this.state.errors.name.join(', ')}</HelpBlock>
              </div>
            </FormGroup>

            <FormGroup controlId="provider_address" validationState={this.state.errors.address && 'error'} className="form-group">
              <ControlLabel className="col-sm-3 control-label">Address:</ControlLabel>
              <div className="col-md-7">
                <FormControl
                  type="text"
                  className="form-control"
                  name="address"
                  value={this.props.provider.address}
                  onChange={this.props.handleChange}
                />
               <FormControl.Feedback className="fa fa-times" />
               <HelpBlock>{this.state.errors.address && this.state.errors.address.join(', ')}</HelpBlock>
              </div>
            </FormGroup>

            <FormGroup controlId="provider_city" validationState={this.state.errors.city && 'error'} className="form-group">
              <ControlLabel className="col-sm-3 control-label">City:</ControlLabel>
              <div className="col-md-7">
                <FormControl
                  type="text"
                  className="form-control"
                  name="city"
                  defaultValue={this.props.provider.city}
                  onChange={this.props.handleChange}
                />
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.city && this.state.errors.city.join(', ')}</HelpBlock>
              </div>
            </FormGroup>

            <FormGroup controlId="provider_state_id" validationState={this.state.errors.state_id && 'error'} className="form-group">
              <ControlLabel className="col-sm-3 control-label">State:</ControlLabel>
              <div className="col-md-7">
                <FormControl 
                  componentClass="select" 
                  onChange={this.handleUsStateChange} 
                  className="form-control"
                  defaultValue={this.props.provider.state_id}
                  name="state_id"
                >
                  {this.displayUsStateOptions()}
                </FormControl>
                <FormControl.Feedback className="fa fa-times" />

                <HelpBlock>{this.state.errors.state_id && this.state.errors.state_id.join(', ')}</HelpBlock>
              </div>
            </FormGroup>

            <FormGroup controlId="provider_zip" validationState={this.state.errors.zip && 'error'} className="form-group">
              <ControlLabel className="col-sm-3 control-label">Zip:</ControlLabel>
              <div className="col-md-7">
                <FormControl
                  type="text"
                  className="form-control"
                  name="zip"
                  defaultValue={this.props.provider.zip}
                  onChange={this.props.handleChange}
                />
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.zip && this.state.errors.zip.join(', ')}</HelpBlock>
              </div>
            </FormGroup>

            <FormGroup controlId="provider_phone" validationState={this.state.errors.phone && 'error'} className="form-group">
              <ControlLabel className="col-sm-3 control-label">Phone:</ControlLabel>
              <div className="col-md-7">
                <MaskedFormControl 
                  type='text' 
                  name='phone'
                  mask='(111) 111-1111' 
                  className="form-control"
                  value={this.props.provider.phone}
                  onChange={this.props.handleChange}
                />
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.phone && this.state.errors.phone.join(', ')}</HelpBlock>
              </div>
            </FormGroup>

            <FormGroup controlId="provider_fax" validationState={this.state.errors.fax && 'error'} className="form-group">
              <ControlLabel className="col-sm-3 control-label">Fax:</ControlLabel>
              <div className="col-md-7">
                <MaskedFormControl 
                  type='text' 
                  name='fax'
                  mask='(111) 111-1111' 
                  className="form-control"
                  value={this.props.provider.fax}
                  onChange={this.props.handleChange}
                />
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.fax && this.state.errors.fax.join(', ')}</HelpBlock>
              </div>
            </FormGroup>

            <FormGroup controlId="provider_website" validationState={this.state.errors.website && 'error'} className="form-group">
              <ControlLabel className="col-sm-3 control-label">Website:</ControlLabel>
              <div className="col-md-7">
                <FormControl
                  type="text"
                  className="form-control"
                  name="website"
                  defaultValue={this.props.provider.website}
                  onChange={this.props.handleChange}
                />
                <FormControl.Feedback className="fa fa-times" />
                <HelpBlock>{this.state.errors.website && this.state.errors.website.join(', ')}</HelpBlock>
              </div>
            </FormGroup>

            <FormGroup className="form-group">
              <div className="col-md-offset-3 col-md-3">
                <Button bsSize="lg" bsStyle="primary" disabled={isLoading} onClick={!isLoading ? this.submit : null}>
                  Save
                </Button>
              </div>
            </FormGroup>
          </form>
          <div className="p-h-xl" />
          <h2 className="m-b">Organization Avatar</h2>
          <BrowserSupportWrapper>
            <OrganizationAvatar organization={this.props.provider} />
          </BrowserSupportWrapper>
          <Modal show={this.state.showSuccessModal} animation={false}>
            <Modal.Body>
              <h1 className="p-h-md">
                Your Care Types Updates Have Been Saved.
              </h1>
            </Modal.Body>
            <Modal.Footer>
              <Button 
                bsSize="large"
                bsStyle="primary"
                onClick={this.closeSuccessModal}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }
}

TheBasicsTab.propTypes = {
  provider: React.PropTypes.object.isRequired,
  handleChange: React.PropTypes.func.isRequired,
  updateProvider:  React.PropTypes.func.isRequired,
};
