import _ from 'lodash';
import utils from '../Misc/Utils';
import React from 'react';
import moment from 'moment';
import inflection from 'inflection';
import PrintReferralModal from './PrintReferralModal';
import SentReferralTabs from '../SentReferralTabs';
import PreviewModal from '../PreviewModal';
import PrintView from '../SentReferralTabs/PrintView';
import Tasks from '../Tasks';
import CountDownClock from './CountDownClock';
import { Modal, Button } from 'react-bootstrap';

export default class ReferralDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      printView: false,
      showPrintModal: false,
      showPrintLinks: false,
      showPreviewModal: (typeof window != 'undefined' && window.location.hash === '#modal-preview')
    }
    this.showPrintModal = this.showPrintModal.bind(this);
    this.closePrintModal = this.closePrintModal.bind(this);
  }

  componentDidMount(){
    if (typeof window != 'undefined' && window.location.hash === '#modal-preview') {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }

  showPrintModal() {
    this.setState({showPrintModal: true});
  }

  closePrintModal() {
    this.setState({showPrintModal: false});
  }

  statusToBsClass = (elemtype, status) => {
    const base = [elemtype]
    var color
    switch(status){
    case 'i_err':
      color = elemtype + '-danger'
      break;
    case 'new_patient':
      color = elemtype + '-warning'
      break;
    case 'awaiting_provider_responses':
      color = elemtype + '-primary'
      break;
    case 'provider_responses_closed':
      color = elemtype + '-info'
      break;
    case 'awaiting_patient':
      color = elemtype + '-info'
      break;
    case 'provider_chosen':
      color = elemtype + '-primary'
      break;
    case 'handed_to_provider':
      color = elemtype + '-primary'
      break;
    case 'i_error':
      color = elemtype + '-danger'
      break;
    case 'canceled':
      color = elemtype + '-danger'
      break;
    case 'in_process':
      color = elemtype + '-warning'
      break;
    default:
      color = elemtype + '-primary'
    }
    base.push(color)
    return base.join(' ')
  }

  // UNUSED
  providerAvailable(){
    return _.includes(['available', 'chosen'], this.props.sent_referral.status)
  }

  providerNotAvailable(){
    return _.includes(['pending', 'unavailable', 'under_review'], this.props.sent_referral.status)
  }

  providerSelected(){
    return this.props.sent_referral.status === 'chosen'
  }

  patientSelection(){
    return _.includes(['provider_chosen', 'handed_to_provider'], this.props.referral.status)
  }

  canceled() {
    return this.props.referral.status === 'canceled'
  }
  // END UNUSED

  render() {
    return(
      <div>
        <div className="col-lg-9">
          <div className="wrapper wrapper-content animated fadeInUp">
            <div className="ibox">
              <div className="ibox-content">
                {/* Referral Status Indication */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="m-b-md">
                      { !this.props.print_view && 
                        <button className={`btn btn-lg ${!this.props.sent_referral.restricted ? 'btn-primary' : 'btn-white'} pull-right`} onClick={this.showPrintModal} disabled={this.props.sent_referral.restricted} >
                          <i className="fa fa-print" /> Print Referral
                        </button> }
                      <div>
                        <h2 style={{display:'inline-block'}}>
                          { utils.providerTypesDesc[this.props.referral.provider_type]} for {this.props.sent_referral.restricted ? this.props.patient.initials : this.props.patient.full_name }
                        </h2>

                        <dl className="dl-horizontal" style={{display:'inline-block', verticalAlign:'0.5em'}}>
                          <dd style={{marginLeft:'0.5em'}}><span className={this.statusToBsClass('label',this.props.referral.status)}>{ inflection.titleize(this.props.referral.status) }</span></dd>
                        </dl>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <CountDownClock
                          referral={this.props.referral}
                          sent_referral={this.props.sent_referral}
                          tasks={this.props.tasks}
                        />
                      </div>
                    </div>

                  </div>
                </div>


                <div className="row m-t-sm">
                  <div className="col-lg-12">
                    {!this.props.print_view ? (
                      <SentReferralTabs
                        referral={this.props.referral}
                        provider={this.props.provider}
                        provider_delivered_to={this.props.provider_delivered_to}
                        sent_referral={this.props.sent_referral}
                        hospital={this.props.hospital}
                        current_user={this.props.current_user}
                        patient={this.props.patient}
                        tasks={this.props.tasks}
                        restrictions={this.props.sent_referral.restricted}
                        showPrintModal={this.showPrintModal}
                      />
                    ) : (
                      <PrintView
                        referral={this.props.referral}
                        provider={this.props.provider}
                        sent_referral={this.props.sent_referral}
                        hospital={this.props.hospital}
                        current_user={this.props.current_user}
                        patient={this.props.patient}
                        tasks={this.props.tasks}
                        restrictions={this.props.sent_referral.restricted}
                        showPrintModal={this.showPrintModal}
                      />                    
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        { !this.props.print_view &&
          <div className="col-lg-3">
            <Tasks
              sent_referral={this.props.sent_referral}
              hospital={this.props.hospital}
              patient={this.props.patient}
              provider={this.props.provider}
              referral={this.props.referral}
            />
          </div>
        }

        { !this.props.print_view && <Modal 
          show={this.state.showPrintModal} 
          onHide={this.closePrintModal}
          animation={false}
        >
          <PrintReferralModal
            sent_referral={this.props.sent_referral}
            attachments={this.props.referral.attachments}
            closePrintModal={this.closePrintModal}
          />
        </Modal> }

        { !this.props.print_view && <PreviewModal
          showPreviewModal={this.state.showPreviewModal}
          closePreviewModal={this.closePreviewModal}
          sent_referral={this.props.sent_referral}
          referral={this.props.referral}
          provider={this.props.provider}
          hospital={this.props.hospital}
          tasks={this.props.tasks}
        />}
      </div>
    )
  }
}

ReferralDetail.propTypes = {
  sent_referral: React.PropTypes.object.isRequired,
  referral: React.PropTypes.shape({
    attachments: React.PropTypes.array.isRequired
  }),
  provider_delivered_to: React.PropTypes.object,
  provider: React.PropTypes.object.isRequired,
  hospital: React.PropTypes.object.isRequired,
  provider: React.PropTypes.object.isRequired,
  current_user: React.PropTypes.object.isRequired,
  patient: React.PropTypes.shape({
    followers: React.PropTypes.array.isRequired
  }).isRequired,
  tasks: React.PropTypes.array.isRequired
}
