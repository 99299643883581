import React from 'react';
import utils from '../Misc/Utils'
import { FormGroup, FormControl, Button, Modal } from 'react-bootstrap';

export default class TokenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showErrorModal: false, token: ''};
  }

  closeErrorModal = () => {
    this.setState({showErrorModal: false});
  }

  handleChange = (event) => {
    this.setState({token: event.target.value});
  }

  handleSubmit = (event) => {
    event.preventDefault();

    $.post('/api/token_sessions', {token: this.state.token}, (response) => {
      utils.location_replace(`/sent_referrals/${response.id}#modal-preview`);
    }).fail((response) => {
      this.setState({showErrorModal: true, error_message: ((response.responseJSON && response.responseJSON.message) ? response.responseJSON.message : 'Sorry, that passphrase is invalid.') });
    });
  }

  render() {
    return(
      <div className="TokenForm text-center">
        <h1>If you didn't receive an email, enter your passphrase with dashes:</h1>
        <form className="form-inline p-h-md" onSubmit={this.handleSubmit}>
          <FormGroup className="form-group">
            <FormControl onChange={this.handleChange} className="form-control input-lg text-center" style={{width: 980}} type="text" autoFocus />
          </FormGroup>
          <br />
          <Button className="btn btn-white btn-lg m-t-md" type="submit">Continue as Guest</Button>
        </form>
        <Modal show={this.state.showErrorModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">{this.state.error_message}</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={this.closeErrorModal}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

TokenForm.propTypes = {
}
