import React from 'react';
import utils from '../Misc/Utils';
import _ from 'lodash';
import moment from 'moment';
import {Button, Modal } from 'react-bootstrap';
import PreviewBrochure from './PreviewBrochure';

export default class PreviewBrochureModal extends React.Component {
  render() {
    return(
      <Modal 
        show={this.props.showPreviewBrochure} 
        onHide={this.props.closePreviewBrochure}
        dialogClassName="custom-large-modal"
      >
        <Modal.Header closeButton>
          <h1>{this.props.provider.name}</h1>
          <div className='col-md-12'>
            <p>
              {this.props.provider.address}, {this.props.provider.city}, {utils.usStatesMap[this.props.provider.state_id]}, {this.props.provider.zip}
            </p>
            <p>P: {this.props.provider.phone}</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <PreviewBrochure
            provider={this.props.provider}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button 
            bsStyle="primary"
            onClick={this.props.closePreviewBrochure}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

PreviewBrochureModal.propTypes = {
  provider: React.PropTypes.shape({
    attachments: React.PropTypes.array.isRequired,
    reviews: React.PropTypes.array.isRequired
  }),
  showPreviewBrochure: React.PropTypes.bool.isRequired,
  closePreviewBrochure: React.PropTypes.func.isRequired
}
