import React from 'react';
import _ from 'lodash';
import flatten from 'flat';
import moment from 'moment';
import update from 'immutability-helper';
import { Button, Modal, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

export default class Insurance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eligibilities: props.patient.eligibilities,
      errors: {},
      loadingEligibilityModal: true,
      npi: props.provider.npi || '',
      selected_insurance: props.patient.insurances[0],
      showEligibilityModal: false,
      showEligibilityDisabledModal: false,
      showEligibilityErrorModal: false,
      trading_partner: '',
      trading_partner_id: '',
      trading_partners: []
    }
  }

  displayInsurances(){
    return _.orderBy(this.props.patient.insurances, [({ primary }) => primary || '', 'secondary'], ['desc', 'desc']).map((insurance, index) => {
      return (
        <div key={index} className="m-t-sm">
          <h3>{insurance.insurance_company_name}{this.insuranceLabelHelper(insurance)}</h3>
          <div className='m-t-sm'>
            <dt>Plan Name:</dt>
            <dd>{insurance.plan_name ? insurance.plan_name : <span className='font-italic'>No data available</span>}</dd>
          </div>
          <div className='m-t-sm'>
            <dt>Group Name:</dt>
            <dd>{ insurance.group_name ? insurance.group_name : <span className='font-italic'>No data available</span> }</dd>
          </div>
          <div className='m-t-sm'>
            <dt>Group Number:</dt>
            <dd>{insurance.group_number}</dd>
          </div>
          <div className='m-t-sm'>
            <dt>Policy Number:</dt>
            <dd>{ insurance.member_id }</dd>
          </div>
          <div className='m-t-sm'>
            <dt>Effective Date:</dt>
            <dd>{ moment(insurance.effective_date).format('MM/DD/Y') }</dd>
          </div>
          <div className='m-t-sm'>
            <dt>Expiration Date:</dt>
            <dd>{ moment(insurance.expiration_date).format('MM/DD/Y') }</dd>
          </div> 
          <div className='m-t-sm'>
            <dt>Company Address 1:</dt>
            <dd>{ insurance.insurance_company_address_1 }</dd>
          </div>
          <div className='m-t-sm'>
            <dt>Company Address 2:</dt>
            <dd>{ insurance.insurance_company_address_2 ? insurance.insurance_company_address_2 : <span className='font-italic'>No data available</span> }</dd>
          </div>
          <div className='m-t-sm'>
            <dt>Company City:</dt>
            <dd>{ insurance.insurance_company_city }</dd>
          </div>
          <div className='m-t-sm'>
            <dt>Company State:</dt>
            <dd>{ insurance.insurance_company_state }</dd>
          </div>
          <div className='m-t-sm'>
            <dt>Company Zip:</dt>
            <dd>{ insurance.insurance_company_zip }</dd>
          </div>
        </div>
      )
    })
  }

  insuranceLabelHelper(insurance) {
    if (insurance.primary) {
      return <span className="btn btn-white btn-xs m-l-sm">Primary</span>
    } else if (insurance.secondary) {
      return <span className="btn btn-white btn-xs m-l-sm">Secondary</span>
    } else {''}
  }

  handleEligibilityChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleInsuranceChange = (event) => {
    this.setState({
      selected_insurance: _(this.props.patient.insurances).find(i => i.id == event.target.value)
    }, () => this.setState({trading_partner_id: ''}));
  }

  handleTradingPartnerChange = (event) => {
    this.setState({
      trading_partner: event.target.options[event.target.selectedIndex].text,
      trading_partner_id: event.target.value
    });
  }

  orderedTradingPartners = () => {
    let terms = [];

    if (this.state.selected_insurance && this.state.selected_insurance.insurance_company_name) {
      terms = _(this.state.selected_insurance.insurance_company_name.toLowerCase().split(' ')).difference(['and', 'of', 'the', '-']).value();
    }

    return _(this.state.trading_partners).orderBy([p => p.name.toLowerCase().split(' ').some(t => terms.indexOf(t) > -1), 'name'], 'desc').value();
  }

  showEligibilityModal = () => {
    if (this.props.provider.eligibility_enabled) {
      this.setState({showEligibilityModal: true}, () => {
        if (this.state.loadingEligibilityModal) {
          $.get(`/api/eligibilities/trading_partners`, (response) => {
            this.setState({
              loadingEligibilityModal: false,
              trading_partners: response.trading_partners
            });
          });
        }
      });
    } else {
      this.setState({showEligibilityDisabledModal: true});
    }

    typeof ga != 'undefined' && ga('send', 'event', 'Button', 'Click', 'Check Eligibility button');
  }

  submitEligibilityForm = () => {
    this.setState({loadingEligibilityModal: true}, () => {
      $.ajax({
        data: {
          eligibility: {
            insurance_company_name: this.state.selected_insurance.insurance_company_name,
            member_id: this.state.selected_insurance.member_id,
            trading_partner: this.state.trading_partner
          },
          npi: this.state.npi,
          patient_id: this.props.patient.id,
          trading_partner_id: this.state.trading_partner_id
        },
        complete: () => this.setState({loadingEligibilityModal: false}),
        error: (response) => {this.setState({errors: response.responseJSON, showEligibilityErrorModal: true})},
        success: (response) => {
          this.setState({
            eligibilities: this.state.eligibilities.concat([response.eligibility]),
            showEligibilityModal: false
          });
        },
        type: 'POST',
        url: '/api/eligibilities'
      });
    });
  }

  render() {
    return(
      <div className='Insurance'>
        <dl className="dl-horizontal">
          <h2>Eligibility</h2>
          <Button bsSize="large" bsStyle="danger" onClick={this.showEligibilityModal}><i className="fa fa-shield" /> New! Check Eligibility</Button>
          {
            _.orderBy(this.state.eligibilities, 'created_at', 'desc').map(eligibility => {
              return(
                <div key={eligibility.id} className="m-t-md">
                  <h3>Eligibility Results</h3>
                  <i>checked at: {moment(eligibility.created_at).format('MMMM D, YYYY h:mm A')} local time</i>

                  <div className="m-t-md">
                    <h3>{_.get(eligibility.data, 'payer.name')} results from {eligibility.trading_partner}</h3>
                    <div className='m-t-sm'>
                      <dt>Phone:</dt>
                      <dd>{_.get(eligibility.data, 'payer.phone')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Member ID:</dt>
                      <dd>{eligibility.member_id}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Plan Description:</dt>
                      <dd>{_.get(eligibility.data, 'coverage.plan_description')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Plan Begin Date:</dt>
                      <dd>{_.get(eligibility.data, 'coverage.plan_date')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Coverage:</dt>
                      <dd>{_.get(eligibility.data, 'coverage.active') ? 'ACTIVE' : 'INACTIVE'}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Coverage Level:</dt>
                      <dd>{_.get(eligibility.data, 'coverage.level')}</dd>
                    </div>
                  </div>

                  <div className="m-t-md">
                    <h3>Other Payers</h3>
                    {
                      _.get(eligibility.data, 'coverage.other_payers', []).map(other_payer => {
                        return(
                          <div key={other_payer.id} className="m-t-md">
                            <div className='m-t-sm'>
                              <dt>Name:</dt>
                              <dd>{other_payer.name}</dd>
                            </div>
                            <div className='m-t-sm'>
                              <dt>Eligibility date:</dt>
                              <dd>{moment(other_payer.eligibility_date).format('MM.DD.Y h:mma')}</dd>
                            </div>
                            <div className='m-t-sm'>
                              <dt>Insurance type:</dt>
                              <dd>{other_payer.insurance_type}</dd>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>

                  <div className="m-t-md">
                    <h3>Coverage</h3>
                    <div className='m-t-sm'>
                      <dt>Service types:</dt>
                      <dd>{_.get(eligibility.data, 'coverage.service_types', []).join(', ')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Coinsurance:</dt>
                      <dd>{_.map(_.get(eligibility.data, 'coverage.coinsurance', []), 'description').join(', ')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Copay:</dt>
                      <dd>{_.map(_.get(eligibility.data, 'coverage.copay', []), 'description').join(', ')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Deductibles:</dt>
                      <dd>{_.map(_.get(eligibility.data, 'coverage.deductibles', []), 'description').join(', ')}</dd>
                    </div>
                  </div>

                  <div className="m-t-md">
                    <h3>In Network Deductibles</h3>
                    <div className='m-t-sm'>
                      <dt>Applied amount:</dt>
                      <dd>{_.get(eligibility.data, 'summary.deductible.individual.in_network.applied.amount')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Remaining amount:</dt>
                      <dd>{_.get(eligibility.data, 'summary.deductible.individual.in_network.remaining.amount')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Limit:</dt>
                      <dd>{_.get(eligibility.data, 'summary.deductible.individual.in_network.limit.amount')}</dd>
                    </div>
                  </div>

                  <div className="m-t-md">
                    <h3>Out of Network Deductibles</h3>
                    <div className='m-t-sm'>
                      <dt>Applied amount:</dt>
                      <dd>{_.get(eligibility.data, 'summary.deductible.individual.out_of_network.applied.amount')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Remaining amount:</dt>
                      <dd>{_.get(eligibility.data, 'summary.deductible.individual.out_of_network.remaining.amount')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Limit:</dt>
                      <dd>{_.get(eligibility.data, 'summary.deductible.individual.out_of_network.limit.amount')}</dd>
                    </div>
                  </div>

                  <div className="m-t-md">
                    <h3>Out of Pocket In Network</h3>
                    <div className='m-t-sm'>
                      <dt>Applied amount:</dt>
                      <dd>{_.get(eligibility.data, 'summary.out_of_pocket.individual.in_network.applied.amount')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Remaining amount:</dt>
                      <dd>{_.get(eligibility.data, 'summary.out_of_pocket.individual.in_network.remaining.amount')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Limit:</dt>
                      <dd>{_.get(eligibility.data, 'summary.out_of_pocket.individual.in_network.limit.amount')}</dd>
                    </div>
                  </div>

                  <div className="m-t-md">
                    <h3>Out of Pocket Out of Network</h3>
                    <div className='m-t-sm'>
                      <dt>Applied amount:</dt>
                      <dd>{_.get(eligibility.data, 'summary.out_of_pocket.individual.out_of_network.applied.amount')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Remaining amount:</dt>
                      <dd>{_.get(eligibility.data, 'summary.out_of_pocket.individual.out_of_network.remaining.amount')}</dd>
                    </div>
                    <div className='m-t-sm'>
                      <dt>Limit:</dt>
                      <dd>{_.get(eligibility.data, 'summary.out_of_pocket.individual.out_of_network.limit.amount')}</dd>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </dl>
        <dl className="dl-horizontal">
          <h2>Insurance</h2>
          {this.displayInsurances()}
        </dl>
        <h3>Insurance Company Edits:</h3>
        <p>{this.props.referral.insurance_edits ? this.props.referral.insurance_edits : <span className='font-italic'>No data available</span>}</p>
        <dl className="dl-horizontal">
          {
            this.props.referral.rq &&
            <div>
              <div className='m-t-sm'>
                <dt>Program Site:</dt>
                <dd>{this.props.patient.program_site}</dd>
              </div>
              <div className='m-t-sm'>
                <dt>UR Initial Cert Date:</dt>
                <dd>{this.props.patient.ur_initial_cert_date}</dd>
              </div>
              <div className="m-t-sm">
                <dt>Approved Services:</dt>
                <dd>{this.props.patient.approved_services}</dd>
              </div>
              <div className="m-t-sm">
                <dt>UR Deny Date:</dt>
                <dd>{this.props.patient.ur_deny_date}</dd>
              </div>
              <div className="m-t-sm">
                <dt>Denial Reason:</dt>
                <dd>{this.props.patient.denial_reason}</dd>
              </div>
              <div className="m-t-sm">
                <dt>UR Appeal Date:</dt>
                <dd>{this.props.patient.ur_appeal_date}</dd>
              </div>
              <div className="m-t-sm">
                <dt>2nd UR Approval Date:</dt>
                <dd>{this.props.patient.second_ur_approval_date}</dd>
              </div>
              <div className="m-t-sm">
                <dt>2nd UR Deny Date:</dt>
                <dd>{this.props.patient.second_ur_deny_date}</dd>
              </div>
              <div className="m-t-sm">
                <dt>LOA Approval Date:</dt>
                <dd>{this.props.patient.loa_approval_date}</dd>
              </div>
              <div className="m-t-sm">
                <dt>LOA Denial Date:</dt>
                <dd>{this.props.patient.loa_denial_date}</dd>
              </div>
              <div className="m-t-sm">
                <dt>LOA Denial Comment:</dt>
                <dd>{this.props.patient.loa_denial_comment}</dd>
              </div>
            </div>
          }
        </dl>
        <Modal bsSize="lg" show={this.state.showEligibilityModal}>
          <Modal.Body>
            <div className={`ibox-content${this.state.loadingEligibilityModal ? ' sk-loading' : ''}`} style={{border: 'none'}}>
              <div className="sk-spinner sk-spinner-double-bounce">
                <div className="sk-double-bounce1"></div>
                <div className="sk-double-bounce2"></div>
              </div>
              <form className="form-horizontal m-t-md" onSubmit={this.submitEligibilityForm}>
                <FormGroup controlId="provider_npi" className="form-group">
                  <ControlLabel className="col-sm-3 control-label">NPI:</ControlLabel>
                  <div className="col-sm-4">
                    <FormControl type="text" className="form-control" name="npi" autoFocus="true" value={this.state.npi} onChange={this.handleEligibilityChange} />
                  </div>
                </FormGroup>

                <FormGroup controlId="trading_partner_id" className="form-group">
                  <ControlLabel className="col-sm-3 control-label">Member ID:</ControlLabel>
                  <div className="col-sm-8">
                    <FormControl
                      componentClass="select"
                      onChange={this.handleInsuranceChange}
                      className="form-control"
                      name="member_id"
                    >
                      {
                        this.props.patient.insurances.map(insurance => {
                          return <option key={insurance.id} value={insurance.id}>{insurance.insurance_company_name} - {insurance.member_id}</option>;
                        })
                      }
                    </FormControl>
                  </div>
                </FormGroup>

                <FormGroup controlId="trading_partner_id" className="form-group">
                  <ControlLabel className="col-sm-3 control-label">Trading partner:</ControlLabel>
                  <div className="col-sm-8">
                    <FormControl
                      componentClass="select"
                      onChange={this.handleTradingPartnerChange}
                      className="form-control"
                      name="trading_partner_id"
                      value={this.state.trading_partner_id}
                    >
                      <option value="">Select one</option>
                      {this.orderedTradingPartners().map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                    </FormControl>
                  </div>
                </FormGroup>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={() => this.setState({showEligibilityModal: false})}>Cancel</Button>
            <Button bsSize="lg" bsStyle="primary" onClick={this.submitEligibilityForm}>Check Eligibility</Button>
          </Modal.Footer>
        </Modal>
        <Modal bsSize="lg" show={this.state.showEligibilityErrorModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">There was an error with your eligibility check. Please verify the information you are submitting and try again.</h1>
            <ul>{_.values(flatten(this.state.errors)).map((e, i) => <li key={i} className="text-danger">{e}</li>)}</ul>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={() => this.setState({showEligibilityErrorModal: false})}>OK</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showEligibilityDisabledModal} animation={false}>
          <Modal.Body>
            <h1 className="p-h-md">You have not enabled this feature yet. To register, click the Help button and submit a support request with your interest.</h1>
          </Modal.Body>
          <Modal.Footer>
            <Button bsSize="lg" onClick={() => this.setState({showEligibilityDisabledModal: false})}>OK</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

}

Insurance.propTypes = {
  patient: React.PropTypes.shape({
    insurances: React.PropTypes.array.isRequired
  }),
  provider: React.PropTypes.object.isRequired,
  referral: React.PropTypes.object.isRequired
};
