import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import SentReferralRow from './SentReferralRow';
import TablePaginator from '../TablePaginator';
import { SpinnerWave } from '../Misc/Spinners.jsx';

class AllSentReferrals extends React.Component {
  render() {
    let messageGroups = _.groupBy(this.props.messages, message => message.referral_id);
    let taskGroups = _.groupBy(this.props.tasks, task => task.sent_referral_id);
    return(
      <div>

        { !this.props.loading_search_results && this.props.meta.total_pages == 0 ?  <div className="p-xl"> <h1>No results</h1> </div> : '' }

        { !this.props.loading_search_results && <div className="project-list">
          <table className="table table-hover">
            <tbody>
              {
                this.props.sent_referrals.map((sent_referral) => {
                  return (
                    <SentReferralRow
                      key={sent_referral.id}
                      messageGroups={messageGroups}
                      taskGroups={taskGroups}
                      sent_referral={sent_referral}
                    />
                  );
                })
              }
            </tbody>
            <TablePaginator
              current_page={this.props.meta.current_page}
              total_pages={this.props.meta.total_pages}
              getPage={this.props.getSentReferrals}
            />
          </table> 
        </div> }

        { this.props.loading_search_results && <SpinnerWave /> }

        { (!this.props.sent_referrals) ? 
          <div className="sk-spinner sk-spinner-wave">
            <div className="sk-rect1"></div>
            <div className="sk-rect2"></div>
            <div className="sk-rect3"></div>
            <div className="sk-rect4"></div>
            <div className="sk-rect5"></div>
          </div>
          : '' 
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.messages,
    tasks: state.tasks
  }
}

export default connect(mapStateToProps)(AllSentReferrals)

AllSentReferrals.propTypes = {
  messages: React.PropTypes.object.isRequired,
  sent_referrals: React.PropTypes.array.isRequired,
  tasks: React.PropTypes.object.isRequired,
  getSentReferrals: React.PropTypes.func.isRequired,
  handleRecommendedSearchClick: React.PropTypes.func.isRequired
}
